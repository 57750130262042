const B3_MERCADO = {
  NM: {
    id: "NM",
    nivel: "Novo Mercado",
    descricao:
      "O mais alto nível de governança corporativa da B3. Empresas devem seguir as melhores práticas de governança, como Tag Along de 100%, apenas ações ordinárias em circulação e um conselho de administração composto por uma maioria de membros independentes, entre outros requisitos.",
  },
  N1: {
    id: "N1",
    nivel: "Nível 1",
    cs: "Ações ON e PN (conforme legislação)",
    descricao:
      "Voltado para empresas que desejam abrir seu capital, mas não atendem a todos os requisitos de listagem do Novo Mercado. Exige práticas mínimas de governança corporativa, como divulgação de demonstrações financeiras em padrões internacionais e conselho de administração.",
  },
  N2: {
    id: "N2",
    nivel: "Nível 2",
    cs: "Ações ON e PN (com direitos adicionais)",
    descricao:
      "Requer um maior compromisso com a governança corporativa em comparação com o Nível 1. As empresas devem aderir a um conjunto mais abrangente de práticas de governança, incluindo o Tag Along de 100% e um Conselho de Administração com uma quantidade mínima de membros independentes.",
  },
  MA: {
    id: "BM",
    nivel: "Bovespa Mais",
    descricao:
      "Destinado a empresas em crescimento que buscam acesso ao mercado de capitais. Opção para empresas menores que não atendem aos requisitos do Novo Mercado, Nível 2 ou Nível 1, mas desejam ingressar na bolsa de valores.",
  },
  MB: {
    id: "MB",
    nivel: "Balcão Org. Tradicional",
    cs: "Ações ON e PN (conforme legislação)",
    descricao:
      "Destinado a empresas que desejam ingressar na bolsa de valores e possuem características específicas de governança e liquidez. Oferece uma opção de listagem para empresas que não atendem aos requisitos dos outros níveis de mercado.",
  },
  M2: {
    id: "BM2",
    nivel: "Bovespa Mais Nível 2",
    descricao:
      "Similar ao Bovespa Mais, mas exige um compromisso maior com a governança corporativa. Destinado a empresas de pequeno e médio porte.",
  },
  DR1: {
    id: "DR1",
    nivel: "BDR Nivel 1",
    descricao:
      "Este nível de BDR não requer registro do emissor estrangeiro na CVM, a menos que os BDRs sejam lastreados em títulos de dívida de empresas brasileiras. A oferta pública é permitida, mas com esforços restritos. A negociação desses BDRs ocorre em um balcão não organizado ou em um segmento específico de bolsa ou balcão organizado. As informações do emissor devem seguir os regulamentos do país de origem. Este tipo de BDR está aberto a investidores qualificados, funcionários do patrocinador e, sob certas condições, investidores em geral.",
  },
  DR2: {
    id: "DR2",
    nivel: "BDR Nivel 2",
    descricao:
      "Os BDRs deste nível exigem registro do emissor estrangeiro na CVM. A oferta pública também é permitida, mas com esforços restritos. A negociação ocorre em bolsa ou balcão organizado. As informações do emissor devem seguir as regras estabelecidas pela CVM. Este tipo de BDR está aberto a todos os investidores.",
  },
  DR3: {
    id: "DR3",
    nivel: "BDR Nivel 3",
    descricao:
      "Similar aos BDRs de Nível II, este nível também exige registro do emissor estrangeiro na CVM. No entanto, a diferença principal é que a oferta pública ocorre simultaneamente à oferta no exterior dos valores mobiliários. A negociação também ocorre em bolsa ou balcão organizado, e as informações do emissor devem seguir as regras da CVM. Assim como os BDRs de Nível II, este tipo está aberto a todos os investidores.",
  },
  DRW: {
    id: "DRW",
    nivel: "ETF",
    descricao: "",
  },
};

export default B3_MERCADO;
