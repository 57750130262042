import React, { memo, useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { validators } from "investira.sdk";
import {
  Stack,
  Typography,
  Menu,
  List,
  ListItem,
  Box,
  Icon,
  CircularProgress,
  ListItemText,
  ListSubheader,
  ListItemIcon,
} from "investira.react.components";

import services from "../../../services";
import { acBackgroundArquivosChanged } from "../../../store/actions";

const FilesProcessing = (props) => {
  const process = useSelector((state) => state.background.arquivos.process);
  const dispatch = useDispatch();

  const ping = useRef(null);

  const [files, setFiles] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const readFiles = useCallback(() => {
    services.posicoes.arquivos(
      {
        params: {
          sort: "data_envio desc",
          em_processamento: true,
          size: null,
        },
      },
      (rRes) => {
        setFiles(rRes.data);
      }
    );
  }, []);

  // Executa na montagem do componente
  useEffect(() => {
    readFiles();
  }, [readFiles]);

  // Executa ao inciar um novo processamento (ex.: Após enviar um arquivo)
  useEffect(() => {
    if (process) {
      readFiles();
    }
  }, [readFiles, process]);

  // PING (Enquanto não tempos o WS)
  useEffect(() => {
    if (validators.isEmpty(files)) {
      dispatch(acBackgroundArquivosChanged(false));
      setAnchorEl(null);
      clearTimeout(ping.current);
    } else {
      ping.current = setTimeout(() => readFiles(), 3000);
    }
    return () => {
      clearTimeout(ping.current);
    };
  }, [files]);

  if (validators.isEmpty(files)) {
    return null;
  }

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          cursor: "pointer",
          px: 1,
          py: 1,
          "&:hover": {
            backgroundColor: "#2e2e46",
          },
        }}
        onClick={handleClick}
      >
        <Box>
          <CircularProgress size={16} color="primary" />
        </Box>
        <Typography variant="caption" color="text.secondary">
          Processando arquivos...
        </Typography>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List subheader={<ListSubheader>Arquivos</ListSubheader>} dense>
          {files.map((xItem) => {
            return (
              <ListItem
                key={xItem.arquivo}
                secondaryAction={
                  <Box>
                    <CircularProgress size={16} color="primary" />
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="caption" color="text.primary">
                      {xItem.arquivo}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </Stack>
  );
};

FilesProcessing.propTypes = {};

export default FilesProcessing;
