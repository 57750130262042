import React, { memo } from "react";
import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "../../../components/molecules";
// import { WebSocketController } from "../../../controllers";
import {
  Home,
  About,
  Login,
  EmailConfirm,
  EsqueciSenha,
  PageNotFound,
  ChangePassword,
  Ativo,
} from "../../../pagesPublic";
import {
  Notificacoes,
  Dashboard,
  Arquivos,
  Carteiras,
  Reports,
} from "../../../pages";
import EmpresasRoutes from "./EmpresasRoutes";
// import CalculadorasRoutes from "./CalculadorasRoutes";
// import CarteirasAbertasRoutes from "./CarteirasAbertasRoutes";
// import ConfigRoutes from "./ConfigRoutes";
// import SimulacoesRoutes from "./SimulacoesRoutes";
// import AtivosRoutes from "./AtivosRoutes";
// import MercadosRoutes from "./MercadosRoutes";
// import RelatorioProvider from "../../../contexts/RelatorioContext";
// import PrivateSession from "../PrivateSession";
import { PATHS } from "../../../enums";
import PublicSession from "../PublicSession";

const Routes = memo((props) => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          {/* <Route
            exact
            path={`${PATHS.login.path}/:username`}
            element={<Login />}
          /> */}
          {/* <Route exact path={PATHS.login.path} element={<Login />} />
          <Route
            exact
            path={PATHS["email-confirm"].path}
            element={<EmailConfirm />}
          />
          <Route
            exact
            path={PATHS["change-password"].path}
            element={<ChangePassword />}
          />
          <Route
            exact
            path={PATHS["esqueci-senha"].path}
            element={<EsqueciSenha />}
          /> */}
          <Route
            exact
            path={PATHS.home.path}
            element={
              <PublicSession>
                <Home />
              </PublicSession>
            }
          />
          {/* <Route
            exact
            path={PATHS.sobre.path}
            element={
              <PublicSession>
                <About />
              </PublicSession>
            }
          /> */}
          {/* <Route
            exact
            path={PATHS.dashboard.path}
            element={
              <PrivateSession>
                <Dashboard />
              </PrivateSession>
            }
          /> */}
          <Route
            exact
            path={`${PATHS.empresas.path}/*`}
            element={
              <PublicSession>
                <EmpresasRoutes />
              </PublicSession>
            }
          />
          {/* <Route
            exact
            path={`${PATHS.ativos.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          /> */}
          {/* <Route
            exact
            path={`${PATHS.prv.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.fnd.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.tpu.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.tpr.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.tba.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.acn.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.dri.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.crp.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.moe.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.bem.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.cbl.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          />
          <Route
            exact
            path={`${PATHS.etf.path}/*`}
            element={
              <PublicSession>
                <AtivosRoutes />
              </PublicSession>
            }
          /> */}
          {/* <Route
            exact
            path={`${PATHS.calculadoras.path}/*`}
            element={
              <PublicSession>
                <CalculadorasRoutes />
              </PublicSession>
            }
          /> */}
          {/* <Route
            path={`${PATHS.configuracoes.path}/*`}
            element={
              <PrivateSession>
                <ConfigRoutes />
              </PrivateSession>
            }
          />
          <Route
            path={PATHS.notificacoes.path}
            element={
              <PrivateSession>
                <Notificacoes />
              </PrivateSession>
            }
          /> */}
          {/* <Route
            path={PATHS.carteiras.path}
            element={
              <PrivateSession>
                <Carteiras />
              </PrivateSession>
            }
          /> */}
          {/* <Route
            path={PATHS["reports"].path}
            element={
              <PrivateSession>
                <RelatorioProvider>
                  <Reports />
                </RelatorioProvider>
              </PrivateSession>
            }
          /> */}
          {/* <Route
            path={PATHS["carteiras-abertas"].path}
            element={
              <PrivateSession>
                <CarteirasAbertasRoutes />
              </PrivateSession>
            }
          /> */}
          {/* <Route
            path={PATHS.uploads.path}
            element={
              <PrivateSession>
                <Arquivos />
              </PrivateSession>
            }
          /> */}
          {/* <Route
            path={`${PATHS.simulacoes.path}/*`}
            element={
              <PrivateSession>
                <SimulacoesRoutes />
              </PrivateSession>
            }
          /> */}
          <Route
            path="*"
            element={
              <PublicSession>
                <PageNotFound />
              </PublicSession>
            }
          />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
});

Routes.displayName = "Routes";

export default Routes;
