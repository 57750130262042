import React, { memo } from "react";
import { Stack } from "investira.react.components";
import { BaseTemplate } from "../../components/templates";
import { BuscaEmpresa, BuscaGlobal } from "../../components/organisms";

const Dashboard = memo(() => {
  return (
    <BaseTemplate title="Dashboard">
      <Stack alignItems="center" height="100%">
        <BuscaEmpresa />
        {/* <BuscaGlobal /> */}
      </Stack>
    </BaseTemplate>
  );
});

Dashboard.displayName = "Dashboard";

export default Dashboard;
