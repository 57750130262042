import { EMPRESAS_SEARCH, EMPRESAS_LOGOUT } from "../../const/actionsType";
import { dates } from "investira.sdk";

const INITIAL_STATE = {
  search: {
    top_setor_id: null,
    setor_id: null,
    pessoa_tipo_id: 1365,
    pessoas_tipo: {
      IF: "on",
      LST: "on",
      SA: "on",
      GES: "on",
      ADM: "on",
      CUS: "on",
    },
  },
  updatedAt: dates.toDate(),
};

function empresasReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EMPRESAS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case EMPRESAS_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default empresasReducer;
