import React, { memo } from "react";
import { CarteirasTemplate } from "../../components/templates";

import withCarteiras from "../../hoc/withCarteiras";

const Carteiras = memo((props) => {
  return (
    <CarteirasTemplate
      title="Carteiras"
      //subtitle="Carteiras"
      dataGridProps={{
        data: props.carteiras,
        readData: props.readCarteiras,
        isLoading: props.isLoading,
        initialState: { pagination: props.pagination },
        manualPagination: true,
        getRowId: (row) => {
          return row.carteira_id;
        },
        state: {
          isLoading: props.isLoading,
          pagination: props.pagination,
          showProgressBars: props.isRefetching,
          rowSelection: props.rowSelection,
        },
        rowCount: props.rowCount,
        onPaginationChange: props.setPagination,
        onRowSelectionChange: props.setRowSelection,
        onFavClick: props.favCarteira,
        onDeleteClick: props.deleteCarteira,
        onBulkDeleteClick: props.bulkDeleteCarteiras,
      }}
    />
  );
});

Carteiras.displayName = "Carteiras";

export default withCarteiras(Carteiras);
