import React, { memo } from "react";
import { validators } from "investira.sdk";
import {
  Stack,
  Typography,
  Card,
  CardContent,
} from "investira.react.components";
import { InlineInfo } from "../../../../components/molecules";

const CvmSection = memo((props) => {
  const { pessoa } = props;

  if (validators.isEmpty(pessoa?.cvm)) {
    return null;
  }

  return (
    <Stack flexGrow={1} sx={{ minWidth: "288px" }}>
      <Card sx={{ height: "100%" }}>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2} sx={{ py: 2 }}>
            <Stack>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontWeight: 600 }}
                title="Informações da B3"
              >
                CVM
              </Typography>
            </Stack>
            <Stack direction="column" sx={{ gap: 1 }}>
              <InlineInfo
                label="Setor de atividade"
                value={pessoa?.cvm?.setor_atividade}
              />
              <InlineInfo label="Código" value={pessoa?.cvm?.codigo} />
              <InlineInfo
                label="Registro"
                variant="date"
                value={pessoa?.cvm?.registro}
                copy={false}
              />
              <InlineInfo
                label={"Responsável"}
                value={pessoa.cvm?.responsavel?.nome}
              />
              <InlineInfo
                label="E-mail do reponsável "
                value={pessoa.cvm?.responsavel?.email}
                variant="email"
                divider="false"
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
});

export default CvmSection;
