import React, { memo } from "react";
import PropTypes from "prop-types";
import { AberturaDataGrid } from "../../organisms";
import { PageTemplate } from "../";

const CarteirasAbertasTemplate = memo((props) => {
  const { initialState, state, ...restDataGridProps } = props.dataGridProps;

  return (
    <PageTemplate
      title={props.title}
      subtitle={props.subtitle}
      scrollable={false}
      headerRenderActions={props.headerRenderActions}
    >
      <AberturaDataGrid
        {...restDataGridProps}
        data={[]}
        initialState={{
          ...initialState,
          density: "comfortable",
        }}
        state={state}
      />
    </PageTemplate>
  );
});

CarteirasAbertasTemplate.propTypes = {
  data: PropTypes.array,
  isFetching: PropTypes.bool,
  readData: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default CarteirasAbertasTemplate;
