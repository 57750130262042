import React, { memo, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { dates, validators } from "investira.sdk";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  TextField,
  LoadingButton,
  MenuItem,
  ListItem,
  ListItemText,
  InputAdornment,
} from "investira.react.components";

import {
  DatePicker,
  DecimalTextField,
  InputAsync,
  TextFieldCurrency,
} from "../../components/molecules";

import { VALIDATIONS } from "../../const";
import { useSimulacoesContext } from "../../contexts/SimulacoesContext";
import services from "../../services";
import withResponseHandling from "../../hoc/withResponseHandling";

const DialogAddAtivo = memo((props) => {
  const { state, actions } = useSimulacoesContext();
  const params = useParams();

  const mercadosStored = useSelector((store) => store.mercados.data);
  const mercados = useMemo(
    () =>
      Object.values(mercadosStored).filter((pMercado) => {
        return ![
          "CNT",
          "POU",
          "PRV",
          "IMV",
          "VEI",
          "CAR",
          "MOE",
          "MTL",
          "BEM",
          "CBL",
        ].includes(pMercado.id);
      }),
    [mercadosStored]
  );

  const isEdit = !validators.isNull(state.posicaoSelected.posicao_id);
  const isOpen = state.isDialogOpen && state.dialogType === "new";

  const xInitialValues = {
    pu: 1,
    quantidade: 100,
    isin: "",
    mercado: "ACN",
    ativo_id: "",
    taxa: 1,
    vencimento: "",
    data: state.data_posicao || new Date(),
  };

  const formik = useFormik({
    initialValues: xInitialValues,
    //validationSchema: Yup.object().shape({}),
    onSubmit: (pValues, pActions) => {
      actions.addAtivo(
        {
          carteira_id: params.id,
          data: dates.toSqlDate(new Date()),
          ...pValues,
        },
        (rRes) => {
          //pActions.resetForm();
          //actions.handleMergePosicoes(rRes.data);
          actions.handleNewPosicaoCancel();
        },
        null,
        () => {
          pActions.setSubmitting(false);
        }
      );
    },
  });

  // const xDefaultValue = useMemo(
  //   () => ({ isin: formik.values.isin }),
  //   [formik.values.isin]
  // );

  const handleChange = (pFieldName, pFieldValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  const handleNext = (pDate) => {
    actions.handleSetDataPosicao(pDate);
  };

  const handleAsyncChange = (pValues) => {
    const { ativo, ativo_id, ativo_tipo_id, isin, mercado, ticker } = pValues;
    formik.setValues({
      ...formik.values,
      ativo,
      ifeed_ativo_id: ativo_id,
      ativo_tipo_id,
      isin,
      mercado,
      ticker,
    });
  };

  const handleClose = () => {
    actions.handleNewPosicaoCancel();
  };

  function renderOption(pProps, pOption, pState, pOwnerState) {
    if (validators.isNull(pOption)) {
      return;
    }

    return (
      <ListItem {...pProps} key={pOption["ativo_id"]}>
        <ListItemText
          primary={pOption["ativo"]}
          secondary={pOption["ativo_seuid"]}
        />
      </ListItem>
    );
  }

  function hasTaxa(pMercado) {
    return [""].includes(pMercado);
  }

  function hasVencimento(pMercado) {
    return ["TPR", "TBA"].includes(pMercado);
  }

  useEffect(() => {
    // EDIT
    if (isEdit) {
      formik.setValues(state.posicaoSelected);
    } else {
      formik.setValues(xInitialValues);
    }
  }, [isOpen, state.posicaoSelected]);

  return (
    <Dialog open={isOpen} maxWidth="sm">
      <DialogTitle>{isEdit ? "Editar Ativo" : "Adicionar Ativo"}</DialogTitle>
      {validators.isEmpty(state.data_posicao) ? (
        <DialogContent>
          <Stack sx={{ marginBottom: 2, py: 1 }} spacing={2}>
            <Stack flex="1">
              <DatePicker
                label="Data Posição"
                name="data"
                onChange={(pValue) => {
                  handleChange("data", pValue);
                }}
                value={formik.values.data}
                error={Boolean(formik.errors.data)}
                helperText={formik.errors.data}
                disabled={formik.isSubmitting}
                fullWidth
              />
            </Stack>
            <Stack flex="1">
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={() => handleNext(formik.values.data)}
              >
                Continuar
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      ) : (
        <DialogContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack sx={{ marginBottom: 2, py: 1 }} spacing={2}>
              <Stack direction="row" spacing={2}>
                <Stack flexGrow={1}>
                  <TextField
                    label="Mercado"
                    id="mercado"
                    name="mercado"
                    variant="outlined"
                    select
                    value={formik.values.mercado}
                    onChange={formik.handleChange}
                  >
                    {mercados.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} flex="1">
                <Stack flex="1">
                  <InputAsync
                    label="Ativo"
                    name="ativo_id"
                    id="ativo_id"
                    messageError="Falha na busca de ativos"
                    //defaultValue={xDefaultValue}
                    service={services.ativos.list}
                    serviceParams={{
                      sort: "isin desc",
                      mercado: formik.values.mercado,
                    }}
                    renderOptionKey="ativo_id"
                    onChange={(_e, pValues) => {
                      handleAsyncChange(pValues);
                    }}
                    getOptionKey={(pOption) => {
                      return pOption["ativo_id"];
                    }}
                    renderOption={renderOption}
                    getOptionLabel={(option) => {
                      return !validators.isNull(option?.ativo)
                        ? option.ativo
                        : "";
                    }}
                    disabled={formik.isSubmitting}
                  />
                </Stack>
              </Stack>

              <Stack flex="1" direction="row" spacing={2}>
                <Stack flex="1">
                  <TextField
                    id="quantidade"
                    label="Quantidade"
                    name="quantidade"
                    type="text"
                    value={formik.values.quantidade}
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.quantidade)}
                    helperText={formik.errors.quantidade}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      pattern: "[0-9]*",
                      decimal: 2,
                      formNoValidate: "formnovalidate",
                      maxLength: 9,
                    }}
                  />
                </Stack>

                <Stack flex="1">
                  <TextFieldCurrency
                    id="pu"
                    label="PU"
                    name="pu"
                    value={formik.values.pu}
                    onChange={(_, pValue) => handleChange("pu", pValue)}
                    error={Boolean(formik.errors.pu)}
                    helperText={formik.errors.pu}
                    inputProps={{
                      decimal: 6,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Stack>
              </Stack>

              <Stack flex="1" direction="row" spacing={2}>
                {hasTaxa(formik.values.mercado) && (
                  <Stack flex="1">
                    <DecimalTextField
                      id="taxa"
                      name="taxa"
                      label="Taxa"
                      variant="outlined"
                      value={formik.values.taxa}
                      placeholder={"100,00"}
                      onChange={(_, pValue) => handleChange("taxa", pValue)}
                      allowNegative={false}
                      decimal={2}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      error={Boolean(formik.errors.percentual_pos)}
                      helperText={formik.errors.percentual_pos}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </Stack>
                )}
                {hasVencimento(formik.values.mercado) && (
                  <Stack flex="1">
                    <DatePicker
                      label="Vencimento"
                      name="vencimento"
                      onChange={(pValue) => {
                        handleChange("vencimento", pValue);
                      }}
                      value={formik.values.vencimento}
                      error={Boolean(formik.errors.vencimento)}
                      helperText={formik.errors.vencimento}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </Stack>
                )}
              </Stack>

              <Stack
                alignItems="center"
                justifyContent="flex-end"
                direction="row"
                spacing={2}
              >
                <Button
                  variant="outlined"
                  type="button"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || formik.initialValid}
                >
                  {isEdit ? "Alterar" : "Adicionar"}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
});

export default withResponseHandling(DialogAddAtivo);
