import React, { memo } from "react";
import PropTypes from "prop-types";
import { formats, validators } from "investira.sdk";
import {
  Stack,
  Typography,
  Skeleton,
  Alert,
  Divider,
} from "investira.react.components";
import { useCalculadoraVariacaoContext } from "../../../../contexts/CalculadoraVariacaoContext";

const FatorResult = memo((props) => {
  const { state } = useCalculadoraVariacaoContext();

  return (
    <Stack
      sx={{
        my: 2,
        px: 3,
        display: "grid",
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(5, 1fr)",
        },
        gap: "8px 8px",
      }}
    >
      {!validators.isEmpty(props.error) ? (
        <Stack flexGrow={1}>
          <Alert severity="warning">{props.error.description}</Alert>
        </Stack>
      ) : (
        <>
          <Stack flexGrow={1}>
            <Typography variant="caption">Fator de Variação</Typography>
            {props.result.fator ? (
              <Typography variant="h6">
                {formats.formatNumber(props.result.fator, 15)}
              </Typography>
            ) : (
              <Skeleton
                variant="text"
                width="100%"
                height={32}
                animation="wave"
              />
            )}
          </Stack>
          {/* <Divider
            sx={{ display: "none", xs: { display: "flex" } }}
            orientation="vertical"
            flexItem
          /> */}
          <Stack flexGrow={1}>
            <Typography variant="caption">Variação Percentual</Typography>
            {props.result.fator ? (
              <Typography variant="h6">
                {formats.formatNumber((props.result.fator - 1) * 100, 6)}%
              </Typography>
            ) : (
              <Skeleton
                variant="text"
                width="100%"
                height={32}
                animation="wave"
              />
            )}
          </Stack>

          {/* <Divider
            sx={{ display: "none", xs: { display: "flex" } }}
            orientation="vertical"
            flexItem
          /> */}
          <Stack flexGrow={1}>
            <Typography variant="caption">Correção</Typography>
            {props.result.fator &&
            !validators.isNull(props.payload.valor_correcao) ? (
              <Typography variant="h6">
                {formats.formatNumber(
                  props.payload.valor_correcao * props.result.fator,
                  2,
                  true,
                  true
                )}
              </Typography>
            ) : (
              <Skeleton
                variant="text"
                width="100%"
                height={32}
                animation="wave"
              />
            )}
          </Stack>

          {state.indicador_tipo === props.indicadorTipo.C.id && (
            <>
              {/* <Divider
                sx={{ display: "none", xs: { display: "flex" } }}
                orientation="vertical"
                flexItem
              /> */}
              <Stack flexGrow={1}>
                <Typography variant="caption">Fator de Variação Pós</Typography>
                {props.result.fator_pos ? (
                  <Typography variant="h6">
                    {formats.formatNumber(props.result.fator_pos, 15)}
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={32}
                    animation="wave"
                  />
                )}
              </Stack>
              <Stack flexGrow={1}>
                <Typography variant="caption">Fator de Variação Pré</Typography>
                {props.result.fator_pre ? (
                  <Typography variant="h6">
                    {formats.formatNumber(props.result.fator_pre, 15)}
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={32}
                    animation="wave"
                  />
                )}
              </Stack>
            </>
          )}

          {state.indicador_tipo === props.indicadorTipo.M.id && (
            <>
              {/* <Divider
                sx={{ display: "none", xs: { display: "flex" } }}
                orientation="vertical"
                flexItem
              /> */}
              <Stack flexGrow={1}>
                <Typography variant="caption">Cotação Início</Typography>
                {props.result.cotacao_inicio ? (
                  <Typography variant="h6">
                    R$ {formats.formatNumber(props.result.cotacao_inicio, 4)}
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={32}
                    animation="wave"
                  />
                )}
              </Stack>
              <Stack flexGrow={1}>
                <Typography variant="caption">Cotação Fim</Typography>
                {props.result.cotacao_fim ? (
                  <Typography variant="h6">
                    R$ {formats.formatNumber(props.result.cotacao_fim, 4)}
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={32}
                    animation="wave"
                  />
                )}
              </Stack>
            </>
          )}
        </>
      )}
    </Stack>
  );
});

FatorResult.propTypes = {
  error: PropTypes.bool,
  result: PropTypes.object,
  indicadorTipo: PropTypes.object,
};

FatorResult.displayName = "FatorResult";

export default FatorResult;
