import React, { memo } from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  Icon,
  Chip,
  Divider,
} from "investira.react.components";
import { PessoaImage, MutedCopy } from "../../../components/molecules";
import { useAtivosContext } from "../../../contexts/AtivosContext";
import { ATIVO_TIPO } from "@investirapri/financa.js/lib/enums";
import { displays } from "investira.react.lib";
import { formats } from "investira.sdk";

const AtivoHeader = memo((props) => {
  const { preciseOption = false, compare = false } = props;

  const { state, actions } = useAtivosContext();
  console.log(state.ativo);
  const {
    logo_pessoa_id,
    ticker,
    ativo,
    ativo_tipo_id,
    emissor,
    logo_ok,
    mercado,
    isin,
    ativo_seuid,
    vencimento,
    serie,
  } = state.ativo;

  const { setor, cnpj } = state.pessoa;

  const handleChange = (event) => {
    actions.handleShowPreciseChange(event.target.checked);
  };

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 1 }}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Stack>
            <PessoaImage
              logoPessoaId={logo_pessoa_id}
              alt={ativo}
              hasLogo={logo_ok}
              mercado={mercado}
              size={50}
            />
          </Stack>
          <Stack>
            <Typography variant="h5" color="textPrimary">
              {ticker || ativo_seuid || ativo}
            </Typography>
            <Typography variant="caption" color="textPrimary">
              {emissor?.pessoa_fantasia || ativo}
            </Typography>
          </Stack>
        </Stack>

        {preciseOption && (
          <Stack>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={
                <Switch checked={state.showPrecise} onChange={handleChange} />
              }
              label={
                <Typography variant="body2" color="textPrimary">
                  Valores simples
                </Typography>
              }
            />
          </Stack>
        )}
      </Stack>
      {compare && (
        <>
          <Stack py={2}>
            <Divider />
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="flex-end"
            //sx={{ py: 1 }}
          >
            <Stack>
              <Button
                color="primary"
                variant="contained"
                sx={{ px: 4 }}
                endIcon={<Icon iconName="compare" size={21} />}
                //disabled
              >
                Comparar
              </Button>
            </Stack>
          </Stack>
        </>
      )}
      <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
        {ativo_tipo_id && (
          <MutedCopy value={ATIVO_TIPO[ativo_tipo_id].descricao}>
            <Chip
              label={ATIVO_TIPO[ativo_tipo_id].descricao}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ px: 1.5 }}
            />
          </MutedCopy>
        )}

        {setor?.top_setor && (
          <MutedCopy value={isin}>
            <Chip
              label={isin}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ px: 1.5 }}
            />
          </MutedCopy>
        )}

        {cnpj && (
          <MutedCopy value={cnpj}>
            <Chip
              label={displays.cnpj(cnpj)}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ px: 1.5 }}
            />
          </MutedCopy>
        )}

        {vencimento && (
          <MutedCopy value={formats.formatDateCustom(vencimento, "DD/MM/YYYY")}>
            <Chip
              label={`Vencimento: ${formats.formatDateCustom(
                vencimento,
                "DD/MM/YYYY"
              )}`}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ px: 1.5 }}
            />
          </MutedCopy>
        )}

        {serie && (
          <MutedCopy value={serie}>
            <Chip
              label={`Série: ${serie}`}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ px: 1.5 }}
            />
          </MutedCopy>
        )}
      </Stack>
    </Stack>
  );
});

AtivoHeader.displayName = "AtivoHeader";

export default AtivoHeader;
