import {
  INDICADORES_CHANGED,
  INDICADORES_LOGOUT,
} from "../../const/actionsType";

export function acIndicadoresChanged(pData) {
  const xFilters = ["M", "I", "J", "C"];
  const xData = {};

  xFilters.forEach((xFilter) => {
    xData[xFilter] = pData.filter((x) => x.indicador_tipo_id === xFilter);
  });

  return {
    type: INDICADORES_CHANGED,
    payload: xData,
  };
}

export function acIndicadoresLogout() {
  return {
    type: INDICADORES_LOGOUT,
  };
}
