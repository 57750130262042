import {
  ENTIDADES_LIST,
  ENTIDADES_UPDATE,
  ENTIDADES_DELETE,
  ENTIDADES_USUARIO_ADD,
  ENTIDADES_USUARIO_LIST,
  ENTIDADES_USUARIO_REMOVE,
  ENTIDADES_USUARIO_MODIFY,
  ENTIDADES_IFEED_LOGO,
} from "../const/servicesURI";
import { httpRequests } from "investira.sdk";
import requestService from "./requestService";
import utils from "../utils";

export const cancel = {
  list: null,
  usuarios: null,
  merge: null,
  ifeedLogo: null,
};

export const list = (pProps, pResolve, pReject, pFinally) => {
  if (cancel.list) {
    cancel.list.cancel();
  }

  cancel.list = httpRequests.cancelToken();

  const xProps = {
    ...pProps,
    cancelToken: cancel.list.token,
  };

  return requestService(xProps, ENTIDADES_LIST, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidades List");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const usuarios = (pProps, pResolve, pReject, pFinally) => {
  if (cancel.usuarios) {
    cancel.usuarios.cancel();
  }

  cancel.usuarios = httpRequests.cancelToken();

  const xProps = {
    ...pProps,
    cancelToken: cancel.usuarios.token,
  };

  return requestService(xProps, ENTIDADES_USUARIO_LIST, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidade Usuário List");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const merge = (pProps = {}, pResolve, pReject, pFinally) => {
  if (cancel.merge) {
    cancel.merge.cancel();
  }

  cancel.merge = httpRequests.cancelToken();

  const {
    entidade_id,
    ifeed_pessoa_id,
    entidade,
    cnpj,
    image,
    method = "post",
    onUploadProgress,
  } = pProps;
  const xFormData = new FormData();

  xFormData.append(`entidade`, entidade);
  xFormData.append(`cnpj`, cnpj);
  ifeed_pessoa_id && xFormData.append(`ifeed_pessoa_id`, ifeed_pessoa_id);
  entidade_id && xFormData.append(`entidade_id`, entidade_id);
  image && xFormData.append(`image`, image);

  const xProps = {
    headers: { "Content-Type": "multipart/form-data" },
    data: xFormData,
    method,
    onUploadProgress,
    cancelToken: cancel.merge.token,
  };

  return requestService(xProps, ENTIDADES_UPDATE, 3, 2400)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Upload");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const ifeedLogo = (pProps, pResolve, pReject, pFinally) => {
  if (cancel.ifeedLogo) {
    cancel.ifeedLogo.cancel();
  }

  cancel.ifeedLogo = httpRequests.cancelToken();

  const xProps = {
    ...pProps,
    header: { "Access-Control-Allow-Origin": "*", "Content-Type": "image/png" },
    cancelToken: cancel.ifeedLogo.token,
  };

  return requestService(xProps, ENTIDADES_IFEED_LOGO, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidade iFeed Pessoa Logo");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const remove = (pId, pResolve, pReject, pFinally) => {
  const xProps = {
    method: "DELETE",
  };

  return requestService(xProps, `${ENTIDADES_DELETE}${pId}`, 3)
    .then((rRes) => {
      pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidade Delete");
      pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

// entidades/usuario
export const usuario = {
  remove: (pProps, pResolve, pReject, pFinally) => {
    const xProps = {
      data: { ...pProps },
      method: "DELETE",
    };

    return requestService(xProps, ENTIDADES_USUARIO_REMOVE, 3)
      .then((rRes) => {
        return pResolve(rRes);
      })
      .catch((rErr) => {
        utils.request.tracer(rErr, "Error Service Entidade Usuário List");
        return pReject(rErr);
      })
      .finally(() => {
        pFinally && pFinally();
      });
  },
  list: (pParams, pResolve, pReject, pFinally) => {
    const xProps = {
      params: {
        usuario_id: "*",
      },
    };

    return requestService(xProps, ENTIDADES_USUARIO_LIST, 3)
      .then((rRes) => {
        return pResolve(rRes);
      })
      .catch((rErr) => {
        utils.request.tracer(rErr, "Error Service Entidade Usuário List");
        return pReject(rErr);
      })
      .finally(() => {
        pFinally && pFinally();
      });
  },
  modify: (pProps, pResolve, pReject, pFinally) => {
    const xProps = {
      data: { ...pProps },
      method: "DELETE",
    };

    return requestService(xProps, ENTIDADES_USUARIO_MODIFY, 3)
      .then((rRes) => {
        return pResolve(rRes);
      })
      .catch((rErr) => {
        utils.request.tracer(rErr, "Error Service Entidade Usuário Modify");
        return pReject(rErr);
      })
      .finally(() => {
        pFinally && pFinally();
      });
  },
  entidades: (pParams, pResolve, pReject, pFinally) => {
    const xProps = {
      params: {
        entidade_id: "*",
      },
    };

    return requestService(xProps, ENTIDADES_USUARIO_LIST, 3)
      .then((rRes) => {
        return pResolve(rRes);
      })
      .catch((rErr) => {
        utils.request.tracer(rErr, "Error Service Entidade Usuário List");
        return pReject(rErr);
      })
      .finally(() => {
        pFinally && pFinally();
      });
  },
};

const entidades = { list, usuarios, remove, usuario, merge, ifeedLogo, cancel };

export default entidades;
