import React, { memo } from "react";
import PropTypes from "prop-types";
import { validators } from "investira.sdk";
import Row from "./Row";

const Rows = memo((props) => {
  const { balanco, variacao, periodMarks, decimal, escala, level } = props;

  function filterRows(pBalanco, pPeriodMarks) {
    if (validators.isEmpty(pBalanco)) {
      return [];
    }

    // Verifica contas de nivel 1 com saldo no periodo
    let xLevelOneSaldos = new Set();
    pPeriodMarks.forEach((xElement) => {
      Object.values(xElement.saldos).forEach((xSaldo) => {
        if (xSaldo.nivel === 1) {
          xLevelOneSaldos.add(xSaldo.conta_key);
        }
      });
    });

    xLevelOneSaldos = Array.from(xLevelOneSaldos);

    // Cria um novo objeto somente com as contas com saldo no periodo
    let xRows = {};
    for (const xKey in pBalanco) {
      if (
        Object.prototype.hasOwnProperty.call(pBalanco, xKey) &&
        xLevelOneSaldos.includes(xKey)
      ) {
        xRows[xKey] = pBalanco[xKey];
      }
    }

    return xRows;
  }

  function isBreak(pData, pIndex) {
    let xIsBreakConta = false;
    const xBalanco = Object.values(pData);

    if (pIndex > 0) {
      let xPrevConta = String(xBalanco[pIndex - 1].conta).charAt(0);
      let xConta = String(xBalanco[pIndex].conta).charAt(0);

      xIsBreakConta = xConta !== xPrevConta;
      return xIsBreakConta;
    }
  }

  function renderRows(pData) {
    const row = (pData) => {
      return Object.entries(pData).map(([xKey, xValue], xIndex) => {
        return (
          <Row
            id={xKey}
            key={xKey}
            isBreak={isBreak(pData, xIndex)}
            conta={xValue?.conta}
            nivel={xValue?.nivel}
            descricao={xValue?.descricao}
            filhos={xValue?.filhos}
            periodMarks={periodMarks}
            variacao={variacao}
            decimal={decimal}
            escala={escala}
            level={level}
          />
        );
      });
    };

    return !validators.isEmpty(pData) && row(pData);
  }

  if (level === 0) {
    const xData = filterRows(balanco, periodMarks);
    return renderRows(xData);
  }

  return renderRows(balanco);
});

Rows.displayName = "Rows";

Rows.propTypes = {};

export default Rows;
