import React, { memo, useState, useEffect } from "react";
import { validateCNPJ } from "validations-br";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validators } from "investira.sdk";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  InputAdornment,
  TextField,
  Icon,
  LoadingButton,
} from "investira.react.components";
import { VALIDATIONS } from "../../const";
import { useEntidadesContext } from "../../contexts/EntidadesContext";
import services from "../../services";
import withResponseHandling from "../../hoc/withResponseHandling";
import withMessage from "../../hoc/withMessage";
import EntidadeLogo from "./EntidadeLogo";

const VALIDATION_SCHEMA = Yup.object().shape({
  entidade: Yup.string()
    .max(128, "Máximo de 128 caracteres")
    .required(VALIDATIONS.REQUIRED),
  cnpj: Yup.string()
    .test("is-cnpj", "CNPJ não é válido", (value) => validateCNPJ(value))
    .required(VALIDATIONS.REQUIRED),
});

const EntidadeAddDialog = memo((props) => {
  const { state, actions } = useEntidadesContext();

  const [pessoa, setPessoa] = useState({});

  const isEdit = !validators.isEmpty(state.entidade);

  const xInitialValues = {
    entidade: "",
    entidade_id: state.entidade.entidade_id,
    cnpj: "",
    image: "",
    ifeed_pessoa_id: "",
  };

  const formik = useFormik({
    initialValues: xInitialValues,
    validationSchema: VALIDATION_SCHEMA,
    validateOnMount: false,
    onSubmit: (pValues, pActions) => {
      const xCnpj = formik.values.cnpj.replace(/[./-]/g, "");
      const xValues = { ...pValues, cnpj: xCnpj };
      actions.handleEntidadeMerge(
        xValues,
        () => {
          handleCancel();
        },
        isEdit
      );
    },
  });

  function setEntidadeField(pPessoa) {
    let xPessoa = pPessoa.pessoa_fantasia || pPessoa.pessoa || "";

    const xIndexHifen = xPessoa.indexOf("-");

    if (xIndexHifen !== -1) {
      xPessoa = xPessoa.substring(0, xIndexHifen).trim();
    }

    formik.setFieldValue("entidade", xPessoa, true);
  }

  function setImageField(pFile) {
    formik.setFieldValue("image", pFile, true);
  }

  function handleCancel() {
    services.entidades.cancel.merge?.cancel();
    formik.setSubmitting(false);
    formik.resetForm();
    setPessoa({});
    actions.handleNewEntidadeCancel();
  }

  function cnpjMask(pValue) {
    return (
      pValue &&
      pValue
        .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
        .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2") // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1")
    ); // captura os dois últimos 2 números, com um - antes dos dois números;
  }

  useEffect(() => {
    if (state.isDialogOpen && isEdit) {
      const { cnpj, entidade, entidade_id } = state.entidade;
      formik.setValues({
        cnpj: cnpj || "",
        entidade: entidade || "",
        entidade_id: entidade_id || "",
      });
    }
  }, [state.isDialogOpen, state.entidade, isEdit]);

  useEffect(() => {
    const xCnpj = formik.values.cnpj.replace(/[./-]/g, "");

    if (!validators.isEmpty(xCnpj) && !isEdit) {
      services.pessoas.list(
        { cnpj: xCnpj },
        (rRes) => {
          if (!validators.isEmpty(rRes.data)) {
            const xPessoa = rRes.data[0];
            setPessoa(rRes.data[0]);
            setEntidadeField(xPessoa);
          } else {
            setPessoa({});
            setEntidadeField({});
          }
        },
        (_rErr) => {
          props.onMessageError("Falha na requisição.");
        }
      );
    }
  }, [formik.values.cnpj]);

  useEffect(() => {
    if (!validators.isEmpty(pessoa.pessoa_id)) {
      formik.setFieldValue("ifeed_pessoa_id", pessoa.pessoa_id, true);
    }
  }, [pessoa]);

  return (
    <Dialog open={state.isDialogOpen} fullWidth maxWidth="sm">
      <DialogTitle>{isEdit ? "Editar Entidade" : "Nova Entidade"}</DialogTitle>

      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Stack sx={{ marginBottom: 2, py: 1 }} spacing={2}>
            <Stack direction="row" spacing={2}>
              <EntidadeLogo
                pessoa={pessoa}
                entidade={state.entidade}
                onChange={setImageField}
              />

              <Stack flexGrow={1} spacing={2}>
                <Stack flexGrow={1}>
                  <TextField
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    placeholder="XX.XXX.XXX/0001-XX"
                    value={cnpjMask(formik.values.cnpj)}
                    onChange={formik.handleChange}
                    fullWidth
                    error={Boolean(formik.errors.cnpj)}
                    helperText={formik.errors.cnpj}
                    disabled={formik.isSubmitting}
                  />
                </Stack>

                <Stack flexGrow={1}>
                  <TextField
                    id="entidade"
                    name="entidade"
                    label="Entidade"
                    variant="outlined"
                    value={formik.values.entidade}
                    onChange={formik.handleChange}
                    fullWidth
                    error={Boolean(formik.errors.entidade)}
                    helperText={formik.errors.entidade}
                    inputProps={{ maxLength: 128 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon iconName="parthenon" size={21} />
                        </InputAdornment>
                      ),
                    }}
                    disabled={formik.isSubmitting}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              alignItems="center"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
            >
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Salvar
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
});

EntidadeAddDialog.displayName = "EntidadeAddDialog";

export default withResponseHandling(withMessage(EntidadeAddDialog));
