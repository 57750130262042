import { ATIVOS_SEARCH, ATIVOS_LOGOUT } from "../../const/actionsType";
import { dates } from "investira.sdk";

const INITIAL_STATE = {
  search: {
    mercado: null,
  },
  updatedAt: dates.toDate(),
};

function ativosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ATIVOS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case ATIVOS_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default ativosReducer;
