import { componentsTheme as base } from "./baseLightTheme";

const highchartsTheme = {
  colors: [
    "#2ec4b6",
    "#ff9f1c",
    "#c62828",
    "#1976d2",
    "#fbc02d",
    "#DDDF00",
    "#24CBE5",
    "#64E572",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
  chart: {
    backgroundColor: "transparent",
  },
  title: {
    style: {
      color: base.colors.text.primary,
      font: 'bold 16px "Montserrat", sans-serif',
    },
  },
  subtitle: {
    style: {
      color: base.colors.text.primary,
      font: 'bold 16px "Montserrat", sans-serif',
    },
  },
  xAxis: {
    gridLineColor: base.colors.background.darkness,
    gridLineDashStyle: "ShortDot",
    labels: {
      style: {
        color: base.colors.text.primary,
      },
    },
    lineColor: base.colors.background.darkness,
    minorGridLineColor: base.colors.background.darkness,
    minorGridLineDashStyle: "ShortDot",
    tickColor: base.colors.background.darkness,
    title: {
      style: {
        color: base.colors.text.primary,
      },
    },
  },
  yAxis: {
    gridLineColor: base.colors.background.darkness,
    gridLineDashStyle: "ShortDot",
    labels: {
      style: {
        color: base.colors.text.primary,
      },
    },
    lineColor: base.colors.background.darkness,
    minorGridLineColor: base.colors.background.darkness,
    minorGridLineDashStyle: "ShortDot",
    tickColor: base.colors.background.darkness,
    tickWidth: 1,
    title: {
      style: {
        color: base.colors.text.primary,
      },
    },
  },
  legend: {
    itemStyle: {
      color: "#fff",
      fontFamily: '"Montserrat", sans-serif',
    },
    itemHoverStyle: {
      color: "#fff",
    },
  },
  plotOptions: {
    bar: {
      borderWidth: 0,
    },
    column: {
      borderWidth: 0,
    },
  },
};

export default highchartsTheme;
