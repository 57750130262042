import React, { memo, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  InputCurrency,
  InputAdornment,
} from "investira.react.components";

const TextFieldCurrency = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  const inputRef = useRef(null);

  function setCursorToEnd(pInput) {
    setTimeout(
      () => pInput.setSelectionRange(pInput.value.length, pInput.value.length),
      0
    );
  }

  const handleFocus = (e) => {
    setCursorToEnd(inputRef.current);
    props.onFocus && props.onFocus(e);
  };

  const handleChange = (e, value) => {
    setInputValue(e.target.value);
    props.onChange && props.onChange(e, value);
  };

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  return (
    <TextField
      inputRef={inputRef}
      {...props}
      value={inputValue}
      disabled={props.disabled}
      onChange={handleChange}
      onFocus={handleFocus}
      inputProps={{
        decimal: 2,
        formNoValidate: "formnovalidate",
        maxLength: 15,
        ...props.inputProps,
        pattern: "[0-9]*",
      }}
      fullWidth
      InputProps={{
        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        ...props.InputProps,
        inputComponent: InputCurrency,
      }}
    />
  );
});

TextFieldCurrency.propTypes = {
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};

TextFieldCurrency.defaultName = "TextFieldCurrency";

export default TextFieldCurrency;
