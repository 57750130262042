import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Typography,
  CircularProgress,
  LoadingButton,
  Stack,
  PasswordField,
} from "investira.react.components";
import { MESSAGES, VALIDATIONS, GENERIC } from "../../const";
import services from "../../services";

import withMessage from "../../hoc/withMessage";
import withResponseHandling from "../../hoc/withResponseHandling";

const ChangePasswordSchema = Yup.object().shape({
  registerPassword: Yup.string()
    .matches(/^(?=.*[a-z])/, `${VALIDATIONS.ATLEAST_LETTER}`)
    .matches(/^(?=.*[A-Z])/, `${VALIDATIONS.ATLEAST_CAPITAL_LETTER}`)
    .matches(/^(?=.*[0-9])/, `${VALIDATIONS.ATLEAST_NUMBER}`)
    .min(6, `${VALIDATIONS.TOO_SHORT}`)
    .required(`${VALIDATIONS.REQUIRED}`),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("registerPassword")], `${VALIDATIONS.PASSWORDS_MUST_MATCH}`)
    .required(`${VALIDATIONS.REQUIRED}`),
});

function ChangePassword(props) {
  const navigate = useNavigate();
  const match = useMatch("/change-password/:code");

  const [validate, setValidate] = useState(false);
  const [payload, setPayload] = useState({});

  const doCodeValidate = useCallback(
    (pCode) => {
      services.auth.passwordValidate(
        {
          code: pCode,
        },
        (rRes) => {
          if (rRes.status !== 200) {
            props.onMessageError(MESSAGES.LOGIN.PASSWORD_RESET_EXPIRED);
            navigate("/login");
          } else {
            setValidate(true);
            setPayload(rRes.data.data.payload);
          }
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
          navigate("/login");
        }
      );
    },
    [navigate, props]
  );

  const doChangePassword = (pValues, pActions) => {
    services.auth.passwordConfirm(
      {
        code: pValues.code,
        data: {
          username: pValues.username,
          password: pValues.registerPassword,
          password_confirm: pValues.confirmPassword,
        },
      },
      (rRes) => {
        if (rRes.status !== 200) {
          props.onMessageError(MESSAGES.GENERIC.ERROR);
        } else {
          props.onMessageSuccess("Senha altera!");
          navigate(`/login/${pValues.username}`);
        }
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      },
      () => {
        pActions.setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    if (match.pattern.path !== "/change-password/:code") {
      navigate("/");
    }

    match.pattern.path === "/change-password/:code" &&
      doCodeValidate(match.params.code);
  }, [doCodeValidate, match.pattern.path, navigate, match.params.code]);

  return (
    <>
      <Stack
        id="validate"
        flexGrow={1}
        px={4}
        py={4}
        justifyContent="center"
        alignItems="center"
      >
        {validate ? (
          <Formik
            initialValues={{
              registerPassword: "",
              confirmPassword: "",
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values, actions) => {
              values.username = payload.username;
              values.code = match.params.code;
              doChangePassword(values, actions);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <Stack alignItems="center">
                  <Stack spacing={2} sx={{ minWidth: "400px" }}>
                    <Stack alignItems="center">
                      <Stack sx={{ width: "180px" }}>
                        <svg
                          id="key"
                          x="0px"
                          y="0px"
                          width="100%"
                          height="100%"
                          viewBox="0 0 16 16"
                        >
                          <path
                            style={{
                              fill: "#00dfa8",
                            }}
                            d="M3.787,12.582v0.814c0,0.293-0.107,0.48-0.347,0.648l-1.373,0.963c-0.241,0.168-0.453,0.203-0.729,0.104
                                                            l-0.766-0.279L3.787,12.582z M14.082,9.018c-1.888,1.322-4.434,0.971-5.929-0.713L0,14.014v-0.814c0-0.293,0.106-0.48,0.347-0.648
                                                            l7.231-5.062C6.487,5.504,7.023,2.975,8.92,1.646c2.033-1.424,4.845-0.928,6.268,1.104C16.611,4.783,16.115,7.594,14.082,9.018z
                                                                M14.368,3.324c-1.106-1.58-3.293-1.967-4.874-0.859C7.912,3.572,7.528,5.758,8.635,7.34c1.106,1.58,3.293,1.967,4.874,0.859
                                                            C15.091,7.092,15.476,4.904,14.368,3.324z"
                          />
                        </svg>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography
                        gutterBottom
                        variant={"h4"}
                        color={"textPrimary"}
                        align={"center"}
                      >
                        {GENERIC.REMEMBER_PASSWORD.NEW}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant={"body2"}
                        align={"center"}
                        color={"textPrimary"}
                      >
                        {`${payload.name}, defina um nova senha de acesso.`}
                      </Typography>
                    </Stack>
                    <Stack
                      component="form"
                      spacing={4}
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <Stack spacing={2}>
                        <Stack>
                          <PasswordField
                            variant="outlined"
                            name={"registerPassword"}
                            label={GENERIC.REMEMBER_PASSWORD.SENHA}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.registerPassword}
                            error={
                              errors.registerPassword &&
                              touched.registerPassword
                                ? true
                                : false
                            }
                            helperText={errors.registerPassword}
                            fullWidth={true}
                            disabled={isSubmitting}
                          />
                        </Stack>
                        <Stack>
                          <PasswordField
                            variant="outlined"
                            name={"confirmPassword"}
                            label={GENERIC.REMEMBER_PASSWORD.CONFIRM}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            error={
                              errors.confirmPassword && touched.confirmPassword
                                ? true
                                : false
                            }
                            helperText={errors.confirmPassword}
                            fullWidth={true}
                            disabled={isSubmitting}
                          />
                        </Stack>
                      </Stack>
                      <Stack>
                        <LoadingButton
                          type={"submit"}
                          size="large"
                          color={"primary"}
                          variant={"contained"}
                          fullWidth
                          loading={isSubmitting}
                          disabled={
                            isSubmitting || errors.confirmPassword
                              ? true
                              : false || values.confirmPassword === ""
                          }
                        >
                          {GENERIC.REMEMBER_PASSWORD.ENVIAR}
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              );
            }}
          </Formik>
        ) : (
          <Stack>
            <CircularProgress size={40} />
          </Stack>
        )}
      </Stack>
    </>
  );
}

ChangePassword.displayName = "ChangePassword";

export default withResponseHandling(withMessage(ChangePassword));
