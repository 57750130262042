import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  IconButton,
  Icon,
  Tooltip,
  ListItemIcon,
  Divider,
} from "investira.react.components";
import { EntidadeAvatar } from "../../../components/molecules";
import { useEntidadesContext } from "../../../contexts/EntidadesContext";
import EntidadeConfirmDelete from "./EntidadeConfirmDelete";

const LEVEL = {
  us: "Membro",
  ad: "Admin",
  sp: "Super User",
};

const EntidadesListItem = memo((props) => {
  const { entidade = {} } = props;

  const navigate = useNavigate();

  const { actions } = useEntidadesContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const open = Boolean(anchorEl);

  const isDefault = entidade.default_entidade_id === entidade.entidade_id;
  const isMember = entidade.perfil === "us";
  const isAdmin = entidade.perfil === "ad";
  const isSuperUser = entidade.perfil === "sp";

  const isDeletable =
    !isDefault && Number(entidade.quantidade_usuarios) === 1 && isAdmin;

  const hasActions = isDefault && isMember;

  function handleMenuOpen(pEvent) {
    setAnchorEl(pEvent.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  const handleEditClick = (pEntidade) => {
    actions.handleEditEntidade(pEntidade);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setOpenDeleteConfirm(true);
  };

  const handleMembersClick = () => {
    handleMenuClose();
    navigate(`/configuracoes/entidades/membros`);
  };

  return (
    <>
      <ListItem
        id={entidade.entidade_id}
        key={entidade.entidade_id}
        sx={{ borderRadius: 2 }}
        secondaryAction={
          !hasActions && (
            <IconButton edge="end" onClick={handleMenuOpen}>
              <Icon iconName="others" />
            </IconButton>
          )
        }
      >
        <ListItemAvatar>
          <EntidadeAvatar
            alt={entidade.entidade}
            entidadeId={entidade.entidade_id}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1">{entidade.entidade}</Typography>

              <Stack direction="row" spacing={1} sx={{ pr: 2 }}>
                {!isMember && (
                  <Tooltip title={"Você é administrador nesta entidade"}>
                    <span>
                      <Chip
                        variant="outlined"
                        size="small"
                        color="secondary"
                        label={LEVEL[entidade.perfil]}
                        icon={<Icon iconName="user_tie" size={14} />}
                        sx={{
                          px: 1,
                          "& svg": {
                            fill: "var(--color-secondary-lightness)",
                          },
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                <Tooltip title={"Quantidade de Membros"}>
                  <span>
                    <Chip
                      sx={{
                        px: 1,
                        "& svg": {
                          fill: "var(--color-secondary-lightness)",
                        },
                      }}
                      size="small"
                      icon={<Icon iconName="users" size={14} />}
                      color="secondary"
                      label={entidade.quantidade_usuarios}
                      variant="outlined"
                    />
                  </span>
                </Tooltip>
                <Tooltip title={"Quantidade de Carteiras"}>
                  <span>
                    <Chip
                      sx={{
                        px: 1,
                        "& svg": {
                          fill: "var(--color-secondary-lightness)",
                        },
                      }}
                      size="small"
                      icon={
                        <Icon iconName="wallet" size={14} color="secondary" />
                      }
                      color="secondary"
                      label={entidade.quantidade_carteiras}
                      variant="outlined"
                    />
                  </span>
                </Tooltip>
              </Stack>
            </Stack>
          }
        />
      </ListItem>
      <Menu
        id={`entidade-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {entidade.default_entidade_id !== entidade.entidade_id && (
          <MenuItem
            onClick={() => {
              actions.handleSetDefaultEntidade(entidade.entidade_id);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Icon iconName="arrow-left-right" size={16} />
            </ListItemIcon>
            <ListItemText>Ativar</ListItemText>
          </MenuItem>
        )}
        {isDefault && (
          <MenuItem onClick={handleMembersClick}>
            <ListItemIcon>
              <Icon iconName="users" size={16} />
            </ListItemIcon>
            <ListItemText>Membros</ListItemText>
          </MenuItem>
        )}
        {(isAdmin || isSuperUser) && isDefault && (
          <MenuItem onClick={() => handleEditClick(entidade)}>
            <ListItemIcon>
              <Icon iconName="edit" size={16} />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </MenuItem>
        )}
        {isDeletable && <Divider />}
        {isDeletable && (
          <MenuItem onClick={handleDeleteClick}>
            <ListItemIcon>
              <Icon iconName="delete" size={16} color="error" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "error" }}>
              Excluir
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <EntidadeConfirmDelete
        open={openDeleteConfirm}
        entidade={entidade}
        onDelete={actions.handleRemoveEntidade}
        onClose={() => setOpenDeleteConfirm(false)}
      />
    </>
  );
});

EntidadesListItem.propTypes = {
  entidades: PropTypes.array,
  onClick: PropTypes.func,
};

export default EntidadesListItem;
