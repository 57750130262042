import React, { memo, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom/";
import { formats, validators, dates, objects } from "investira.sdk";
import services from "../services";

// Context
const AtivosContext = React.createContext();

const TODAY = new Date();
const DATA_INICIO = dates.addYears(dates.toSqlDate(TODAY), -5);
const CURRENT_YEAR = TODAY.getFullYear();
const FIRST_DATE_YEAR = new Date(CURRENT_YEAR, 0, 1);

const initialState = {
  ativo: {},
  pessoa: {},
  cotacao: {
    hoje: {},
    historico: {},
    cotacao_12m: {},
    cotacao_min_12m: null,
    cotacao_max_12m: null,
    variacoes: {},
    rentabilidades: {},
  },
  indicador: {
    current: "IBOV",
    historico: {},
  },
  showPrecise: true,
  isPageLoading: true,
  isIndicadorLoading: false,
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "ATIVO_CHANGED") {
    return {
      ...state,
      ativo: action.payload,
    };
  }

  if (action.type === "INDICADOR_CHANGED") {
    return {
      ...state,
      indicador: action.payload,
    };
  }

  if (action.type === "PESSOA_CHANGED") {
    return {
      ...state,
      pessoa: action.payload,
    };
  }

  if (action.type === "COTACAO_CHANGED") {
    return {
      ...state,
      cotacao: action.payload,
    };
  }

  if (action.type === "SHOW_PRECISE_CHANGED") {
    return {
      ...state,
      showPrecise: action.payload,
    };
  }

  if (action.type === "IS_PAGE_LOADING_CHANGED") {
    return {
      ...state,
      isPageLoading: action.payload,
    };
  }

  if (action.type === "IS_INDICADOR_LOADING_CHANGED") {
    return {
      ...state,
      isIndicadorLoading: action.payload,
    };
  }
};

// Dispatchs

const acAtivoChanged = (pObjAtivo) => ({
  type: "ATIVO_CHANGED",
  payload: pObjAtivo,
});

const acIndicadorChanged = (pObjIndicador) => ({
  type: "INDICADOR_CHANGED",
  payload: pObjIndicador,
});

const acPessoaChanged = (pObjPessoa) => ({
  type: "PESSOA_CHANGED",
  payload: pObjPessoa,
});

const acCotacaoChanged = (pObjCotacao) => ({
  type: "COTACAO_CHANGED",
  payload: pObjCotacao,
});

const acRentabilidadeChanged = (pObjRentabilidade) => ({
  type: "RENTABILIDADE_CHANGED",
  payload: pObjRentabilidade,
});

const acShowPreciseChanged = (pBool) => ({
  type: "SHOW_PRECISE_CHANGED",
  payload: pBool,
});

const acIsPageLoadingChanged = (pBool) => ({
  type: "IS_PAGE_LOADING_CHANGED",
  payload: pBool,
});

const acIsIndicadorLoadingChanged = (pBool) => ({
  type: "IS_INDICADOR_LOADING_CHANGED",
  payload: pBool,
});

// Custom Hook
export const useAtivosContext = () => React.useContext(AtivosContext);

export const EmpresasProvider = memo(({ children }) => {
  const params = useParams();
  const [state, dispatch] = React.useReducer(reducers, initialState);

  const handleShowPreciseChange = (pValue) => {
    dispatch(acShowPreciseChanged(pValue));
  };

  const handleIndicadorChange = (pValue) => {
    dispatch(acIndicadorChanged(pValue));
  };

  const handleAtivoChange = (pValue) => {
    dispatch(acAtivoChanged(pValue));
  };

  const handleCotacaoChange = (pValue) => {
    dispatch(acCotacaoChanged(pValue));
  };

  const handlePessoaChange = (pValue) => {
    dispatch(acPessoaChanged(pValue));
  };

  const formatNumber = (
    pValue = null,
    pShowPrecise = true,
    pShowCurrency = true
  ) => {
    if (validators.isNull(pValue) || isNaN(pValue)) {
      return "";
    }

    if (!pShowPrecise) {
      return formats.formatNumber(pValue, 2, true, pShowCurrency);
    }

    return formats.friendlyNumber(pValue || 0, 2, pShowCurrency, "mi");
  };

  const filtrarPorPeriodo = (pDados, pMeses, pDataRef = dates.toDate()) => {
    const xDataLimite = dates.toSqlDate(dates.addMonths(pDataRef, pMeses));

    return pDados.filter(
      (xItem) => dates.toSqlDate(new Date(xItem.data)) >= xDataLimite
    );
  };

  const filtrarPorData = (pDados, pDataEspecifica) => {
    const xData = new Date(pDataEspecifica);
    return pDados.filter((xItem) => new Date(xItem.data) >= xData);
  };

  const findCotacao = (pDados, pMath = "max") => {
    if (pDados.length === 0) return null;

    const xMath = {
      max: Math.max,
      min: Math.min,
    };

    return xMath[pMath](...pDados.map((item) => item.cotacao_ajustada));
  };

  const calcularVariacaoPercentual = (valorInicial, valorFinal) => {
    if (valorInicial === 0) {
      return valorFinal === 0 ? 0 : Infinity;
    }

    return ((valorInicial - valorFinal) / valorFinal) * 100;
  };

  const calcularDiffNoPeriodo = (
    pDadosFiltrados,
    pKey = "cotacao_ajustada"
  ) => {
    if (pDadosFiltrados.length < 2) return null;

    const cotacaoInicial = pDadosFiltrados[pDadosFiltrados.length - 1][pKey];
    const cotacaoFinal = pDadosFiltrados[0][pKey];

    const xResult = calcularVariacaoPercentual(cotacaoInicial, cotacaoFinal);

    if (isNaN(xResult)) {
      return null;
    }

    return xResult;
  };

  function adicionarRentabilidade(pData) {
    const xData = objects.deepCopy([...pData]);
    if (xData.length < 2) {
      return pData;
    }

    let rentabilidade = 0;

    // O primeiro elemento não tem rentabilidade calculável, então definimos como n0
    xData[0].rentabilidade = 0;

    for (let i = 1; i < xData.length; i++) {
      rentabilidade = (rentabilidade + 1) * xData[i].variacao_diaria - 1;
      // Adiciona o novo atributo 'rentabilidade' ao objeto existente
      xData[i].rentabilidade = rentabilidade * 100;
      //pData[i].rentabilidade = pData[i].variacao_acumulada;
    }

    return xData;
  }

  const createHistoric = useCallback((pData, pDataCorte) => {
    let xData = pData;

    if (!validators.isNull(pDataCorte)) {
      xData = filtrarPorData(xData, pDataCorte);
    }

    const xHistorico = {
      ytd: adicionarRentabilidade(filtrarPorData(xData, FIRST_DATE_YEAR)),
      umMes: adicionarRentabilidade(filtrarPorPeriodo(xData, -1)),
      seisMeses: adicionarRentabilidade(filtrarPorPeriodo(xData, -6)),
      umAno: adicionarRentabilidade(filtrarPorPeriodo(xData, -12)),
      doisAnos: adicionarRentabilidade(filtrarPorPeriodo(xData, -24)),
      tresAnos: adicionarRentabilidade(filtrarPorPeriodo(xData, -36)),
      quatroAnos: adicionarRentabilidade(filtrarPorPeriodo(xData, -48)),
      cincoAnos: adicionarRentabilidade(filtrarPorPeriodo(xData, -60)),
      full: adicionarRentabilidade(xData),
    };

    return xHistorico;
  }, []);

  // Request cotação
  useEffect(() => {
    if (params.id) {
      services.ativos.list(
        { ativo_id: params.id, size: 10 },
        (rRes) => {
          handleAtivoChange(Object.values(rRes.data)[0]);
        },
        (rErr) => {
          console.log(rErr);
        },
        () => {
          dispatch(acIsPageLoadingChanged(false));
        }
      );

      services.ativos.cotacao(
        {
          ativo_id: params.id,
          data_inicio: DATA_INICIO,
        },
        (rRes) => {
          const xData = rRes.data;

          const xHistoric = createHistoric(xData);

          const xVariacoes = {
            inicio: calcularDiffNoPeriodo(
              xHistoric.full,
              "cotacao_ajustada_ex_receitas"
            ),
            umAno: calcularDiffNoPeriodo(
              xHistoric.umAno,
              "cotacao_ajustada_ex_receitas"
            ),
          };

          const xRentabilidades = {
            inicio: calcularDiffNoPeriodo(xHistoric.full, "cotacao_ajustada"),
            umAno: calcularDiffNoPeriodo(xHistoric.umAno, "cotacao_ajustada"),
          };

          const xAtualCotacao = rRes.data[rRes.data.length - 1];

          const xPayload = {
            hoje: xAtualCotacao,
            historico: xHistoric,
            variacoes: xVariacoes,
            rentabilidades: xRentabilidades,
            cotacao_12m: xHistoric.umAno[0],
            cotacao_min_12m: findCotacao(xHistoric.umAno, "min"),
            cotacao_max_12m: findCotacao(xHistoric.umAno, "max"),
          };

          handleCotacaoChange(xPayload);
        },
        (rErr) => {
          console.log(rErr);
        },
        () => {
          dispatch(acIsPageLoadingChanged(false));
        }
      );
    }
  }, [params.id]);

  // Request indicador
  useEffect(() => {
    if (
      !validators.isEmpty(state.indicador.current) &&
      !validators.isNull(state.cotacao.historico.full)
    ) {
      dispatch(acIsIndicadorLoadingChanged(true));
      services.indicadores.cotacao(
        { indicador_id: state.indicador.current, data_inicio: DATA_INICIO },
        (rRes) => {
          const xData = rRes.data;
          const xDataCorte = state.cotacao.historico.full[0];
          const xHistoric = createHistoric(xData, xDataCorte.data);

          handleIndicadorChange({
            current: state.indicador.current,
            historico: xHistoric,
          });
        },
        (rErr) => {
          console.log(rErr);
        },
        () => {
          dispatch(acIsIndicadorLoadingChanged(false));
        }
      );
    }
  }, [state.cotacao.historico.full, createHistoric, state.indicador.current]);

  useEffect(() => {
    if (!validators.isEmpty(state.ativo)) {
      services.pessoas.list(
        { pessoa_id: state.ativo.emissor_pessoa_id },
        (rRes) => {
          handlePessoaChange(rRes.data[0]);
        },
        (rErr) => {
          console.log(rErr);
        }
      );
    }
  }, [state.ativo]);

  return (
    <AtivosContext.Provider
      value={{
        state,
        actions: {
          handleIndicadorChange,
          handleShowPreciseChange,
          handleAtivoChange,
          handleCotacaoChange,
          formatNumber,
          filtrarPorPeriodo,
          createHistoric,
          calcularDiffNoPeriodo,
        },
      }}
    >
      {children}
    </AtivosContext.Provider>
  );
});

export default EmpresasProvider;
