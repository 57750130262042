import React, { memo } from "react";
import PropTypes from "prop-types";
import { List, ListSubheader } from "investira.react.components";
import EntidadesListItem from "./EntidadesListItem";

const EntidadesList = memo((props) => {
  const { entidades = [], title } = props;

  return (
    <List disablePadding subheader={<ListSubheader>{title}</ListSubheader>}>
      {entidades.map((xEntidade) => {
        return (
          <EntidadesListItem key={xEntidade.entidade_id} entidade={xEntidade} />
        );
      })}
    </List>
  );
});

EntidadesList.propTypes = {
  entidades: PropTypes.array,
  onClick: PropTypes.func,
};

export default EntidadesList;
