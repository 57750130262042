import {
  CARTEIRAS_LIST,
  CARTEIRAS_FAV,
  CARTEIRAS_DELETE,
  CARTEIRAS_CREATE,
  CARTEIRAS_READ,
} from "../const/servicesURI";
import requestService from "./requestService";
import { httpRequests } from "investira.sdk";
import utils from "../utils";

const cancel = {
  list: null,
  create: null,
};

export const create = (pData, pResolve, pReject, pFinally) => {
  // if (cancel.create) {
  //   cancel.create.cancel();
  // }

  //cancel.create = httpRequests.cancelToken();

  const xProps = {
    data: pData,
    method: "post",
    //cancelToken: cancel.create.token,
  };

  return requestService(xProps, CARTEIRAS_CREATE, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras Create");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const list = (pProps, pResolve, pReject, pFinally) => {
  // if (cancel.list) {
  //   cancel.list.cancel();
  // }

  //cancel.list = httpRequests.cancelToken();

  const xProps = {
    params: pProps,
    //cancelToken: cancel.list.token,
  };

  return requestService(xProps, CARTEIRAS_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras List");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const read = (pCarteiraId, pResolve, pReject, pFinally) => {
  return requestService({}, `${CARTEIRAS_READ}/${pCarteiraId}`, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras Read");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const modify = (pData, pResolve, pReject, pFinally) => {
  const xProps = {
    data: pData,
    method: "patch",
  };

  return requestService(xProps, CARTEIRAS_FAV, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras Modify");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const remove = (pCarteiraId, pResolve, pReject, pFinally) => {
  const xProps = {
    data: {
      carteira_id: pCarteiraId,
    },
    method: "delete",
  };

  return requestService(xProps, CARTEIRAS_DELETE, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras Remove");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const entidades = { list, read, modify, remove, create };

export default entidades;
