import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Icon,
  Typography,
  IconButton,
  Stack,
} from "investira.react.components";

import services from "../../../services";

const Preview = memo((props) => {
  useEffect(() => {
    if (props.logID) {
      services.outputs.preview(
        props.logID,
        (rRes) => {
          const xObjectURL = URL.createObjectURL(rRes.data);
          const xArea = document.getElementById("previewArea");
          const xObjectElem = document.createElement("object");
          xObjectElem.id = "preview-pdf";
          xObjectElem.type = "application/pdf";
          xObjectElem.style.width = "100%";
          xObjectElem.style.maxHeight = "100%";
          xObjectElem.style.height = "100%";
          xObjectElem.alt = props.title || "preview pdf";
          xObjectElem.ariaLabel = "preview pdf";
          xObjectElem.data = xObjectURL;
          xArea.appendChild(xObjectElem);
        },
        (rErr) => {
          console.log(rErr);
        }
      );
    }
  }, [props.logID]);

  return (
    <Dialog fullScreen open={props.open} onClose={props.onClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            onClick={props.onClose}
            aria-label="close"
          >
            <Icon iconName="cancel" />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Duration
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ display: "flex", padding: 0 }}>
        <Stack id="previewArea" flexGrow={1}></Stack>
      </DialogContent>
    </Dialog>
  );
});

Preview.propTypes = {
  open: PropTypes.bool,
};

export default Preview;
