import React, { memo } from "react";
import { formats } from "investira.sdk";
import {
  Stack,
  Typography,
  Chip,
  useMediaQuery,
} from "investira.react.components";
import { displays } from "investira.react.lib";
import { ATIVO_MERCADO } from "@investirapri/financa.js/lib/enums";
import { PessoaImage } from "../../molecules";

const SearchAtivosItem = memo((props) => {
  const { ativo, onClick } = props;
  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Stack
      id={ativo.pessoa_id}
      key={ativo.pessoa_id}
      direction="row"
      flexGrow={1}
      spacing={3}
      alignItems="flex-start"
      component="li"
      onClick={onClick}
      sx={{
        px: 2,
        py: 2,
        cursor: "pointer",
        "&:hover": {
          color: "gray",
          backgroundColor: "background.main",
        },
        borderBottom: "1px solid",
        borderBottomColor: "background.main",
      }}
    >
      <Stack>
        <PessoaImage
          logoPessoaId={ativo.logo_pessoa_id}
          alt={ativo.pessoa}
          hasLogo={ativo.logo_ok}
          size={36}
        />
      </Stack>
      <Stack flexGrow={1}>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ fontWeight: 600 }}
        >
          {ativo.ticker || ativo.ativo_seuid || ativo.ativo}
        </Typography>
        <Stack direction="row" spacing={{ xs: 0, sm: 1 }} mb={1}>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{
              display: { xs: "none", sm: "inline-block" },
            }}
          >
            {ativo.ativo || ativo.isin}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          sx={{ gap: "8px 8px" }}
          flexWrap="wrap"
          spacing={{ xs: 0, sm: 1 }}
        >
          <Chip
            label={ATIVO_MERCADO[ativo.mercado].descricao}
            size="small"
            color="primary"
            sx={{ display: { xs: "flex", md: "none" } }}
          />

          {ativo.emissor_cnpj && (
            <Chip
              label={displays.cnpj(ativo.emissor_cnpj)}
              size="small"
              color="secondary"
            />
          )}

          {ativo.isin && (
            <Chip label={ativo.isin} size="small" color="secondary" />
          )}
          {ativo.vencimento && (
            <Chip
              label={`Venc.: ${formats.formatDate(ativo.vencimento)}`}
              size="small"
              color="secondary"
            />
          )}
          {ativo.serie && (
            <Chip
              label={`Série: ${ativo.serie}`}
              size="small"
              color="secondary"
            />
          )}
        </Stack>
      </Stack>
      {isUpMd && (
        <Stack>
          <Stack justifyContent="end">
            <Chip
              label={ATIVO_MERCADO[ativo.mercado].descricao}
              size="small"
              color="primary"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
});

export default SearchAtivosItem;
