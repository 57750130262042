import React from "react";
import { Box, Stack } from "investira.react.components";
import { ErrorBoundary } from "../../molecules";
import AppBar from "./AppBar";
import Main from "./Main";
import Sidebar from "./Sidebar";

const MasterTemplate = (props) => {
  const APPBAR_HEIGHT = 64;
  const { isLoggedIn, children } = props;

  if (isLoggedIn) {
    return (
      <ErrorBoundary>
        <Box
          id="navigator"
          sx={{
            position: "relative",
            display: "flex",
            flexGrow: 1,
            width: "100vw",
          }}
        >
          <Sidebar isLoggedIn={isLoggedIn} />
          <Stack
            id="content-area"
            sx={{ flexGrow: 1, height: "100%", minWidth: "1px" }}
          >
            <AppBar isLoggedIn={isLoggedIn} />
            <Main isLoggedIn={isLoggedIn}>{children}</Main>
          </Stack>
        </Box>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Box
        id="master"
        sx={{
          flexGrow: 1,
          width: "100vw",
        }}
      >
        <Stack id="app-bar-area" sx={{ height: APPBAR_HEIGHT }}>
          <AppBar isLoggedIn={isLoggedIn} />
        </Stack>
        <Stack
          id="content-area"
          sx={{ height: `calc(100% - ${APPBAR_HEIGHT}px)` }}
        >
          <Main>{children}</Main>
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default MasterTemplate;
