import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  CopyToClipboard,
  Icon,
  IconButton,
  Tooltip,
} from "investira.react.components";
import { dates, formats, validators } from "investira.sdk";
import services from "../../../../../services";
import { useEmpresasContext } from "../../../../../contexts/EmpresasContext";
import CardState from "./CardState";
import CardFooter from "./CardFooter";
import { HighchartsReact } from "investira.react.charts";

const CapitalSocialCard = memo(() => {
  const params = useParams();
  const [capital, setCapital] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { state, actions } = useEmpresasContext();

  const xData = formatData(state.historico.cs);

  const xOptions = {
    title: false,
    chart: {
      height: 120,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
    },
    legend: { enabled: false },
    yAxis: [
      {
        type: "linear",
        title: {
          text: `Capital Social (R$)`,
        },
        showEmpty: false,
        visible: false,
      },
      {
        type: "linear",
        title: {
          text: `ON`,
        },
        visible: false,
        showEmpty: false,
      },
      {
        type: "linear",
        title: {
          text: `PN`,
        },
        visible: false,
        showEmpty: false,
      },
    ],
    xAxis: {
      type: "datetime",
      crosshair: true,
    },
    responsive: {
      condition: {
        maxWidth: 500,
      },
    },
    series: [
      {
        name: "Capital Social",
        type: "line",
        data: xData[0] || [],
        connectNulls: true,
        zIndex: 10,
        showInLegend: Boolean(xData[0]),
      },

      {
        name: "ON",
        type: "column",
        data: xData[1] || [],
        connectNulls: true,
        yAxis: 1,
        showInLegend: Boolean(xData[1]),
        pointWidth: 10,
        opacity: 1,
      },

      {
        name: "PN",
        type: "column",
        data: xData[2] || [],
        connectNulls: true,
        yAxis: 2,
        showInLegend: Boolean(xData[2]),
        pointWidth: 10,
        opacity: 1,
      },
    ],
    tooltip: {
      formatter: function (tooltip) {
        const xDate = formats.formatDateCustom(this.point.x, "DD/MMM/YYYY");
        let xHTML = `<span style="font-size: 1em"><b>${xDate}</b></span><br/>`;
        let xTotalPapeis = 0;

        this.points.forEach((xPoint) => {
          if (["ON", "PN"].includes(xPoint.series.name)) {
            xTotalPapeis = xTotalPapeis + xPoint.point.y;
          }
          const xValue = formats.friendlyNumber(xPoint.point.y, 2, false);
          const xFullValue = formats.formatNumber(
            xPoint.point.y,
            2,
            true,
            false
          );

          xHTML =
            xHTML +
            `<span style="color:${xPoint.color}">\u25CF</span>${xPoint.series.name}: <b>${xValue}</b> (${xFullValue})<br/>`;
        });

        xHTML =
          xHTML +
          `Total de Papéis: <b>${formats.friendlyNumber(
            xTotalPapeis,
            2,
            false
          )}</b> (${xTotalPapeis})<br/>`;

        return xHTML;
      },
      shared: true,
    },
  };

  function formatData(pData = []) {
    const xData = [];
    pData.forEach((xPoint) => {
      const xDate = dates.toDate(xPoint.data).getTime();
      const xPapeis = xPoint?.acao ? Object.keys(xPoint.acao) : [];

      if (validators.isEmpty(xData[0])) {
        xData[0] = [];
      }

      xData[0].push([xDate, xPoint.cs]);

      if (xPapeis.includes("on")) {
        if (validators.isEmpty(xData[1])) {
          xData[1] = [];
        }

        xData[1].push([xDate, xPoint.acao.on]);
      }

      if (xPapeis.includes("pn")) {
        if (validators.isEmpty(xData[2])) {
          xData[2] = [];
        }

        xData[2].push([xDate, xPoint.acao.pn]);
      }
    });

    return xData;
  }

  function somaPapeis(pOn, pPn) {
    const xOn = pOn || 0;
    const xPn = pPn || 0;

    return xOn + xPn;
  }

  function totalPapeis(pOn, pPn) {
    return actions.formatNumber(
      somaPapeis(pOn, pPn),
      state.showPrecise,
      false,
      0
    );
  }

  function handleOpenClick() {
    actions.handleChartVariantChange("cs");
  }

  useEffect(() => {
    if (state.pessoa.pessoa_id) {
      setLoading(true);
      services.pessoas.cs(
        {
          data: dates.toSqlDate(dates.toDate()),
          pessoa_id: state.pessoa.pessoa_id,
        },
        (rRes) => {
          setCapital(rRes.data[0]);
        },
        null,
        () => {
          setLoading(false);
        }
      );
    }

    return () => services.pessoas.cancel.cs.cancel();
  }, [state.pessoa.pessoa_id]);

  return (
    <Card sx={{ minHeight: "100%" }}>
      <CardContent
        sx={{ minHeight: "100%", display: "flex", flexDirection: "column" }}
      >
        <Stack direction="row" spacing={2}>
          <Stack flexGrow={1}>
            <Typography variant="caption" color="textPrimary" gutterBottom>
              Capital social
            </Typography>
          </Stack>
          {!validators.isEmpty(capital) && (
            <Stack>
              <Tooltip title="Histórico Capital Social">
                <div>
                  <IconButton color="primary" onClick={handleOpenClick}>
                    <Icon iconName="chart_bar" size={16} />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>
          )}
        </Stack>

        <CardState loading={isLoading} data={capital}>
          <Stack
            spacing={1}
            justifyContent="space-between"
            sx={{ minHeight: "100%" }}
          >
            <Stack>
              <Typography
                variant="h4"
                color="textPrimary"
                sx={{ fontWeight: 600 }}
              >
                {actions.formatNumber(
                  capital?.cs,
                  state.showPrecise,
                  true,
                  2,
                  null
                )}
              </Typography>
              <Typography variant="caption" color="textPrimary" gutterBottom>
                Data de referência{" "}
                {formats.formatDateCustom(capital?.data, "DD/MMM/YYYY")}
              </Typography>
            </Stack>
            {/* <Divider /> */}
            <Stack flexGrow="1">
              <HighchartsReact options={xOptions} />
            </Stack>
            <Stack direction="row" justifyContent="flex-start" spacing={4}>
              <Stack>
                <Typography variant="caption" color="textSecondary">
                  Total de Papéis
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ fontWeight: 600 }}
                  >
                    {totalPapeis(capital?.acao?.on, capital?.acao?.pn)}
                  </Typography>
                  <Stack>
                    <CopyToClipboard
                      value={somaPapeis(capital?.acao?.on, capital?.acao?.pn)}
                    />
                  </Stack>
                </Stack>
              </Stack>

              {capital?.acao?.on && (
                <Stack>
                  <Typography variant="caption" color="textSecondary">
                    ON
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      sx={{ fontWeight: 600 }}
                    >
                      {actions.formatNumber(
                        capital?.acao?.on,
                        state.showPrecise,
                        false,
                        0
                      )}
                    </Typography>
                    <Stack>
                      {<CopyToClipboard value={capital?.acao?.on} />}
                    </Stack>
                  </Stack>
                </Stack>
              )}

              {capital?.acao?.pn && (
                <Stack>
                  <Typography variant="caption" color="textSecondary">
                    PN
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      sx={{ fontWeight: 600 }}
                    >
                      {actions.formatNumber(
                        capital?.acao?.pn,
                        state.showPrecise,
                        false,
                        0
                      )}
                    </Typography>
                    <Stack>
                      {<CopyToClipboard value={capital?.acao?.pn} />}
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <CardFooter />
          </Stack>
        </CardState>
      </CardContent>
    </Card>
  );
});

CapitalSocialCard.displayName = "CapitalSocialCard";

export default CapitalSocialCard;
