import {
  SEARCH_ATIVOS_CHANGED,
  SEARCH_ATIVOS_LOGOUT,
} from "../../const/actionsType";
import { dates } from "investira.sdk";

const INITIAL_STATE = {
  pesquisa: "",
  result: {},
  pages: {},
  updatedAt: dates.toDate(),
};

function searchAtivosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH_ATIVOS_CHANGED:
      return {
        ...state,
        ...action.payload,
      };
    case SEARCH_ATIVOS_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default searchAtivosReducer;
