import React, { memo } from "react";
import { Stack } from "investira.react.components";
import { Search } from "../../components/organisms";
import { Head } from "../../components/molecules";
import logo from "../../assets/images/brdados.svg";

const Home = memo(() => {
  return (
    <>
      <Head title="Home">
        <meta
          name="description"
          content="Sistemas para fundos de investimentos, empresas e instituições financeiras dos mais diversos portes como Fundos de Pensão, Bancos, Assets Managements e Family Offices."
        />
      </Head>
      <Stack
        id="page"
        flexGrow={1}
        sx={{
          height: "100%",
          paddingTop: 3,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent={{ xs: "flex-start", md: "center" }}
          height="100%"
          flexGrow={1}
        >
          <Stack
            spacing={3}
            alignItems="center"
            mb="80px"
            sx={{ width: { xs: "100%", sm: "600px" }, px: 2 }}
          >
            <Stack flexGrow={1}>
              <img src={logo} alt="Investirabiz" width="272px" />
            </Stack>
            {/* <BuscaEmpresa /> */}
            {/* <BuscaGlobal /> */}
            <Search />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
});

export default Home;
