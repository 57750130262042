import React, { memo } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
} from "investira.react.components";

const EntidadeConfirmDelete = memo((props) => {
  const { open, entidade, onClose, onDelete } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Excluir a entidade?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A entidade <b>{entidade.entidade}</b> será excluída permanentemente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          variant={"contained"}
          onClick={() => onDelete(entidade.entidade_id, onClose)}
          autoFocus
        >
          Sim, excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default EntidadeConfirmDelete;
