const MERCADOS_ICONES = {
  ACN: { id: "ACN", icon: "stock" }, // Ação
  BEM: { id: "BEM", icon: "box_treasure" }, // Outros Bens
  CAR: { id: "CAR", icon: "creditcard" }, //Cartão
  CNT: { id: "CNT", icon: "bank_account" }, // Conta
  POU: { id: "POU", icon: "money_saving" }, // Poupança
  DRI: { id: "DRI", icon: "derivatives" }, // Derivativo
  FND: { id: "FND", icon: "fund" }, // Fundo de Investimento
  PRV: { id: "PRV", icon: "foresight" }, // Previdência
  FGT: { id: "FGT", icon: "money_bag" }, //FGTS
  AGR: { id: "AGR", icon: "agro" }, // Agronegócio
  IMV: { id: "IMV", icon: "home" }, // Imóvel
  FDL: { id: "FDL", icon: "fidelity" }, // Programa de Fidelidade
  MOE: { id: "FDL", icon: "coin" }, // Moeda Estrangeira
  MTL: { id: "MTL", icon: "metal" }, // Metal
  PDR: { id: "PDR", icon: "precious_stone" }, // Pedra
  CRP: { id: "CRP", icon: "currency_bitcoin" }, // Criptomoeda
  TPU: { id: "TPU", icon: "pub_bond" }, // Título Público
  TPR: { id: "TPR", icon: "pri_bond" }, // Título Privado
  TBA: { id: "TBA", icon: "bank_bond" }, // Título Bancário
  VEI: { id: "VEI", icon: "car" }, //Veículo
  ART: { id: "ART", icon: "work_art" }, // Obra de Arte
  EMP: { id: "EMP", icon: "lending" }, // Empréstimo
  SEG: { id: "SEG", icon: "shield" }, //Seguro
  CBL: { id: "CBL", icon: "balance" }, //Contabil
};

export default MERCADOS_ICONES;
