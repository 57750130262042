import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom/";
import { formats, validators, dates } from "investira.sdk";
import services from "../services";

// Context
const EmpresasContext = React.createContext();

const initialState = {
  pessoa: {},
  showPrecise: true,
  isPageLoading: true,
  variantChart: null,
  historico: {
    pl: [],
    cs: [],
    saldos: [],
  },
  config: {
    consolidacao_tipo: 1,
  },
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "PESSOA_CHANGED") {
    return {
      ...state,
      pessoa: action.payload,
    };
  }

  if (action.type === "SHOW_PRECISE_CHANGED") {
    return {
      ...state,
      showPrecise: action.payload,
    };
  }

  if (action.type === "IS_PAGE_LOADING_CHANGED") {
    return {
      ...state,
      isPageLoading: action.payload,
    };
  }

  if (action.type === "VARIANT_CHART_CHANGED") {
    return {
      ...state,
      variantChart: action.payload,
    };
  }

  if (action.type === "HISTORICO_CHANGED") {
    return {
      ...state,
      historico: {
        ...state.historico,
        ...action.payload,
      },
    };
  }

  if (action.type === "CONFIG_CHANGED") {
    return {
      ...state,
      config: {
        ...state.config,
        ...action.payload,
      },
    };
  }
};

// Dispatchs
const acShowPreciseChanged = (pBool) => ({
  type: "SHOW_PRECISE_CHANGED",
  payload: pBool,
});

const acPessoaChanged = (pObjPessoa) => ({
  type: "PESSOA_CHANGED",
  payload: pObjPessoa,
});

const acIsPageLoadingChanged = (pBool) => ({
  type: "IS_PAGE_LOADING_CHANGED",
  payload: pBool,
});

const acVariantChartChanged = (pStr) => ({
  type: "VARIANT_CHART_CHANGED",
  payload: pStr,
});

const acHistoricoChanged = (pObj) => ({
  type: "HISTORICO_CHANGED",
  payload: pObj,
});

const acConfigChanged = (pObj) => ({
  type: "CONFIG_CHANGED",
  payload: pObj,
});

// Custom Hook
export const useEmpresasContext = () => React.useContext(EmpresasContext);

export const EmpresasProvider = memo(({ children }) => {
  const TODAY = new Date();
  const DATA_FIM = dates.toSqlDate(dates.toSqlDate(TODAY));
  const DATA_INICIO = dates.addYears(dates.toSqlDate(TODAY), -10);

  const params = useParams();
  const [state, dispatch] = React.useReducer(reducers, initialState);

  const formatNumber = (
    pValue = null,
    pShowPrecise = true,
    pShowCurrency = true,
    pDecimal = 2,
    pBase = "mi"
  ) => {
    if (validators.isNull(pValue) || isNaN(pValue)) {
      return "";
    }

    if (!pShowPrecise) {
      return formats.formatNumber(pValue, pDecimal, true, pShowCurrency);
    }

    return formats.friendlyNumber(pValue || 0, pDecimal, pShowCurrency, pBase);
  };

  const handleShowPreciseChange = (pValue) => {
    dispatch(acShowPreciseChanged(pValue));
  };

  const handlePessoaChange = (pValue) => {
    dispatch(acPessoaChanged(pValue));
  };

  const handleChartVariantChange = (pValue) => {
    dispatch(acVariantChartChanged(pValue));
  };

  const handleHistoricoChange = (pObj) => {
    dispatch(acHistoricoChanged(pObj));
  };

  const handleConfigChange = (pObj) => {
    dispatch(acConfigChanged(pObj));
  };

  useEffect(() => {
    if (params.cnpj) {
      services.pessoas.list(
        { cnpj: params.cnpj },
        (rRes) => {
          handlePessoaChange(rRes.data[0]);
        },
        (rErr) => {
          console.log(rErr);
        },
        () => {
          dispatch(acIsPageLoadingChanged(false));
        }
      );
    }
  }, [params.cnpj]);

  useEffect(() => {
    const xParams = {
      data: null,
      data_inicio: DATA_INICIO,
      data_fim: DATA_FIM,
      cnpj: params.cnpj,
      sort: "data ASC",
      // consolidacao_tipo: state.config.consolidacao_tipo,
    };

    services.pessoas.pl(xParams, (rRes) => {
      handleHistoricoChange({ pl: rRes.data });
    });

    services.pessoas.cs(xParams, (rRes) => {
      handleHistoricoChange({ cs: rRes.data });
    });

    services.pessoas.saldos(
      { ...xParams, conta: "3.08.00.00.00.00.00" },
      (rRes) => {
        handleHistoricoChange({ saldos: rRes.data });
      }
    );
  }, [params.cnpj, state.config.consolidacao_tipo]);

  return (
    <EmpresasContext.Provider
      value={{
        state,
        actions: {
          handleShowPreciseChange,
          handlePessoaChange,
          handleChartVariantChange,
          formatNumber,
          handleConfigChange,
        },
      }}
    >
      {children}
    </EmpresasContext.Provider>
  );
});

export default EmpresasProvider;
