import { combineReducers } from "investira.react.lib";
import storage from "./storage";

import aplicationReducer from "./App/AppReducer";
import ativosReducer from "./Ativos/AtivosReducer";
import authReducer from "./Auth/AuthReducer";
import authLoginReducer from "./AuthLogin/AuthLoginReducer";
import backgroundJobsReducer from "./BackgroundJobs/BackgroundJobsReducer";
import calculadorasReducer from "./Calculadoras/CalculadorasReducer";
import carteirasReducer from "./Carteiras/CarteirasReducer";
import entidadeReducer from "./Entidade/EntidadeReducer";
import empresasReducer from "./Empresas/EmpresasReducer";
import infoReducer from "./Info/InfoReducer";
import indicadoresReducer from "./Indicadores/IndicadoresReducer";
import mercadosReducer from "./Mercados/MercadosReducer";
import messageReducer from "./Message/MessageReducer";
import notificacoesReducer from "./Notificacoes/NotificacoesReducer";
import notificacoesAnswerReducer from "./NotificacoesAnswer/NotificacoesAnswerReducer";
import notificacoesListReducer from "./NotificacoesList/NotificacoesListReducer";
import searchReducer from "./Search/SearchReducer";
import searchAtivosReducer from "./SearchAtivos/SearchAtivosReducer";
import userReducer from "./User/UserReducer";
import userPhotoReducer from "./UserPhoto/UserPhotoReducer";
import websocketReducer from "./Websocket/WebsocketReducer";

const reducers = {
  app: [aplicationReducer],
  ativos: [ativosReducer],
  auth: [authReducer],
  authLogin: [authLoginReducer],
  background: [backgroundJobsReducer],
  calculadoras: [calculadorasReducer],
  carteiras: [carteirasReducer],
  entidade: [entidadeReducer],
  empresas: [empresasReducer],
  info: [infoReducer],
  indicadores: [indicadoresReducer],
  mercados: [mercadosReducer],
  message: [messageReducer],
  newNotificacoes: [notificacoesReducer],
  notificacoesAnswerViewed: [notificacoesAnswerReducer],
  notificacoesList: [notificacoesListReducer],
  search: [searchReducer],
  searchAtivos: [searchAtivosReducer],
  user: [userReducer],
  userPhoto: [userPhotoReducer],
  websocket: [websocketReducer, ["connected"]],
};

const appReducer = combineReducers(reducers, "cnpj.brdados", storage);

export default appReducer;
