import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  IconButton,
  Icon,
  Tooltip,
  Button,
  MenuItem,
  Menu,
} from "investira.react.components";
import { dates, arrays, formats, validators } from "investira.sdk";
import services from "../../../../../../services";
import { useEmpresasContext } from "../../../../../../contexts/EmpresasContext";
import CardState from "../CardState";
import { CONSOLIDACAO_TIPO } from "@investirapri/financa.js/lib/enums";
import { HighchartsReact } from "investira.react.charts";

const PatrimonioCard = memo(() => {
  const params = useParams();
  const { state, actions } = useEmpresasContext();
  const [patrimonio, setPatrimonio] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [consolidacaoTipo, setConsolidacaoTipo] = useState();
  const [anchorElConsolidaco, setAnchorElConsolidaco] = useState(null);
  const [options, setOptions] = useState([]);

  const openConsolidacao = Boolean(anchorElConsolidaco);

  const xPatrimonio = patrimonio[consolidacaoTipo];

  const formatData = useCallback((pData = [], pConsolidacoaTipo) => {
    if (validators.isNull(pData) || validators.isNull(pConsolidacoaTipo)) {
      return [];
    }

    const xData = pData.filter((xItem) => {
      return xItem.consolidacao_tipo === pConsolidacoaTipo;
    });

    return xData.map((xPoint) => {
      const xDate = dates.toDate(xPoint.data).getTime();
      return [xDate, xPoint.pl];
    });
  }, []);

  const xOptions = useMemo(
    () => ({
      title: false,
      chart: {
        height: 120,
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
      },
      legend: { enabled: false },
      xAxis: { visible: false },
      yAxis: { visible: false },
      responsive: {
        condition: {
          maxWidth: 500,
        },
      },
      series: [
        {
          name: "PL",
          type: "line",
          data: formatData(state.historico.pl, consolidacaoTipo),
          marker: {
            enabled: false,
          },
        },
      ],
      tooltip: {
        formatter: function (tooltip) {
          const xDate = formats.formatDateCustom(this.point.x, "DD/MMM/YYYY");
          const xValue = formats.friendlyNumber(this.point.y, 2, false);
          const xFullValue = formats.formatNumber(this.point.y, 2, true, false);

          return `
        <span style="font-size: 1em"><b>${xDate}</b></span><br/>
        <span style="color:${this.color}">\u25CF</span>
        ${this.series.name}: <b>${xValue}</b> (${xFullValue})<br/>
        `;
        },
      },
    }),
    [consolidacaoTipo, formatData, state.historico.pl]
  );

  function handleOpenClick() {
    actions.handleChartVariantChange("pl");
  }

  useEffect(() => {
    if (state.pessoa.pessoa_id) {
      setLoading(true);
      services.pessoas.pl(
        {
          data: dates.toSqlDate(dates.toDate()),
          pessoa_id: state.pessoa.pessoa_id,
        },
        (rRes) => {
          if (!validators.isEmpty(rRes.data)) {
            const xData = arrays.arrayToObject(rRes.data, "consolidacao_tipo");

            let xOptions = new Set();

            rRes.data.forEach((xElem) => {
              xOptions.add(xElem.consolidacao_tipo);
            });

            xOptions = Array.from(xOptions);

            console.log(xOptions[0]);

            const xInitData = xData[xOptions[0]];

            console.log(xInitData);

            setPatrimonio(xData);
            setOptions(xOptions);
            setConsolidacaoTipo(xOptions[0]);
          }
        },
        null,
        () => {
          setLoading(false);
        }
      );
    }

    return () => services.pessoas.cancel.pl.cancel();
  }, [state.pessoa.pessoa_id]);

  function renderConsolidacaoMenu(
    pConsolidacoaTipo,
    pOptions = [],
    pCurrentConsolidacao
  ) {
    if (validators.isEmpty(pOptions)) {
      return null;
    }

    const xMenu = pOptions.map((xId) => {
      const xConsolidacaoTipo = Object.values(pConsolidacoaTipo).filter(
        (xItem) => xItem.id === xId
      )[0];

      return {
        label: xConsolidacaoTipo.descricao,
        value: xConsolidacaoTipo.id,
      };
    });

    const xCurrentLabel = Object.values(pConsolidacoaTipo).filter(
      (xItem) => xItem.id === pCurrentConsolidacao
    )[0];

    const xProps = {
      ...(pOptions.length >= 2
        ? { onClick: (pEvent) => setAnchorElConsolidaco(pEvent.currentTarget) }
        : { disabled: true }),
    };

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          disableElevation
          {...xProps}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          {xCurrentLabel?.descricao}
        </Button>
        <Menu
          anchorEl={anchorElConsolidaco}
          open={openConsolidacao}
          onClose={() => setAnchorElConsolidaco(null)}
        >
          {xMenu.map((xItem) => {
            return (
              <MenuItem
                key={xItem.value}
                onClick={() => {
                  setConsolidacaoTipo(xItem.value);
                  actions.handleConfigChange({
                    consolidacao_tipo: xItem.value,
                  });
                }}
              >
                {xItem.label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Stack direction="row" spacing={2}>
          <Stack flexGrow={1}>
            <Typography variant="caption" color="textPrimary" gutterBottom>
              {state.pessoa?.setor_id === "65.41.3.00"
                ? "Patrimônio Social"
                : "Patrimônio líquido"}
            </Typography>
          </Stack>
          {!validators.isEmpty(xPatrimonio) && (
            <Stack>
              <Tooltip title="Histórico Patrimônio Líquido">
                <div>
                  <IconButton color="primary" onClick={handleOpenClick}>
                    <Icon iconName="chart_bar" size={16} />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        <CardState loading={isLoading} data={xPatrimonio}>
          <Stack spacing={1} justifyContent="space-between">
            <Stack>
              <Typography
                variant="h4"
                color="textPrimary"
                sx={{ fontWeight: 600 }}
              >
                {actions.formatNumber(
                  xPatrimonio?.pl,
                  state.showPrecise,
                  true,
                  2,
                  null
                )}
              </Typography>

              <Typography variant="caption" color="textPrimary" gutterBottom>
                Data de referência{" "}
                {formats.formatDateCustom(xPatrimonio?.data, "DD/MMM/YYYY")}
              </Typography>
            </Stack>
            <Stack flexGrow="1">
              <HighchartsReact options={xOptions} />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                {renderConsolidacaoMenu(
                  CONSOLIDACAO_TIPO,
                  options,
                  consolidacaoTipo
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardState>
      </CardContent>
    </Card>
  );
});

PatrimonioCard.displayName = "PatrimonioCard";

export default PatrimonioCard;
