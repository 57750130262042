import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Icon,
} from "investira.react.components";

import { validators } from "investira.sdk";

import {
  CONSOLIDACAO_TIPO,
  PLANO_CONTABIL_TIPO,
} from "@investirapri/financa.js/lib/enums";

const Filters = memo((props) => {
  const {
    percentual,
    values,
    filters,
    escala,
    framing,
    onChangeValues,
    onTooglePercentual,
    onChangeDecimal,
    onChangeEscala,
    onChangeFraming,
  } = props;

  const [anchorElConsolidaco, setAnchorElConsolidaco] = useState(null);
  const [anchorElPlano, setAnchorElPlano] = useState(null);
  const [anchorElBalancete, setAnchorElBalancete] = useState(null);

  const openConsolidacao = Boolean(anchorElConsolidaco);
  const openPlano = Boolean(anchorElPlano);
  const openBalancete = Boolean(anchorElBalancete);

  function handleClickBalancete(pEvent) {
    setAnchorElBalancete(pEvent.currentTarget);
  }

  function handleCloseBalancete() {
    setAnchorElBalancete(null);
  }

  function handleSetBalancete(pValue) {
    onChangeValues({ balancete: pValue });
    handleCloseBalancete();
  }

  function handleClickConsolidacao(pEvent) {
    setAnchorElConsolidaco(pEvent.currentTarget);
  }

  function handleCloseConsolidacao() {
    setAnchorElConsolidaco(null);
  }

  function handleSetConsolidacao(pValue) {
    onChangeValues({ consolidacao_tipo: pValue });
    handleCloseConsolidacao();
  }

  function handleClickPlano(pEvent) {
    setAnchorElPlano(pEvent.currentTarget);
  }

  function handleClosePlano() {
    setAnchorElPlano(null);
  }

  function handleSetPlano(pValue) {
    onChangeValues({ plano_contabil_tipo: pValue });
    handleClosePlano();
  }

  function renderConsolidacaoMenu(
    pConsolidacoaTipo,
    pOptions = [],
    pCurrentConsolidacao
  ) {
    if (validators.isEmpty(pOptions)) {
      return null;
    }

    const xMenu = pOptions.map((xId) => {
      const xConsolidacaoTipo = Object.values(pConsolidacoaTipo).filter(
        (xItem) => xItem.id === xId
      )[0];

      return {
        label: xConsolidacaoTipo.descricao,
        value: xConsolidacaoTipo.id,
      };
    });

    const xCurrentLabel = Object.values(pConsolidacoaTipo).filter(
      (xItem) => xItem.id === pCurrentConsolidacao
    )[0];

    const xProps = {
      ...(pOptions.length >= 2
        ? { onClick: handleClickConsolidacao }
        : { disabled: true }),
    };

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          disableElevation
          {...xProps}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          {xCurrentLabel?.descricao}
        </Button>
        <Menu
          anchorEl={anchorElConsolidaco}
          open={openConsolidacao}
          onClose={handleCloseConsolidacao}
        >
          {xMenu.map((xItem) => {
            return (
              <MenuItem
                key={xItem.value}
                onClick={() => handleSetConsolidacao(xItem.value)}
              >
                {xItem.label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }

  function renderPlanoMenu(pPlano, pOptions = [], pPlanoContabilTipo) {
    if (validators.isEmpty(pOptions)) {
      return null;
    }

    let xOptions = pOptions.map((xItem) => {
      return pPlano[xItem];
    });

    xOptions = xOptions.filter((xItem) => {
      return xItem.demonstrativo === false;
    });

    const xMenu = xOptions.map((xPlanoTipo) => {
      return {
        label: xPlanoTipo.descricao,
        value: xPlanoTipo.id,
      };
    });

    const xProps = {
      ...(pOptions.length >= 2
        ? { onClick: handleClickPlano }
        : { disabled: true }),
    };

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          disableElevation
          {...xProps}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          {pPlano[pPlanoContabilTipo]?.descricao || "Plano Contábil"}
        </Button>
        <Menu
          anchorEl={anchorElPlano}
          open={openPlano}
          onClose={handleClosePlano}
        >
          {xMenu.map((xItem) => {
            return (
              <MenuItem
                key={xItem.label}
                onClick={() => handleSetPlano(xItem.value)}
              >
                {xItem.label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }

  function renderBalanceteMenu(pOptions = [], pBalancete) {
    if (validators.isEmpty(pOptions)) {
      return null;
    }

    const xMenu = [
      { label: "Balancete", value: true },
      { label: "Balanço", value: false },
    ];

    const xProps = {
      ...(pOptions.length >= 2
        ? { onClick: handleClickBalancete }
        : { disabled: true }),
    };

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          disableElevation
          {...xProps}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          {pBalancete ? "Balancete" : "Balanço"}
        </Button>
        <Menu
          anchorEl={anchorElBalancete}
          open={openBalancete}
          onClose={handleCloseBalancete}
        >
          {xMenu.map((xItem) => {
            return (
              <MenuItem
                key={xItem.label}
                onClick={() => handleSetBalancete(xItem.value)}
              >
                {xItem.label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }

  return (
    <Stack
      direction="row"
      sx={{ gap: "8px 8px" }}
      flexWrap="wrap"
      justifyContent="flex-end"
      mb={3}
    >
      {renderConsolidacaoMenu(
        CONSOLIDACAO_TIPO,
        filters.consolidacao,
        values.consolidacao_tipo
      )}

      {renderPlanoMenu(
        PLANO_CONTABIL_TIPO,
        filters.plano,
        values.plano_contabil_tipo
      )}

      {renderBalanceteMenu(filters.balancete, values.balancete)}

      <Button
        variant="outlined"
        size="small"
        onClick={onTooglePercentual}
        sx={{ whiteSpace: "nowrap", fontSize: "0.7rem", padding: "3px 8px" }}
      >
        {percentual ? "Ocultar Var. %" : "Mostar Var. %"}
      </Button>

      <ButtonGroup variant="outlined" size="small">
        <Button
          startIcon={<Icon iconName="arrow_left" size={12} />}
          onClick={() => onChangeDecimal(-1)}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          .0
        </Button>
        <Button
          endIcon={<Icon iconName="arrow_right" size={12} />}
          onClick={() => onChangeDecimal(1)}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          .00
        </Button>
      </ButtonGroup>
      <ToggleButtonGroup
        value={escala}
        exclusive
        variant="outlined"
        size="small"
        color="primary"
      >
        <ToggleButton
          value={"mil"}
          onClick={() => onChangeEscala("mil")}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          Mil
        </ToggleButton>
        <ToggleButton
          value={"mi"}
          onClick={() => onChangeEscala("mi")}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          Mi
        </ToggleButton>
        <ToggleButton
          value={"bi"}
          onClick={() => onChangeEscala("bi")}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          Bi
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        value={framing[0]}
        exclusive
        variant="outlined"
        size="small"
        color="primary"
      >
        <ToggleButton
          value={"anual"}
          onClick={() => onChangeFraming(["anual", 10])}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          Anual
        </ToggleButton>
        <ToggleButton
          value={"semestral"}
          onClick={() => onChangeFraming(["semestral", 12])}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          Semestral
        </ToggleButton>
        <ToggleButton
          value={"trimestral"}
          onClick={() => onChangeFraming(["trimestral", 12])}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          Trimestral
        </ToggleButton>
        <ToggleButton
          value={"mensal"}
          onClick={() => onChangeFraming(["mensal", 12])}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          Mensal
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
});

Filters.propTypes = {};

export default Filters;
