import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { validators, formats, dates } from "investira.sdk";
import {
  IconButton,
  Tooltip,
  Stack,
  Icon,
  Typography,
} from "investira.react.components";
import { PosicoesContextActions, DataTable } from "../";
import { displays } from "investira.react.lib";

const PosicoesDataGrid = (props) => {
  const { state, output_id, renderDetailPanel, ...restProps } = props;

  const columns = useMemo(
    () => [
      {
        accessorKey: "carteira",
        header: "Carteira",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "ifeed_carteira",
        header: "ISIN/Código",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "ifeed_cnpj",
        header: "CNPJ",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return displays.cnpj(cell.getValue());
        },
      },
      {
        accessorKey: "modified",
        header: "Atualizado",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          if (validators.isNull(cell.getValue())) {
            return;
          }
          return formats.formatDate(dates.toDate(cell.getValue()));
        },
      },
    ],
    []
  );

  const xProps = {
    columns,
    state,
    ...restProps,
    ...(!validators.isNull(renderDetailPanel) && {
      enableExpandAll: false,
      enableExpanding: false,
      renderDetailPanel: renderDetailPanel,
    }),
    enableGlobalFilter: false,
    enableSubRowSelection: false,
    // renderEmptyRowsFallback: () => {
    //   <Stack direction="row" justifyContent="center" alignItems="center">
    //     <Typography variant="body2">
    //       Ainda não foi enviado nenhum upload
    //     </Typography>
    //   </Stack>;
    // },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    renderRowActions: ({ row }) => {
      const { carteira_id } = row.original;
      return (
        <Stack direction="row">
          <Tooltip title="Novo Relatório">
            <IconButton
              color="primary"
              onClick={() =>
                props.onCreateClick({
                  carteira: row.original,
                  output_id,
                })
              }
            >
              <Icon iconName="execute" size={24} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Relatórios">
            <IconButton
              color="primary"
              onClick={() => props.onListClick({ carteira_id })}
            >
              <Icon iconName="box_paper" size={24} />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    },
  };

  return <DataTable {...xProps} />;
};

PosicoesDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  state: PropTypes.object,
  initialState: PropTypes.object,
};

export default PosicoesDataGrid;
