import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Logo } from "../../organisms";
import { StatusBar } from "../../../components/organisms";
import {
  Stack,
  Button,
  AppBar as MuiAppBar,
  Toolbar,
  useMediaQuery,
  Icon,
} from "investira.react.components";
import MainMenu from "./MainMenu";

const AppBar = memo((props) => {
  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const fndUrl = useSelector((state) => state.user.fnd_url);

  function render(pIsLoggedIn) {
    if (pIsLoggedIn) {
      return (
        <>
          <Logo isLoggedIn={pIsLoggedIn} />
          <StatusBar height={props.height} />

          <Stack direction="row" spacing={2} alignItems="center">
            {fndUrl && (
              <Button
                id="appbar-btn-login"
                variant="contained"
                size="small"
                sx={{ px: 2 }}
                component={"a"}
                href={fndUrl}
                target="_blank"
                endIcon={<Icon iconName="arrow_right" size={12} />}
              >
                Acessar FND
              </Button>
            )}
          </Stack>
        </>
      );
    }

    return (
      <Stack
        direction="row"
        spacing={{ xs: 2, md: 5 }}
        alignItems="center"
        flexGrow={1}
      >
        <Logo isLoggedIn={pIsLoggedIn} />

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={{ xs: "right", md: "left" }}
          flexGrow={1}
        >
          <MainMenu />
        </Stack>

        {/* {isUpMd && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              id="appbar-btn-login"
              variant="contained"
              size="small"
              sx={{ px: 2 }}
              component={Link}
              to="/login"
            >
              Acessar Conta
            </Button>
          </Stack>
        )} */}
      </Stack>
    );
  }

  return (
    <MuiAppBar position="static" elevation={0}>
      <Stack
        id="appbar"
        sx={{
          height: "inherit",
          // borderBottom: "1px solid #2E2E46",
        }}
        spacing={3}
      >
        <Toolbar sx={{ px: 2 }}>{render(props.isLoggedIn)}</Toolbar>
      </Stack>
    </MuiAppBar>
  );
});

export default AppBar;
