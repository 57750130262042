import React from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip, Stack, Icon } from "investira.react.components";
import withPreview from "../../../hoc/withPreview";
import { formats, dates, validators } from "investira.sdk";

import { BulkContextActions, DataTable } from "../";

import services from "../../../services";

const RelatoriosDataGrid = (props) => {
  function onDownloadClick(pLogId, pLog) {
    if (pLogId) {
      services.outputs.download(
        pLogId,
        (rRes) => {
          const { carteira_id, data_posicao, entidade_id, output_id } = pLog;

          let xOutputFilename = `${String(pLogId).padStart(
            15,
            "0"
          )}-${output_id}-${formats.formatDateCustom(
            dates.toDate(data_posicao),
            "DD-MM-YYYY"
          )}-${carteira_id}-${formats.formatDateCustom(
            new Date(),
            "DD-MM-YYYY"
          )}-${String(entidade_id).padStart(15, "0")}.pdf`;

          if (rRes?.headers["content-disposition"]) {
            let xFilenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let xMatches = xFilenameRegex.exec(
              rRes.headers["content-disposition"]
            );
            if (xMatches != null && xMatches[1]) {
              xOutputFilename = xMatches[1].replace(/['"]/g, "");
            }
          }

          const xFileURL = URL.createObjectURL(rRes.data);
          let xLinkElem = document.createElement("a");
          xLinkElem.href = xFileURL;
          xLinkElem.download = xOutputFilename;
          xLinkElem.click();
        },
        (rErr) => {
          console.log(rErr);
        }
      );
    }
  }

  const { state, columns, renderDetailPanel, onBulkDeleteClick, ...restProps } =
    props;

  const xProps = {
    columns,
    state,
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const isSomeRowSelected = selectedRows.length > 0;

      return (
        <BulkContextActions
          onDeleteClick={() => {
            console.log(selectedRows);
            onBulkDeleteClick(selectedRows);
          }}
          disabled={
            state.isLoading || state.showProgressBars || !isSomeRowSelected
          }
        />
      );
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    renderRowActions: ({ row }) => {
      const {
        processamento_evento,
        processamento_arquivo,
        processamento_erros,
        log_id,
      } = row.original;

      const isDisabled = !(
        processamento_evento === "LF" && validators.isNull(processamento_erros)
      );

      return (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Visualizar">
            <span>
              <IconButton
                color="primary"
                onClick={() =>
                  props.onClickOpenPreview({
                    filename: processamento_arquivo,
                    log_id: log_id,
                  })
                }
                disabled={isDisabled}
              >
                <Icon iconName="eye" size={24} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Download">
            <span>
              <IconButton
                color="primary"
                onClick={() => onDownloadClick(log_id, row.original)}
                //component="a"
                //href={`${OUTPUTS_DOWNLOAD}?log_id=${log_id}&authorization=${token}`}
                disabled={isDisabled}
              >
                <Icon iconName="download" size={24} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      );
    },
    ...restProps,
  };

  return <DataTable {...xProps} />;
};

RelatoriosDataGrid.propTypes = {
  // data: PropTypes.array,
  initialState: PropTypes.object,
};

RelatoriosDataGrid.propTypes = {
  // data: [],
  initialState: PropTypes.object,
};

RelatoriosDataGrid.displayName = "RelatoriosDataGrid";

export default withPreview(RelatoriosDataGrid);
