import React, { memo } from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  Chip,
} from "investira.react.components";
import { PessoaImage } from "../../../components/molecules";
import { useEmpresasContext } from "../../../contexts/EmpresasContext";
import utils from "../../../utils";

const EmpresaHeader = memo((props) => {
  const { preciseOption = true } = props;

  const { state, actions } = useEmpresasContext();
  const {
    logo_pessoa_id,
    ativo,
    logo_ok,
    mercado,
    pessoa,
    pessoa_fantasia,
    pessoa_tipo_id,
  } = state.pessoa;

  const xPessoaTipoList = utils.helpers.getPessoaTipoById(pessoa_tipo_id);

  const handleChange = (event) => {
    actions.handleShowPreciseChange(event.target.checked);
  };

  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 1 }}
    >
      <Stack
        direction="row"
        spacing={3}
        alignItems={{ xs: "flex-start", md: "center" }}
      >
        <Stack>
          <PessoaImage
            logoPessoaId={logo_pessoa_id}
            alt={ativo}
            hasLogo={logo_ok}
            mercado={mercado}
            size={50}
          />
        </Stack>
        <Stack spacing={0.5}>
          <Stack>
            <Typography variant="h5" color="textPrimary">
              {pessoa_fantasia || pessoa}
            </Typography>
          </Stack>
          <Stack flexGrow={1} direction="row" gap={1} flexWrap="wrap">
            {xPessoaTipoList.map((xPessoaTipo) => {
              return (
                <Chip
                  key={xPessoaTipo.descricao}
                  label={xPessoaTipo.descricao}
                  size="small"
                  color="secondary"
                />
              );
            })}
          </Stack>
        </Stack>
      </Stack>
      {preciseOption && (
        <Stack>
          <FormControlLabel
            sx={{ marginRight: 0 }}
            control={
              <Switch checked={state.showPrecise} onChange={handleChange} />
            }
            label={
              <Typography variant="body2" color="textPrimary">
                Valores simples
              </Typography>
            }
          />
        </Stack>
      )}
    </Stack>
  );
});

EmpresaHeader.displayName = "EmpresaHeader";

export default EmpresaHeader;
