import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { validators } from "investira.sdk";
import { Stack } from "investira.react.components";

function ScrollContainer(props) {
  const { pathname } = useLocation();
  const scrollRef = useRef();

  useEffect(() => {
    //Força a posição da pagina no topo ao mudar de rota
    !validators.isNull(scrollRef.current) && scrollRef.current.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Stack
      id="scroll"
      ref={scrollRef}
      sx={{
        flex: "1 1 auto",
        WebkitOverflowScrolling: "touch",
        scrollBehavior: "smooth",
        overflowY: "scroll",
        overflowX: "hidden",
        minHeight: 0,
        position: "relative",
        marginTop: 0,
        paddingBottom: 3,
      }}
    >
      {props.children}
    </Stack>
  );
}

ScrollContainer.displayName = "ScrollContainer";

export default ScrollContainer;
