import React from "react";
import { useLocation } from "react-router-dom";
import { strings } from "investira.sdk";

function useSearch() {
  const { search } = useLocation();
  return React.useMemo(() => strings.querystringToObject(search), [search]);
}

export default useSearch;
