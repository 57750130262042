import React, { useState, useEffect } from "react";
import { Preview } from "../components/organisms";
import services from "../services";

const withPreview = (Component) => {
  function WrapComponent(props) {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(null);
    const [data, setData] = useState(null);
    // const [urlPreview, setUrlPreview] = useState(null);

    function handleClickOpenPreview({ filename, log_id }) {
      setTitle(log_id);
      // setUrlPreview(url_preview);
      // services.outputs.preview(log_id, (rRes) => {
      //   const pdf = document.querySelector("#preview-pdf");
      //   console.log("preview-pdf", pdf);

      //   // pdf.data = URL.createObjectURL(rRes);
      //   // console.log(pdf.src);
      // });
      setOpen(true);
    }

    function handleClickClosePreview(pCallback) {
      setTitle(null);
      // setUrlPreview(null);
      setOpen(false);
    }

    // useEffect(() => {
    //   services.outputs.preview(props.log_id, (rRes) => {
    //     console.log("preview", rRes);
    //     setData(rRes);
    //   });
    // }, [props.log_id]);

    const xProps = {
      ...props,
      isPreviewOpen: open,
      onClickOpenPreview: handleClickOpenPreview,
      onClickClosePreview: handleClickClosePreview,
    };

    return (
      <>
        <Component {...xProps} />
        <Preview
          open={open}
          title={title}
          data={data}
          logID={title}
          onClose={handleClickClosePreview}
        />
      </>
    );
  }

  return WrapComponent;
};

export default withPreview;
