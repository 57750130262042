import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ResponsiveImage, Box } from "investira.react.components";
import { getPessoaImageSet } from "../../../utils/images";

// Instituições / Custodiante
import placeholder_parthenon1x from "../../../assets/images/placeholder_parthenon@1x.png";
import placeholder_parthenon2x from "../../../assets/images/placeholder_parthenon@2x.png";
import placeholder_parthenon3x from "../../../assets/images/placeholder_parthenon@3x.png";
import { validators } from "investira.sdk";

const PessoaImage = memo((props) => {
  const [imageSource, setImageSource] = useState(
    getPessoaImageSet(props.logoPessoaId)
  );

  const SETS = {
    INS: [
      {
        srcSet: `${placeholder_parthenon1x}, ${placeholder_parthenon2x}, ${placeholder_parthenon3x}`,
      },
    ],
  };

  const xPlaceholderSet = SETS[props.variant];

  useEffect(() => {
    const xImageSet = getPessoaImageSet(props.logoPessoaId);
    if (validators.isEmpty(xImageSet)) {
      setImageSource(xPlaceholderSet);
    } else {
      setImageSource(xImageSet);
    }
  }, [props.logoPessoaId]);

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "20%",
        height: `${props.size}px`,
        width: `${props.size}px`,
      }}
    >
      <ResponsiveImage
        id={props.id}
        source={imageSource}
        alt={props.alt}
        placeholder={xPlaceholderSet}
        onLoad={props.onLoad}
      />
    </Box>
  );
});

PessoaImage.propTypes = {
  size: PropTypes.number,
  id: PropTypes.string,
  onLoad: PropTypes.func,
  variant: PropTypes.oneOf(["INS"]),
};

PessoaImage.defaultProps = {
  size: 32,
  variant: "INS",
};

PessoaImage.displayName = "PessoaImage";

export default PessoaImage;
