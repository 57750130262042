import React, { memo } from "react";
import { Button, Stack, Typography, Icon } from "investira.react.components";
import { useNavigate } from "react-router-dom";
import { PosicoesTemplate } from "../../components/templates";
import { useRelatorioContext } from "../../contexts/RelatorioContext";
import { OUTPUT_TIPO } from "../../enums";
import DurationParamsDialog from "./DurationParamsDialog";

import withCarteiras from "../../hoc/withCarteiras";

const Duration = memo((props) => {
  const { actions } = useRelatorioContext();
  const navigate = useNavigate();

  return (
    <>
      <PosicoesTemplate
        title="Duration"
        subtitle="Reports"
        dataGridProps={{
          output_id: `${OUTPUT_TIPO.RP.id}DUR`,
          data: props.carteiras,
          readData: props.readCarteiras,
          isLoading: props.isLoading,
          initialState: { pagination: props.pagination },
          renderEmptyRowsFallback: ({ table }) => {
            console.log(table);
            return (
              <Stack
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ py: 4 }}
              >
                <Typography variant="body1">
                  Ainda não foi enviado nenhum arquivo
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Icon iconName="upload" size={16} />}
                  onClick={() => navigate("/uploads")}
                >
                  Ir para Uploads
                </Button>
              </Stack>
            );
          },
          manualPagination: true,
          getRowId: (row) => row._rid,
          state: {
            isLoading: props.isLoading,
            pagination: props.pagination,
            showProgressBars: props.isRefetching,
          },
          rowCount: props.rowCount,
          onPaginationChange: props.setPagination,
        }}
        onCreateClick={actions.handleNewReport}
        onListClick={(pParams) =>
          actions.handleListReports("duration", pParams)
        }
      />
      <DurationParamsDialog />
    </>
  );
});

Duration.displayName = "Duration";

export default withCarteiras(Duration);
