import {
  BACKGROUND_CARTEIRA_CHANGED,
  BACKGROUND_ARQUIVOS_CHANGED,
  BACKGROUND_REPORTS_CHANGED,
  BACKGROUND_LOGOUT,
} from "../../const/actionsType";

export function acBackgroundArquivosChanged(pBool) {
  return {
    type: BACKGROUND_ARQUIVOS_CHANGED,
    payload: pBool,
  };
}

export function acBackgroundCarteiraChanged(pBool) {
  return {
    type: BACKGROUND_CARTEIRA_CHANGED,
    payload: pBool,
  };
}

export function acBackgroundReportsChanged(pBool) {
  return {
    type: BACKGROUND_REPORTS_CHANGED,
    payload: pBool,
  };
}

export function acBackgroundLogout(pBool) {
  return {
    type: BACKGROUND_LOGOUT,
    payload: pBool,
  };
}
