import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Box,
  Typography,
  Stack,
  MaterialReactTable,
  Tooltip,
  IconButton,
  Icon,
} from "investira.react.components";
import { BulkContextActions } from "../../../../components/organisms";
import { useCalculadoraVariacaoContext } from "../../../../contexts/CalculadoraVariacaoContext";
import {
  acCalculadoraMoedaDeleted,
  acCalculadoraCorrecaoDeleted,
  acCalculadoraMercadoDeleted,
  acCalculadoraJurosDeleted,
} from "../../../../store/actions";
import columns from "./columns";

const Historico = memo((props) => {
  const { state, actions } = useCalculadoraVariacaoContext();
  const calculadoras = useSelector((store) => store.calculadoras);
  const dispatch = useDispatch();

  const xData = calculadoras[state.indicador_tipo].data;
  const xColumns = columns[state.indicador_tipo];

  function handleDeleteClick(pSeletedRows, pIndicador, pData) {
    const xActions = {
      M: acCalculadoraMoedaDeleted,
      C: acCalculadoraCorrecaoDeleted,
      I: acCalculadoraMercadoDeleted,
      J: acCalculadoraJurosDeleted,
    };

    const xSelectedIDs = pSeletedRows.map((xItem) => {
      return xItem.original.id;
    });

    const xNewData = pData.filter((xItem) => {
      return !xSelectedIDs.includes(xItem.id);
    });

    dispatch(xActions[pIndicador](xNewData));
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Stack px={2} py={2}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Histórico
          </Typography>
        </Stack>

        <MaterialReactTable
          key={state.indicador_tipo}
          columns={xColumns}
          data={xData}
          getRowId={(row) => row.id}
          state={{}}
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          memoMode="cell"
          enableFullScreenToggle={false}
          enableBottomToolbar={true}
          enableGlobalFilter={false}
          muiTablePaperProps={{
            sx: {
              pb: 2,
            },
          }}
          enableRowSelection={true}
          renderTopToolbarCustomActions={({ table }) => {
            const selectedRows = table.getSelectedRowModel().rows;
            const isSomeRowSelected = selectedRows.length > 0;

            return (
              <BulkContextActions
                onDeleteClick={() => {
                  handleDeleteClick(selectedRows, state.indicador_tipo, xData);
                }}
                disabled={!isSomeRowSelected}
              />
            );
          }}
          enableRowActions={true}
          positionActionsColumn={"last"}
          renderRowActions={({ row }) => {
            console.log(row.original);
            return (
              <Stack direction="row">
                <Tooltip title="Repetir">
                  <IconButton
                    color="primary"
                    onClick={() => actions.handleRepeat(row.original.payload)}
                  >
                    <Icon iconName="undo" size={21} />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          }}
        />
      </Paper>
    </Box>
  );
});

Historico.displayName = "Historico";

export default Historico;
