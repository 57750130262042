import React from "react";
import { Link, useMatch } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Icon,
  Stack,
} from "investira.react.components";

import { BaseTemplate } from "../../components/templates";
import app from "../../utils/app";
import { PATHS } from "../../enums";

function Configuracoes() {
  let { pathnameBase } = useMatch("/configuracoes/*");

  return (
    <BaseTemplate title="Configurações" maxWidth="md">
      <Stack spacing={2}>
        <List
          component={"nav"}
          subheader={
            <Typography color={"textSecondary"} variant={"caption"}>
              Segurança
            </Typography>
          }
        >
          <ListItem
            component={Link}
            to={pathnameBase + PATHS.configuracoes.nested["alterar-senha"].path}
          >
            <ListItemIcon>
              <Icon iconName={"key"} size={21} color={"greenLight"} />
            </ListItemIcon>
            <ListItemText
              primary="Alterar Senha"
              primaryTypographyProps={{
                color: "textPrimary",
              }}
            />
          </ListItem>
        </List>
        <Divider />
        <List
          component={"nav"}
          subheader={
            <Typography color={"textSecondary"} variant={"caption"}>
              Entidades
            </Typography>
          }
        >
          <ListItem
            component={Link}
            to={pathnameBase + PATHS.configuracoes.nested.entidades.path}
          >
            <ListItemIcon>
              <Icon iconName={"parthenon"} size={21} color={"greenLight"} />
            </ListItemIcon>
            <ListItemText
              primary="Minhas Entidades"
              primaryTypographyProps={{
                color: "textPrimary",
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to={
              pathnameBase +
              PATHS.configuracoes.nested.entidades.nested.membros.path
            }
          >
            <ListItemIcon>
              <Icon iconName={"users"} size={21} color={"greenLight"} />
            </ListItemIcon>
            <ListItemText
              primary="Membros"
              primaryTypographyProps={{
                color: "textPrimary",
              }}
            />
          </ListItem>
        </List>

        <Divider />
        <List
          component={"nav"}
          subheader={
            <Typography color={"textSecondary"} variant={"caption"}>
              Sessão
            </Typography>
          }
        >
          <ListItem
            button
            onClick={() => {
              app.logout();
            }}
          >
            <ListItemIcon>
              <Icon iconName={"on_off"} size={21} color={"greenLight"} />
            </ListItemIcon>
            <ListItemText
              primary="Sair"
              primaryTypographyProps={{
                color: "textPrimary",
              }}
            />
          </ListItem>
        </List>
      </Stack>
    </BaseTemplate>
  );
}

Configuracoes.displayName = "Configuracoes";

export default Configuracoes;
