import React, { memo } from "react";
import { Persona } from "investira.react.components";
import { useSelector } from "react-redux";

const EntidadeAvatar = memo((props) => {
  const TIMESTAMP = new Date().getTime();
  const LOGO_HOST_URL = useSelector((store) => store.info.logo);

  const { alt, entidadeId, sizeLetters = 2 } = props;

  let xImageSrc = "";

  if (entidadeId) {
    const xLogoFolder = String(entidadeId).padStart(32, 0);
    xImageSrc = `${LOGO_HOST_URL}/${xLogoFolder}/lg@3x.png?${TIMESTAMP}`;
  }

  return (
    <Persona
      alt={alt}
      src={xImageSrc}
      sx={{ borderRadius: "15%", ...props.sx }}
      sizeLetters={sizeLetters}
    />
  );
});

EntidadeAvatar.displayName = "EntidadeAvatar";

export default EntidadeAvatar;
