import { CONSULTAS_INDICADORES_VARIACAO } from "../const/servicesURI";
import requestService from "./requestService";
import { httpRequests } from "investira.sdk";
import utils from "../utils";

const cancel = {
  indicadores: { variacao: null },
};

export const indicadores = {
  variacao: (pParams = {}, pResolve, pReject, pFinally) => {
    if (cancel.indicadores.variacao) {
      cancel.indicadores.variacao.cancel();
    }

    cancel.indicadores.variacao = httpRequests.cancelToken();

    const xProps = {
      params: pParams,
      cancelToken: cancel.indicadores.variacao.token,
    };

    return requestService(xProps, CONSULTAS_INDICADORES_VARIACAO)
      .then((rRes) => {
        return pResolve && pResolve(rRes);
      })
      .catch((rErr) => {
        utils.request.tracer(
          rErr,
          "Error Service Outputs Indicadores Variação"
        );
        return pReject && pReject(rErr);
      })
      .finally(() => {
        return pFinally && pFinally();
      });
  },
};

const consultas = {
  indicadores,
  cancel,
};

export default consultas;
