import React, { memo, useEffect, useCallback } from "react";
import { dates } from "investira.sdk";
import services from "../services";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withMessage from "../hoc/withMessage";
import withResponseHandling from "../hoc/withResponseHandling";
import { acBackgroundReportsChanged } from "../store/actions";

// Context
const RelatorioContext = React.createContext();

const initialState = {
  output: null,
  isDialogOpen: false,
  output_id: null,
  carteira: {},
  params: {},
  isProcessing: false,
  carteiras: [],
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "NEW_REPORT_OPENED") {
    return {
      ...state,
      isDialogOpen: true,
      carteira: action.payload.carteira,
      output_id: action.payload.output_id,
    };
  }

  if (action.type === "NEW_REPORT_CANCELED") {
    return {
      ...state,
      isDialogOpen: false,
      //carteira: {},
      //output_id: null,
    };
  }

  if (action.type === "REPORT_PARAMS_CHANGED") {
    return {
      ...state,
      params: action.payload,
    };
  }

  if (action.type === "CARTEIRAS_CHANGED") {
    return {
      ...state,
      carteiras: action.payload,
    };
  }

  if (action.type === "OUTPUT_CHANGED") {
    return {
      ...state,
      output: action.payload,
    };
  }
};

// Dispatchs
const acNewReportOpened = (pPayload = {}) => ({
  type: "NEW_REPORT_OPENED",
  payload: pPayload,
});

const acNewReportCanceled = () => ({
  type: "NEW_REPORT_CANCELED",
});

const acParamsChanged = (pPayload = {}) => ({
  type: "REPORT_PARAMS_CHANGED",
  payload: pPayload,
});

const acCarteirasChanged = (pPayload = []) => ({
  type: "CARTEIRAS_CHANGED",
  payload: pPayload,
});

const acOutputChanged = (pPayload = null) => ({
  type: "OUTPUT_CHANGED",
  payload: pPayload,
});

// Custom Hook
export const useRelatorioContext = () => React.useContext(RelatorioContext);

// Provider
export const RelatorioProvider = memo((props) => {
  const carteirasStored = useSelector((store) => store.carteiras.data);

  const entidadeId = useSelector(
    (state) => state.entidade.current.default_entidade_id
  );

  const { output } = useParams();
  const storeDispatch = useDispatch();

  // const [isOnDrag, setIsOnDrag] = useState(false);
  // const [isUploading, setIsUploading] = useState(false);

  const [state, dispatch] = React.useReducer(reducers, initialState);

  const handleNewReport = (pPayload = {}) => {
    dispatch(acNewReportOpened(pPayload));
  };

  const handleNewReportCancel = () => {
    dispatch(acNewReportCanceled());
  };

  const handleParamsUpdate = (pPayload) => {
    dispatch(acParamsChanged(pPayload)); // TODO: Armazenar no redux
  };

  const handleOutputUpdate = (pPayload) => {
    dispatch(acOutputChanged(pPayload));
  };

  const handleCarteirasUpdate = (pPayload) => {
    dispatch(acCarteirasChanged(Object.values(pPayload)));
  };

  const handleCreate = (pParams = {}, pFinally) => {
    const xParams = {
      //...state.params,
      carteira_id: pParams.carteira_id,
      juros_delta: pParams.juros_delta,
      data: dates.toSqlDate(dates.toDate(pParams.data_posicao)),
      //data_posicao: dates.toSqlDate(dates.toDate(pParams.data_posicao)),
      aberta: pParams.aberta,
    };

    services.outputs.duration(
      xParams,
      (rRes) => {
        storeDispatch(acBackgroundReportsChanged(true));
        props.onMessage("Processamento do relatório iniciado.");
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      },
      () => {
        pFinally && pFinally();
      }
    );
  };

  const readCarteiras = useCallback(
    (pParams = {}) => {
      services.carteiras.list(
        {
          favorito: 1,
          ...pParams,
        },
        (rRes) => {
          handleCarteirasUpdate(rRes.data);
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        }
      );
    },
    [props]
  );

  // Atualiza carteiras caso a entidade seja alterada
  useEffect(() => {
    readCarteiras();
  }, [readCarteiras, entidadeId]);

  // Atualiza carteiras caso a store seja atualizada
  useEffect(() => {
    handleCarteirasUpdate(carteirasStored);
  }, [carteirasStored]);

  const VALUES = {
    state: { ...state },
    actions: {
      handleNewReport,
      handleNewReportCancel,
      handleParamsUpdate,
      handleCreate,
      handleOutputUpdate,
    },
  };

  return (
    <RelatorioContext.Provider value={VALUES}>
      {props.children}
    </RelatorioContext.Provider>
  );
});

export default withResponseHandling(withMessage(RelatorioProvider));
