import React, { memo } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { formats, validators, dates } from "investira.sdk";
import {
  Dialog,
  DialogContent,
  Stack,
  Button,
  LoadingButton,
  Icon,
  TextField,
} from "investira.react.components";
import { DatePicker } from "../../components/molecules";
import { useSimulacoesContext } from "../../contexts/SimulacoesContext";
import withResponseHandling from "../../hoc/withResponseHandling";
import { VALIDATIONS } from "../../const";

const DialogPosicao = memo((props) => {
  const { state, actions } = useSimulacoesContext();
  const params = useParams();

  const isOpen = state.isDialogOpen && state.dialogType === "posicao";

  const VALIDATION_SCHEMA = Yup.object().shape({
    data: Yup.date().max(new Date()).required(VALIDATIONS.REQUIRED),
  });

  const formik = useFormik({
    initialValues: { data: state.data_posicao || new Date() },
    validationSchema: VALIDATION_SCHEMA,
    validateOnMount: false,
    onSubmit: (pValues, pActions) => {
      pActions.setSubmitting(true);
      const xDate = dates.toDate(pValues.data);
      actions.updatePosicoes(
        xDate,
        () => {
          actions.handleDataPosicaoCancel();
        },
        null,
        () => {
          pActions.setSubmitting(false);
        }
      );
    },
  });

  const handleChange = (pFieldName, pFieldValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogContent sx={{ pt: 4 }}>
        <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Stack>
            <Stack flexGrow={0}>
              <DatePicker
                label="Data Posição"
                name="data"
                id="id"
                onChange={(pValue) => {
                  handleChange("data", pValue);
                }}
                value={formik.values.data}
                error={Boolean(formik.errors.data)}
                maxDate={new Date()}
                helperText={formik.errors.data}
                disabled={formik.isSubmitting}
                fullWidth
              />
            </Stack>
            <Stack
              flex={1}
              direction="row"
              justifyContent="flex-end"
              sx={{ py: 2 }}
              spacing={2}
            >
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={actions.handleDataPosicaoCancel}
              >
                Cancelar
              </Button>
              <LoadingButton
                startIcon={<Icon iconName="upload" size={16} />}
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Salvar
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
});

export default withResponseHandling(DialogPosicao);
