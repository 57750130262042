import React, { memo, useEffect, useMemo } from "react";
import { validators, dates, formats } from "investira.sdk";
import { useDispatch } from "react-redux";
import services from "../services";
import { acIndicadoresChanged } from "../store/actions";

// Context
const CalculadoraVariacaoContext = React.createContext();

const initialState = {
  indicador_tipo: "C",
  isLoading: true,
  initValues: {},
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "INDICADOR_TIPO_CHANGED") {
    return {
      ...state,
      indicador_tipo: action.payload,
    };
  }
  if (action.type === "IS_LOADING_CHANGED") {
    return {
      ...state,
      isLoading: action.payload,
    };
  }
  if (action.type === "INIT_VALUES_CHANGED") {
    return {
      ...state,
      initValues: action.payload,
    };
  }
};

// Dispatchs
const updateIndicarTipo = (pString) => ({
  type: "INDICADOR_TIPO_CHANGED",
  payload: pString,
});

const updateIsLoading = (pBool) => ({
  type: "IS_LOADING_CHANGED",
  payload: pBool,
});

const updateInitValues = (pObj) => ({
  type: "INIT_VALUES_CHANGED",
  payload: pObj,
});

// Custom Hook
export const useCalculadoraVariacaoContext = () =>
  React.useContext(CalculadoraVariacaoContext);

export const CalculadoraVariacaoProvider = memo(({ children }) => {
  const storeDispatch = useDispatch();
  const [state, dispatch] = React.useReducer(reducers, initialState);

  const handleUpdateIndicadorTipo = (pValue) => {
    dispatch(updateInitValues({}));
    dispatch(updateIndicarTipo(pValue));
  };

  const handleUpdateIsLoading = (pValue) => {
    dispatch(updateIsLoading(pValue));
  };

  const handleRepeat = (pValues) => {
    console.log(pValues);
    dispatch(updateInitValues(pValues));
  };

  useEffect(() => {
    handleUpdateIsLoading(true);
    services.indicadores.list(
      {},
      (rRes) => {
        storeDispatch(acIndicadoresChanged(rRes.data));
      },
      (rErr) => {
        console.log(rErr);
      },
      () => {
        handleUpdateIsLoading(false);
      }
    );
  }, []);

  return (
    <CalculadoraVariacaoContext.Provider
      value={{
        state,
        actions: {
          handleUpdateIndicadorTipo,
          handleUpdateIsLoading,
          handleRepeat,
        },
      }}
    >
      {children}
    </CalculadoraVariacaoContext.Provider>
  );
});

export default CalculadoraVariacaoProvider;
