import { validators, formats, dates } from "investira.sdk";
import { Stack, Typography } from "investira.react.components";

const columns = [
  {
    id: "ativo",
    accessorKey: "ativo.ativo_seuid",
    header: "Ativo",
    enableColumnActions: false,
    enableSorting: true,
    filterVariant: "select",
    size: 300,
    Cell: ({ cell, row }) => {
      return (
        <Stack>
          <Stack>
            {row.original.ativo.ticker
              ? `${row.original.ativo.ticker} - ${cell.getValue()}`
              : cell.getValue()}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" color="textSecondary">
              {row.original.ativo.isin}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {row.original.ativo.serie && `Série: ${row.original.ativo.serie}`}
            </Typography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    id: "titulo",
    accessorKey: "ativo.ativo",
    header: "Título",
    enableColumnActions: false,
    enableSorting: true,
    filterVariant: "select",
    size: 80,
  },
  {
    id: "data",
    accessorKey: "payload.data",
    header: "Data",
    enableColumnActions: false,
    enableSorting: true,
    filterVariant: "select",
    size: 80,
    Cell: ({ cell }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return formats.formatDateCustom(
        dates.toDate(cell.getValue()),
        "DD/MMM/YYYY"
      );
    },
  },
  {
    id: "quantidade",
    accessorKey: "payload.quantidade",
    header: "Quantidade",
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilter: false,
    size: 80,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
    Cell: ({ cell }) => {
      if (validators.isNull(cell.getValue())) {
        return null;
      }
      return `${Number(cell.getValue()).toFixed(4)}`;
    },
  },
  {
    id: "operacao_taxa",
    accessorKey: "payload.operacao_taxa",
    header: "Taxa de Compra",
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilter: false,
    size: 80,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
    Cell: ({ cell }) => {
      if (validators.isNull(cell.getValue())) {
        return null;
      }
      return `${Number(cell.getValue()).toFixed(6)}%`;
    },
  },
  {
    id: "vencimento",
    accessorKey: "result.vencimento",
    header: "Vencimento",
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilter: false,
    size: 80,
    Cell: ({ cell }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return formats.formatDateCustom(
        dates.toDate(cell.getValue()),
        "DD/MMM/YYYY"
      );
    },
  },
  {
    id: "emissao",
    accessorKey: "result.emissao",
    header: "Emissão",
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilter: false,
    size: 80,
    Cell: ({ cell }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return formats.formatDateCustom(
        dates.toDate(cell.getValue()),
        "DD/MMM/YYYY"
      );
    },
  },
  {
    id: "database",
    accessorKey: "result.cm.data_base",
    header: "Data Base",
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilter: false,
    size: 80,
    Cell: ({ cell }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return formats.formatDateCustom(
        dates.toDate(cell.getValue()),
        "DD/MMM/YYYY"
      );
    },
  },
  {
    id: "pu",
    accessorKey: "result.operacao.pu",
    header: "PU",
    enableColumnActions: false,
    enableSorting: true,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
    Cell: ({ cell, row }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return `${formats.formatNumber(cell.getValue(), 6, true)}`;
    },
  },
  {
    id: "financeiro",
    accessorKey: "result.operacao.pu",
    header: "Financeiro",
    enableColumnActions: false,
    enableSorting: true,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
    Cell: ({ cell, row }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return `${formats.formatNumber(
        cell.getValue() * row.original.payload.quantidade,
        6,
        true,
        true
      )}`;
    },
  },

  {
    id: "vna",
    accessorKey: "result.curva.pu_vna",
    header: "PU VNA",
    enableColumnActions: false,
    enableSorting: true,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
    Cell: ({ cell, row }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return `${formats.formatNumber(cell.getValue(), 6, true)}`;
    },
  },
  {
    id: "par",
    accessorKey: "result.curva.pu_par",
    header: "PU Par",
    enableColumnActions: false,
    enableSorting: true,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
    Cell: ({ cell, row }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return `${formats.formatNumber(cell.getValue(), 6, true)}`;
    },
  },
  {
    id: "duration",
    accessorKey: "result.analise.duration",
    header: "Duration",
    enableColumnActions: false,
    enableSorting: true,
    size: 80,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
    Cell: ({ cell, row }) => {
      if (validators.isNull(cell.getValue())) {
        return;
      }
      return `${formats.formatNumber(cell.getValue(), 6, true)}`;
    },
  },
  {
    id: "emissor",
    accessorKey: "ativo.emissor.pessoa_fantasia",
    header: "Emissor",
    enableColumnActions: false,
    enableSorting: true,
    filterVariant: "select",
  },
];

export default columns;
