import React, { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

const Head = memo((props) => {
  const location = useLocation();
  const isProduction = process.env.NODE_ENV === "production";

  useEffect(() => {
    isProduction &&
      ReactGA.send({
        hitType: "pageView",
        page: location.pathname + location.search,
        title: props.title,
      });
  }, [location, props.title]);

  return (
    <Helmet>
      <title>{props.title} | BR Dados</title>
      <link rel="canonical" href={location.pathname} />
      {props.children}
    </Helmet>
  );
});

export default Head;
