import React, { memo } from "react";
import { Link } from "react-router-dom";
import { BaseTemplate } from "../../components/templates";
import { Box, Card, Stack, Typography, Icon } from "investira.react.components";

const Calculadoras = memo(() => {
  return (
    <BaseTemplate
      title="Calculadoras"
      description="Calculadoras de Correção de Valores, Curva."
      maxWidth="md"
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" },
          gap: "8px 8px",
        }}
      >
        <Card>
          <Link id="calculacora-variacao" to={`/calculadoras/variacao`}>
            <Stack px={2} py={2} spacing={2}>
              <Stack flexGrow={1}>
                <Stack
                  backgroundColor="primary.main"
                  width="32px"
                  height="32px"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="8px"
                >
                  <Icon iconName="delta" color="secondary" size={21} />
                </Stack>
              </Stack>
              <Stack flexGrow={1}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  Variação
                </Typography>
              </Stack>
            </Stack>
          </Link>
        </Card>
        <Card>
          <Link id="calculacora-curva" to={`/calculadoras/curva`}>
            <Stack px={2} py={2} spacing={2}>
              <Stack flexGrow={1}>
                <Stack
                  backgroundColor="primary.main"
                  width="32px"
                  height="32px"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="8px"
                >
                  <Icon iconName="paper_ribbon" color="secondary" size={21} />
                </Stack>
              </Stack>
              <Stack flexGrow={1}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  Curva
                </Typography>
              </Stack>
            </Stack>
          </Link>
        </Card>
      </Box>
    </BaseTemplate>
  );
});

export default Calculadoras;
