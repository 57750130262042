import React from "react";
import PropTypes from "prop-types";
import { Divider, Card, CardContent } from "investira.react.components";
import withResponseHandling from "../../../hoc/withResponseHandling";
import { useEntidadesContext } from "../../../contexts/EntidadesContext";
import EntidadesList from "./EntidadesList";

const EntidadesListRoot = (props) => {
  const { state } = useEntidadesContext();

  const xEntidadeAtiva = state.entidades.filter(
    (pElem) => pElem.entidade_id === pElem.default_entidade_id
  );

  const xEntidades = state.entidades.filter(
    (pElem) => pElem.entidade_id !== pElem.default_entidade_id
  );

  return (
    <Card>
      <CardContent>
        <EntidadesList entidades={xEntidadeAtiva} title="Entidade Ativa" />
        <Divider sx={{ my: 2 }} />
        <EntidadesList entidades={xEntidades} title="Minhas Entidades" />
      </CardContent>
    </Card>
  );
};

EntidadesListRoot.propTypes = {};
EntidadesListRoot.displayName = "EntidadesListRoot";

export default withResponseHandling(EntidadesListRoot);
