import { CARTEIRAS_CHANGED } from "../../const/actionsType";

import { dates } from "investira.sdk";

const INITIAL_STATE = {
  data: [],
  updatedAt: dates.toDate(),
};

const RESET_STATE = {
  ...INITIAL_STATE,
};

function carteirasReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CARTEIRAS_CHANGED:
      return {
        ...state,
        data: action.payload,
        updatedAt: dates.toDate(),
      };
    default:
      return state;
  }
}

export default carteirasReducer;
