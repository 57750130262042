import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { dates } from "investira.sdk";
import { useSelector, useDispatch } from "react-redux";
import { validators } from "investira.sdk";
import {
  Stack,
  Typography,
  Menu,
  List,
  ListItem,
  Box,
  Icon,
  CircularProgress,
  ListItemText,
  ListSubheader,
  ListItemIcon,
} from "investira.react.components";

import services from "../../../services";
import { acBackgroundReportsChanged } from "../../../store/actions";
import withResponseHandling from "../../../hoc/withResponseHandling";

const ReportProcessing = (props) => {
  const process = useSelector((state) => state.background.reports.process);
  const dispatch = useDispatch();

  const ping = useRef(null);

  const [logs, setLogs] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const readLogs = useCallback(() => {
    services.outputs.logs(
      {
        //processamento_arquivo: 0,
        em_processamento: true,
        size: "null",
      },
      (rRes) => {
        setLogs(rRes.data);
      }
    );
  }, []);

  // Executa na montagem do componente
  useEffect(() => {
    readLogs();
  }, [readLogs]);

  // Executa após solicitar novo relatório
  useEffect(() => {
    if (process) {
      readLogs();
    }
  }, [readLogs, process]);

  // PING (Enquanto não tempos o WS)
  useEffect(() => {
    if (validators.isEmpty(logs)) {
      dispatch(acBackgroundReportsChanged(false));
      clearTimeout(ping.current);
    } else {
      ping.current = setTimeout(() => readLogs(), 3000);
    }
    return () => {
      clearTimeout(ping.current);
    };
  }, [logs]);

  if (validators.isEmpty(logs)) {
    return null;
  }

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          cursor: "pointer",
          px: 1,
          py: 1,
          "&:hover": {
            backgroundColor: "#2e2e46",
          },
        }}
        onClick={handleClick}
      >
        <Box>
          <CircularProgress size={16} color="primary" />
        </Box>
        <Typography variant="caption" color="text.secondary">
          Gerando Relatório...
        </Typography>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List subheader={<ListSubheader>Carteiras</ListSubheader>} dense>
          {logs.map((xItem, xIndex) => {
            const {
              log_id,
              output_id,
              created,
              carteira_id,
              data_posicao,
              entidade_id,
            } = xItem;

            const xFilename = `${String(log_id).padStart(
              15,
              "0"
            )}-${output_id}-${dates.toSqlDate(
              created
            )}-${carteira_id}-${dates.toSqlDate(data_posicao)}-${String(
              entidade_id
            ).padStart(10, "0")}`;

            return (
              <ListItem
                key={xFilename}
                secondaryAction={
                  <Box>
                    <CircularProgress size={16} color="primary" />
                  </Box>
                }
              >
                <ListItemIcon sx={{ minWidth: "32px" }}>
                  <Icon iconName="paper" size={18} color="secondaryLightness" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="caption" color="text.primary">
                      {xFilename}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </Stack>
  );
};

ReportProcessing.propTypes = {};

export default withResponseHandling(ReportProcessing);
