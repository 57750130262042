import React, { memo } from "react";
import {
  Stack,
  Typography,
  LinearProgress,
  Icon,
} from "investira.react.components";

const ReportCounter = memo((props) => {
  return (
    <Stack mx={2} my={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}
      >
        <Icon size={16} iconName="papers" color="primary" />
        <Typography variant="caption">Relatórios</Typography>
      </Stack>
      <Stack my={1}>
        <LinearProgress variant="determinate" value={50} />
      </Stack>
      <Stack>
        <Typography variant="caption">2 de 12 usados</Typography>
      </Stack>
    </Stack>
  );
});

export default ReportCounter;
