import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { validators } from "investira.sdk";
import {
  Persona,
  Stack,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Icon,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "investira.react.components";

import withResponseHandling from "../../hoc/withResponseHandling";
import withMessage from "../../hoc/withMessage";

import EntidadeMembroConfirmDelete from "./EntidadeMembroConfirmDelete";

const Membros = memo((props) => {
  const { membro, onDeleteClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const open = Boolean(anchorEl);

  const isAdmin = membro.perfil === "ad";
  const isMember = membro.perfil === "us";

  function handleMenuOpen(pEvent) {
    setAnchorEl(pEvent.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleDeleteClick(pUsername, pUsuarioId) {
    handleMenuClose();
    setOpenDeleteConfirm(true);
    //onDeleteClick({ username: pUsername, usuario_id: pUsuarioId });
  }

  function handlePromoveClick(pUsuarioId) {
    handleMenuClose();
    props.onModifyClick({
      usuario_id: pUsuarioId,
      perfil: "ad",
    });
  }

  function handleDegradeClick(pUsuarioId) {
    handleMenuClose();
    props.onModifyClick({
      usuario_id: pUsuarioId,
      perfil: "us",
    });
  }

  return (
    <>
      <ListItem
        key={membro.username}
        sx={{ borderRadius: 2 }}
        secondaryAction={
          (isAdmin && props.adminSize > 1) || !isAdmin ? (
            <IconButton edge="end" onClick={handleMenuOpen}>
              <Icon iconName="others" />
            </IconButton>
          ) : null
        }
      >
        <ListItemAvatar>
          <Persona alt={membro.name} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" color="textPrimary">
                {membro.name || membro.username}
              </Typography>
              <Stack direction="row" spacing={1} sx={{ pr: 2 }}>
                {isAdmin && (
                  <span>
                    <Chip
                      variant="outlined"
                      size="small"
                      color="secondary"
                      label="Admin"
                      icon={<Icon iconName="user_tie" size={14} />}
                      sx={{
                        px: 1,
                        "& svg": {
                          fill: "var(--color-secondary-lightness) ",
                        },
                      }}
                    />
                  </span>
                )}
                {membro.status === "N" && (
                  <Chip size="small" color="warning" label={"Novo"} />
                )}
              </Stack>
            </Stack>
          }
          secondary={
            membro.name && (
              <Typography variant="caption" color="textSecondary">
                {membro.username}
              </Typography>
            )
          }
        />
      </ListItem>
      <Menu
        id={`entidade-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {membro.usuario_id && !isAdmin && (
          <MenuItem onClick={() => handlePromoveClick(membro.usuario_id)}>
            <ListItemIcon>
              <Icon iconName="user_tie" size={16} />
            </ListItemIcon>
            <ListItemText>Promover para Administrador</ListItemText>
          </MenuItem>
        )}

        {isAdmin && props.adminSize > 1 && (
          <MenuItem onClick={() => handleDegradeClick(membro.usuario_id)}>
            <ListItemIcon>
              <Icon iconName="user" size={16} />
            </ListItemIcon>
            <ListItemText>Rebaixar para usuário</ListItemText>
          </MenuItem>
        )}

        {/* {!isAdmin && <Divider />} */}
        {!isAdmin && (
          <MenuItem onClick={() => handleDeleteClick(membro.username)}>
            <ListItemIcon>
              <Icon iconName="delete" size={16} color="error" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "error" }}>
              Excluir
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <EntidadeMembroConfirmDelete
        open={openDeleteConfirm}
        membro={membro}
        onDelete={onDeleteClick}
        onClose={() => setOpenDeleteConfirm(false)}
      />
    </>
  );
});

Membros.propTypes = {};

export default withResponseHandling(withMessage(Membros));
