import {
  POSICOES_UPLOAD,
  POSICOES_UPLOAD_LIST,
  POSICOES_LIST,
  POSICOES_DATAS,
  POSICOES_ADD,
  POSICOES_DELETE,
  POSICOES_UPLOAD_DELETE,
} from "../const/servicesURI";
import requestService from "./requestService";
import utils from "../utils";

export const cancel = {
  arquivos: null,
};

export const upload = (pProps = {}, pResolve, pReject, pFinally) => {
  const xFormData = new FormData();

  xFormData.append(`file-${pProps.file.name}`, pProps.file);

  const xProps = {
    headers: { "Content-Type": "multipart/form-data" },
    data: xFormData,
    method: "post",
    onUploadProgress: pProps.onUploadProgress,
  };

  return requestService(xProps, POSICOES_UPLOAD, 3, 2400)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Upload");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const arquivos = (pProps, pResolve, pReject, pFinally) => {
  // if (cancel.arquivos) {
  //   cancel.arquivos.cancel();
  // }

  // cancel.arquivos = httpRequests.cancelToken();

  const xProps = {
    ...pProps,
    // cancelToken: cancel.arquivos.token,
  };

  return requestService(xProps, POSICOES_UPLOAD_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Arquivos");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const remove = (pData, pResolve, pReject, pFinally) => {
  const xProps = {
    data: pData,
    method: "delete",
  };

  return requestService(xProps, POSICOES_DELETE, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Excluir");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const uploadRemove = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: pParams,
    method: "delete",
  };

  return requestService(xProps, POSICOES_UPLOAD_DELETE, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Excluir");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const datas = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: pParams,
  };

  return requestService(xProps, POSICOES_DATAS, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Datas");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const list = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: pParams,
  };

  return requestService(xProps, POSICOES_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Listar");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const add = (pData, pResolve, pReject, pFinally) => {
  const xProps = {
    data: pData,
    method: "post",
  };

  return requestService(xProps, POSICOES_ADD, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Adicionar");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const update = (pData, pResolve, pReject, pFinally) => {
  const xProps = {
    data: pData,
    method: "patch",
  };

  return requestService(xProps, POSICOES_ADD, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Posições Update");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const posicoes = {
  upload,
  arquivos,
  remove,
  cancel,
  list,
  add,
  datas,
  uploadRemove,
  update,
};

export default posicoes;
