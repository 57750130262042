import React, { memo } from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
  Container,
} from "investira.react.components";
import CapitalSocialCard from "./CapitalSocialCard";
import PatrimonioCard from "./PatrimonioCard";
import TecnicoCard from "./TecnicoCard";
import HistoricoFinanceiro from "./HistoricoFinanceiro";
import { useEmpresasContext } from "../../../../../contexts/EmpresasContext";

const ContabilSection = memo(() => {
  const { state, actions } = useEmpresasContext();

  const handleChange = (event) => {
    actions.handleShowPreciseChange(event.target.checked);
  };

  return (
    <Stack spacing={2} sx={{ py: 2 }}>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 1 }}
      >
        <Stack>
          <Typography variant="h6" color="textPrimary">
            Contábil
          </Typography>
        </Stack>
        <Stack>
          <FormControlLabel
            sx={{ marginRight: 0 }}
            control={
              <Switch checked={state.showPrecise} onChange={handleChange} />
            }
            label={
              <Typography variant="body2" color="textPrimary">
                Valores simples
              </Typography>
            }
          />
        </Stack>
      </Stack>

      <Container disableGutters maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PatrimonioCard />
          </Grid>
          <Grid item xs={12} md={6}>
            {state.pessoa?.setor_id === "65.41.3.00" ? (
              <TecnicoCard />
            ) : (
              <CapitalSocialCard />
            )}
          </Grid>
        </Grid>
      </Container>

      <Stack
        direction="row"
        sx={{ gap: "8px 8px" }}
        alignItems
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <HistoricoFinanceiro />
      </Stack>
    </Stack>
  );
});

export default ContabilSection;
