/* APP */
export const APP_LOCATION_CHANGED = "APP_LOCATION_CHANGED";
export const APP_CONNECTION_CHANGED = "APP_CONNECTION_CHANGED";
export const APP_VISIBILITY_CHANGED = "APP_VISIBILITY_CHANGED";
export const APP_ACTIVE_CHANGED = "APP_ACTIVE_CHANGED";
export const APP_THEME_CHANGED = "APP_THEME_CHANGED";
export const APP_LOGOUT = "APP_LOGOUT";

/* AUTH */
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_UPDATED = "AUTH_UPDATED";
export const AUTH_STATUS_TOKEN_UPDATED = "AUTH_STATUS_TOKEN_UPDATED";
export const AUTH_IS_FETCHING = "AUTH_IS_FETCHING";
export const AUTH_LOGIN_RESET = "AUTH_LOGIN_RESET";
export const AUTH_TOKEN_NO_EXPIRED = "AUTH_TOKEN_NO_EXPIRED";

/* EMPRESAS */
export const EMPRESAS_SEARCH = "EMPRESAS_SEARCH";
export const EMPRESAS_LOGOUT = "EMPRESAS_LOGOUT";

/* ATIVOS */
export const ATIVOS_SEARCH = "ATIVOS_SEARCH";
export const ATIVOS_LOGOUT = "ATIVOS_LOGOUT";

/* MESSAGE */
export const MESSAGE_CHANGED = "MESSAGE_CHANGED";
export const MESSAGE_CLOSED = "MESSAGE_CLOSED";
export const MESSAGE_LOGOUT = "MESSAGE_LOGOUT";

/* NOTIFICATIONS */
export const NOTIFICATIONS_CHANGED = "NOTIFICATIONS_CHANGED";
export const NOTIFICATIONS_VIEWED = "NOTIFICATIONS_VIEWED";
export const NOTIFICATIONS_IS_FETCHING = "NOTIFICATIONS_IS_FETCHING";
export const NOTIFICATIONS_AMOUNT_CHANGED = "NOTIFICATIONS_AMOUNT_CHANGED";
export const NOTIFICATIONS_AMOUNT_LOGOUT = "NOTIFICATIONS_AMOUNT_LOGOUT";
export const NOTIFICATIONS_VIEWED_LOGOUT = "NOTIFICATIONS_VIEWED_LOGOUT";
export const NOTIFICATIONS_LIST_LOGOUT = "NOTIFICATIONS_LIST_LOGOUT";

/* INFO */
export const INFO_CHANGED = "INFO_CHANGED";
export const INFO_LOGOUT = "INFO_LOGOUT";

/* USER */
export const USER_LOADED = "USER_LOADED";
export const USER_USERNAME_CHANGED = "USER_USERNAME_CHANGED";
export const USER_PASSWORD_CHANGED = "USER_PASSWORD_CHANGED";
export const USER_PHOTO_CHANGED = "USER_PHOTO_CHANGED";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_PHOTO_LOGOUT = "USER_PHOTO_LOGOUT";
export const USER_DEFAULT_ENTIDADE_CHANGED = "USER_DEFAULT_ENTIDADE_CHANGED";

/* ENTIDADE */
export const ENTIDADE_CHANGED = "ENTIDADE_CHANGED";
export const ENTIDADE_CURRENT_CHANGED = "ENTIDADE_CURRENT_CHANGED";
export const ENTIDADE_LOGOUT = "ENTIDADE_LOGOUT";

/* WEBSOCKET */
export const WS_NOTIFICATION_CHANGED = "WS_NOTIFICATION_CHANGED";
export const WS_CONNECTED_CHANGED = "WS_CONNECTED_CHANGED";
export const WS_LOGOUT = "WS_LOGOUT";

/* BACKGROUND */
export const BACKGROUND_CARTEIRA_CHANGED = "BACKGROUND_CARTEIRA_CHANGED";
export const BACKGROUND_ARQUIVOS_CHANGED = "BACKGROUND_ARQUIVOS_CHANGED";
export const BACKGROUND_REPORTS_CHANGED = "BACKGROUND_REPORTS_CHANGED";
export const BACKGROUND_LOGOUT = "BACKGROUND_LOGOUT";

/* INDICADORES */
export const INDICADORES_CHANGED = "INDICADORES_CHANGED";
export const INDICADORES_LOGOUT = "INDICADORES_LOGOUT";

/* CALCULADORAS */
export const CALCULADORAS_CORRECAO_CHANGED = "CALCULADORAS_CORRECAO_CHANGED";
export const CALCULADORAS_JUROS_CHANGED = "CALCULADORAS_JUROS_CHANGED";
export const CALCULADORAS_MERCADO_CHANGED = "CALCULADORAS_MERCADO_CHANGED";
export const CALCULADORAS_MOEDA_CHANGED = "CALCULADORAS_MOEDA_CHANGED";
export const CALCULADORAS_CURVA_CHANGED = "CALCULADORAS_CURVA_CHANGED";
export const CALCULADORAS_MOEDA_DELETED = "CALCULADORAS_MOEDA_DELETED";
export const CALCULADORAS_CORRECAO_DELETED = "CALCULADORAS_CORRECAO_DELETED";
export const CALCULADORAS_MERCADO_DELETED = "CALCULADORAS_MERCADO_DELETED";
export const CALCULADORAS_JUROS_DELETED = "CALCULADORAS_JUROS_DELETED";
export const CALCULADORAS_CURVA_DELETED = "CALCULADORAS_CURVA_DELETED";

/* CARTEIRAS */
export const CARTEIRAS_CHANGED = "CARTEIRAS_CHANGED";

/* MERCADOS */
export const MERCADOS_CHANGED = "MERCADOS_CHANGED";
export const MERCADOS_LOGOUT = "MERCADOS_LOGOUT";

/* SEARCH */
export const SEARCH_CHANGED = "SEARCH_CHANGED";
export const SEARCH_LOGOUT = "SEARCH_LOGOUT";
export const SEARCH_ATIVOS_CHANGED = "SEARCH_ATIVOS_CHANGED";
export const SEARCH_ATIVOS_LOGOUT = "SEARCH_ATIVOS_LOGOUT";
