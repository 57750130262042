// URI
export const BASE_URL = process.env.REACT_APP_BACK_URI + "api/v1/";

//STATUS
export const STATUS_URL = process.env.REACT_APP_BACK_URI + "status";

// INFO
export const INFO_URL = process.env.REACT_APP_BACK_URI + "info/";

// BASE
export const BASE_USER_URI = BASE_URL + "usuarios/";
export const BASE_OAUTH_URI = BASE_URL + "auth/";
export const BASE_NOTIFICACOES_URI = BASE_URL + "notificacoes/";
export const BASE_POSICOES_URI = BASE_URL + "posicoes/";
export const BASE_RELATORIOS_URI = BASE_URL + "relatorios/";
export const BASE_ENTIDADES_URI = BASE_URL + "entidades/";
export const BASE_CONVITES_URI = BASE_URL + "convites/";
export const BASE_CARTEIRAS_URI = BASE_URL + "carteiras/";
export const BASE_INDICADORES_URI = BASE_URL + "indicadores/";
export const BASE_CONSULTAS_URI = BASE_URL + "consultas/";
export const BASE_OUTPUTS_URI = BASE_URL + "outputs/";
export const BASE_ATIVOSBASE_URI = BASE_URL + "ativosbase/";
export const BASE_ATIVOS_URI = BASE_URL + "ativos/";
export const BASE_PESSOAS_URI = BASE_URL + "pessoas/";
export const BASE_SETORES_URI = BASE_URL + "setores/";

// Autenticação
export const OAUTH_VERIFY_USERNAME = BASE_OAUTH_URI;
export const OAUTH_UPDATE_TOKEN = BASE_OAUTH_URI;
export const OAUTH_LOGIN = BASE_OAUTH_URI;
export const OAUTH_LOGOUT = BASE_OAUTH_URI;
export const OAUTH_REGISTER = BASE_OAUTH_URI + "register/";
export const OAUTH_REGISTER_CODE_VALIDATE = OAUTH_REGISTER;
export const OAUTH_PASSWORD_RESET = BASE_OAUTH_URI + "password/";
export const OAUTH_PASSWORD_RESET_VALIDATE = OAUTH_PASSWORD_RESET;
export const OAUTH_PASSWORD_RESET_CONFIRM = OAUTH_PASSWORD_RESET;

// User
export const USER_READ = BASE_USER_URI;
export const USER_READ_USERNAME = BASE_USER_URI;
export const USER_UPDATE = BASE_USER_URI;
export const USER_UPDATE_PASSWORD = BASE_USER_URI + "password/";
export const USER_FAVORITE_LIST = BASE_USER_URI + "favoritos/";
export const USER_FAVORITE_UPDATE = BASE_USER_URI;
export const USER_SUMMARY_LIST = BASE_USER_URI + "resumos/";
export const USER_SUMMARY_UPDATE = BASE_USER_URI;

// Notificações
export const NOTIFICACAO_LIST = BASE_NOTIFICACOES_URI;
export const NOTIFICACAO_RESPONDER = BASE_NOTIFICACOES_URI;

// Posicoes
export const POSICOES_UPLOAD_LIST = BASE_POSICOES_URI + "upload/";
export const POSICOES_UPLOAD = BASE_POSICOES_URI + "upload/";
export const POSICOES_UPLOAD_DELETE = BASE_POSICOES_URI + "upload/";
export const POSICOES_DELETE = BASE_POSICOES_URI;
export const POSICOES_LIST = BASE_POSICOES_URI;
export const POSICOES_ADD = BASE_POSICOES_URI;
export const POSICOES_DATAS = BASE_POSICOES_URI + "datas/";

// Relatórios
export const RELATORIOS_CREATE = BASE_RELATORIOS_URI;

// Entidades
export const ENTIDADES_LIST = BASE_ENTIDADES_URI;
export const ENTIDADES_UPDATE = BASE_ENTIDADES_URI;
export const ENTIDADES_DELETE = BASE_ENTIDADES_URI;
export const ENTIDADES_USUARIO_ADD = BASE_ENTIDADES_URI + "usuario/";
export const ENTIDADES_USUARIO_LIST = BASE_ENTIDADES_URI + "usuario/";
export const ENTIDADES_USUARIO_REMOVE = BASE_ENTIDADES_URI + "usuario/";
export const ENTIDADES_USUARIO_MODIFY = BASE_ENTIDADES_URI + "usuario/";
export const ENTIDADES_IFEED_LOGO = BASE_ENTIDADES_URI + "ifeed/logo/";

// Convites
export const CONVITES_CONVIDAR = BASE_CONVITES_URI;

// Carteiras
export const CARTEIRAS_LIST = BASE_CARTEIRAS_URI;
export const CARTEIRAS_FAV = BASE_CARTEIRAS_URI;
export const CARTEIRAS_DELETE = BASE_CARTEIRAS_URI;
export const CARTEIRAS_CREATE = BASE_CARTEIRAS_URI;
export const CARTEIRAS_READ = BASE_CARTEIRAS_URI;

// Indicadores
export const INDICADORES_LIST = BASE_INDICADORES_URI;
export const INDICADORES_COTACAO = BASE_INDICADORES_URI + "cotacao";

// Consultas
export const CONSULTAS_INDICADORES = BASE_CONSULTAS_URI + "indicadores/";
export const CONSULTAS_INDICADORES_VARIACAO =
  CONSULTAS_INDICADORES + "variacao/";

// Outputs
export const OUTPUTS_LOGS = BASE_OUTPUTS_URI + "logs/";
export const OUTPUTS_LOGS_DELETE = OUTPUTS_LOGS;
export const OUTPUTS_DURATION = BASE_OUTPUTS_URI + "duration/";
export const OUTPUTS_VAR = BASE_OUTPUTS_URI + "var/";
export const OUTPUTS_DOWNLOAD = BASE_OUTPUTS_URI + "download/";
export const OUTPUTS_PREVIEW = BASE_OUTPUTS_URI + "preview/";

// Ativos Base
export const ATIVOSBASE_LIST = BASE_ATIVOSBASE_URI;
export const ATIVOSBASE_MERCADO = BASE_ATIVOSBASE_URI + "mercados";

// Ativos
export const ATIVOS_LIST = BASE_ATIVOS_URI;
export const ATIVOS_MERCADO = BASE_ATIVOS_URI + "mercados";
export const ATIVOS_TIPOS = BASE_ATIVOS_URI + "tipos";
export const ATIVOS_COTACAO = BASE_ATIVOS_URI + "cotacao";
export const ATIVOS_CURVA = BASE_ATIVOS_URI + "curva";

// Pessoas
export const PESSOAS_LIST = BASE_PESSOAS_URI;
export const PESSOAS_BALANCO = BASE_PESSOAS_URI + "balanco";
export const PESSOAS_BALANCO_RESUMO = BASE_PESSOAS_URI + "balanco/disponivel";
export const PESSOAS_BALANCO_CONTAS = BASE_PESSOAS_URI + "balanco/contas";
export const PESSOAS_BALANCO_SALDOS = BASE_PESSOAS_URI + "balanco/saldos";
export const PESSOAS_PL = BASE_PESSOAS_URI + "pl";
export const PESSOAS_CS = BASE_PESSOAS_URI + "cs";
export const PESSOAS_ATIVOS = BASE_PESSOAS_URI + "ativos";
export const PESSOAS_SETORES = BASE_PESSOAS_URI + "setores";
export const PESSOAS_BR = BASE_PESSOAS_URI + "br";

// Setores
export const SETORES_LIST = BASE_SETORES_URI;
export const SETORES_READ = BASE_SETORES_URI;
