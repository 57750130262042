import {
  CALCULADORAS_MOEDA_CHANGED,
  CALCULADORAS_CORRECAO_CHANGED,
  CALCULADORAS_MERCADO_CHANGED,
  CALCULADORAS_JUROS_CHANGED,
  CALCULADORAS_CURVA_CHANGED,
  CALCULADORAS_MOEDA_DELETED,
  CALCULADORAS_CORRECAO_DELETED,
  CALCULADORAS_MERCADO_DELETED,
  CALCULADORAS_JUROS_DELETED,
  CALCULADORAS_CURVA_DELETED,
} from "../../const/actionsType";

import { v4 as uuidv4 } from "uuid";

import { dates } from "investira.sdk";

const INITIAL_STATE = {
  M: {
    data: [],
    updatedAt: dates.toDate(),
  },
  C: {
    data: [],
    updatedAt: dates.toDate(),
  },
  I: {
    data: [],
    updatedAt: dates.toDate(),
  },
  J: {
    data: [],
    updatedAt: dates.toDate(),
  },
  curva: {
    data: [],
    updatedAt: dates.toDate(),
  },
};

const RESET_STATE = {
  ...INITIAL_STATE,
};

function backgroundReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CALCULADORAS_MOEDA_CHANGED:
      return {
        ...state,
        M: {
          ...state.M,
          data: addID([action.payload].concat(state.M.data)),
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_CORRECAO_CHANGED:
      return {
        ...state,
        C: {
          ...state.C,
          data: addID([action.payload].concat(state.C.data)),
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_MERCADO_CHANGED:
      return {
        ...state,
        I: {
          ...state.I,
          data: addID([action.payload].concat(state.I.data)),
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_JUROS_CHANGED:
      return {
        ...state,
        J: {
          ...state.J,
          data: addID([action.payload].concat(state.J.data)),
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_MOEDA_DELETED:
      return {
        ...state,
        M: {
          ...state.M,
          data: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_CORRECAO_DELETED:
      return {
        ...state,
        C: {
          ...state.C,
          data: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_MERCADO_DELETED:
      return {
        ...state,
        I: {
          ...state.I,
          data: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_JUROS_DELETED:
      return {
        ...state,
        J: {
          ...state.J,
          data: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_CURVA_CHANGED:
      return {
        ...state,
        curva: {
          ...state.curva,
          data: addID([action.payload].concat(state.curva.data)),
          updatedAt: dates.toDate(),
        },
      };
    case CALCULADORAS_CURVA_DELETED:
      return {
        ...state,
        curva: {
          ...state.curva,
          data: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    default:
      return state;
  }
}

function addID(pArray) {
  return pArray.map((xItem) => {
    return { id: xItem.id || uuidv4(), ...xItem };
  });
}

export default backgroundReducer;
