import React, { memo } from "react";
import {
  Typography,
  Stack,
  CircularProgress,
} from "investira.react.components";
import { validators } from "investira.sdk";

const CardState = memo((props) => {
  const { loading = true, data = null, children } = props;
  return (
    <>
      {loading && (
        <Stack
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          sx={{ py: 2, height: "100%" }}
        >
          <CircularProgress size={24} />
        </Stack>
      )}

      {!loading && validators.isEmpty(data) && (
        <Stack
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          sx={{ py: 2, height: "100%" }}
        >
          <Typography variant="caption" color="textSecondary">
            Nenhuma informação disponível
          </Typography>
        </Stack>
      )}

      {!loading && !validators.isEmpty(data) && children}
    </>
  );
});

export default CardState;
