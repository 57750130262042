import React, { memo, useEffect } from "react";
import * as Yup from "yup";
import { formats, validators } from "investira.sdk";
import { useFormik } from "formik";
import {
  Stack,
  Button,
  Icon,
  TextField,
  LoadingButton,
  Typography,
  IconButton,
} from "investira.react.components";
import { useSimulacoesContext } from "../../contexts/SimulacoesContext";
import { VALIDATIONS } from "../../const";

const Carteira = memo((props) => {
  const { state, actions } = useSimulacoesContext();

  const VALIDATION_SCHEMA = Yup.object().shape({
    carteira: Yup.string()
      .max(128, "Máximo de 128 caracteres")
      .required(VALIDATIONS.REQUIRED),
    carteira_seuid: Yup.string()
      .max(30, "Máximo de 30 caracteres")
      .required(VALIDATIONS.REQUIRED),
  });

  const formik = useFormik({
    initialValues: { carteira: "", carteira_seuid: "" },
    validationSchema: VALIDATION_SCHEMA,
    validateOnMount: false,
    onSubmit: (pValues, pActions) => {
      pActions.setSubmitting(true);
      if (props.mode === "edit") {
        actions.modifyCarteira(
          { carteira_id: state.carteira_id, ...pValues },
          () => {
            actions.handleModifyCarteiraCancel();
          },
          null,
          () => {
            pActions.setSubmitting(false);
          }
        );
      } else {
        actions.createCarteira(
          { ...pValues, simulada: true },
          null,
          null,
          () => {
            pActions.setSubmitting(false);
          }
        );
      }
    },
  });

  useEffect(() => {
    props.id && actions.readCarteira(props.id);
  }, [props.id]);

  useEffect(() => {
    if (props.mode === "edit") {
      formik.setValues({
        carteira: state.carteira,
        carteira_seuid: state.carteira_seuid,
      });
    }
  }, [state.carteira, state.carteira_seuid, props.mode]);

  if (!validators.isNull(state.carteira_id) && props.mode === "read") {
    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="end"
      >
        <Stack>
          <Typography variant="caption">{state.carteira}</Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack>
              <Typography variant="h4">{state.carteira_seuid}</Typography>
            </Stack>
            <Stack>
              <IconButton
                color="primary"
                onClick={actions.handleModifyCarteira}
              >
                <Icon iconName="edit" size={18} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        {state.data_posicao && (
          <Stack>
            <Typography variant="caption" color="textSecondary">
              Data posição
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack>
                <Typography variant="body1" color="textPrimary">
                  {formats.formatDateCustom(state.data_posicao, "DD/MM/YYYY")}
                </Typography>
              </Stack>
              <Stack>
                <IconButton color="primary" onClick={actions.handleDataPosicao}>
                  <Icon iconName="edit" size={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
      <Stack>
        <Stack spacing={2}>
          <Stack>
            <TextField
              id="carteira"
              name="carteira"
              label="Carteira"
              value={formik.values.carteira}
              onChange={formik.handleChange}
              placeholder="Digite um nome"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              error={Boolean(formik.errors.carteira)}
              helperText={formik.errors.carteira}
              disabled={formik.isSubmitting}
            />
          </Stack>
          <Stack flexGrow={0} sx={{ width: "50%" }}>
            <TextField
              id="carteira_seuid"
              name="carteira_seuid"
              label="Apelido"
              value={formik.values.carteira_seuid}
              onChange={formik.handleChange}
              placeholder="Digite um apelido para a carteira"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              error={Boolean(formik.errors.carteira_seuid)}
              helperText={formik.errors.carteira_seuid}
              disabled={formik.isSubmitting}
            />
          </Stack>
        </Stack>
        <Stack
          flex={1}
          direction="row"
          justifyContent="flex-end"
          sx={{ py: 2 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            type="button"
            color="primary"
            onClick={actions.handleModifyCarteiraCancel}
          >
            Cancelar
          </Button>
          <LoadingButton
            startIcon={<Icon iconName="upload" size={16} />}
            variant="contained"
            type="submit"
            loading={formik.isSubmitting}
            disabled={
              validators.isEmpty(formik.values.carteira) ||
              formik.isSubmitting ||
              !formik.isValid
            }
          >
            Salvar
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
});

export default Carteira;
