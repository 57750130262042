import React, { useState, useEffect } from "react";
import { TextField } from "investira.react.components";

const ExcelLikeInput = (props) => {
  const { decimal = 2, value = 0, onChange } = props;

  const xInitDisplayValue = formatNumber(String(value), decimal);
  const xInitRawValue = formatRaw(xInitDisplayValue, decimal);

  const [displayValue, setDisplayValue] = useState(xInitDisplayValue);
  const [rawValue, setRawValue] = useState(xInitRawValue);

  function formatNumber(pNum, pDecimal = 2) {
    // Remove todos os caracteres não numéricos, exceto vírgula e ponto
    const xCleanNum = pNum.replace(/[^\d,.-]/g, "");
    // Substitui vírgula por ponto para cálculos
    const xNumericValue = parseFloat(xCleanNum.replace(",", "."));

    if (isNaN(xNumericValue)) {
      return "";
    }

    // Formata o número para o padrão brasileiro
    return xNumericValue.toLocaleString("pt-BR", {
      minimumFractionDigits: pDecimal,
      maximumFractionDigits: pDecimal,
      style: "decimal",
      signDisplay: "auto",
    });
  }

  function formatRaw(pValue = 0, pDecimal = 2) {
    let xRawValue = pValue.replace(",", ".");
    xRawValue = Number(xRawValue).toFixed(pDecimal);
    return xRawValue;
  }

  const handleChange = (pEvent, pDecimal) => {
    const xInputValue = pEvent.target.value;
    // Permite apenas números e vírgula
    if (/^-?\d*,?\d*$/.test(xInputValue)) {
      setDisplayValue(xInputValue);
      let xRawValue = formatRaw(xInputValue, pDecimal);
      xRawValue = Number(xRawValue);
      setRawValue(xRawValue);
    }
  };

  const handleBlur = () => {
    const formattedValue = formatNumber(displayValue, decimal);
    setDisplayValue(formattedValue);
    // Atualiza o valor bruto com base no valor formatado
    setRawValue(formattedValue.replace(/\./g, "").replace(",", "."));
  };

  const handleFocus = () => {
    // Ao focar, mostra o valor sem formatação para edição
    setDisplayValue(displayValue.replace(/\./g, ""));
  };

  useEffect(() => {
    onChange && onChange(Number(rawValue));
  }, [rawValue]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      inputProps={{
        inputMode: "decimal",
      }}
      {...props}
      value={displayValue}
      onChange={(e) => handleChange(e, decimal)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      InputLabelProps={{ shrink: true }}
    />
  );
};
export default ExcelLikeInput;
