import React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Drawer,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Icon,
  Stack,
  ListSubheader,
  IconButton,
  Tooltip,
} from "investira.react.components";
import { validators } from "investira.sdk";
import { ReportCounter, UserProfile } from "../../../components/molecules";
import { SECTIONS } from "../../../enums";

const MAIN_ROUTES_WIDTH = 64;
const NESTED_ROUTES_WIDTH = 240;
const DRAWER_WIDTH = MAIN_ROUTES_WIDTH + NESTED_ROUTES_WIDTH + 2;

const Sidebar = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  //const { actions } = useNavigationContext();

  const [selected, setSelected] = React.useState(null);
  const [nested, setNested] = React.useState(null);

  const options = [
    // {
    //   ...SECTIONS.home,
    //   nested: null,
    //   disabled: false,
    // },
    // {
    //   key: "divider",
    // },
    {
      ...SECTIONS.dashboard,
      nested: null,
      disabled: false,
    },
    {
      key: "divider",
    },
    {
      ...SECTIONS.reports,
      disabled: false,
      nested: [
        {
          ...SECTIONS.duration,
          disabled: false,
        },
        {
          ...SECTIONS.var,
          disabled: true,
        },
        {
          ...SECTIONS.enquadramento,
          disabled: true,
        },
        {
          ...SECTIONS.raiox,
          disabled: true,
        },
        {
          ...SECTIONS.rentabilidade,
          disabled: true,
        },
        {
          ...SECTIONS.stresstest,
          disabled: true,
        },
        {
          ...SECTIONS.backtesting,
          disabled: true,
        },
        {
          ...SECTIONS.fronteiraeficiente,
          disabled: true,
        },
      ],
    },
    // {
    //   ...SECTIONS["carteiras-abertas"],
    //   nested: null,
    //   disabled: process.env.NODE_ENV === "development" ? false : true,
    // },
    {
      ...SECTIONS.carteiras,
      nested: null,
      disabled: false,
    },
    {
      ...SECTIONS.uploads,
      nested: null,
      disabled: false,
    },
    {
      key: "divider",
      component: <Divider />,
    },
    {
      ...SECTIONS.simulacoes,
      nested: null,
      disabled: process.env.NODE_ENV === "development" ? false : true,
    },
    {
      key: "divider",
    },
    {
      ...SECTIONS.empresas,
      nested: null,
      disabled: false,
    },
    {
      ...SECTIONS.ativos,
      nested: null,
      disabled: false,
    },
    {
      ...SECTIONS.calculadoras,
      nested: null,
      disabled: false,
    },
    // {
    //   ...SECTIONS.dados,
    //   nested: null,
    //   disabled: false,
    // },
    // {
    //   key: "origens",
    //   label: "Origens",
    //   path: "/origens",
    //   icon: <Icon iconName="dna" color="primary" size={24} />,
    //   nested: null,
    // },
  ];

  const hasNested = !validators.isNull(nested);

  const MENU_WIDTH = hasNested ? DRAWER_WIDTH : MAIN_ROUTES_WIDTH;

  function updateRoute(pPath = "/") {
    navigate(pPath);
  }

  function handleSelected(pIndex, pCurrentOptions) {
    if (selected === pIndex && hasNested) {
      setNested(null);
      return;
    }

    if (validators.isNull(pCurrentOptions.nested)) {
      updateRoute(pCurrentOptions.path);
      setNested(null);
    } else {
      setNested(pCurrentOptions.nested);
    }

    setSelected(pIndex);
  }

  function handleNestedSelected(pCurrentOptions) {
    updateRoute(pCurrentOptions.path);
  }

  return (
    <Drawer
      id="sidebar"
      variant="permanent"
      sx={{
        width: MENU_WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: MENU_WIDTH,
          boxSizing: "border-box",
          overflow: "hidden",
          zIndex: 0,
        },
      }}
    >
      <Stack direction="row" flexGrow={1}>
        <Stack
          sx={{
            width: MAIN_ROUTES_WIDTH,
            flexShrink: 0,
            borderRight: "1px solid #2e2e46",
            justifyContent: "space-between",
          }}
          spacing={2}
        >
          {/* Root Menu */}
          <Stack>
            <AppBar position="static" elevation={0}>
              <Toolbar disableGutters sx={{ justifyContent: "center" }}>
                <Link to={props.isLoggedIn ? "/dashboard" : "/"}>
                  <Icon iconName="investira_icon" size={32} color="primary" />
                </Link>
              </Toolbar>
            </AppBar>
            <List disablePadding>
              {options.map((option, index) => {
                if (validators.isEmpty(option)) {
                  return null;
                }

                if (option.key === "divider") {
                  return <Divider key={`${option.key}-${index}`} />;
                }

                const isSelected =
                  pathname.split("/")[1] === option.path.split("/")[1];

                return (
                  <ListItem
                    key={`${option.key}-${index}`}
                    disablePadding
                    disableGutters
                    disabled={option.disabled}
                    button
                    to={option.path}
                    onClick={() => handleSelected(index, option)}
                    selected={isSelected}
                    sx={{ height: "64px", width: "64px" }}
                  >
                    <Tooltip title={option.label} placement="right">
                      <IconButton
                        disableRipple
                        sx={{
                          height: "64px",
                          width: "64px",
                        }}
                      >
                        {option.icon}
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                );
              })}
            </List>
          </Stack>

          {/* {props.isLoggedIn && ( */}
          <Stack>
            <UserProfile />
          </Stack>
          {/* )} */}
        </Stack>
        {/* Nested Menu */}
        <Stack
          sx={{
            width: hasNested ? NESTED_ROUTES_WIDTH : "0",
            flexShrink: 0,
          }}
        >
          {hasNested && (
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                      sx={{
                        width: "24px",
                        height: "24px",
                      }}
                      onClick={() => setNested(null)}
                    >
                      <Icon
                        iconName="arrow-previous"
                        color="secondaryLightness"
                        size={12}
                      />
                    </IconButton>
                    <span>{options[selected].label}</span>
                  </Stack>
                </ListSubheader>
              }
            >
              {nested.map((option) => (
                <ListItem
                  key={option.label}
                  disableGutters
                  disablePadding
                  disabled={option.disabled}
                  button
                  onClick={() => handleNestedSelected(option)}
                  selected={pathname === option.path}
                >
                  <ListItemButton>
                    <ListItemText primary={option.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
          {options[selected]?.key === "relatorios" && (
            <>
              <Divider />
              <ReportCounter />
            </>
          )}
        </Stack>
      </Stack>
    </Drawer>
  );
};

Sidebar.displayName = "Sidebar";

export default Sidebar;
