import {
  ENTIDADES_USUARIO_ADD,
  ENTIDADES_USUARIO_LIST,
  ENTIDADES_USUARIO_REMOVE,
  ENTIDADES_USUARIO_MODIFY,
} from "../const/servicesURI";
import { httpRequests } from "investira.sdk";
import requestService from "./requestService";
import utils from "../utils";

export const cancel = {
  list: null,
};

export const list = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.list) {
    cancel.list.cancel();
  }

  cancel.list = httpRequests.cancelToken();

  const xProps = {
    params: {
      usuario_id: "*",
    },
    cancelToken: cancel.list.token,
  };

  return requestService(xProps, ENTIDADES_USUARIO_LIST, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidade Usuário List");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const remove = (pProps, pResolve, pReject, pFinally) => {
  const xProps = {
    data: { ...pProps },
    method: "DELETE",
  };

  return requestService(xProps, ENTIDADES_USUARIO_REMOVE, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidade Usuário List");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const modify = (pProps, pResolve, pReject, pFinally) => {
  const xProps = {
    data: { ...pProps },
    method: "patch",
  };

  return requestService(xProps, ENTIDADES_USUARIO_MODIFY, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidade Usuário Modify");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const entidades = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: {
      entidade_id: "*",
    },
  };

  return requestService(xProps, ENTIDADES_USUARIO_LIST, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Entidade Usuário List");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const entidadesUsuario = { list, remove, modify, entidades, cancel };

export default entidadesUsuario;
