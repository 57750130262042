import React, { memo, useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { validators, strings, objects } from "investira.sdk";
import { displays } from "investira.react.lib";
import { PESSOA_TIPO } from "@investirapri/financa.js/lib/enums";
import {
  Stack,
  CircularProgress,
  TextField,
  InputAdornment,
  Icon,
  Popover,
  Backdrop,
  Typography,
  Divider,
  IconButton,
  Button,
  Skeleton,
  Badge,
} from "investira.react.components";
import services from "../../../services";
import utils from "../../../utils";
import withResponseHandling from "../../../hoc/withResponseHandling";
import { acEmpresasSearchChanged } from "../../../store/actions";
import { PessoaImage } from "../../molecules";
import BuscaEmpresaFilter from "./BuscaEmpresaFilter";

const BuscaEmpresa = memo((props) => {
  const PAGE_SIZE = 10;

  const empresasSearch = useSelector((store) => store.empresas.search);

  const { top_setor_id, pessoa_tipo_id, pessoas_tipo } = empresasSearch;

  const location = useLocation();
  const navigate = useNavigate();

  const storeDispatch = useDispatch();

  // const queryPesquisa =
  //   strings.querystringToObject(location.search).pesquisa || "";

  const [anchorEl, setAnchorEl] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState({
    top_setor_id: top_setor_id,
    pessoa_tipo_id: pessoa_tipo_id,
  });
  const [modalStyle, setModalStyle] = useState({});

  const pt = { ...pessoas_tipo };
  const ptf = objects.deleteNull({ top_setor_id, ...pt });

  const initCount = validators.isEmpty(ptf)
    ? 0
    : Object.keys(objects.deleteNull({ top_setor_id, ...pt }));

  const [countFilters, setCountFilters] = useState(initCount.length);
  const [isLoading, setRequesting] = useState(true);
  const [pesquisa, setPesquisa] = useState("");
  // const [pesquisa, setPesquisa] = useState(queryPesquisa);
  const [data, setData] = useState({});
  const [pages, setPages] = useState({});
  const [message, setMessage] = useState(null);

  const anchorRef = useRef();

  const open = Boolean(anchorEl);

  const TOTAL_ITEMS = pages.total_items;

  const handleOpen = (pEvent) => {
    setAnchorEl(pEvent.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenFilters = () => {
    setOpenFilters(true);
  };

  const handleCloseOpenFilters = () => {
    setOpenFilters(false);
  };

  const handleChange = (pEvent) => {
    const xValue = pEvent.currentTarget.value;

    setPesquisa(pEvent.currentTarget.value);
    handleOpen(pEvent);

    if (validators.isEmpty(xValue)) {
      handleClose();
      return;
    }
  };

  const handleFocus = (pEvent) => {
    if (!validators.isEmpty(data) && !validators.isEmpty(pesquisa) && !open) {
      const xWindowHeight = window.innerHeight;
      const xInputRect = pEvent.currentTarget.getBoundingClientRect();

      const availableHeightBelow = xWindowHeight - xInputRect.bottom;

      setModalStyle({
        maxHeight: `${availableHeightBelow}px`, // Altura máxima do modal
      });

      handleOpen(pEvent);
    }
  };

  const handleClearPesquisa = (pLocation = {}) => {
    setPesquisa("");
    setData({});
    setPages({});

    if (!validators.isEmpty(pLocation?.search)) {
      const xSearch = strings.querystringToObject(pLocation.search);

      if (!validators.isEmpty(xSearch.pesquisa)) {
        delete xSearch.pesquisa;
        const xQuery = objects.objectToQueryString(xSearch);
        navigate(`/empresas${xQuery}`);
      }
    }
  };

  const handleGoTo = (pEvent, pPessoaId, pClearOnClose) => {
    pEvent.preventDefault();
    pEvent.stopPropagation();

    handleClose();
    pClearOnClose && handleClearPesquisa();
    navigate(`/empresas/${pPessoaId}`);
  };

  // TODO: Removes espaço da pesquisa
  const handleSearch = (pPesquisa, pFilters) => {
    const xFilters = objects.deleteNull({ ...pFilters });

    const xPesquisa = pPesquisa.trim();

    const xSearch = { pesquisa: xPesquisa, ...xFilters };
    const xSearchQueryString = objects.objectToQueryString(xSearch);
    const xUrl = `/empresas${xSearchQueryString}`;

    handleClose();
    navigate(xUrl);
  };

  const handleUpdateFilters = (pObjFilters, pAnchorRef, pPesquisa) => {
    const xFilters = {};
    const { top_setor_id, ...tipos } = pObjFilters;

    filtersSize(pObjFilters);

    xFilters["top_setor_id"] = top_setor_id;
    xFilters["pessoa_tipo_id"] = somaTiposSelected({ ...tipos });

    setFilters((prevFilters) => {
      const xNewFilters = {
        ...prevFilters,
        ...xFilters,
      };

      storeDispatch(
        acEmpresasSearchChanged({ ...xNewFilters, pessoas_tipo: { ...tipos } })
      );

      return {
        ...prevFilters,
        ...xFilters,
      };
    });

    !validators.isEmpty(pPesquisa) && setAnchorEl(pAnchorRef.current);
  };

  function filtersSize(pObj) {
    const xFiltersSize = Object.keys(pObj).length;
    setCountFilters(xFiltersSize);
  }

  function somaTiposSelected(pObjTipos) {
    let xPessoaTipoId = 0;
    const xTiposSelected = Object.keys({ ...pObjTipos });

    xTiposSelected.forEach((xKey) => {
      xPessoaTipoId = xPessoaTipoId + PESSOA_TIPO[xKey].id;
    });

    return xPessoaTipoId;
  }

  function verifySpecialChar(pStr) {
    const regex = /[./-]/;
    return regex.test(pStr);
  }

  function cleanSpecialChar(pStr) {
    const regex = /[./-]/g;
    if (verifySpecialChar(pStr)) {
      return pStr.replace(regex, "");
    }

    return pStr;
  }

  function isNumeric(pStr) {
    if (typeof pStr !== "string") {
      return false;
    }

    return /^[0-9]+$/.test(pStr);
  }

  const request = useMemo(() => {
    return utils.helpers.debounce(
      (pParams, pRes, pErr, pFin) =>
        services.pessoas.list(pParams, pRes, pErr, pFin),
      1000
    );
  }, []);

  function removeDoubleSpaces(pStr) {
    return pStr.replace(/\s{2,}/g, " ").trim();
  }

  function removeEmptyItems(pArr) {
    return pArr.filter((item) => item !== "");
  }

  function formatSearch(pValue) {
    let xValue = removeDoubleSpaces(pValue);
    xValue = cleanSpecialChar(pValue);

    let xStrings = xValue.split(" ");
    xStrings = removeEmptyItems(xStrings);
    xStrings = xStrings.map((xString) => {
      return `+${xString}*`.trim();
    });

    const xSearchFormated = xStrings.join("");

    return xSearchFormated;
  }

  useEffect(() => {
    if (pesquisa && pesquisa.length > 1 && !props.pageIsLoading) {
      setRequesting(true);
      setData({});
      setMessage(null);

      const xPesquisa = formatSearch(pesquisa);

      request(
        {
          pesquisa: xPesquisa,
          size: PAGE_SIZE,
          cancelado: "null",
          ...(!isNumeric(xPesquisa) && { ...filters }),
          sort: "relevancia DESC, __mr__ DESC, top_setor_id ASC, setor_id DESC, pessoa_fantasia ASC",
        },
        (rRes) => {
          setData(rRes.data);
          setPages(rRes.pages);

          if (validators.isEmpty(rRes.data)) {
            setMessage("Nenhum dado encontrado");
          }
        },
        (rErr) => {
          setMessage("Erro na busca por empresa");
        },
        () => {
          setRequesting(false);
        }
      );
    }
  }, [pesquisa, request, filters]);

  // useEffect(() => {
  //   if (props.pageIsLoading) {
  //     handleClose();
  //   }
  // }, [props.pageIsLoading]);

  console.log("isLoading:", isLoading);
  console.log("validators.isEmpty(data)", validators.isEmpty(data));

  return (
    <>
      <Stack
        id="search-area"
        sx={{
          position: "relative",
          // minWidth: { xs: "100%", sm: "400px" },
          width: "100%",
          zIndex: "999",
        }}
      >
        {props.pageIsLoading ? (
          <Skeleton variant="rounded" width="100%" height={56} />
        ) : (
          <TextField
            id="busca-empresa"
            inputRef={anchorRef}
            value={pesquisa}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleClose}
            variant="outlined"
            placeholder={"Pesquise por empresas"}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon iconName="find" size={21} color={"primary"} />
                </InputAdornment>
              ),
              // endAdornment: (
              //   <InputAdornment position="end">
              //     {!validators.isEmpty(pesquisa) && (
              //       <IconButton
              //         onClick={() => handleClearPesquisa(location)}
              //         size="small"
              //       >
              //         <Icon
              //           iconName="cancel"
              //           size={12}
              //           color="secondaryLightness"
              //         />
              //       </IconButton>
              //     )}
              //     <Divider orientation="vertical" />
              //     <Badge
              //       badgeContent={countFilters}
              //       color="warning"
              //       overlap="circular"
              //     >
              //       <IconButton onClick={handleClickOpenFilters}>
              //         <Icon iconName="filter" size={21} color={"primary"} />
              //       </IconButton>
              //     </Badge>
              //   </InputAdornment>
              // ),
            }}
            disabled={props.pageIsLoading}
          />
        )}
      </Stack>
      <Popover
        id="popover-busca-empresa"
        disableAutoFocus={true}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: "center",
        }}
        style={modalStyle}
        sx={{ maxHeight: "420px" }}
      >
        <Stack sx={{ minWidth: { xs: "200px", sm: "420px" } }}>
          {!isLoading && !validators.isEmpty(data) && (
            <>
              <Stack id="busca-empresa-list" sx={{ px: 0 }} component="ul">
                {Object.values(data).map((xPessoa, xIndex) => {
                  return (
                    <Stack
                      id={xPessoa.pessoa_id}
                      key={xPessoa.pessoa_id}
                      direction="row"
                      spacing={3}
                      alignItems="center"
                      component="li"
                      onClick={(e) =>
                        handleGoTo(e, xPessoa.pessoa_id, props.clearOnClose)
                      }
                      sx={{
                        px: 2,
                        py: 1,
                        cursor: "pointer",
                        "&:hover": {
                          color: "gray",
                          backgroundColor: "background.main",
                        },
                      }}
                    >
                      <Stack>
                        <PessoaImage
                          logoPessoaId={xPessoa.logo_pessoa_id}
                          alt={xPessoa.pessoa}
                          hasLogo={xPessoa.logo_ok}
                          size={36}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="body2" color="textPrimary">
                          {xPessoa.pessoa_fantasia || xPessoa.pessoa}
                        </Typography>
                        <Stack direction="row" spacing={{ xs: 0, sm: 1 }}>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{
                              display: { xs: "none", sm: "inline-block" },
                            }}
                          >
                            {`${xPessoa.pessoa}`}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {`${displays.cnpj(xPessoa.cnpj)}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
              <Stack sx={{ px: 2, py: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => handleSearch(pesquisa, filters)}
                >
                  Visualizar Todos ({TOTAL_ITEMS})
                </Button>
              </Stack>
            </>
          )}

          {isLoading && (
            <Stack
              justifyContent="center"
              alignItems="center"
              flexGrow={1}
              sx={{ py: 2 }}
            >
              <CircularProgress size={24} />
            </Stack>
          )}

          {!isLoading && validators.isEmpty(data) && (
            <Stack
              justifyContent="center"
              alignItems="center"
              flexGrow={1}
              sx={{ py: 2 }}
            >
              <Typography variant="body2" color="textPrimary">
                {message}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Popover>

      <Backdrop style={{ marginTop: 0 }} open={open} />
      <BuscaEmpresaFilter
        open={openFilters}
        onSubmit={(pObjFilters) =>
          handleUpdateFilters(pObjFilters, anchorRef, pesquisa)
        }
        onClose={handleCloseOpenFilters}
      />
    </>
  );
});

export default withResponseHandling(BuscaEmpresa);
