import React, { memo, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { validators } from "investira.sdk";
import {
  Stack,
  Typography,
  CircularProgress,
  Button,
  Icon,
  Box,
} from "investira.react.components";
import { CardInfo } from "../../../../components/molecules";
import services from "../../../../services";
import { ATIVO_TIPO } from "@investirapri/financa.js/lib/enums";
import { MERCADOS_ICONES } from "../../../../enums";
//import { ATIVO_TIPO } from "../../../../enums";

const AtivosSection = memo((props) => {
  const { pessoa } = props;
  const params = useParams();
  const [ativos, setAtivos] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (pessoa.pessoa_id) {
      setLoading(true);
      services.pessoas.ativos(
        {
          pessoa_id: pessoa.pessoa_id,
          sort: "mercado ASC",
          logo_ok: 1,
        },
        (rRes) => {
          setAtivos(rRes.data);
        },
        null,
        () => {
          setLoading(false);
        }
      );
    }
  }, [pessoa.pessoa_id]);

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ py: 2, height: "100%" }}
      >
        <CircularProgress size={24} />
      </Stack>
    );
  }

  if (validators.isEmpty(ativos)) {
    return null;
  }

  return (
    <Stack spacing={2} sx={{ py: 2 }}>
      <Stack direction="row">
        <Stack>
          <Typography variant="h6" color="textPrimary">
            Ativos Relacionados
          </Typography>
        </Stack>
        <Stack flexGrow="1"></Stack>
        {/* <Stack>
          <Button
            component={HashLink}
            endIcon={<Icon iconName="arrow-next" size={12} />}
            size="small"
            to={`/empresas/${params.pessoa_id}/ativos`}
          >
            Ver Detalhes
          </Button>
        </Stack> */}
      </Stack>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(6, 1fr)",
          },
          gap: "8px 8px",
        }}
      >
        {ativos.map((xAtivo) => {
          return (
            // <Link
            //   to={`/empresas/${params.pessoa_id}/ativos/${xAtivo.ativo_tipo_id}`}
            // >
            <CardInfo
              icon={
                <Icon
                  iconName={MERCADOS_ICONES[xAtivo.mercado].icon}
                  size={16}
                  color="primary"
                />
              }
              label={ATIVO_TIPO[xAtivo.ativo_tipo_id].sigla}
              description={ATIVO_TIPO[xAtivo.ativo_tipo_id].descricao}
              value={xAtivo.quantidade}
              valueProps={{ variant: "h6" }}
              copy={"none"}
            />
            // </Link>
          );
        })}
      </Box>
    </Stack>
  );
});

export default AtivosSection;
