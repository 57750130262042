import {
  ENTIDADE_CHANGED,
  ENTIDADE_CURRENT_CHANGED,
  ENTIDADE_LOGOUT,
} from "../../const/actionsType";

export function acEntidadeChanged(pArray) {
  return {
    type: ENTIDADE_CHANGED,
    payload: pArray,
  };
}

export function acEntidadeCurrentChanged(pObject) {
  return {
    type: ENTIDADE_CURRENT_CHANGED,
    payload: pObject,
  };
}

export function acEntidadeLogout() {
  return {
    type: ENTIDADE_LOGOUT,
  };
}
