import React from "react";
import PropTypes from "prop-types";
import { DatePicker as MuiDatePicker, Icon } from "investira.react.components";

const CalendarIcon = () => <Icon iconName="calendar" size={21} />;

const DatePicker = React.forwardRef((props, ref) => {
  return (
    <MuiDatePicker
      ref={ref}
      slots={{ openPickerIcon: CalendarIcon }}
      slotProps={{
        textField: {
          variant: "outlined",
        },
      }}
      {...props}
    />
  );
});

DatePicker.propTypes = {
  // label: PropTypes.string,
  // name: PropTypes.string,
  // onChange: PropTypes.func,
  //defaultValue: PropTypes.instanceOf(Date),
};

export default DatePicker;
