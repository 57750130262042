import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dates } from "investira.sdk";
import services from "../services";
import withResponseHandling from "./withResponseHandling";
import withMessage from "./withMessage";
import {
  acBackgroundArquivosChanged,
  acCarteirasChanged,
} from "../store/actions";
import usePrevious from "../hooks/usePrevious";

const PAGE_SIZE = 10;

const withCarteiras = (Component) => {
  function WrapComponent(props) {
    const arquivosUpdatedAt = useSelector(
      (state) => state.background.arquivos.updatedAt
    );

    const entidadeId = useSelector(
      (state) => state.entidade.current.default_entidade_id
    );

    const prevArquivosUpdateAt = usePrevious(arquivosUpdatedAt);

    const storeDispatch = useDispatch();

    const [carteiras, setCarteiras] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowSelection, setRowSelection] = useState({});
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    });

    function updateStore(pData) {
      storeDispatch(acCarteirasChanged(pData));
    }

    const readCarteiras = useCallback(
      (pParams = {}, pCallback) => {
        if (!carteiras.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }

        const xParams = {
          simulada: false,
          size: PAGE_SIZE,
          page: 1,
          sort: "favorito desc, carteira",
          ...pParams,
        };

        services.carteiras.list(
          xParams,
          (rRes) => {
            setCarteiras(Object.values(rRes.data));
            setRowCount(rRes.pages.total_items);
            pCallback && pCallback(Object.values(rRes.data));
          },
          (rErr) => {
            props.responseErrorHandling(rErr);
          },
          () => {
            setIsLoading(false);
            setIsRefetching(false);
          }
        );
      },
      [carteiras.length, props]
    );

    const favCarteira = (pData = {}) => {
      services.carteiras.modify(
        pData,
        (rRes) => {
          // Força reload da lista nas telas que "escutam" arquivosUpdatedAt
          storeDispatch(acBackgroundArquivosChanged(false));
          services.carteiras.list(
            {
              favorito: 1,
            },
            (rRes) => {
              updateStore(rRes.data);
            }
          );
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {}
      );
    };

    const deleteCarteira = (pCarteiraId, onFinish) => {
      services.carteiras.remove(
        pCarteiraId,
        (rRes) => {
          props.onMessageSuccess("Carteira excluída com sucesso");
          readCarteiras({}, updateStore);
          setRowSelection({});
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          onFinish && onFinish();
        }
      );
    };

    const bulkDeleteCarteiras = (pRows) => {
      const xCarteirasId = pRows.map((xRow) => {
        const { carteira_id } = xRow.original;
        return carteira_id;
      });

      xCarteirasId.forEach((xId) => {
        deleteCarteira(xId);
      });
    };

    useEffect(() => {
      //TODO: Se trocar de entidade, refazer o request e resetar a paginação

      const isSameDate = dates.diff(
        "second",
        prevArquivosUpdateAt,
        arquivosUpdatedAt
      );

      if (isSameDate !== 0) {
        const xPage = pagination.pageIndex + 1;

        readCarteiras({
          page: xPage,
          size: pagination.pageSize,
        });
      }
    }, [
      readCarteiras,
      pagination.pageIndex,
      pagination.pageSize,
      arquivosUpdatedAt,
      prevArquivosUpdateAt,
      entidadeId,
    ]);

    const xProps = {
      carteiras,
      isLoading,
      isRefetching,
      rowCount,
      rowSelection,
      pagination,
      setPagination,
      setRowSelection,
      readCarteiras,
      deleteCarteira,
      bulkDeleteCarteiras,
      favCarteira,
    };

    return <Component {...xProps} />;
  }

  return withMessage(withResponseHandling(WrapComponent));
};

export default withCarteiras;
