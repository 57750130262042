import React, { memo, useEffect, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { validators } from "investira.sdk";
import services from "../services";
import withMessage from "../hoc/withMessage";
import withResponseHandling from "../hoc/withResponseHandling";

// Context
const SimulacoesContext = React.createContext();

const initialState = {
  posicoes: [],
  carteira: "",
  carteira_id: null,
  carteira_seuid: null,
  data_posicao: null,
  isDialogOpen: false,
  dialogType: null,
  posicaoSelected: {},
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "SIMULACAO_RESETED") {
    return initialState;
  }
  if (action.type === "CARTEIRA_CHANGED") {
    const { carteira_id, carteira_seuid, carteira, data_posicao } =
      action.payload;
    return {
      ...state,
      carteira_id,
      carteira_seuid,
      carteira,
      data_posicao,
    };
  }
  if (action.type === "DATA_POSICAO_CHANGED") {
    return {
      ...state,
      data_posicao: action.payload,
    };
  }
  if (action.type === "POSICOES_CHANGED") {
    return {
      ...state,
      posicoes: action.payload,
    };
  }
  if (action.type === "NEW_POSICAO_CHANGED") {
    return {
      ...state,
      posicoes: [...state.posicoes].concat(action.payload),
    };
  }
  if (action.type === "POSICAO_OPENED") {
    return {
      ...state,
      isDialogOpen: true,
      dialogType: "new",
    };
  }
  if (action.type === "POSICAO_CANCELED") {
    return {
      ...state,
      isDialogOpen: false,
      dialogType: null,
      posicaoSelected: {},
    };
  }
  if (action.type === "POSICAO_SELECTED") {
    return {
      ...state,
      posicaoSelected: action.payload,
    };
  }
  if (action.type === "CARTEIRA_EDIT_OPENED") {
    return {
      ...state,
      isDialogOpen: true,
      dialogType: "carteira",
    };
  }
  if (action.type === "CARTEIRA_EDIT_CANCELED") {
    return {
      ...state,
      isDialogOpen: false,
      dialogType: null,
    };
  }
  if (action.type === "CLONE_POSICAO_OPENED") {
    return {
      ...state,
      isDialogOpen: true,
      dialogType: "clone",
    };
  }
  if (action.type === "CLONE_POSICAO_CANCELED") {
    return {
      ...state,
      isDialogOpen: false,
      dialogType: null,
    };
  }
  if (action.type === "DATA_POSICAO_OPENED") {
    return {
      ...state,
      isDialogOpen: true,
      dialogType: "posicao",
    };
  }
  if (action.type === "DATA_POSICAO_CANCELED") {
    return {
      ...state,
      isDialogOpen: false,
      dialogType: null,
    };
  }
};

// Dispatchs

const acSimulacaoReset = (pString) => ({
  type: "SIMULACAO_RESETED",
  payload: pString,
});

const acUpdateCarteira = (pObjCarteira) => ({
  type: "CARTEIRA_CHANGED",
  payload: pObjCarteira,
});

const acAddPosicao = (pArray) => ({
  type: "NEW_POSICAO_CHANGED",
  payload: pArray,
});

const acUpdateDataPosicao = (pDate) => ({
  type: "DATA_POSICAO_CHANGED",
  payload: pDate,
});

const acUpdatePosicoes = (pArray) => ({
  type: "POSICOES_CHANGED",
  payload: pArray,
});

const acUpdateNewPosicaoOpened = () => ({
  type: "POSICAO_OPENED",
});

const acUpdateNewPosicaoCanceled = () => ({
  type: "POSICAO_CANCELED",
});

const acEditCarteiraOpened = () => ({
  type: "CARTEIRA_EDIT_OPENED",
});

const acEditCarteiraCanceled = () => ({
  type: "CARTEIRA_EDIT_CANCELED",
});

const acUpdatePosicaoSelected = (pObjPosicao) => ({
  type: "POSICAO_SELECTED",
  payload: pObjPosicao,
});

const acUpdateClonePosicaoOpened = () => ({
  type: "CLONE_POSICAO_OPENED",
});

const acUpdateClonePosicaoCanceled = () => ({
  type: "CLONE_POSICAO_CANCELED",
});

const acEditDataPosicaoOpened = () => ({
  type: "DATA_POSICAO_OPENED",
});

const acEditDataPosicaoCanceled = () => ({
  type: "DATA_POSICAO_CANCELED",
});

// Custom Hook
export const useSimulacoesContext = () => React.useContext(SimulacoesContext);

export const SimulacoesProvider = memo((props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducers, initialState);

  const handleReset = () => {
    dispatch(acSimulacaoReset());
  };

  const handleSetDataPosicao = (pDate) => {
    dispatch(acUpdateDataPosicao(pDate));
  };

  const handleNewPosicao = () => {
    dispatch(acUpdateNewPosicaoOpened());
  };

  const handleNewPosicaoCancel = () => {
    services.ativosbase.cancel.list.cancel();
    dispatch(acUpdateNewPosicaoCanceled());
  };

  const handleModifyCarteira = () => {
    dispatch(acEditCarteiraOpened());
  };

  const handleModifyCarteiraCancel = () => {
    dispatch(acEditCarteiraCanceled());
  };

  const handleClonePosicao = () => {
    dispatch(acUpdateClonePosicaoOpened());
  };

  const handleClonePosicaoCancel = () => {
    dispatch(acUpdateClonePosicaoCanceled());
  };

  const handleDataPosicao = () => {
    dispatch(acEditDataPosicaoOpened());
  };

  const handleDataPosicaoCancel = () => {
    dispatch(acEditDataPosicaoCanceled());
  };

  const handleUpdateCarteira = (pObjCarteira) => {
    dispatch(acUpdateCarteira(pObjCarteira));
  };

  const handleEditCarteira = (pCarteira) => {
    handleUpdateCarteira(pCarteira);
    navigate(`/simulacoes/${pCarteira.carteira_id}`);
  };

  const handleBulkAddPosicoes = (pPosicoes = []) => {
    dispatch(acUpdatePosicoes(pPosicoes));
  };

  const handleAddPosicoes = (pObjPosicao) => {
    const xId = pObjPosicao.posicao_id || uuidv4();
    // const xPosicoes = state.posicoes.concat({
    //   posicao_id: xId,
    //   ...pObjPosicao,
    // });
    const xNovaPosicao = [
      {
        posicao_id: xId,
        ...pObjPosicao,
      },
    ];

    dispatch(acAddPosicao(xNovaPosicao));

    //dispatch(acUpdatePosicoes(xNovaPosicao));
  };

  const handleRemovePosicoes = (pObjPosicao) => {
    const { posicao_id, carteira_id } = pObjPosicao;

    deleteAtivo(
      { posicao_id, carteira_id },
      (_rRes) => {
        const xPosicoes = state.posicoes.filter(
          (x) => x.posicao_id !== pObjPosicao.posicao_id
        );
        dispatch(acUpdatePosicoes(xPosicoes));
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      }
    );
  };

  const handleUpdatePosicoes = (pObjPosicao) => {
    const xIndex = state.posicoes.findIndex(
      (pObjeto) => pObjeto.id === pObjPosicao.id
    );

    if (xIndex !== -1) {
      let xPosicoes = state.posicoes;
      xPosicoes[xIndex] = pObjPosicao;
      dispatch(acUpdatePosicoes(xPosicoes));
    }
  };

  const handleEditPosicao = (pObjeto) => {
    dispatch(acUpdatePosicaoSelected(pObjeto));
    dispatch(acUpdateNewPosicaoOpened());
  };

  const handleMergePosicoes = (pObjeto) => {
    if (validators.isNull(pObjeto.id)) {
      handleAddPosicoes(pObjeto);
    } else {
      handleUpdatePosicoes(pObjeto);
    }
  };

  function readPosicoes(pParams, pResolve, pReject, pFinally) {
    services.posicoes.list(
      { ...pParams, sort: "isin DESC" },
      (rRes) => {
        //dispatch(acUpdatePosicoes(rRes.data));
        pResolve && pResolve(rRes);
      },
      (rErr) => {
        props.onMessageError(rErr.message);
        pReject && pReject(rErr);
      },
      () => {
        pFinally && pFinally();
      }
    );
  }

  function readCarteira(pCarteiraId, pResolve, pReject, pFinally) {
    services.carteiras.read(
      pCarteiraId,
      (rRes) => {
        handleUpdateCarteira(rRes.data[0]);
        pResolve && pResolve();
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
        pReject && pReject();
      },
      () => {
        pFinally && pFinally();
      }
    );
  }

  function createCarteira(pData = {}, pResolve, pReject, pFinally) {
    services.carteiras.create(
      pData,
      (rRes) => {
        props.onMessageSuccess("Carteira criada.");
        handleEditCarteira(rRes.data);
      },
      (rErr) => {
        props.onMessageError(rErr.message);
      },
      () => {
        pFinally && pFinally();
      }
    );
  }

  function addAtivo(pAtivo = {}, pResolve, pReject, pFinally) {
    services.posicoes.add(
      pAtivo,
      (rRes) => {
        handleAddPosicoes(rRes.data);
        return pResolve && pResolve(rRes);
      },
      (rErr) => {
        pReject && pReject(rErr);
      },
      () => {
        pFinally && pFinally();
      }
    );
  }

  function updateAtivo(pAtivo = {}, pResolve, pReject, pFinally) {
    return services.posicoes.update(
      pAtivo,
      (rRes) => {
        return rRes.data;
      },
      (rErr) => {
        return rErr;
      },
      () => {
        pFinally && pFinally();
      }
    );
  }

  function savePosicoes(pData, pResolve, pReject, pFinally) {
    const xPromises = Object.values(pData.ativos).map((xAtivo) => {
      return addAtivo({ ...xAtivo, carteira_id: pData.carteira_id });
    });

    Promise.all(xPromises)
      .then((rRes) => {
        pResolve && pResolve(rRes);
        props.onMessageSuccess(`Posição copiada com sucesso`);
      })
      .catch((rErr) => {
        pReject && pReject(rErr);
        props.onMessageError(`Ocorreu uma falha durante copia da posição`);
      })
      .finally(() => {
        pFinally && pFinally();
      });
  }

  function updatePosicoes(pDate, pResolve, pReject, pFinally) {
    const xPromises = state.posicoes.map((xPosicao) => {
      return updateAtivo({ ...xPosicao, data: pDate });
    });

    Promise.all(xPromises)
      .then((rRes) => {
        pResolve && pResolve(rRes);
        handleSetDataPosicao(pDate);
        props.onMessageSuccess(`Posição atualiza com sucesso`);
        dispatch(acUpdatePosicoes(rRes));
      })
      .catch((rErr) => {
        pReject && pReject(rErr);
        props.onMessageError(`Ocorreu um erro durante a atualização posição`);
      })
      .finally(() => {
        pFinally && pFinally();
      });
  }

  function deleteAtivo(pPosicaoId, pResolve, pReject, pFinally) {
    services.posicoes.remove(
      pPosicaoId,
      (rRes) => {
        pResolve && pResolve(rRes);
      },
      (rErr) => {
        pReject && pReject(rErr);
      },
      () => {
        pFinally && pFinally();
      }
    );
  }

  function modifyCarteira(pData, pResolve, pReject, pFinally) {
    services.carteiras.modify(
      pData,
      (rRes) => {
        handleUpdateCarteira(rRes.data);
        pResolve && pResolve(rRes);
      },
      (rErr) => {
        pReject && pReject(rErr);
      },
      pFinally
    );
  }

  useEffect(() => {
    if (
      location.pathname === "/simulacoes/carteiras" ||
      location.pathname === "/simulacoes"
    ) {
      handleReset();
    }
  }, [location.pathname]);

  return (
    <SimulacoesContext.Provider
      value={{
        state,
        actions: {
          handleSetDataPosicao,
          handleNewPosicao,
          handleNewPosicaoCancel,
          handleUpdateCarteira,
          handleEditCarteira,
          handleAddPosicoes,
          handleRemovePosicoes,
          handleUpdatePosicoes,
          handleEditPosicao,
          handleMergePosicoes,
          handleClonePosicao,
          handleClonePosicaoCancel,
          handleDataPosicao,
          handleDataPosicaoCancel,
          handleBulkAddPosicoes,
          handleReset,
          readPosicoes,
          createCarteira,
          addAtivo,
          updatePosicoes,
          deleteAtivo,
          savePosicoes,
          readCarteira,
          handleModifyCarteira,
          handleModifyCarteiraCancel,
          modifyCarteira,
        },
      }}
    >
      {props.children}
    </SimulacoesContext.Provider>
  );
});

SimulacoesProvider.displayName = "SimulacoesProvider";

export default withMessage(withResponseHandling(SimulacoesProvider));
