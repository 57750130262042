import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formats, validators } from "investira.sdk";
import { CURVA_TIPO } from "@investirapri/financa.js/lib/enums";
import { PERIODICIDADE } from "../../../../enums";
import { Stack, Typography, Chip, Card } from "investira.react.components";

import { PessoaImage, MutedCopy } from "../../../../components/molecules";
import { PATHS } from "../../../../enums";

const EmpresaAtivosListItem = memo((props) => {
  const { mercado, ativo, hasLogo, logoPessoaId, emissor } = props;

  function renderBody(pMercado, pAtivo) {
    if (["ACN"].includes(pMercado)) {
      return (
        <Stack>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ fontWeight: 600 }}
          >
            {pAtivo.ticker}
          </Typography>

          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ fontWeight: 600 }}
          >
            {pAtivo.ativo}
          </Typography>
        </Stack>
      );
    }

    if (["TBA", "TPR", "BEM", "FND"].includes(pMercado)) {
      // if (pAtivo.isin === "BRBBDCLFRP09") {
      //   console.log(pAtivo);
      // }
      return (
        <>
          <Stack direction="row" flexGrow={1} spacing={1}>
            <Stack flexGrow="1">
              <Stack>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  {pAtivo.ativo}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ fontWeight: 600 }}
                >
                  {emissor.pessoa_fantasia}
                </Typography>
              </Stack>
            </Stack>
            <Stack alignItems="flex-end">
              <Stack>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  <MutedCopy value={pAtivo.isin}>{pAtivo.isin}</MutedCopy>
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  {!validators.isNull(pAtivo.vencimento) &&
                    formats.formatDate(pAtivo.vencimento)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {(!validators.isNull(pAtivo.curva_id) ||
            !validators.isEmpty(pAtivo?.evento?.juros?.periodicidade) ||
            !validators.isEmpty(pAtivo?.evento?.juros?.prazo)) && (
            <Stack direction="row" spacing={1}>
              {!validators.isNull(pAtivo.curva_id) && (
                <Chip
                  label={`Curva: ${pAtivo.curva_id}`}
                  size="small"
                  color="secondary"
                />
              )}

              {!validators.isEmpty(pAtivo?.evento?.juros?.periodicidade) && (
                <Chip
                  label={`Juros:  ${
                    PERIODICIDADE[pAtivo.evento.juros.periodicidade].descricao
                  }`}
                  size="small"
                  color="secondary"
                />
              )}

              {!validators.isEmpty(pAtivo?.evento?.juros?.prazo) && (
                <Chip
                  label={`Prazo  ${pAtivo.evento.juros.prazo}`}
                  size="small"
                  color="secondary"
                />
              )}
            </Stack>
          )}
        </>
      );
    }

    return (
      <>
        <Stack flexGrow={1}>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ fontWeight: 600 }}
          >
            {pAtivo.ticker || pAtivo.ativo}
          </Typography>
        </Stack>
        <Stack>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ fontWeight: 600 }}
          >
            {emissor.pessoa_fantasia}
          </Typography>
        </Stack>
      </>
    );
  }

  const xPath = PATHS[mercado.toLowerCase()].path;

  return (
    <Card key={ativo.ativo_id}>
      <Link to={`${xPath}/${ativo.ativo_id}`}>
        <Stack px={2} py={2} spacing={2} direction="row">
          <Stack>
            <PessoaImage
              logoPessoaId={logoPessoaId}
              alt={ativo.ativo}
              hasLogo={hasLogo}
              mercado={mercado}
              size={40}
            />
          </Stack>
          <Stack flexGrow={1} spacing={1}>
            {renderBody(mercado, ativo)}
          </Stack>
        </Stack>
      </Link>
    </Card>
  );
});

EmpresaAtivosListItem.propTypes = {};

export default EmpresaAtivosListItem;
