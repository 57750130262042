export const REQUIRED = "Obrigatório";

export const AT_LEAST = "deve ter no mínimo";

export const ATLEAST_NUMBER = "Ao menos um número";

export const ATLEAST_CAPITAL_LETTER = "Ao menos uma letra maiúscula";

export const ATLEAST_LETTER = "Ao menos uma letra minúscula";

export const ATLEAST_3_CARACT = "Ao menos 3 caracteres";

export const CARACT_MAX_30 = "Máximo 30 caracteres";

export const TOO_SHORT = "Muito curta";

export const NAME_TOO_LONG = "Nome muito longo";

export const PASSWORDS_MUST_MATCH = "As senhas devem ser iguais";

export const EMAILS_MUST_MATCH = "Os emails devem ser iguais";

export const BEFORE_TODAY = "A data não pode ser maior a hoje";

export const AFTER_TODAY = "A data não pode ser menor a hoje";

export const INVALID_DATE = "Data inválida";

export const INVALID_EMAIL = "Formato de email inválido";

export const BIGGER_ZERO = "Valor deve ser maior que zero";

export const MINIMUM = "deve ter no mínimo";

const validations = {
  REQUIRED,
  AT_LEAST,
  ATLEAST_NUMBER,
  ATLEAST_CAPITAL_LETTER,
  ATLEAST_LETTER,
  ATLEAST_3_CARACT,
  CARACT_MAX_30,
  TOO_SHORT,
  NAME_TOO_LONG,
  PASSWORDS_MUST_MATCH,
  EMAILS_MUST_MATCH,
  BEFORE_TODAY,
  AFTER_TODAY,
  INVALID_DATE,
  INVALID_EMAIL,
  BIGGER_ZERO,
  MINIMUM,
};

export default validations;
