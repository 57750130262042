import React, { memo } from "react";
import { useSelector } from "react-redux";
import { BaseTemplate } from "../../components/templates";
import withResponseHandling from "../../hoc/withResponseHandling";

import MembrosList from "./MembrosList";

const EntidadeMembros = memo((props) => {
  const xDefaultEntidadeStored = useSelector((store) => store.entidade.current);

  return (
    <BaseTemplate
      title="Membros"
      subtitle={xDefaultEntidadeStored.entidade}
      maxWidth="lg"
    >
      <MembrosList />
    </BaseTemplate>
  );
});

export default withResponseHandling(EntidadeMembros);
