import React, { memo } from "react";
import { Typography, Stack } from "investira.react.components";
import { formats, validators } from "investira.sdk";
import {
  CONSOLIDACAO_TIPO,
  PLANO_CONTABIL_TIPO,
} from "@investirapri/financa.js/lib/enums";
import { ORIGEM } from "../../../../../enums";

const CardFooter = memo((props) => {
  const { consolidacao, atualizado, origem, pc } = props;
  //console.log("pc", pc);
  // const consolidacaoTipo = Object.values(CONSOLIDACAO_TIPO).map(
  //   (xItem) => xItem.descricao
  // );

  const consolidacaoTipo = Object.values(CONSOLIDACAO_TIPO).filter(
    (xItem) => xItem.id === consolidacao
  )[0];

  //console.log(consolidacaoTipo);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        {!validators.isNull(consolidacao) && (
          <Typography variant="caption" color="textSecondary">
            {consolidacaoTipo?.descricao}
          </Typography>
        )}
      </Stack>
      <Stack>
        {!validators.isNull(origem) && (
          <Typography variant="caption" color="textSecondary">
            {`Origem ${ORIGEM[origem].descricao}`}
          </Typography>
        )}
        {!validators.isNull(atualizado) && (
          <Typography variant="caption" color="textSecondary">
            {`Atualizado em ${formats.formatDateCustom(
              atualizado,
              "DD/MM/YYYY"
            )}`}
          </Typography>
        )}
        {!validators.isNull(pc) && (
          <Typography variant="caption" color="textSecondary">
            {`Plano Contábil: ${PLANO_CONTABIL_TIPO[pc].descricao}`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
});

CardFooter.displayName = "CardFooter";

export default CardFooter;
