import React, { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { validators } from "investira.sdk";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "investira.react.components";

const IndicadorSelect = memo((props) => {
  const indicadores = useSelector((state) => state.indicadores.data);

  const handleChange = (event) => {
    props.onChange && props.onChange(event);
  };

  if (validators.isEmpty(props.tipoId)) {
    console.error("tipoId não informado");

    return;
  }

  return (
    <FormControl fullWidth>
      <InputLabel shrink={true} id="moeda-select-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="moeda-select-label"
        id="moeda-select"
        defaultValue={props.defaultValue}
        value={props.value}
        label={props.label}
        name={props.name}
        onChange={handleChange}
        disabled={props.disabled}
      >
        {!validators.isEmpty(indicadores) &&
          indicadores[props.tipoId].map((xItem) => {
            return (
              <MenuItem key={xItem.indicador_id} value={xItem.indicador_id}>
                {`${xItem.indicador_seuid} - ${xItem.indicador}`}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
});

IndicadorSelect.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  tipoId: PropTypes.oneOf(["M", "C", "I", "J"]),
};

IndicadorSelect.defaultProps = {};

IndicadorSelect.displayName = "IndicadorSelect";

export default IndicadorSelect;
