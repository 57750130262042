import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "investira.react.components";

import { ATIVO_TIPO } from "@investirapri/financa.js/lib/enums";
import EmpresaAtivosListItem from "./EmpresaAtivosListItem";

const EmpresAtivosList = memo((props) => {
  const { ativoTipo, ativos, pessoa } = props;

  function direction(pMercado) {
    if (["ACN"].includes(pMercado)) {
      return "row";
    }

    return "column";
  }

  const xTitle =
    ATIVO_TIPO[ativoTipo.ativo_tipo_id].label ||
    ATIVO_TIPO[ativoTipo.ativo_tipo_id].descricao;

  return (
    <Stack px={2} py={2} spacing={2}>
      <Typography
        id={`${ativoTipo.ativo_tipo_id}`}
        variant="h6"
        component="h3"
        color="textPrimary"
      >
        {`${xTitle} (${ativoTipo.quantidade})`}
      </Typography>

      <Stack
        direction={direction(ativoTipo.mercado)}
        sx={{ gap: "8px 8px" }}
        flexWrap="wrap"
      >
        {ativos.map((xAtivo) => {
          return (
            <EmpresaAtivosListItem
              mercado={ativoTipo.mercado}
              ativo={xAtivo}
              hasLogo={ativoTipo.logo_ok}
              logoPessoaId={ativoTipo.logo_pessoa_id}
              emissor={pessoa}
            />
          );
        })}
      </Stack>
    </Stack>
  );
});

EmpresAtivosList.propTypes = {};

export default EmpresAtivosList;
