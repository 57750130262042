import React, { memo, useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validators, strings, objects } from "investira.sdk";

import {
  Stack,
  CircularProgress,
  TextField,
  InputAdornment,
  Icon,
  Typography,
  Dialog,
  Divider,
  IconButton,
  Button,
  Skeleton,
  DialogContent,
  useMediaQuery,
} from "investira.react.components";
import services from "../../../services";
import utils from "../../../utils";
import { PATHS } from "../../../enums";
import withResponseHandling from "../../../hoc/withResponseHandling";
import { acSearchChanged } from "../../../store/actions";
import SearchAtivosItem from "./SearchAtivosItem";

const Search = memo((props) => {
  const PAGE_SIZE = 10;

  const search = useSelector((store) => store.searchAtivos);
  const empresasSearch = useSelector((store) => store.empresas.search);

  const { top_setor_id, pessoa_tipo_id, pessoas_tipo } = empresasSearch;

  const navigate = useNavigate();

  const storeDispatch = useDispatch();

  const [filters, setFilters] = useState({
    top_setor_id: top_setor_id,
    pessoa_tipo_id: pessoa_tipo_id,
  });

  const pt = { ...pessoas_tipo };
  const ptf = objects.deleteNull({ top_setor_id, ...pt });

  const initCount = validators.isEmpty(ptf)
    ? 0
    : Object.keys(objects.deleteNull({ top_setor_id, ...pt }));

  const [isLoading, setRequesting] = useState(false);
  const [pesquisa, setPesquisa] = useState("");
  const [data, setData] = useState({});
  const [pages, setPages] = useState({});
  const [message, setMessage] = useState(null);
  const [open, setOpen] = useState(null);

  const inputRef = useRef();
  const placeholderRef = useRef();

  const TOTAL_ITEMS = pages.total_items;

  const handleOpen = (pEvent) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    placeholderRef.current.blur();
    inputRef.current.blur();
    handleClearPesquisa();
  };

  const handleChange = (pEvent) => {
    const xValue = pEvent.currentTarget.value;

    setPesquisa(pEvent.currentTarget.value);
    handleOpen(pEvent);

    if (validators.isEmpty(xValue)) {
      handleClose();
      return;
    }
  };

  const openDrawerOnFocus = (pEvent) => {
    placeholderRef.current.blur();
    handleOpen(pEvent);
  };

  const handleClearPesquisa = (pLocation = {}) => {
    setPesquisa("");
    setData({});
    setPages({});

    if (!validators.isEmpty(pLocation?.search)) {
      const xSearch = strings.querystringToObject(pLocation.search);

      if (!validators.isEmpty(xSearch.pesquisa)) {
        delete xSearch.pesquisa;
        const xQuery = objects.objectToQueryString(xSearch);
        navigate(`/ativos${xQuery}`);
      }
    }
  };

  const handleGoTo = (pEvent, pPessoaId, pMercado, pClearOnClose) => {
    pEvent.preventDefault();
    pEvent.stopPropagation();

    const xPath = PATHS[pMercado.toLowerCase()].path;

    handleClose();
    pClearOnClose && handleClearPesquisa();
    navigate(`${xPath}/${pPessoaId}`);
  };

  const handleSearch = (pPesquisa, pFilters) => {
    const xFilters = objects.deleteNull({ ...pFilters });

    const xPesquisa = validators.isEmpty(pPesquisa)
      ? search.pesquisa.trim()
      : pPesquisa.trim();

    const xSearch = { pesquisa: xPesquisa, ...xFilters };
    const xSearchQueryString = objects.objectToQueryString(xSearch);
    const xUrl = `/ativos${xSearchQueryString}`;

    handleClose();
    navigate(xUrl);
  };

  function verifySpecialChar(pStr) {
    const regex = /[./-]/;
    return regex.test(pStr);
  }

  function cleanSpecialChar(pStr) {
    const regex = /[./-]/g;
    if (verifySpecialChar(pStr)) {
      return pStr.replace(regex, "");
    }

    return pStr;
  }

  function isNumeric(pStr) {
    if (typeof pStr !== "string") {
      return false;
    }

    return /^[0-9]+$/.test(pStr);
  }

  const request = useMemo(() => {
    return utils.helpers.debounce(
      (pParams, pRes, pErr, pFin) =>
        services.ativos.list(pParams, pRes, pErr, pFin),
      1000
    );
  }, []);

  function removeDoubleSpaces(pStr) {
    return pStr.replace(/\s{2,}/g, " ").trim();
  }

  function removeEmptyItems(pArr) {
    return pArr.filter((item) => item !== "");
  }

  function formatSearch(pValue) {
    let xValue = removeDoubleSpaces(pValue);
    xValue = cleanSpecialChar(pValue);

    let xStrings = xValue.split(" ");
    xStrings = removeEmptyItems(xStrings);
    xStrings = xStrings.map((xString) => {
      return `+${xString}*`.trim();
    });

    const xSearchFormated = xStrings.join("");

    return xSearchFormated;
  }

  useEffect(() => {
    if (open) {
      placeholderRef.current.blur();
      inputRef.current.focus();

      setData(search.result);
      setPages(search.pages);
    }
  }, [open, inputRef.current, placeholderRef.current]);

  useEffect(() => {
    if (pesquisa && pesquisa.length > 1 && !props.pageIsLoading) {
      setRequesting(true);
      setData({});
      setMessage(null);

      const xPesquisa = formatSearch(pesquisa);

      request(
        {
          pesquisa: xPesquisa,
          size: PAGE_SIZE,
          cancelado: "null",
          ...(!isNumeric(xPesquisa) && { ...filters }),
          sort: "relevancia DESC, __mr__ DESC, mercado ASC",
          fracionario: 0,
          prazo: "VST",
        },
        (rRes) => {
          setData(rRes.data);
          setPages(rRes.pages);
          storeDispatch(
            acSearchChanged({
              pesquisa: pesquisa,
              result: rRes.data,
              pages: rRes.pages,
            })
          );

          if (validators.isEmpty(rRes.data)) {
            setMessage("Nenhum dado encontrado");
          }
        },
        (rErr) => {
          setMessage("Erro na busca por empresa");
        },
        () => {
          setRequesting(false);
        }
      );
    }
  }, [pesquisa, request, filters]);

  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const xDialogProps = isUpMd
    ? {
        fullScreen: false,
        PaperProps: {
          sx: {
            pt: 3,
            pb: 2,
            px: 2,
          },
        },
        maxWidth: "lg",
      }
    : {
        fullScreen: true,
        PaperProps: {
          sx: {
            margin: 0,
            backgroundColor: "background.dark",
          },
        },
      };

  return (
    <>
      <Stack
        id="search-area"
        sx={{
          position: "relative",
          width: "100%",
          zIndex: "999",
        }}
      >
        {props.pageIsLoading ? (
          <Skeleton variant="rounded" width="100%" height={56} />
        ) : (
          <TextField
            id="placeholder-busca-empresa"
            inputRef={placeholderRef}
            value={pesquisa}
            onFocus={openDrawerOnFocus}
            variant="outlined"
            placeholder={"Pesquise por ativos (Ações, Fundos, CDBs...)"}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon iconName="find" size={21} color={"primary"} />
                </InputAdornment>
              ),
            }}
            disabled={props.pageIsLoading}
          />
        )}

        <Dialog
          {...xDialogProps}
          open={open}
          onClose={handleClose}
          keepMounted
          fullWidth
        >
          <DialogContent>
            <Stack direction="row" alignItems="center" spacing={2} mb={1}>
              <Stack>
                <IconButton onClick={handleClose} color={"primary"}>
                  <Icon iconName="arrow_left" size={21} />
                </IconButton>
              </Stack>
              <Stack flexGrow={1}>
                <TextField
                  id="busca-empresa"
                  inputRef={inputRef}
                  value={pesquisa}
                  onChange={handleChange}
                  sx={{ border: "none" }}
                  variant="outlined"
                  size={isUpMd ? "medium" : "small"}
                  placeholder={"Pesquise por ativos (Ações, Fundos, CDBs...)"}
                  fullWidth
                  disabled={props.pageIsLoading}
                />
              </Stack>
            </Stack>
            <Divider />
            <Stack>
              {!isLoading && !validators.isEmpty(data) && (
                <>
                  <Stack id="busca-empresa-list" sx={{ px: 0 }} component="ul">
                    {Object.values(data).map((xAtivo) => {
                      return (
                        <SearchAtivosItem
                          key={crypto.randomUUID()}
                          ativo={xAtivo}
                          onClick={(e) =>
                            handleGoTo(
                              e,
                              xAtivo.ativo_id,
                              xAtivo.mercado,
                              props.clearOnClose
                            )
                          }
                        />
                      );
                    })}
                  </Stack>
                  <Stack
                    sx={{
                      px: 2,
                      py: 2,
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleSearch(pesquisa, filters)}
                      sx={{ maxWidth: "500px", px: 4 }}
                    >
                      Visualizar Todos ({TOTAL_ITEMS})
                    </Button>
                  </Stack>
                </>
              )}

              {isLoading && (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  sx={{ py: 2 }}
                >
                  <CircularProgress size={24} />
                </Stack>
              )}

              {!isLoading && validators.isEmpty(data) && (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  sx={{ py: 2 }}
                >
                  <Typography variant="body2" color="textPrimary">
                    {message}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </DialogContent>
        </Dialog>
      </Stack>
    </>
  );
});

export default withResponseHandling(Search);
