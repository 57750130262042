import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "investira.react.components";

const HeaderTemplate = memo((props) => {
  return (
    <Stack
      id="header"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        //px: 3,
        //height: `${props.height}px`,
        marginBottom: 3,
      }}
    >
      <Stack>
        {props.subtitle && (
          <Typography variant="subtitle" component="span" color={"textPrimary"}>
            {props.subtitle}
          </Typography>
        )}
        {props.title && (
          <Typography
            variant="h4"
            component="h1"
            color={"textPrimary"}
            gutterBottom
          >
            {props.title}
          </Typography>
        )}
        {props.description && (
          <Typography variant="body1" color={"textPrimary"} gutterBottom>
            {props.description}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={1.5}>
        {props.renderActions}
      </Stack>
    </Stack>
  );
});

HeaderTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  height: PropTypes.number,
  renderActions: PropTypes.node,
};

HeaderTemplate.defaultProps = {};

HeaderTemplate.displayName = "HeaderTemplate";

export default HeaderTemplate;
