import React, { memo } from "react";
import PropTypes from "prop-types";
import { formats, validators } from "investira.sdk";
import {
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Icon,
  useMediaQuery,
} from "investira.react.components";
import Rows from "./Rows";

const Row = memo((props) => {
  const {
    id,
    isBreak,
    conta,
    nivel,
    descricao,
    filhos,
    periodMarks,
    variacao,
    decimal,
    escala,
    level,
  } = props;

  const isUpLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [open, setOpen] = React.useState(false);

  function handleToogleRow(pOpen) {
    setOpen((prevState) => {
      return !prevState;
    });
  }

  function renderSaldo(pData, pKey, pDecimal, pEscala) {
    if (validators.isEmpty(pData) || validators.isEmpty(pData[pKey])) {
      return "---";
    }

    return formats.friendlyNumber(pData[pKey].valor, pDecimal, false, pEscala);
  }

  function renderVariacao(pData, pKey, pDecimal, pEscala, pPrevData) {
    if (validators.isEmpty(pData) || validators.isEmpty(pData[pKey])) {
      return "---";
    }

    const xCurrentValue = pData[pKey].valor;
    const xPrevValue = pPrevData?.[pKey]?.valor;

    if (!validators.isEmpty(xCurrentValue) && !validators.isEmpty(xPrevValue)) {
      const xVariacao = ((xCurrentValue - xPrevValue) / xPrevValue) * 100;
      return `${xVariacao.toFixed(2)}%`;
    }

    return "---";
  }

  function renderArrowButton(pChildren, pOpen) {
    if (validators.isEmpty(pChildren)) {
      return null;
    }

    return (
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => handleToogleRow(pOpen)}
      >
        {pOpen ? (
          <Icon iconName={"none"} size={12} color="primary" />
        ) : (
          <Icon iconName={"insert"} size={12} color="primary" />
        )}
      </IconButton>
    );
  }

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: (theme) => theme.palette.background.dark,
          ...(variacao
            ? {
                "&:nth-of-type(4n-3)": {
                  backgroundColor: (theme) => theme.palette.background.light,
                },
              }
            : {
                "&:nth-of-type(odd)": {
                  backgroundColor: (theme) => theme.palette.background.light,
                },
              }),
          ...(isBreak && {
            borderTop: (theme) =>
              `${theme.palette.background.lightness} 2px solid`,
          }),
        }}
      >
        <TableCell
          sx={{
            borderBottom: 0,
            width: "65px",
            maxWidth: "65px",
            paddingLeft: nivel + 1,

            overflow: "hidden",
          }}
        >
          {renderArrowButton(filhos, open)}
        </TableCell>
        {isUpLg && (
          <TableCell
            sx={{
              borderBottom: 0,
              whiteSpace: "nowrap",
              fontSize: "0.775rem",
              width: "150px",
              maxWidth: "150px",
              overflow: "hidden",
            }}
          >
            {conta}
          </TableCell>
        )}
        <TableCell
          sx={
            isUpLg
              ? {
                  borderBottom: 0,
                  paddingLeft: nivel + 1,
                  ...(nivel === 1 && { fontWeight: 600 }),
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "0.775rem",
                  width: "400px",
                  maxWidth: "400px",
                }
              : {
                  borderBottom: 0,
                  paddingLeft: nivel + 1,
                  ...(nivel === 1 && { fontWeight: 600 }),
                  fontSize: "0.775rem",
                  width: "200px",
                  maxWidth: "400px",
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  backgroundColor: "inherit",
                }
          }
        >
          {descricao}
        </TableCell>
        {periodMarks.map((xMark, zIndex) => {
          return (
            <TableCell
              key={`${zIndex}-${conta}`}
              align="right"
              sx={{
                whiteSpace: "nowrap",
                borderBottom: 0,
                fontSize: "0.775rem",
                //maxWidth: "200px",
              }}
            >
              {renderSaldo(xMark?.saldos, id, decimal, escala)}
            </TableCell>
          );
        })}
      </TableRow>

      {variacao && (
        <TableRow
          sx={{
            ...(variacao && {
              "&:nth-of-type(4n-2)": {
                backgroundColor: (theme) => theme.palette.background.light,
              },
            }),
          }}
        >
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell
            sx={{
              borderBottom: 0,
              paddingLeft: nivel + 1,
              ...(nivel === 1 && { fontWeight: 600 }),
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontStyle: "italic",
              fontSize: "0.775rem",
            }}
          >
            {`${descricao} Var. %`}
          </TableCell>
          {periodMarks.map((xMark, vIndex) => {
            let xAnteriorMark = {};

            if (vIndex > 0) {
              xAnteriorMark = periodMarks[vIndex - 1];
            }

            return (
              <TableCell
                key={`${vIndex}-var-${conta}`}
                align="right"
                sx={{ whiteSpace: "nowrap" }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    ...(nivel === 1 && {
                      fontWeight: 600,
                    }),
                    fontStyle: "italic",
                    fontSize: "0.775rem",
                  }}
                >
                  {renderVariacao(
                    xMark?.saldos,
                    id,
                    decimal,
                    escala,
                    xAnteriorMark?.saldos
                  )}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      )}

      {!validators.isEmpty(filhos) && open ? (
        <Rows
          id={`rows-${id}`}
          key={`rows-${id}`}
          balanco={filhos}
          periodMarks={periodMarks}
          variacao={variacao}
          decimal={decimal}
          escala={escala}
          level={level + 1}
        />
      ) : null}
    </>
  );
});

Row.displayName = "Row";

Row.propTypes = {};

export default Row;
