import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Icon,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "investira.react.components";
import { useNavigate } from "react-router-dom";
import { RelatoriosDataGrid } from "../../organisms";
import { PageTemplate } from "../";

const ReportsTemplate = memo((props) => {
  const { initialState, state, ...restDataGridProps } = props.dataGridProps;
  const navigate = useNavigate();
  const isUpSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <PageTemplate
      title={props.title}
      subtitle={props.subtitle}
      scrollable={false}
      headerRenderActions={
        <Stack direction="row" spacing={2}>
          {props.disabledButtons ? (
            <>
              <Stack direction="row" spacing={2} alignItems="center">
                <Icon iconName="warning" color="warn" size={21} />

                <Typography color="textPrimary" variant="caption">
                  Necessário possuir uma carteira favorita
                </Typography>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon iconName="wallet" size={16} />}
                size="small"
                sx={{ px: 2 }}
                onClick={() => navigate("/carteiras")}
              >
                Ir para Carteiras
              </Button>
            </>
          ) : (
            <Button
              disabled={props.disabledButtons}
              variant="contained"
              color="primary"
              startIcon={<Icon iconName="insert" size={16} />}
              size="small"
              sx={{ px: 2 }}
              onClick={props.onNewReportClick}
            >
              {isUpSm ? "Novo relatório" : "Novo"}
            </Button>
          )}
        </Stack>
      }
    >
      <RelatoriosDataGrid
        {...restDataGridProps}
        initialState={{
          ...initialState,
          density: "comfortable",
        }}
        state={state}
      />
    </PageTemplate>
  );
});

ReportsTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dataGridProps: PropTypes.object,
};

export default ReportsTemplate;
