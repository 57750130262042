import { validators, formats, dates } from "investira.sdk";

const columns = {
  C: [
    {
      id: "indicador_id",
      accessorKey: "result.indicador_id",
      header: "Indicador",
      enableColumnActions: false,
      enableSorting: true,
      filterVariant: "select",
      size: 80,
    },
    {
      id: "data_inicio",
      accessorKey: "result.data_inicio",
      header: "Data Início",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDateCustom(
          dates.toDate(cell.getValue()),
          "DD/MMM/YYYY"
        );
      },
    },
    {
      id: "data_fim",
      accessorKey: "result.data_fim",
      header: "Data Fim",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDateCustom(
          dates.toDate(cell.getValue()),
          "DD/MMM/YYYY"
        );
      },
    },
    {
      id: "taxa_pre",
      accessorKey: "payload.taxa_pre",
      header: "Taxa Pré",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      size: 100,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return `${Number(cell.getValue()).toFixed(4)}%`;
      },
    },
    {
      id: "correcao",
      accessorKey: "payload.valor_correcao",
      header: "Valor a Corrigir",
      enableColumnActions: false,
      enableSorting: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 2, true, true)}`;
      },
    },
    {
      id: "fator_variacao",
      accessorKey: "result.fator",
      header: "Fator Variação",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 15)}`;
      },
    },
    {
      id: "variacao_percentual",
      header: "Variação Percentual",
      enableColumnActions: false,
      enableSorting: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ row }) => {
        return `${formats.formatNumber(
          (row.original.result.fator - 1) * 100,
          6
        )}%`;
      },
    },
    {
      id: "valor_correcao",
      accessorKey: "payload.valor_correcao",
      header: "Correção",
      enableColumnActions: false,
      enableSorting: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ cell, row }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(
          cell.getValue() * row.original.result.fator,
          2,
          true,
          true
        )}`;
      },
    },
    {
      id: "fator_pos",
      accessorKey: "result.fator_pos",
      header: "Fator Variação Pós",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 15)}`;
      },
    },
    {
      id: "fator_pre",
      accessorKey: "result.fator_pre",
      header: "Fator Variação Pre",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 15)}`;
      },
    },
  ],
  J: [
    {
      id: "indicador_id",
      accessorKey: "result.indicador_id",
      header: "Indicador",
      enableColumnActions: false,
      enableSorting: true,
      filterVariant: "select",
      size: 80,
    },
    {
      id: "data_inicio",
      accessorKey: "result.data_inicio",
      header: "Data Início",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "data_fim",
      accessorKey: "result.data_fim",
      header: "Data Fim",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "percentual_indice",
      accessorKey: "payload.percentual_pos",
      header: "Percentual do Índice",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return `${cell.getValue().toFixed(4)}%`;
      },
    },
    {
      id: "correcao",
      accessorKey: "payload.valor_correcao",
      header: "Valor a Corrigir",
      enableColumnActions: false,
      enableSorting: true,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 2, true, true)}`;
      },
    },
    {
      id: "fator_variacao",
      accessorKey: "result.fator",
      header: "Fator Variação",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 15)}`;
      },
    },
    {
      id: "variacao_percentual",
      header: "Variação Percentual",
      enableColumnActions: false,
      enableSorting: true,
      Cell: ({ row }) => {
        return `${formats.formatNumber(
          (row.original.result.fator - 1) * 100,
          6
        )}%`;
      },
    },
    {
      id: "valor_correcao",
      accessorKey: "payload.valor_correcao",
      header: "Correção",
      enableColumnActions: false,
      enableSorting: true,
      Cell: ({ cell, row }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(
          cell.getValue() * row.original.result.fator,
          2,
          true,
          true
        )}`;
      },
    },
  ],
  I: [
    {
      id: "indicador_id",
      accessorKey: "result.indicador_id",
      header: "Indicador",
      enableColumnActions: false,
      enableSorting: true,
      filterVariant: "select",
      size: 80,
    },
    {
      id: "data_inicio",
      accessorKey: "result.data_inicio",
      header: "Data Início",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "data_fim",
      accessorKey: "result.data_fim",
      header: "Data Fim",
      enableColumnActions: false,
      enableSorting: true,
      filterVariant: "select",
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },

    {
      id: "correcao",
      accessorKey: "payload.valor_correcao",
      header: "Valor a Corrigir",
      enableColumnActions: false,
      enableSorting: true,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 2, true, true)}`;
      },
    },
    {
      id: "fator_variacao",
      accessorKey: "result.fator",
      header: "Fator Variação",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 15)}`;
      },
    },
    {
      id: "variacao_percentual",
      header: "Variação Percentual",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return `${formats.formatNumber(
          (row.original.result.fator - 1) * 100,
          6
        )}%`;
      },
    },
    {
      id: "valor_correcao",
      accessorKey: "payload.valor_correcao",
      header: "Correção",
      enableColumnActions: false,
      enableSorting: true,
      Cell: ({ cell, row }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(
          cell.getValue() * row.original.result.fator,
          2,
          true,
          true
        )}`;
      },
    },
  ],
  M: [
    {
      id: "indicador_id",
      accessorKey: "result.indicador_id",
      header: "Indicador",
      enableColumnActions: false,
      enableSorting: true,
      filterVariant: "select",
      size: 80,
    },
    {
      id: "data_inicio",
      accessorKey: "result.data_inicio",
      header: "Data Início",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "data_fim",
      accessorKey: "result.data_fim",
      header: "Data Fim",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      size: 80,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "correcao",
      accessorKey: "payload.valor_correcao",
      header: "Valor a Corrigir",
      enableColumnActions: false,
      enableSorting: true,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 2, true, true)}`;
      },
    },
    {
      id: "fator_variacao",
      accessorKey: "result.fator",
      header: "Fator Variação",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(cell.getValue(), 15)}`;
      },
    },
    {
      id: "variacao_percentual",
      header: "Variação Percentual",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return `${formats.formatNumber(
          (row.original.result.fator - 1) * 100,
          6
        )}%`;
      },
    },
    {
      id: "valor_correcao",
      accessorKey: "payload.valor_correcao",
      header: "Correção",
      enableColumnActions: false,
      enableSorting: true,
      Cell: ({ cell, row }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `${formats.formatNumber(
          cell.getValue() * row.original.result.fator,
          2,
          true,
          true
        )}`;
      },
    },
    {
      id: "cotacao_inicio",
      accessorKey: "result.cotacao_inicio",
      header: "Cotação Início",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `R$ ${formats.formatNumber(cell.getValue(), 4)}`;
      },
    },
    {
      id: "cotacao_fim",
      accessorKey: "result.cotacao_fim",
      header: "Cotação Fim",
      enableColumnActions: false,
      enableSorting: true,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return `R$ ${formats.formatNumber(cell.getValue(), 4)}`;
      },
    },
  ],
};

export default columns;
