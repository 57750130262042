import React, { memo, useState } from "react";
import { validators } from "investira.sdk";
import { InlineInfo, CardInfo } from "../";
import ModalInfoDialog from "./ModalInfoDialog";

const ModalInfo = memo((props) => {
  const { modalContent, modalProps, ...infoProps } = props;

  const [open, setOpen] = useState(false);

  if (validators.isEmpty(props.value)) {
    return null;
  }

  const infoModalProps = {
    iconName: "others",
    onClick: () => {
      setOpen(true);
    },
  };

  const Content = modalContent;

  if (props.type === "row") {
    return (
      <>
        <InlineInfo {...infoProps} modal={infoModalProps} />
        <ModalInfoDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          {...modalProps}
        >
          <Content />
        </ModalInfoDialog>
      </>
    );
  }

  if (props.type === "card") {
    return (
      <>
        <CardInfo {...infoProps} modal={infoModalProps} />
        <ModalInfoDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          {...modalProps}
        >
          <Content />
        </ModalInfoDialog>
      </>
    );
  }

  return null;
});

ModalInfo.displayName = "ModalInfo";

export default ModalInfo;
