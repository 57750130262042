import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";
import {
  Stack,
  Card,
  CardContent,
  List,
  TextField,
  Box,
  LoadingButton,
} from "investira.react.components";
import { VALIDATIONS } from "../../const";
import { useEntidadesContext } from "../../contexts/EntidadesContext";
import withResponseHandling from "../../hoc/withResponseHandling";
import withMessage from "../../hoc/withMessage";
import MembrosListItem from "./MembrosListItem";

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email(`${VALIDATIONS.INVALID_EMAIL}`)
    .required(`${VALIDATIONS.REQUIRED}`),
});

const Membros = memo((props) => {
  const { state, actions } = useEntidadesContext();

  const defaultEntidadeId = useSelector(
    (store) => store.user.default_entidade_id
  );

  const adminSize = state.membros.filter(
    (xMembro) => xMembro.perfil === "ad"
  ).length;

  function ordenarPorPerfil(pUsers, pPerfil) {
    // Filtra os objetos com perfil "ad"
    const xAdmins = pUsers.filter((objeto) => objeto.perfil === pPerfil);

    // Filtra os objetos com perfil diferente de "ad"
    const xMembers = pUsers.filter((objeto) => objeto.perfil !== pPerfil);

    // Concatena os dois arrays, colocando os objetos com perfil "ad" no topo
    const xResult = xAdmins.concat(xMembers);

    return xResult;
  }

  useEffect(() => {
    actions.readMembros();
  }, [actions.readMembros, defaultEntidadeId]);

  const xOrderedUsers = ordenarPorPerfil(state.membros, "ad");

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Stack sx={{ px: 2, py: 1 }}>
            <Formik
              enableReinitialize
              initialValues={{ email: "" }}
              validationSchema={VALIDATION_SCHEMA}
              onSubmit={(xValues, xActions) => {
                actions.inviteMembro(xValues, xActions);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                isSubmitting,
                isValid,
                dirty,
              }) => {
                const initialValid = !isValid || !dirty;
                return (
                  <Form noValidate>
                    <Stack
                      direction="row"
                      spacing={3}
                      alignItems={errors.email ? "center" : "flex-end"}
                    >
                      <Stack flexGrow={1}>
                        <TextField
                          label="Novo membro"
                          name="email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email && touched.email}
                          placeholder="Digite o e-mail"
                          helperText={errors.email}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Stack>
                      <Box>
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={isSubmitting}
                          disabled={isSubmitting || initialValid}
                          edge="end"
                        >
                          Adicionar
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <List disablePadding>
            {xOrderedUsers.map((xMembro) => (
              <MembrosListItem
                key={xMembro.username}
                membro={xMembro}
                onModifyClick={actions.handleModifyMembro}
                onDeleteClick={actions.handleRemoveMembro}
                adminSize={adminSize}
              />
            ))}
          </List>
        </CardContent>
      </Card>
    </Stack>
  );
});

Membros.propTypes = {};

export default withResponseHandling(withMessage(Membros));
