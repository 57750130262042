import React, { memo, useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { dates, formats, validators } from "investira.sdk";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  InputAdornment,
  MenuItem,
  Typography,
  TextField,
  InputDecimal,
  Icon,
  LoadingButton,
  Skeleton,
} from "investira.react.components";
import { useRelatorioContext } from "../../contexts/RelatorioContext";
import services from "../../services";
import withResponseHandling from "../../hoc/withResponseHandling";

const DurationParamsDialog = memo((props) => {
  const { state, actions } = useRelatorioContext();
  const [datas, setDatas] = useState([]);

  const xInitialValues = { juros_delta: 0, data: "" };

  const handleDateChange = (pFieldName, pFieldValue, pSetValue) => {
    const xDate = dates.toDate(pFieldValue);
    pSetValue && pSetValue(pFieldName, xDate, true);
  };

  const handleNumberChange = (pFieldName, pFieldValue, pSetValue) => {
    pSetValue && pSetValue(pFieldName, pFieldValue, true);
  };

  useEffect(() => {
    if (state.isDialogOpen) {
      setDatas([]);

      services.posicoes.arquivos(
        {
          params: {
            sort: "data_envio desc, data_processamento",
            carteira_id: state.carteira.carteira_id,
          },
        },
        (rRes) => {
          const xDates = rRes.data.map((xArquivo) => {
            return {
              value: xArquivo.ifeed_data_posicao,
              label: formats.formatDateCustom(
                dates.toDate(xArquivo.ifeed_data_posicao),
                "DD/MMM/YYYY"
              ),
            };
          });
          setDatas(xDates);
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        }
      );
    }
  }, [state.isDialogOpen, state.carteira.carteira_id, props]);

  return (
    <Dialog open={state.isDialogOpen} maxWidth="sm" disableEscapeKeyDown>
      <DialogTitle>Novo Relatório</DialogTitle>

      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={xInitialValues}
          onSubmit={(pValues, pActions) => {
            actions.handleParamsUpdate(pValues);
            actions.handleCreate(pValues, () => {
              pActions.setSubmitting(false);
              actions.handleNewReportCancel();
            });
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            isSubmitting,
            isValid,
            dirty,
          }) => {
            const initialValid = !isValid;

            if (validators.isEmpty(values.data) && !validators.isEmpty(datas)) {
              setFieldValue("data", datas[0].value);
            }

            return (
              <Form noValidate>
                <Stack sx={{ width: "500px", marginBottom: 2 }} spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <Stack>
                      <Typography variant="caption" color="textSecondary">
                        Carteira:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {state.carteira.carteira}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <Stack flexGrow={1} flexBasis={1}>
                      {validators.isEmpty(dates) ? (
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          height={32}
                        />
                      ) : (
                        <TextField
                          id="data-posicao"
                          name="data"
                          label="Data Posição"
                          variant="outlined"
                          value={values.data}
                          onChange={handleChange}
                          select
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon iconName="calendar" size={21} />
                              </InputAdornment>
                            ),
                          }}
                        >
                          {datas.map((xOption, xIndex) => (
                            <MenuItem key={xOption.value} value={xOption.value}>
                              {xOption.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Stack>
                    <Stack flexGrow={1} flexBasis={1}>
                      <TextField
                        id="juros-delta"
                        name="juros_delta"
                        label="Juros Delta"
                        variant="outlined"
                        value={values.juros_delta}
                        onChange={(_, pValue) =>
                          handleNumberChange(
                            "juros_delta",
                            pValue,
                            setFieldValue
                          )
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputComponent: InputDecimal,
                        }}
                        error={Boolean(errors.juros_delta)}
                        helperText={errors.juros_delta}
                        fullWidth
                      />
                    </Stack>
                  </Stack>

                  <Stack
                    alignItems="center"
                    justifyContent="flex-end"
                    direction="row"
                    spacing={2}
                  >
                    <Button
                      variant="outlined"
                      type="button"
                      color="primary"
                      onClick={actions.handleNewReportCancel}
                    >
                      Cancelar
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      disabled={isSubmitting || initialValid}
                    >
                      Processar
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
});

export default withResponseHandling(DurationParamsDialog);
