import React, { memo } from "react";
import {
  Stack,
  Typography,
  Card,
  CardContent,
} from "investira.react.components";
import { Info, InlineInfo } from "../../../../components/molecules";
import { validators } from "investira.sdk";

const PrevidenciaSection = memo((props) => {
  const { pessoa } = props;

  if (validators.isEmpty(pessoa?.juridica?.previdencia)) {
    return null;
  }

  return (
    <Stack flexGrow={1} sx={{ minWidth: "288px" }}>
      <Card sx={{ height: "100%" }}>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2} sx={{ py: 2 }}>
            <Stack>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontWeight: 600 }}
              >
                Previdência
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ gap: 2 }}>
              <Info
                label="Patrocinadores"
                value={pessoa.juridica?.previdencia?.patrocinadores}
                valueProps={{ variant: "h5" }}
                copy={"muted"}
                type="block"
              />
              <Info
                label="Origem do Patrocínio"
                value={pessoa.juridica?.previdencia?.patrocinio}
                valueProps={{ variant: "h5" }}
                copy={"muted"}
                type="block"
              />
              <Info
                label="Planos"
                value={pessoa.juridica?.previdencia?.planos}
                valueProps={{ variant: "h5" }}
                copy={"muted"}
                type="block"
              />
            </Stack>
            <Stack direction="column" sx={{ gap: 1 }}>
              <InlineInfo
                label="Conselho Deliberativo"
                value={pessoa.juridica?.previdencia?.conselho_deliberativo}
                copy={"muted"}
              />
              <InlineInfo
                label="Conselho fiscal"
                value={pessoa.juridica?.previdencia?.conselho_fiscal}
                copy={"muted"}
              />
              <InlineInfo
                label="Diretor executivo"
                value={pessoa.juridica?.previdencia?.diretor_executivo}
                copy={"muted"}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
});

export default PrevidenciaSection;
