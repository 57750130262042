import React, { memo } from "react";
import { validators } from "investira.sdk";
import {
  Stack,
  Typography,
  Card,
  CardContent,
} from "investira.react.components";
import { InlineInfo } from "../../../../components/molecules";
import { B3_MERCADO } from "../../../../enums";

const B3Section = memo((props) => {
  const { pessoa } = props;

  if (validators.isEmpty(pessoa?.b3)) {
    return null;
  }

  return (
    <Stack flexGrow={1} sx={{ minWidth: "288px" }}>
      <Card sx={{ height: "100%" }}>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2} sx={{ py: 2 }}>
            <Stack>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontWeight: 600 }}
              >
                B3
              </Typography>
            </Stack>
            <Stack direction="column" sx={{ gap: 1 }}>
              <InlineInfo
                label="Código emissor"
                value={pessoa?.b3?.codigo_emissor}
              />
              <InlineInfo
                label="Data de listagem"
                value={pessoa?.b3?.data_listagem}
                variant="date"
                copy={false}
              />
              {/* <InlineInfo label="Indicador" value={pessoa?.b3?.indicador} /> */}
              <InlineInfo
                label="Segmento de listagem"
                value={
                  pessoa?.b3?.mercado && B3_MERCADO[pessoa.b3.mercado].nivel
                }
                copy={false}
              />
              <InlineInfo
                label="Nome de pregão"
                value={pessoa?.b3?.nome_pregao}
              />
              <InlineInfo label="Setor" value={pessoa?.b3?.setor} />
              <InlineInfo label="Subsetor" value={pessoa?.b3?.subsetor} />
              <InlineInfo
                label="Segmento"
                value={pessoa?.b3?.segmento}
                divider={false}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
});

export default B3Section;
