import { MERCADOS_CHANGED, MERCADOS_LOGOUT } from "../../const/actionsType";

const INITIAL_STATE = {
  data: {},
  updatedAt: new Date(),
};

const RESET_STATE = {
  ...INITIAL_STATE,
};

function mercados(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MERCADOS_CHANGED:
      return {
        ...state,
        data: action.payload,
        updatedAt: new Date(),
      };

    case MERCADOS_LOGOUT:
      return {
        ...RESET_STATE,
      };

    default:
      return state;
  }
}

export default mercados;
