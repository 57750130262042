import React, { memo } from "react";
import { PosicoesTemplate } from "../../components/templates";
import { useRelatorioContext } from "../../contexts/RelatorioContext";
import withCarteiras from "../../hoc/withCarteiras";

const Enquadramento = memo((props) => {
  const { state, actions } = useRelatorioContext();
  return (
    <PosicoesTemplate
      title="Enquadramento"
      subtitle="Reports"
      dataGridProps={{
        data: props.carteiras,
        readData: props.readCarteiras,
        isLoading: props.isLoading,
        initialState: { pagination: props.pagination },
        manualPagination: true,
        getRowId: (row) => row._rid,
        state: {
          isLoading: props.isLoading,
          pagination: props.pagination,
          showProgressBars: props.isRefetching,
        },
        rowCount: props.rowCount,
        onPaginationChange: props.setPagination,
      }}
      handleCreateClick={actions.handleNewReport}
    />
  );
});

Enquadramento.displayName = "Enquadramento";

export default withCarteiras(Enquadramento);
