import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { withRouter } from "react-router-dom";
import { browsers } from "investira.react.lib";
import { validators } from "investira.sdk";
import { StoreListener, VisibilityListener } from "../../listeners";
import { MESSAGES } from "../../const";
import { MessageListener } from "../../listeners";
import services from "../../services";
import {
  acAuthLogin,
  acAuthIsFetching,
  acAuthUpdateStatusToken,
  acMessageTextChanged,
  acMessageClosed,
  acAppSetConectionState,
  acAppSetVisibilityState,
  acAuthTokenNoExpired,
} from "../../store/actions";
import Routes from "./Routes";

const Root = memo((props) => {
  const dispatch = useDispatch();
  const isFirstLogin = useSelector((store) => store.auth.isFirstLogin);
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);
  const accessToken = useSelector((store) => store.authLogin.accessToken);
  const expiresToken = useSelector((store) => store.authLogin.expiresToken);

  function updateStatusConection() {
    let xConnection = browsers.isOnline();

    dispatch(acAppSetConectionState(xConnection));

    if (!xConnection) {
      dispatch(
        acMessageTextChanged({
          data: {
            message: MESSAGES.STATUS.OFFLINE,
          },
          duration: null,
        })
      );
    } else {
      dispatch(acMessageClosed());
    }
  }

  function verifyTokenExpires(pExpiresToken) {
    const xCurrentDate = new Date().getTime();
    const xExpiresDate = new Date(pExpiresToken).getTime();

    return xCurrentDate > xExpiresDate;
  }

  function refreshToken(pAccessToken, pExpiresToken) {
    if (!validators.isNull(pAccessToken) && verifyTokenExpires(pExpiresToken)) {
      dispatch(acAuthIsFetching(true));
      dispatch(acAuthUpdateStatusToken("updating"));
      services.auth.refreshToken(
        {},
        (rRes) => {
          dispatch(acAuthLogin(rRes.data));
          dispatch(acAuthIsFetching(false));
          dispatch(acAuthUpdateStatusToken("valid"));
        },
        (rErr) => {
          dispatch(acAuthIsFetching(false));
          dispatch(acAuthUpdateStatusToken("invalid"));
        }
      );
    } else {
      dispatch(acAuthTokenNoExpired());
    }
  }

  // Escuta o modo de conexão do navegador
  function connectionListener() {
    window.addEventListener("offline", updateStatusConection);
    window.addEventListener("online", updateStatusConection);
  }

  // Escuta se a janela, aba ou webwiew está ativa
  function visibilityListener() {
    document.addEventListener(
      "visibilitychange",
      dispatch(acAppSetVisibilityState(document.visibilityState))
    );
  }

  // Mount
  useEffect(() => {
    updateStatusConection();
    connectionListener();
    dispatch(acAppSetVisibilityState(document.visibilityState));
    visibilityListener();

    if (accessToken && browsers.isOnline()) {
      refreshToken(accessToken, expiresToken);
    }
  }, []);

  // Render
  return (
    <>
      <MessageListener />
      <VisibilityListener />
      <StoreListener />
      <Routes />
    </>
  );
});

Root.displayName = "Root";

export default Root;
