import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "investira.react.components";
import { CarteirasAbertasTemplate } from "../../components/templates";
import withCarteirasAbertas from "../../hoc/withCarteirasAbertas";

const CarteirasAbertas = memo((props) => {
  return (
    <CarteirasAbertasTemplate
      title="Carteiras Abertas"
      headerRenderActions={
        <Button
          disabled={props.disabled}
          variant="contained"
          color="primary"
          startIcon={<Icon color="secondary" iconName="wallet" size={16} />}
          size="small"
          sx={{ px: 2 }}
          onClick={() => console.log("Abrir Carteira")}
        >
          Abrir Carteira
        </Button>
      }
      dataGridProps={{
        data: props.carteiras,
        readData: props.readCarteiras,
        isLoading: props.isLoading,
        initialState: { pagination: props.pagination },
        manualPagination: true,
        getRowId: (row) => {
          return row.carteira_id;
        },
        state: {
          isLoading: props.isLoading,
          pagination: props.pagination,
          showProgressBars: props.isRefetching,
          rowSelection: props.rowSelection,
        },
        rowCount: props.rowCount,
        onPaginationChange: props.setPagination,
        onRowSelectionChange: props.setRowSelection,
        onFavClick: props.favCarteira,
        onDeleteClick: props.deleteCarteira,
        onBulkDeleteClick: props.bulkDeleteCarteiras,
      }}
    />
  );
});

CarteirasAbertas.propTypes = {};

export default withCarteirasAbertas(CarteirasAbertas);
