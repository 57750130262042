import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Icon, CenterInView } from 'investira.react.components';
import { browsers } from 'investira.react.lib';
import { MESSAGES } from '../../../const';

import Style from './ErrorStateView.module.scss';

function ErrorStateView(props) {
    let d = browsers.isOnline()
        ? 'M21.787 20.342h0.038l-0.395-0.372-19.325-18.22-0.193 1.033c-0.059 0.312 0.030 0.541 0.216 0.767l0.461 0.48 1.819 1.715c-2.608 1.804-3.936 4.987-3.086 8.121 0.256 0.942 0.712 1.833 1.322 2.624 0 0-0.010 0.001-0.008 0.004 1.308 1.755 4.402 3.244 7.055 3.244h7.836c0.439 0 0.932-0.096 1.445-0.261l2.941 2.773 0.194-1.033c0.059-0.313-0.031-0.541-0.217-0.768l-0.103-0.107zM17.836 18.407c-0.105 0.010-0.201 0.041-0.309 0.041h-7.831c-2.154 0.003-4.408-0.994-5.698-2.418-0.634-0.726-1.106-1.577-1.35-2.485-0.727-2.679 0.48-5.378 2.77-6.848l12.418 11.71zM7.602 5.008c0-0.273 0.178-0.508 0.432-0.607l0.302-0.090c1.837-0.256 3.393 0.118 5.014 1.022 1.721 0.959 2.985 2.448 3.597 4.226h0.66c0 0 0.003 0 0.003 0 1.738 0 3.373 0.801 4.375 2.144 1.379 1.853 1.158 4.185-0.056 5.822l-0.154 0.124c-0.12 0.109-0.283 0.18-0.465 0.18-0.37 0-0.671-0.283-0.671-0.634 0-0.191 0.096-0.355 0.236-0.472 0.913-1.257 0.969-2.963-0.012-4.28-0.743-0.998-1.961-1.595-3.253-1.595-0.001 0-0.001 0-0.003 0h-1.173c-0.313 0-0.586-0.2-0.663-0.485-0.451-1.658-1.558-3.050-3.119-3.92-1.281-0.718-2.744-0.965-4.201-0.804-0.049 0.011-0.097 0.029-0.152 0.029-0.025 0-0.044-0.011-0.068-0.012-0.019 0.003-0.040 0.001-0.060 0.004l-0.022-0.020c-0.312-0.067-0.548-0.317-0.548-0.633z'
        : 'M12.693 19.587c0 0.381-0.313 0.688-0.693 0.697v0.005c-0.379-0.010-0.693-0.316-0.693-0.698 0-0.386 0.306-0.701 0.693-0.701v-0.004c0.388 0.001 0.693 0.315 0.693 0.701zM2.051 1.007l-0.195 1.102c-0.059 0.331 0.030 0.577 0.218 0.818l0.462 0.51 2.239 2.239c-1.326 0.638-2.586 1.461-3.738 2.475-0.012 0.012-0.018 0.027-0.030 0.041l0.004-0.004 1.095 0.425c0.227 0.074 0.419 0.048 0.606-0.014 0.977-0.757 2.028-1.376 3.122-1.863l2.762 2.762c-1.425 0.441-2.784 1.161-3.991 2.168-0.014 0.012-0.021 0.029-0.033 0.041l1.046 0.449c0.308 0.11 0.561 0.066 0.82-0.066 1.039-0.684 2.171-1.145 3.339-1.414l3.36 3.36c-0.378-0.065-0.757-0.107-1.138-0.107v0.004c-1.344 0.001-2.686 0.408-3.833 1.218l0.933 0.599c0.228 0.128 0.437 0.135 0.653 0.095 0.71-0.335 1.477-0.51 2.247-0.511v-0.004c0.77 0.001 1.536 0.176 2.248 0.511 0.201 0.036 0.4 0.025 0.61-0.082l7.091 7.091 0.195-1.102c0.059-0.331-0.032-0.577-0.218-0.818l-19.875-19.921zM22.993 8.187l-0.004-0.004-1.095 0.425c-0.227 0.074-0.419 0.048-0.606-0.014-2.728-2.111-6.004-3.181-9.288-3.181v0.004c-0.808 0-1.612 0.093-2.41 0.228-0.041 0.008-0.080 0.026-0.122 0.027v0.005c-0.381-0.010-0.694-0.317-0.694-0.698 0-0.386 0.306-0.701 0.693-0.701v-0.036c0.841-0.132 1.685-0.225 2.534-0.225v-0.004c3.919 0 7.838 1.377 10.963 4.132 0.012 0.012 0.018 0.027 0.030 0.041zM19.427 11.702l-1.044 0.449c-0.308 0.11-0.561 0.066-0.82-0.066-0.951-0.627-2.009-1.142-3.071-1.426v0c-0.379-0.010-0.693-0.316-0.693-0.698 0-0.386 0.306-0.701 0.693-0.701v-0.003c0.121 0 0.228 0.038 0.326 0.092 1.638 0.412 3.207 1.169 4.579 2.311 0.014 0.012 0.019 0.027 0.032 0.041z';

    let msg = browsers.isOnline() ? MESSAGES.SERVER.ERROR_CONEX : MESSAGES.STATUS.OFFLINE;

    return (
        <div className={Style.root} style={{ background: 'red' }}>
            <CenterInView>
                <div className={Style.body}>
                    <div className={Style.img}>
                        <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 24 24">
                            <path className={Style.fill} d={d} />
                        </svg>
                    </div>

                    <Typography variant={'body1'}>{msg}</Typography>

                    <IconButton onClick={props.handleClick}>
                        <Icon iconName={'refresh'} color={'primary'} />
                    </IconButton>
                </div>
            </CenterInView>
        </div>
    );
}

ErrorStateView.propTypes = {
    handleClick: PropTypes.func
};

ErrorStateView.dislpayName = 'ErrorStateView';

export default ErrorStateView;
