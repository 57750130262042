import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import services from "../services";
import withResponseHandling from "./withResponseHandling";
import withMessage from "./withMessage";
import {
  acBackgroundArquivosChanged,
  acCarteirasChanged,
} from "../store/actions";

const PAGE_SIZE = 10;

const withSimulacoes = (Component) => {
  function WrapComponent(props) {
    const arquivosUpdatedAt = useSelector(
      (state) => state.background.arquivos.updatedAt
    );

    const entidadeId = useSelector(
      (state) => state.entidade.current.default_entidade_id
    );

    const storeDispatch = useDispatch();

    const [carteiras, setCarteiras] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowSelection, setRowSelection] = useState({});
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    });

    const readCarteiras = useCallback(
      (pParams = {}) => {
        if (!carteiras.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }

        services.carteiras.list(
          {
            simulada: true,
            size: PAGE_SIZE,
            page: 1,
            sort: "favorito desc, carteira",
            ...pParams,
          },
          (rRes) => {
            setCarteiras(Object.values(rRes.data));
            setRowCount(rRes.pages.total_items);
          },
          (rErr) => {
            props.responseErrorHandling(rErr);
          },
          () => {
            setIsLoading(false);
            setIsRefetching(false);
          }
        );
      },
      [carteiras.length, props]
    );

    const favCarteira = (pData = {}) => {
      services.carteiras.modify(
        pData,
        (rRes) => {
          // Força reload da lista nas telas que "escutam" arquivosUpdatedAt
          storeDispatch(acBackgroundArquivosChanged(false));
          services.carteiras.list(
            {
              favorito: 1,
            },
            (rRes) => {
              storeDispatch(acCarteirasChanged(rRes.data));
            }
          );
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {}
      );
    };

    const deleteCarteira = (pCarteiraId, onFinish) => {
      services.carteiras.remove(
        pCarteiraId,
        (rRes) => {
          props.onMessageSuccess("Carteira excluída com sucesso");
          readCarteiras();
          setRowSelection({});
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          onFinish && onFinish();
        }
      );
    };

    const bulkDeleteCarteiras = (pRows) => {
      const xCarteirasId = pRows.map((xRow) => {
        const { carteira_id } = xRow.original;
        return carteira_id;
      });

      xCarteirasId.forEach((xId) => {
        deleteCarteira(xId);
      });
    };

    useEffect(() => {
      //TODO: Se trocar de entidade, refazer o request e resetar a paginação
      const xPage = pagination.pageIndex + 1;

      readCarteiras({
        page: xPage,
        size: pagination.pageSize,
      });
    }, [
      readCarteiras,
      pagination.pageIndex,
      pagination.pageSize,
      arquivosUpdatedAt,
      entidadeId,
    ]);

    const xProps = {
      carteiras,
      isLoading,
      isRefetching,
      rowCount,
      rowSelection,
      pagination,
      setPagination,
      setRowSelection,
      readCarteiras,
      deleteCarteira,
      bulkDeleteCarteiras,
      favCarteira,
    };

    return <Component {...xProps} />;
  }

  return withMessage(withResponseHandling(WrapComponent));
};

export default withSimulacoes;
