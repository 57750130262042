import { EMPRESAS_SEARCH, EMPRESAS_LOGOUT } from "../../const/actionsType";

export function acEmpresasSearchChanged(pData) {
  return {
    type: EMPRESAS_SEARCH,
    payload: pData,
  };
}

export function acEmpresasLogout() {
  return {
    type: EMPRESAS_LOGOUT,
  };
}
