import React, { memo } from "react";
import PropTypes from "prop-types";
import { validators, formats, dates } from "investira.sdk";
import { MaterialReactTable, Stack, Button } from "investira.react.components";

const DataTable = memo((props) => {
  const {
    columns,
    data = [],
    renderRowActions,
    renderDetailPanel,
    ...restProps
  } = props;

  const xProps = {
    columns,
    data,
    enableColumnResizing: false,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    ...(!validators.isNull(renderRowActions) && {
      enableRowActions: true,
      positionActionsColumn: "last",
      renderRowActions,
    }),
    ...(!validators.isNull(renderDetailPanel) && {
      enableExpandAll: false,
      enableExpanding: true,
      renderDetailPanel: renderDetailPanel,
    }),
    enableFullScreenToggle: false,
    memoMode: "cell",
    muiLinearProgressProps: {
      sx: { height: "4px" },
    },
    muiBottomToolbarProps: {
      sx: {
        mb: 3,
      },
    },
    muiTopToolbarProps: {
      sx: { backgroundColor: "none", alignItems: "center" },
    },
    muiTableContainerProps: ({ table }) => {
      return {
        sx: {
          height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
        },
      };
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        pb: 2,
      },
    },
    ...restProps,
  };

  return (
    <Stack flexGrow={1} sx={{ height: "100%" }}>
      <MaterialReactTable {...xProps} />
    </Stack>
  );
});

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  initialState: PropTypes.object,
};

DataTable.default = {
  pagination: {},
};

export default DataTable;
