import React, { memo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Stack } from "investira.react.components";
import services from "../services";
import withMessage from "../hoc/withMessage";
import withResponseHandling from "../hoc/withResponseHandling";
// Context
const UploadContext = React.createContext();

const ACCEPT_FILES = {
  // "application/pdf": [".pdf"],
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xls"],
  "text/xml": [".xml"],
};

const initialState = {
  files: [],
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "FILES_CHANGED") {
    return {
      ...state,
      files: action.payload,
    };
  }

  if (action.type === "FILES_CLEANED") {
    return {
      files: action.payload,
    };
  }
};

// Dispatchs
const acFilesChanged = (pArray) => ({
  type: "FILES_CHANGED",
  payload: pArray,
});

const acFilesCleaned = (pArray) => ({
  type: "FILES_CLEANED",
  payload: pArray,
});

// Custom Hook
export const useUploadContext = () => React.useContext(UploadContext);

// Provider
export const UploadProvider = memo((props) => {
  const [isOnDrag, setIsOnDrag] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [state, dispatch] = React.useReducer(reducers, initialState);

  const handleUpdateFiles = (pPayload) => {
    dispatch(acFilesChanged(pPayload));
  };

  function handleCleanFiles(pFilename) {
    if (!pFilename) {
      dispatch(acFilesCleaned([]));
      return;
    }

    const xFiles = state.files.filter((xFile) => xFile.file.name !== pFilename);

    dispatch(acFilesCleaned(xFiles));
  }

  function updateFiles(pAcceptedFiles) {
    const xPaths = new Set();
    const xMappedFiles = pAcceptedFiles.map((xFile) => {
      return { file: xFile, progress: 0, status: null }; //null | pending | uploaded | error
    });

    const xFiles = [...state.files, ...xMappedFiles].filter((xFile) => {
      if (xPaths.has(xFile.file.path)) {
        return false;
      }
      xPaths.add(xFile.file.path);
      return true;
    });

    handleUpdateFiles(xFiles);
  }

  function sendFiles(onResolve, onReject, onFinish) {
    const xCurrentFiles = state.files;

    // Altera o status de todos os arquivos para "pending"
    const xPendingFiles = xCurrentFiles.map((xItem) => {
      return { ...xItem, status: "pending" };
    });

    handleUpdateFiles(xPendingFiles);

    // Realiza o upload de cada arquivo e atualiza o progresso
    const xPromises = xPendingFiles.map((xItem, xIndex) => {
      return services.posicoes.upload(
        {
          file: xItem.file,
          onUploadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // Atualiza o status de progresso de cada arquivo
            xPendingFiles[xIndex] = {
              ...xItem,
              progress,
            };

            handleUpdateFiles(xPendingFiles);
          },
        },
        (rRes) => {
          xPendingFiles[xIndex].status = "uploaded";
          handleUpdateFiles(xPendingFiles);
          return rRes.data[0];
        },
        (rErr) => {
          xPendingFiles[xIndex].status = "error";
          handleUpdateFiles(xPendingFiles);
          return rErr;
        }
      );
    });

    // Limpa a lista após enviar todos os arquivos
    Promise.all(xPromises).then((values) => {
      onFinish && onFinish();
      setTimeout(() => handleCleanFiles(), 1000);
    });
  }

  const { isFocused, getRootProps, open, getInputProps } = useDropzone({
    accept: ACCEPT_FILES,
    maxFiles: 100,
    multiple: true,
    noClick: true,
    onDragEnter: () => setIsOnDrag(true),
    onDragLeave: () => setIsOnDrag(false),
    onDropRejected: (pRejectFiles) => {
      if (pRejectFiles.length > 100) {
        props.onMessageError("Envie menos de 100 arquivos por vez.");
      } else {
        props.onMessageError("Somente serão aceitos arquivos .xml");
      }
    },
    onDrop: (pAcceptedFiles) => {
      updateFiles(pAcceptedFiles);
      setIsOnDrag(false);
    },
  });

  const VALUES = {
    state: { ...state, isFocused, isOnDrag, isUploading },
    actions: {
      open,
      handleUpdateFiles,
      handleCleanFiles,
      getRootProps,
      getInputProps,
      sendFiles,
    },
  };

  return (
    <UploadContext.Provider value={VALUES}>
      <Stack
        id="uploadProvider"
        {...getRootProps({})}
        flexGrow={1}
        flexDirection="row"
        sx={{
          height: "100%",
        }}
      >
        <input {...getInputProps()} />
        {props.children}
      </Stack>
    </UploadContext.Provider>
  );
});

export default withResponseHandling(withMessage(UploadProvider));
