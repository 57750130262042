import OUTPUT from "./OUTPUT";
import { Link } from "react-router-dom";

const PATHS = {
  login: {
    key: "login",
    path: "/login",
    name: "Login",
    nested: null,
    icon: null,
    private: false,
  },
  "email-confirm": {
    key: "email-confirm",
    path: "/email-confirm/:code",
    name: "Confirmação de Email",
    nested: null,
    icon: null,
    private: false,
  },
  "change-password": {
    key: "change-password",
    path: "/change-password/:code",
    name: "Trocar Senha",
    nested: null,
    icon: null,
    private: false,
  },
  "esqueci-senha": {
    key: "esqueci-senha",
    path: "/esqueci-senha/:username",
    name: "Esqueci minha senha",
    nested: null,
    icon: null,
    private: false,
  },
  home: {
    key: "home",
    path: "/",
    name: "Home",
    nested: null,
    icon: "home",
    private: false,
  },
  dashboard: {
    key: "dashboard",
    path: "/dashboard",
    name: "Dashboard",
    nested: null,
    icon: "control_panel",
    private: true,
  },
  sobre: {
    key: "sobre",
    path: "/sobre",
    name: "Sobre",
    nested: null,
    icon: null,
    private: false,
  },
  empresas: {
    key: "empresas",
    path: "/empresas",
    name: "Empresas",
    nested: null,
    icon: "office_building",
    private: false,
    // handle: {
    //   crumb: () => <Link to="/messages">Messages</Link>,
    // },
  },
  ativos: {
    key: "ativos",
    path: "/ativos",
    name: "Ativo",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  cnt: {
    key: "cnt",
    path: "/conta",
    name: "Conta",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  pou: {
    key: "pou",
    path: "/poupanca",
    name: "Poupança",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  prv: {
    key: "prv",
    path: "/previdencia",
    name: "Previdência",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  fnd: {
    key: "fnd",
    path: "/fundo",
    name: "Fundo de Investimento",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  tpu: {
    key: "tpu",
    path: "/titulo-publico",
    name: "Título Público",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  tpr: {
    key: "tpr",
    path: "/titulo-privado",
    name: "Título Privado",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  tba: {
    key: "tba",
    path: "/titulo-bancario",
    name: "Título Bancário",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  acn: {
    key: "acn",
    path: "/acao",
    name: "Ação",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  fgt: {
    key: "fgt",
    path: "/fgts",
    name: "FGTS",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  dri: {
    key: "dri",
    path: "/derivativo",
    name: "Derivativo",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  crp: {
    key: "crp",
    path: "/criptomoeda",
    name: "Criptomoeda",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  imv: {
    key: "imv",
    path: "/imovel",
    name: "Imóvel",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  vei: {
    key: "vei",
    path: "/veiculo",
    name: "Veículo",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  car: {
    key: "car",
    path: "/cartao",
    name: "Cartão",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  moe: {
    key: "moe",
    path: "/moeda",
    name: "Moeda Estrangeira",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  agr: {
    key: "agr",
    path: "/agronegocio",
    name: "Agronegócio",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  mtl: {
    key: "mtl",
    path: "/metal-nobre",
    name: "Metal Nobre",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  pdr: {
    key: "pdr",
    path: "/pedra-preciosa",
    name: "Pedra Preciosa",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  art: {
    key: "art",
    path: "/obra-arte",
    name: "Obra de Arte",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  fdl: {
    key: "fdl",
    path: "/programa-fidelidade",
    name: "Programa de Fidelidade",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  seg: {
    key: "seg",
    path: "/seguro",
    name: "Seguro",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  bem: {
    key: "bem",
    path: "/outros-bens",
    name: "Outros Bens",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  cbl: {
    key: "cbl",
    path: "/contabil",
    name: "Contábil",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  etf: {
    key: "etf",
    path: "/etf",
    name: "ETF",
    nested: null,
    icon: "paper_ribbon",
    private: false,
  },
  ferramentas: {
    key: "ferramentas",
    path: "/ferramentas",
    name: "Ferramentas",
    icon: "wrench",
    private: false,
    nested: [
      {
        key: "calculadoras",
        path: "/calculadoras",
        name: "Calculadoras",
        icon: "calculator",
      },
      // {
      //   key: "cotacoes",
      //   path: "/cotacoes",
      //   name: "Cotações",
      //   icon: "chart_line",
      // },
      // {
      //   key: "indicadores",
      //   path: "/indicadores",
      //   name: "Indicadores",
      //   icon: "chart_lines",
      // },
      {
        key: "empresas",
        path: "/empresas",
        name: "Empresas",
        icon: "office_building",
      },
      {
        key: "ativos",
        path: "/ativos?mercado=ACN",
        name: "Ativos",
        icon: "box_3d",
      },
    ],
  },
  calculadoras: {
    key: "calculadoras",
    path: "/calculadoras",
    name: "Calculadoras",
    nested: null,
    icon: "calculator",
    private: false,
  },

  configuracoes: {
    key: "configuracoes",
    path: "/configuracoes",
    name: "Configurações",
    private: true,
    nested: {
      entidades: {
        key: "entidades",
        label: "Entidades",
        icon: "wallet",
        path: "/entidades",
        nested: {
          membros: {
            key: "membros",
            label: "Membros",
            icon: "users",
            path: "/entidades/membros",
          },
        },
      },
      "alterar-senha": {
        key: "alterar-senha",
        label: "Alterar Senha",
        icon: "key",
        path: "/alterar-senha",
        nested: [],
      },
    },
    icon: "gear",
  },
  notificacoes: {
    key: "notificacoes",
    path: "/notificacoes",
    name: "Notificações",
    nested: null,
    icon: "bell",
    private: true,
  },
  carteiras: {
    key: "carteiras",
    path: "/carteiras",
    name: "Carteiras",
    nested: null,
    icon: "wallet",
    private: true,
  },
  reports: {
    key: "reports",
    path: "/reports/:output",
    name: "Reports",
    nested: [
      {
        key: OUTPUT.duration.id,
        name: OUTPUT.duration.descricao,
        path: `/reports/${OUTPUT.duration.id}`,
        icon: "paper",
      },
      {
        key: OUTPUT.var.id,
        label: OUTPUT.var.descricao,
        path: `/reports/${OUTPUT.var.id}`,
        icon: "paper",
      },
      {
        key: OUTPUT.enquadramento.id,
        label: OUTPUT.enquadramento.descricao,
        path: `/reports/${OUTPUT.enquadramento.id}`,
        icon: "paper",
      },
      {
        key: OUTPUT.raiox.id,
        label: OUTPUT.raiox.descricao,
        path: `/reports/${OUTPUT.raiox.id}`,
        icon: "paper",
      },
      {
        key: OUTPUT.rentabilidade.id,
        label: OUTPUT.rentabilidade.descricao,
        path: `/reports/${OUTPUT.rentabilidade.id}`,
        icon: "paper",
      },
      {
        key: OUTPUT.stresstest.id,
        label: OUTPUT.stresstest.descricao,
        path: `/reports/${OUTPUT.stresstest.id}`,
        icon: "paper",
      },
      {
        key: OUTPUT.backtesting.id,
        label: OUTPUT.backtesting.descricao,
        path: `/reports/${OUTPUT.backtesting.id}`,
        icon: "paper",
      },
      {
        key: OUTPUT.fronteiraeficiente.id,
        label: OUTPUT.fronteiraeficiente.descricao,
        path: `/reports/${OUTPUT.fronteiraeficiente.id}`,
        icon: "paper",
      },
    ],
    icon: "papers",
    private: true,
  },
  "carteiras-abertas": {
    key: "carteiras-abertas",
    path: "/carteiras-abertas",
    name: "Carteiras Abertas",
    nested: null,
    icon: "open_wallet",
    private: true,
  },
  simulacoes: {
    key: "simulacoes",
    path: "/simulacoes",
    name: "Simulacoes",
    nested: null,
    icon: "lab",
    private: true,
  },
  uploads: {
    key: "uploads",
    path: "/uploads",
    name: "Uploads",
    nested: null,
    icon: "upload",
    private: true,
  },
};

export default PATHS;
