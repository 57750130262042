import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link as Anchor,
  Typography,
  Stack,
  Button,
  Icon,
} from "investira.react.components";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { SECTIONS } from "../../../enums";

const Breadcrumbs = (_props) => {
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);

  const location = useLocation();

  const pathnames = location.pathname.split("/").filter((x) => x);

  const xSx = {
    textTransform: "capitalize",
  };

  const isDashboard = pathnames.length === 1 && pathnames[0] === "dashboard";

  function basePath(pIsLoggedIn, pPathnames) {
    if (isDashboard) {
      return (
        <Typography sx={xSx} variant="caption">
          Dashboard
        </Typography>
      );
    }

    if (pIsLoggedIn && pPathnames.length > 0) {
      return (
        <Anchor component={Link} sx={xSx} variant="caption" to={"/dashboard"}>
          Dashboard
        </Anchor>
      );
    }

    if (pPathnames.length > 0) {
      return (
        <Anchor component={Link} sx={xSx} variant="caption" to={"/"}>
          Home
        </Anchor>
      );
    }

    return (
      <Typography sx={xSx} variant="caption">
        Home
      </Typography>
    );
  }

  if (pathnames.length > 1) {
    return (
      <Stack
        id="breadcrumb-bar"
        direction="row"
        alignItems="center"
        spacing={3}
        mb={2}
      >
        <Button
          size="small"
          startIcon={<Icon iconName="arrow-previous" size={12} />}
          component={Link}
          key={"back"}
          sx={xSx}
          to={-1}
        >
          Voltar
        </Button>
      </Stack>
    );
  }

  return null;

  // return (
  //   <Stack
  //     id="breadcrumb-bar"
  //     direction="row"
  //     alignItems="center"
  //     spacing={3}
  //     mb={2}
  //   >
  //     <MUIBreadcrumbs aria-label="breadcrumb" separator={"›"}>
  //       {basePath(isLoggedIn, pathnames)}

  //       {!isDashboard &&
  //         pathnames.map((xPathName, xIndex) => {
  //           const routeTo = `/${pathnames.slice(0, xIndex + 1).join("/")}`;
  //           const isLast = xIndex === pathnames.length - 1;

  //           return isLast ? (
  //             <Typography sx={xSx} variant="caption" key={xPathName}>
  //               {SECTIONS[xPathName]?.label || xPathName || ""}
  //             </Typography>
  //           ) : (
  //             <Anchor
  //               component={Link}
  //               key={xPathName}
  //               sx={xSx}
  //               variant="caption"
  //               to={routeTo}
  //             >
  //               {SECTIONS[xPathName]?.label || xPathName || ""}
  //             </Anchor>
  //           );
  //         })}
  //     </MUIBreadcrumbs>
  //   </Stack>
  // );
};

export default Breadcrumbs;
