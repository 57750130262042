import { strings } from "investira.sdk";
import {
  USER_LOADED,
  USER_USERNAME_CHANGED,
  USER_LOGOUT,
  USER_DEFAULT_ENTIDADE_CHANGED,
} from "../../const/actionsType";

export function acUserUpdate(pData) {
  const xName = strings.splitFullName(pData.name);
  console.log("pData", pData);
  const xData = {
    ...pData,
    name_first: xName.first,
    name_last: xName.last,
    name_middle: xName.middle,
  };

  return {
    type: USER_LOADED,
    payload: xData,
  };
}

export function acUserChangeUsername(pData) {
  return {
    type: USER_USERNAME_CHANGED,
    payload: pData,
  };
}

export function acUserChangeEntidade(pData) {
  return {
    type: USER_DEFAULT_ENTIDADE_CHANGED,
    payload: pData,
  };
}

export function acUserLogout() {
  return {
    type: USER_LOGOUT,
  };
}
