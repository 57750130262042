import { Icon } from "investira.react.components";
import OUTPUT from "./OUTPUT";

const SECTIONS = {
  home: {
    key: "home",
    label: "Home",
    path: "/",
    icon: <Icon iconName="home" color="primary" size={24} />,
  },

  dashboard: {
    key: "dashboard",
    label: "Dashboard",
    path: "/dashboard",
    icon: <Icon iconName="control_panel" color="primary" size={24} />,
  },

  reports: {
    key: "reports",
    label: "Reports",
    path: "/reports",
    icon: <Icon iconName="papers" color="primary" size={24} />,
  },
  carteiras: {
    key: "carteiras",
    label: "Carteiras",
    path: "/carteiras",
    icon: <Icon iconName="wallet" color="primary" size={24} />,
  },
  uploads: {
    key: "uploads",
    label: "Uploads",
    path: "/uploads",
    icon: <Icon iconName="upload" color="primary" size={24} />,
  },
  simulacoes: {
    key: "simulacoes",
    label: "Simulações",
    path: "/simulacoes",
    icon: <Icon iconName="lab" color="primary" size={24} />,
  },
  "carteiras-abertas": {
    key: "carteiras-abertas",
    label: "Carteiras Abertas",
    path: "/carteiras-abertas",
    icon: <Icon iconName="open_wallet" color="primary" size={24} />,
  },
  calculadoras: {
    key: "calculadoras",
    label: "Calculadoras",
    path: "/calculadoras",
    icon: <Icon iconName="calculator" color="primary" size={24} />,
  },
  empresas: {
    key: "empresas",
    label: "Empresas",
    path: "/empresas",
    icon: <Icon iconName="office_building" color="primary" size={24} />,
  },
  ativos: {
    key: "ativos",
    label: "Ativos",
    path: `/ativos?mercado=ACN`,
    icon: <Icon iconName="box_3d" color="primary" size={24} />,
  },
  dados: {
    key: "dados",
    label: "Dados",
    path: "/bd",
    icon: <Icon iconName="database" color="primary" size={24} />,
  },
  //Usuário
  configuracoes: {
    key: "configuracoes",
    label: "Configurações",
    icon: <Icon iconName="gear" size={21} />,
    path: "/configuracoes",
  },
  entidades: {
    key: "entidades",
    label: "Entidades",
    icon: <Icon iconName="wallet" size={21} />,
    path: "/configuracoes/entidades",
  },
  membros: {
    key: "membros",
    label: "Membros",
    icon: <Icon iconName="users" size={21} />,
    path: "/configuracoes/entidades/membros",
  },
  "informacoes-pessoais": {
    key: "informacoes-pessoais",
    label: "Informações Pessoais",
    icon: <Icon iconName="user" size={21} />,
    path: "/configuracoes/informacoes-pessoais",
  },
  seguranca: {
    key: "seguranca",
    label: "Segurança",
    icon: <Icon iconName="shield" size={21} />,
    path: "/configuracoes/seguranca",
  },
  "pagamentos-assinaturas": {
    key: "pagamentos-assinaturas",
    label: "Pagamentos e assinaturas",
    icon: <Icon iconName="wallet" size={21} />,
    path: "/configuracoes/pagamentos-assinaturas",
  },
  // Relatórios
  duration: {
    key: OUTPUT.duration.id,
    label: OUTPUT.duration.descricao,
    path: `/reports/${OUTPUT.duration.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  var: {
    key: OUTPUT.var.id,
    label: OUTPUT.var.descricao,
    path: `/reports/${OUTPUT.var.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  enquadramento: {
    key: OUTPUT.enquadramento.id,
    label: OUTPUT.enquadramento.descricao,
    path: `/reports/${OUTPUT.enquadramento.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  raiox: {
    key: OUTPUT.raiox.id,
    label: OUTPUT.raiox.descricao,
    path: `/reports/${OUTPUT.raiox.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  rentabilidade: {
    key: OUTPUT.rentabilidade.id,
    label: OUTPUT.rentabilidade.descricao,
    path: `/reports/${OUTPUT.rentabilidade.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  stresstest: {
    key: OUTPUT.stresstest.id,
    label: OUTPUT.stresstest.descricao,
    path: `/reports/${OUTPUT.stresstest.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  backtesting: {
    key: OUTPUT.backtesting.id,
    label: OUTPUT.backtesting.descricao,
    path: `/reports/${OUTPUT.backtesting.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  fronteiraeficiente: {
    key: OUTPUT.fronteiraeficiente.id,
    label: OUTPUT.fronteiraeficiente.descricao,
    path: `/reports/${OUTPUT.fronteiraeficiente.id}`,
    icon: <Icon iconName="paper" color="primary" size={16} />,
  },
  // Genericos
  add: {
    key: "add",
    label: "Adicionar",
  },
  carteira: {
    key: "carteira",
    label: "Carteira",
  },
};

export default SECTIONS;
