import React, { memo, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Typography, Button, Icon } from "investira.react.components";
import { validators } from "investira.sdk";
import {
  Upload,
  ArquivosDataGrid,
  BulkContextActions,
} from "../../components/organisms";
import { PageTemplate } from "../../components/templates";
import { useUploadContext } from "../../contexts/UploadContext";
import services from "../../services";
import withResponseHandling from "../../hoc/withResponseHandling";
import withMessage from "../../hoc/withMessage";

import { acBackgroundArquivosChanged } from "../../store/actions";

const PAGE_SIZE = 10;

const ArquivosRoot = memo((props) => {
  const updatedAt = useSelector((state) => state.background.arquivos.updatedAt);
  const entidadeId = useSelector(
    (state) => state.entidade.current.default_entidade_id
  );
  const dispatch = useDispatch();

  const [arquivos, setArquivos] = useState([]);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });

  const readArquivos = useCallback(
    (pParams = {}) => {
      if (!arquivos.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      services.posicoes.arquivos(
        {
          params: {
            sort: "data_envio desc",
            size: PAGE_SIZE,
            page: 1,
            ...pParams,
          },
        },
        (rRes) => {
          setArquivos(Object.values(rRes.data));
          setRowCount(rRes.pages.total_items);
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          setIsLoading(false);
          setIsRefetching(false);
        }
      );
    },
    [arquivos.length, props]
  );

  const deleteArquivos = (pFile, onFinish) => {
    const { data_envio, arquivo } = pFile;
    services.posicoes.uploadRemove(
      { data_envio, arquivo },
      (rRes) => {
        props.onMessageSuccess("Arquivo excluído com sucesso");
        readArquivos();
        setRowSelection({});
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      },
      () => {
        onFinish && onFinish();
      }
    );
  };

  const bulkDeleteArquivos = (pRows) => {
    const xArquivosId = pRows.map((xRow) => {
      const { data_envio, arquivo, entidade_id } = xRow.original;
      return { data_envio, arquivo, entidade_id };
    });

    xArquivosId.forEach((xArquivo) => {
      deleteArquivos(xArquivo);
    });
  };

  const uploadContext = useUploadContext();

  const handleUploadClick = () => {
    uploadContext.actions.open();
  };

  const handleFinishUpload = () => {
    readArquivos();
    dispatch(acBackgroundArquivosChanged(true));
  };

  const handleDeleteClick = (pFileData) => {
    setFile(pFileData);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
  };

  useEffect(() => {
    const xPage = pagination.pageIndex + 1;
    readArquivos({
      page: xPage,
      size: pagination.pageSize,
    });
  }, [
    readArquivos,
    pagination.pageIndex,
    pagination.pageSize,
    updatedAt,
    entidadeId,
  ]);

  useEffect(() => {
    if (!validators.isEmpty(file)) {
      handleClickOpen();
    }
  }, [file]);

  return (
    <PageTemplate
      title="Uploads"
      headerRenderActions={
        <Button
          disabled={props.disabled}
          variant="contained"
          color="primary"
          startIcon={<Icon color="secondary" iconName="upload" size={16} />}
          size="small"
          sx={{ px: 2 }}
          onClick={handleUploadClick}
        >
          Selecionar arquivos
        </Button>
      }
      scrollable={false}
    >
      <Upload onFinish={handleFinishUpload}>
        <ArquivosDataGrid
          data={arquivos}
          isLoading={isLoading}
          initialState={{ pagination }}
          // manualFiltering
          manualPagination
          // manualSorting
          getRowId={(row) => row._rid}
          state={{
            isLoading,
            pagination,
            showProgressBars: isRefetching,
            rowSelection,
          }}
          rowCount={rowCount}
          onPaginationChange={setPagination}
          onDeleteClick={handleDeleteClick}
          onRowSelectionChange={setRowSelection}
          renderTopToolbarCustomActions={({ table }) => {
            const selectedRows = table.getSelectedRowModel().rows;
            const isSomeRowSelected = selectedRows.length > 0;

            return (
              <BulkContextActions
                onDeleteClick={() => {
                  bulkDeleteArquivos(selectedRows);
                }}
                disabled={isLoading || isRefetching || !isSomeRowSelected}
              />
            );
          }}
          renderEmptyRowsFallback={({ table }) => {
            return (
              <Stack py={6}>
                <Typography variant="body1" color="textPrimary" align="center">
                  Arraste e solte alguns arquivos aqui
                </Typography>
                <Typography
                  component="p"
                  variant="caption"
                  color="textPrimary"
                  align="center"
                >
                  (Somente serão aceitos arquivos .xml)
                </Typography>
              </Stack>
            );
          }}
        />
      </Upload>
    </PageTemplate>
  );
});

ArquivosRoot.displayName = "ArquivosRoot";

export default withMessage(withResponseHandling(ArquivosRoot));
