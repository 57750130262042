import { ATIVOSBASE_LIST, ATIVOSBASE_MERCADO } from "../const/servicesURI";
import requestService from "./requestService";
import { httpRequests } from "investira.sdk";
import utils from "../utils";

const cancel = {
  list: null,
  mercado: null,
};

export const list = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.list) {
    cancel.list.cancel();
  }

  cancel.list = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.list.token,
  };

  return requestService(xProps, ATIVOSBASE_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service AtivosBase List");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const mercado = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.list) {
    cancel.list.cancel();
  }

  cancel.list = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.list.token,
  };

  return requestService(xProps, ATIVOSBASE_MERCADO, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service AtivosBase Mercado");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const ativosbase = { list, mercado, cancel };

export default ativosbase;
