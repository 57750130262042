import { SETORES_LIST, SETORES_READ } from "../const/servicesURI";
import requestService from "./requestService";
import { httpRequests } from "investira.sdk";
import utils from "../utils";

const cancel = {
  list: null,
  read: null,
};

export const list = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.list) {
    cancel.list.cancel();
  }

  cancel.list = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.list.token,
  };

  return requestService(xProps, SETORES_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Setores List");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const read = (pId, pResolve, pReject, pFinally) => {
  if (cancel.read) {
    cancel.read.cancel();
  }

  cancel.read = httpRequests.cancelToken();

  const xProps = {
    params: {
      setor_id: pId,
      cancelToken: cancel.read.token,
    },
  };

  return requestService(xProps, SETORES_READ, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Setores Read");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const setores = { list, read, cancel };

export default setores;
