import React, { memo } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  useMediaQuery,
} from "investira.react.components";

const Head = memo((props) => {
  const { periodMarks } = props;
  const isUpLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {isUpLg && <TableCell>Conta</TableCell>}
        <TableCell
          sx={
            isUpLg
              ? {
                  width: "400px",
                  maxWidth: "400px",
                }
              : {
                  width: "200px",
                  maxWidth: "400px",
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  backgroundColor: (theme) => theme.palette.background.paper,
                }
          }
        >
          Descrição
        </TableCell>
        {periodMarks.map((xMark) => {
          return (
            <TableCell key={`head-${xMark?.label}`} align="right">
              {xMark?.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
});

export default Head;
