import React, { memo } from "react";
import { Routes, Route } from "react-router-dom/";
import EmpresaOverview from "./EmpresaOverview";
import EmpresaAtivos from "./EmpresaAtivos";
import EmpresaProvider from "../../../contexts/EmpresasContext";

const Empresa = memo(() => {
  return (
    <EmpresaProvider>
      <Routes>
        <Route exact path={"/"} element={<EmpresaOverview />} />
        <Route exact path={`ativos`} element={<EmpresaAtivos />} />
        <Route exact path={`ativos/:tipo`} element={<EmpresaAtivos />} />
        <Route exact path={"*"} element={<EmpresaOverview />} />
      </Routes>
    </EmpresaProvider>
  );
});

export default Empresa;
