import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Box,
  Typography,
  Stack,
  MaterialReactTable,
  Tooltip,
  IconButton,
  Icon,
} from "investira.react.components";
import { BulkContextActions } from "../../../../components/organisms";
//import { useCalculadoraVariacaoContext } from "../../../../contexts/CalculadoraVariacaoContext";
import { acCalculadoraCurvaDeleted } from "../../../../store/actions";
import columns from "./columns";
import Eventos from "../Eventos";

const CurvaHistorico = memo((props) => {
  const calculadoras = useSelector((store) => store.calculadoras);
  const xData = calculadoras.curva.data;

  const dispatch = useDispatch();

  function handleDeleteClick(pSeletedRows, pData) {
    const xSelectedIDs = pSeletedRows.map((xItem) => {
      return xItem.original.id;
    });

    const xNewData = pData.filter((xItem) => {
      return !xSelectedIDs.includes(xItem.id);
    });

    dispatch(acCalculadoraCurvaDeleted(xNewData));
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Stack px={2} py={2}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Histórico
          </Typography>
        </Stack>

        <MaterialReactTable
          //key={state.indicador_tipo}
          columns={columns}
          data={xData}
          getRowId={(row) => row.id}
          state={{}}
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          memoMode="cell"
          enableFullScreenToggle={false}
          enableBottomToolbar={true}
          enableGlobalFilter={false}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "",
            },
          }}
          muiTablePaperProps={{
            sx: {
              pb: 2,
            },
          }}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "1fr 1fr",
                width: "100%",
                pl: 6,
              }}
            >
              <Eventos
                eventos={row.original.result.eventos}
                data={row.original.payload.data}
              />
            </Box>
          )}
          enableRowSelection={true}
          renderTopToolbarCustomActions={({ table }) => {
            const selectedRows = table.getSelectedRowModel().rows;
            const isSomeRowSelected = selectedRows.length > 0;

            return (
              <BulkContextActions
                onDeleteClick={() => {
                  handleDeleteClick(selectedRows, xData);
                }}
                disabled={!isSomeRowSelected}
              />
            );
          }}
          enableRowActions={true}
          positionActionsColumn={"last"}
          renderRowActions={({ row }) => {
            //const { carteira_id } = row.original;
            return (
              <Stack direction="row">
                <Tooltip title="Repetir">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      props.onClickRepeat(
                        row.original.payload,
                        row.original.ativo
                      )
                    }
                  >
                    <Icon iconName="undo" size={21} />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          }}
        />
      </Paper>
    </Box>
  );
});

CurvaHistorico.displayName = "CurvaHistorico";

export default CurvaHistorico;
