import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Button,
  Icon,
  Typography,
  Divider,
  LoadingButton,
} from "investira.react.components";

const UploadContextActions = memo((props) => {
  function handleSendClick() {
    props.onSendClick && props.onSendClick();
  }

  function handleCancelClick() {
    props.onCancelClick && props.onCancelClick();
  }

  function handleAddClick() {
    props.onAddClick && props.onAddClick();
  }

  return (
    <Stack
      mb={2}
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        my={2}
      >
        <Stack>
          <Typography variant="h6" color="textPrimary">
            Arquivos
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={1.5}>
            {/* <Button
              variant="outlined"
              color="primary"
              startIcon={<Icon iconName="in" size={16} />}
              size="small"
              sx={{ px: 2 }}
              onClick={handleAddClick}
              disabled={props.isUploading}
            >
              Adicionar
            </Button> */}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Icon iconName="cancel" size={16} />}
              size="small"
              sx={{ px: 2 }}
              onClick={handleCancelClick}
              disabled={props.isUploading}
            >
              Cancelar
            </Button>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack>
            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<Icon color="secondary" iconName="upload" size={16} />}
              size="small"
              sx={{ px: 2 }}
              onClick={handleSendClick}
              disabled={props.isUploading}
              loading={props.isUploading}
            >
              Enviar
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});

UploadContextActions.propTypes = {};

export default UploadContextActions;
