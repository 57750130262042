import React, { memo } from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "investira.react.components";

const ModalInfoDialog = memo((props) => {
  const { open, onClose, children, ...childrenProps } = props;
  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const xDialogProps = isUpMd
    ? {
        fullScreen: false,
        PaperProps: {
          sx: {
            pt: 3,
            pb: 2,
            px: 2,
          },
        },
        maxWidth: "lg",
      }
    : {
        fullScreen: true,
        PaperProps: {
          sx: {
            margin: 0,
            backgroundColor: "background.dark",
          },
        },
      };

  return (
    <Dialog
      {...xDialogProps}
      open={props.open}
      onClose={props.onClose}
      fullWidth
    >
      <DialogTitle>Setores</DialogTitle>
      <DialogContent sx={{ paddingTop: "8px !important" }}>
        <Stack spacing={2} sx={{ position: "relative" }}>
          {React.cloneElement(children, { ...childrenProps })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
});

export default ModalInfoDialog;
