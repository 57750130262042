import {
  BACKGROUND_CARTEIRA_CHANGED,
  BACKGROUND_ARQUIVOS_CHANGED,
  BACKGROUND_REPORTS_CHANGED,
  BACKGROUND_LOGOUT,
} from "../../const/actionsType";

import { dates } from "investira.sdk";

const INITIAL_STATE = {
  arquivos: {
    process: false,
    updatedAt: null,
  },
  carteiras: {
    process: false,
    updatedAt: null,
  },
  reports: {
    process: false,
    updatedAt: null,
  },
};

const RESET_STATE = {
  ...INITIAL_STATE,
};

function backgroundReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BACKGROUND_ARQUIVOS_CHANGED:
      return {
        ...state,
        arquivos: {
          process: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    case BACKGROUND_CARTEIRA_CHANGED:
      return {
        ...state,
        carteiras: {
          process: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    case BACKGROUND_REPORTS_CHANGED:
      return {
        ...state,
        reports: {
          process: action.payload,
          updatedAt: dates.toDate(),
        },
      };
    case BACKGROUND_LOGOUT:
      return {
        ...RESET_STATE,
      };
    default:
      return state;
  }
}

export default backgroundReducer;
