import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stack, Box, Container } from "investira.react.components";
import { validators } from "investira.sdk";
import { HeaderTemplate } from "../";
import { ScrollContainer } from "../../atoms";
import { Head } from "../../molecules";
import { Breadcrumbs } from "../../organisms";
import withResponseHandling from "../../../hoc/withResponseHandling";

const HEADER_HEIGHT = 84;

const BaseTemplate = memo((props) => {
  const {
    title = "Sem Título",
    description = "Sistemas para fundos de investimentos, empresas e instituições financeiras dos mais diversos portes como Fundos de Pensão, Bancos, Assets Managements e Family Offices.",
    head,
    filterComponent = null,
    searchComponent = null,
    termsComponent = null,
    children,
    maxWidth = false,
    search = "",
    onDeleteSearch,
  } = props;

  return (
    <>
      <Head title={title}>
        <meta name="description" content={description} />
        {head}
      </Head>

      <Stack
        id="page"
        flexGrow={1}
        sx={{
          height: "100%",
          paddingTop: 3,
        }}
      >
        <ScrollContainer>
          <Container id="base-container" maxWidth={maxWidth} sx={{ pb: 8 }}>
            {!validators.isNull(searchComponent) && (
              <Box mb={4}>{searchComponent}</Box>
            )}

            <Breadcrumbs />

            <HeaderTemplate
              title={props.title}
              subtitle={props.subtitle}
              description={props.description}
              height={HEADER_HEIGHT}
              renderActions={props.headerRenderActions}
            />

            {!validators.isEmpty(termsComponent) && (
              <Stack direction="row" alignItems="center">
                <Stack flexGrow={1}>
                  {!validators.isEmpty(termsComponent) && termsComponent}
                </Stack>
              </Stack>
            )}
            {children}
          </Container>
        </ScrollContainer>
      </Stack>
    </>
  );
});

BaseTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  head: PropTypes.element,
  searchComponent: PropTypes.object,
};

BaseTemplate.displayName = "BaseTemplate";

export default withResponseHandling(BaseTemplate);
