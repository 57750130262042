import React, { memo, useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { browsers } from "investira.react.lib";
import { strings } from "investira.sdk";
import {
  Typography,
  Button,
  Success,
  Error,
  CenterInView,
  Loading,
  Stack,
} from "investira.react.components";
import { GENERIC } from "../../const";
import services from "../../services";

const EmailConfirm = memo(() => {
  const animationTimeout = useRef(null);

  // States
  const [validate, setValidate] = useState();
  const [error, setError] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const params = useParams();

  // Methods
  function drawerOnAnimationEnd() {
    const ANIMATION_TIMEOUT = 50;

    animationTimeout.current = setTimeout(() => {
      if (startAnimation === false) {
        setStartAnimation(true);
      }
    }, ANIMATION_TIMEOUT);
  }

  function updateName(pName) {
    const xName = strings.splitFullName(pName);
    setName(xName.first);
  }

  function doCodeValidate() {
    browsers.isOnline()
      ? services.auth.registerConfirm(
          {
            code: params.code,
          },
          (rRes) => {
            if (rRes.status !== 200) {
              setValidate(false);
            } else {
              setUsername(rRes.data.username);
              updateName(rRes.data.name);
              setValidate(true);
            }
          },
          () => {
            setValidate(null);
          }
        )
      : setError(true);
  }

  // Effects

  useEffect(() => {
    doCodeValidate();
    drawerOnAnimationEnd();

    return () => {
      clearTimeout(animationTimeout.current);
    };
  }, []);

  // Render

  if (error) {
    return (
      <CenterInView>
        <Typography
          gutterBottom
          variant={"body1"}
          color={"textPrimary"}
          align={"center"}
        >
          {GENERIC.WELCOME.NO_CONNEXION}
        </Typography>
      </CenterInView>
    );
  }

  return (
    <Stack flexGrow={1} sx={{ padding: "16px" }}>
      {validate === true ? (
        <CenterInView>
          <Stack alignItems="center" spacing={4}>
            <Stack alignItems="center" spacing={2}>
              <Success
                arcStroke="#00dfa8"
                iconStroke="#00dfa8"
                startAnimation={true}
                width={200}
                height={200}
              />

              <Typography
                gutterBottom
                variant={"h5"}
                color={"primary"}
                align={"center"}
              >
                {name}, <br /> {GENERIC.WELCOME.WELCOME}
              </Typography>

              <Typography
                variant={"body2"}
                align={"center"}
                color={"textPrimary"}
              >
                {GENERIC.WELCOME.CONFIRM.EMAIL_CONFIRM}
              </Typography>
            </Stack>

            <Button
              variant={"contained"}
              color={"primary"}
              component={Link}
              to="/login"
            >
              {GENERIC.WELCOME.BUTTONS.LOGIN}
            </Button>
          </Stack>
        </CenterInView>
      ) : validate === false ? (
        <CenterInView>
          <Stack alignItems="center" spacing={4}>
            <Stack alignItems="center" spacing={2}>
              <Error startAnimation={true} width={200} height={200} />
              <Typography
                variant={"body1"}
                align={"center"}
                color={"textPrimary"}
                gutterBottom
              >
                <b>
                  Não foi possível efetuar a confirmação do seu cadastro. <br />
                  A confirmação deve ser efetuada em um prazo de 24h.
                </b>
              </Typography>
              <Typography
                variant={"body1"}
                align={"center"}
                color={"textPrimary"}
              >
                {GENERIC.WELCOME.NEW_SIGNUP}
              </Typography>
            </Stack>
            <Button
              variant={"contained"}
              color={"primary"}
              component={Link}
              to="/login"
            >
              {GENERIC.WELCOME.BUTTONS.SIGNUP}
            </Button>
          </Stack>
        </CenterInView>
      ) : validate === null ? (
        <CenterInView>
          <Stack alignItems="center" spacing={4}>
            <Stack alignItems="center" spacing={2}>
              <Error startAnimation={true} width={200} height={200} />
              <Typography
                variant="body1"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                <b>{GENERIC.WELCOME.ERROR}</b>
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                doCodeValidate();
              }}
              variant="contained"
              color="primary"
            >
              {GENERIC.WELCOME.BUTTONS.RETRY}
            </Button>
          </Stack>
        </CenterInView>
      ) : (
        <CenterInView>
          <Loading />
        </CenterInView>
      )}
    </Stack>
  );
});

EmailConfirm.displayName = "EmailConfirm";

export default EmailConfirm;
