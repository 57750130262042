import { CONVITES_CONVIDAR } from "../const/servicesURI";
import { httpRequests } from "investira.sdk";
import requestService from "./requestService";
import utils from "../utils";

export const cancel = {};

export const convidar = (pProps, pResolve, pReject, pFinally) => {
  const xProps = {
    ...pProps,
    method: "POST",
  };

  return requestService(xProps, CONVITES_CONVIDAR, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Convites Convidar");
      return pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const convites = { convidar };

export default convites;
