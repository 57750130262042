import React, { memo, useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Stack,
  TabContext,
  Box,
  TabList,
  Tab,
  TabPanel,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Typography,
} from "investira.react.components";
import { arrays, dates, validators } from "investira.sdk";
import { PLANO_CONTABIL_TIPO } from "@investirapri/financa.js/lib/enums";
import services from "../../../../../../services";
import Period from "./Period";
import Filters from "./Filters";
import Head from "./Head";
import Rows from "./Rows";
import CardFooter from "../CardFooter";
import { useEmpresasContext } from "../../../../../../contexts/EmpresasContext";

const MONTHS = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];

const HistoricoFinanceiro = memo(() => {
  const params = useParams();
  const { state } = useEmpresasContext();

  // Dados
  const [disponivel, setDisponivel] = useState([]);
  const [balanco, setBalanco] = useState([]);
  const [saldos, setSaldos] = useState([]);

  const [periodMarks, setPeriodMarks] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");

  // Filtros
  const [period, setPeriod] = React.useState([0, 0]); //[2, 4]
  const [percentual, setPercentual] = useState(false);

  // Filtros de visualização
  const [decimal, setDecimal] = useState(2);
  const [escala, setEscala] = useState("mi");
  const [framing, setFraming] = useState(["anual", 10]);

  const [currentFilters, setCurrentFilters] = useState({});
  const [filters, setFilters] = useState({});

  const [requestParams, setRequestParams] = useState({});

  function bestMatchDisponivel(pState, pDisponivel, pKey, pValue) {
    const xDisponivel = pDisponivel.filter((xItem) => {
      return xItem[pKey] === pValue;
    });

    let xMaxMatches = 0;
    let xBestMatchIndex = -1;

    xDisponivel.forEach((xItem, xIndex) => {
      let xMatchCount = 0;

      for (const xKey in pState) {
        if (pState.hasOwnProperty(xKey) && xItem[xKey] === pState[xKey]) {
          xMatchCount++;
        }
      }

      if (xMatchCount > xMaxMatches) {
        xMaxMatches = xMatchCount;
        xBestMatchIndex = xIndex;
      }
    });

    const xNewState = xDisponivel[xBestMatchIndex];

    return xNewState;
  }

  function handleChangeValues(pNewValue, pDisponivel) {
    const xFilter = Object.entries(pNewValue)[0];
    const xKey = xFilter[0];
    const xValue = xFilter[1];

    setCurrentFilters((prevState) => {
      let xNewState = { ...prevState, ...pNewValue };
      xNewState = bestMatchDisponivel(xNewState, pDisponivel, xKey, xValue);

      const xFilters = defineFilters(pDisponivel, xNewState, xKey);
      setFilters(xFilters);
      setRequestParams(xNewState);

      return xNewState;
    });
  }

  function handleTooglePercentual() {
    setPercentual((prevState) => {
      return !prevState;
    });
  }

  const handleChangePeriod = (e, pNewValue) => {
    setPeriod(pNewValue);
  };

  const handleChangeEscala = (pNewValue) => {
    setEscala(pNewValue);
  };

  const handleChangeFraming = (pNewValue) => {
    setFraming(pNewValue);
  };

  const handleChangeTab = (e, pNewValue) => {
    setActiveTab(pNewValue);
  };

  function handleChangeDecimal(pValue) {
    setDecimal((prevValue) => {
      const xNewValue = prevValue + pValue;
      if (xNewValue >= 2 && xNewValue <= 15) {
        return xNewValue;
      }

      return prevValue;
    });
  }

  function fillInterval(pInterval = []) {
    const xInitialValue = pInterval[0];
    const xFinalValue = pInterval[1];

    const xArray = [];

    for (let xI = xInitialValue; xI <= xFinalValue; xI++) {
      xArray.push(xI);
    }

    return xArray;
  }

  const selectedMarks = useCallback((pPeriod = [], pMarks) => {
    const xPeriod = fillInterval(pPeriod);

    let xMarks = xPeriod.map((xValue) => {
      return pMarks[xValue];
    });

    return xMarks;
  }, []);

  function getFilterValues(pArr, pAttr) {
    const xSet = new Set();

    pArr.forEach((xItem) => {
      xSet.add(xItem[pAttr]);
    });

    return Array.from(xSet);
  }

  function getLastMonth(pArr) {
    if (validators.isEmpty(pArr)) {
      return null;
    }

    const xLastElement = pArr[pArr.length - 1];
    const xData = new Date(xLastElement.data);
    const xMes = xData.getMonth();

    return xMes;
  }

  function filterSaldos(pSaldos, pLastMonth) {
    if (validators.isEmpty(pSaldos)) {
      return [];
    }

    const xSaldos = pSaldos.filter((xItem) => {
      const xData = new Date(xItem.data);
      const xMes = xData.getMonth();
      return xMes === pLastMonth;
    });

    return xSaldos;
  }

  function separateByPeriod(pArr) {
    const xMesAno = {};
    const xTrimestreAno = {};
    const xSemestreAno = {};
    const xAno = {};

    pArr.forEach((xItem) => {
      const xDate = new Date(xItem.data);
      const xMonth = xDate.getMonth() + 1; // getMonth() retorna 0-11
      const xYear = xDate.getFullYear();

      // Mes-Ano
      const xKeyMonthYear = `${xYear}-${xMonth}`;

      if (!xMesAno[xKeyMonthYear]) {
        xMesAno[xKeyMonthYear] = [];
      }

      xMesAno[xKeyMonthYear].push(xItem);

      // Trimestre-Ano
      const xQuarter = Math.ceil(xMonth / 3);
      const xKeyQuarterYear = `${xYear}-T${xQuarter}`;

      if (!xTrimestreAno[xKeyQuarterYear]) {
        xTrimestreAno[xKeyQuarterYear] = [];
      }

      xTrimestreAno[xKeyQuarterYear].push(xItem);

      // Semestre-Ano
      const xHalf = xMonth <= 6 ? 1 : 2;
      const xKeyHalfYear = `${xYear}-S${xHalf}`;

      if (!xSemestreAno[xKeyHalfYear]) {
        xSemestreAno[xKeyHalfYear] = [];
      }

      xSemestreAno[xKeyHalfYear].push(xItem);

      // Ano
      const xKeyYear = `${xYear}`;
      if (!xAno[xKeyYear]) {
        xAno[xKeyYear] = [];
      }
      xAno[xKeyYear].push(xItem);
    });

    return {
      mensal: xMesAno,
      trimestral: xTrimestreAno,
      semestral: xSemestreAno,
      anual: xAno,
    };
  }

  function slicePeriod(pSaldos) {
    let xSlice = 0;

    const xPeriod = Object.keys(pSaldos);

    if (xPeriod.length > 5) {
      xSlice = xPeriod.length - 5;
      xSlice = xSlice < 0 ? 0 : xSlice;
    }

    const xPeriodRange = [xSlice, xPeriod.length - 1];

    return xPeriodRange;
  }

  function formatLabel(pFraming, pKey, pLastMonth) {
    if (pFraming === "anual") {
      return `${MONTHS[pLastMonth]} '${String(pKey).slice(-2)}`;
    }

    if (pFraming === "semestral") {
      const xYearMonth = pKey.split("-");
      return `${MONTHS[pLastMonth]} '${String(xYearMonth[0]).slice(-2)}`;
    }

    if (pFraming === "trimestral") {
      const xYearMonth = pKey.split("-");
      return `${MONTHS[pLastMonth]} '${String(xYearMonth[0]).slice(-2)}`;
    }

    if (pFraming === "mensal") {
      const xYearMonth = pKey.split("-");
      return `${MONTHS[xYearMonth[1] - 1]} '${String(xYearMonth[0]).slice(-2)}`;
    }

    return pKey;
  }

  function defineFilters(pDisponivel, pAtual, pAttr) {
    const xDisponivel = pDisponivel;

    const xPlanoContabil = xDisponivel.filter(
      (xItem) => xItem.consolidacao_tipo === pAtual.consolidacao_tipo
    );

    const xBalancete = xPlanoContabil.filter((xItem) => {
      return (
        xItem.plano_contabil_tipo === pAtual.plano_contabil_tipo &&
        xItem.consolidacao_tipo === pAtual.consolidacao_tipo
      );
    });

    const xFilters = {
      consolidacao: getFilterValues(xDisponivel, "consolidacao_tipo"),
      plano: getFilterValues(xPlanoContabil, "plano_contabil_tipo"),
      balancete: getFilterValues(xBalancete, "balancete"),
    };

    return xFilters;
  }

  function updateConsolidado(pConsolidacao, pConsolidacaoTipo = null) {
    let xConsolidado = pConsolidacao.includes(1) ? 1 : pConsolidacao[0];
    xConsolidado = pConsolidacaoTipo ?? xConsolidado;

    return xConsolidado;
  }

  function updatePlanoContabil(pPlanoContabil, pPlanoContabilTipo = null) {
    const xPlano = pPlanoContabil.includes("EFPC") ? "EFPC" : pPlanoContabil[0];
    return xPlano;
  }

  function updateBalancete(pBalancete, pBalanceteValue = null) {
    const xBalancete = pBalanceteValue ?? pBalancete[0];

    return xBalancete;
  }

  function filterUniquesDisponivel(pResumo) {
    const xUniqueSet = new Set();
    const xResult = [];

    pResumo.forEach((xItem) => {
      const { balancete, consolidacao_tipo, plano_contabil_tipo } = xItem;
      const xKey = `${balancete}-${consolidacao_tipo}-${plano_contabil_tipo}`;
      if (!xUniqueSet.has(xKey)) {
        xUniqueSet.add(xKey);
        xResult.push({ balancete, consolidacao_tipo, plano_contabil_tipo });
      }
    });

    return xResult;
  }

  function removeDemonstrativo(pData) {
    let xResumo = pData.map((xItem) => {
      return {
        ...xItem,
        ...PLANO_CONTABIL_TIPO[xItem.plano_contabil_tipo],
      };
    });

    xResumo = xResumo.filter((xItem) => {
      return xItem.demonstrativo === false;
    });

    return xResumo;
  }

  function takeSaldos(pBalanco) {
    let xSaldos = [];

    function saldos(pNode, pKey) {
      if (pNode.saldos) {
        for (const xData in pNode.saldos) {
          if (pNode.saldos.hasOwnProperty(xData)) {
            xSaldos.push({
              conta_key: pKey,
              data: xData,
              valor: pNode.saldos[xData],
              nivel: pNode.nivel,
            });
          }
        }
      }

      if (pNode.filhos) {
        for (const xKey in pNode.filhos) {
          if (pNode.filhos.hasOwnProperty(xKey)) {
            saldos(pNode.filhos[xKey], xKey);
          }
        }
      }
    }

    for (const xKey in pBalanco) {
      if (pBalanco.hasOwnProperty(xKey)) {
        saldos(pBalanco[xKey], xKey);
      }
    }

    return xSaldos;
  }

  function orderBalanco(pBalanco) {
    // Ordena o nivel 1 pela conta
    let xBalanco = Object.entries(pBalanco).map(([xKey, xValue]) => {
      return { ...xValue, conta_key: xKey };
    });

    xBalanco = xBalanco.sort((a, b) => {
      if (a.conta < b.conta) {
        return -1;
      }
      if (a.conta > b.conta) {
        return 1;
      }
      return 0;
    });

    return arrays.arrayToObject(xBalanco, "conta_key");
  }

  // REQUEST RESUMO (Disponível)
  useEffect(() => {
    if (state.pessoa.pessoa_id) {
      setBalanco([]);
      setSaldos([]);
      setCurrentFilters({});
      setRequestParams({});

      services.pessoas.balancoResumo(
        {
          data_inicio: dates.toSqlDate(dates.addYears(dates.toDate(), -10)),
          pessoa_id: state.pessoa.pessoa_id,
        },
        (rRes) => {
          let xData = rRes.data;

          if (validators.isEmpty(xData)) {
            setLoading(false);
          }

          const xResumo = removeDemonstrativo(xData);

          const xDisponivel = filterUniquesDisponivel(xResumo);
          setDisponivel(xDisponivel);

          // SET FILTERS
          const xFilters = defineFilters(xDisponivel, xDisponivel[0]);

          setFilters(xFilters);

          const xParams = {
            balancete: xDisponivel[0].balancete,
            plano_contabil_tipo: xDisponivel[0].plano_contabil_tipo,
            consolidacao_tipo: xDisponivel[0].consolidacao_tipo,
          };

          setCurrentFilters(xParams);
          setRequestParams(xParams);
        },
        null
      );
    }

    return () => services.pessoas.cancel.balancoResumo.cancel();
  }, [state.pessoa.pessoa_id]);

  // REQUEST BALANÇO
  useEffect(() => {
    if (
      state.pessoa.pessoa_id &&
      !validators.isEmpty(requestParams) &&
      !validators.isEmpty(requestParams.balancete) &&
      !validators.isEmpty(requestParams.consolidacao_tipo) &&
      !validators.isEmpty(requestParams.plano_contabil_tipo)
    ) {
      setBalanco([]);
      setLoading(true);

      const xParams = {
        data_inicio: dates.toSqlDate(dates.addYears(dates.toDate(), -10)),
        pessoa_id: state.pessoa.pessoa_id,
        nivel: 4,
        ...requestParams,
      };

      services.pessoas.balanco(
        xParams,
        (rRes) => {
          console.log("BALNCO", rRes.data);
          setBalanco(rRes.data);
        },
        null,
        () => {
          setLoading(false);
        }
      );

      return () => services.pessoas.cancel.balanco.cancel();
    }
  }, [state.pessoa.pessoa_id, requestParams]);

  // Define saldos e period
  useEffect(() => {
    if (
      state.pessoa.pessoa_id &&
      !validators.isEmpty(balanco) &&
      !validators.isEmpty(requestParams)
    ) {
      const xSaldosByPeriods = separateByPeriod(takeSaldos(balanco));

      let xSaldos = [];

      Object.entries(xSaldosByPeriods[framing[0]]).forEach(
        ([xKey, xValue], xIndex) => {
          const xLastMonth = getLastMonth(xValue);
          xSaldos[xIndex] = {
            period: xKey,
            mes: xLastMonth,
            label: formatLabel(framing[0], xKey, xLastMonth),
            value: xIndex,
            saldos: arrays.arrayToObject(
              filterSaldos(xValue, xLastMonth),
              "conta_key"
            ),
          };
        }
      );

      if (xSaldos.length > framing[1]) {
        xSaldos = xSaldos.slice(xSaldos.length - framing[1], xSaldos.length);
        xSaldos = xSaldos.map((xItem, xIndex) => {
          return { ...xItem, value: xIndex };
        });
      }

      const xPeriod = slicePeriod(xSaldos);

      setPeriod(xPeriod);
      setSaldos(xSaldos);
    }
  }, [state.pessoa.pessoa_id, balanco, requestParams, framing]);

  useEffect(() => {
    if (!validators.isEmpty(saldos)) {
      const xMarks = selectedMarks(period, saldos);
      setPeriodMarks(xMarks);
    }
  }, [period, saldos, selectedMarks]);

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ py: 2 }}
      >
        <CircularProgress size={24} />
      </Stack>
    );
  }

  if (validators.isEmpty(disponivel)) {
    return null;
  }

  return (
    <Stack sx={{ width: "100%" }}>
      <Period
        framing={framing}
        saldos={saldos}
        period={period}
        onChange={handleChangePeriod}
      />
      <Filters
        percentual={percentual}
        filters={filters}
        values={currentFilters}
        escala={escala}
        framing={framing}
        onChangeValues={(pNewValue) =>
          handleChangeValues(pNewValue, disponivel)
        }
        onTooglePercentual={handleTooglePercentual}
        onChangeDecimal={handleChangeDecimal}
        onChangeEscala={handleChangeEscala}
        onChangeFraming={handleChangeFraming}
      />
      <Card>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTab}>
              <Tab
                label={currentFilters.balancete ? "Balancete" : "Balanço"}
                value="1"
              />
              {/* <Tab label="DRE" value="2" disabled />
              <Tab label="Fluxo de caixa" value="3" disabled />
              <Tab label="Razões Financeiras" value="4" disabled /> */}
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ padding: "8px" }}>
            <TableContainer
              id="table-container"
              sx={{
                overflow: "auto",
              }}
            >
              <Table size="small" tableLayout="fixed">
                {!validators.isEmpty(saldos) && (
                  <Head periodMarks={periodMarks} />
                )}

                <TableBody>
                  {validators.isEmpty(saldos) ? (
                    <TableRow>
                      <TableCell align="center">
                        <Typography variant="caption" color="textSecondary">
                          Nenhuma informação disponível
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <Rows
                      key={`rows-${currentFilters.plano_contabil_tipo}-${currentFilters.consolidacao_tipo}-${currentFilters.balancete}`}
                      balanco={orderBalanco(balanco)}
                      periodMarks={periodMarks}
                      variacao={percentual}
                      decimal={decimal}
                      escala={escala}
                      level={0}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack>
              <Stack px={2} py={1}>
                <CardFooter
                  consolidacao={currentFilters.consolidacao_tipo}
                  pc={currentFilters.plano_contabil_tipo}
                />
              </Stack>
              <Divider />
            </Stack>
          </TabPanel>
          <TabPanel value="2">
            <Stack>DRE</Stack>
          </TabPanel>
          <TabPanel value="3">Fluxo de caixa</TabPanel>
          <TabPanel value="4">Razões Financeiras</TabPanel>
        </TabContext>
      </Card>
    </Stack>
  );
});

HistoricoFinanceiro.displayName = "HistoricoFinanceiro";

export default HistoricoFinanceiro;
