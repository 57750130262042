import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { validators, formats, dates } from "investira.sdk";
import {
  Chip,
  Stack,
  Tooltip,
  Typography,
  CircularProgress,
} from "investira.react.components";

import { DataTable } from "../";

const ArquivosDataGrid = (props) => {
  const { isFetching, data, onDeleteClick, ...restProps } = props;

  const columns = useMemo(
    () => [
      {
        accessorKey: "arquivo",
        header: "Nome",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          if (validators.isNull(cell.getValue())) {
            return;
          }
          return <Typography variant="body2">{cell.getValue()}</Typography>;
        },
      },
      {
        id: "status",
        accessorKey: "ifeed_posicao_import_fim",
        header: "Status",
        enableColumnActions: false,
        enableSorting: false,
        maxSize: 80,
        muiTableHeadCellProps: () => {
          return { align: "center" };
        },
        muiTableBodyCellProps: () => {
          return { align: "center" };
        },
        Cell: (xProps) => {
          const { cell, row } = xProps;

          if (!validators.isEmpty(row.original.ifeed_errors)) {
            const xError = !validators.isNull(
              row.original.ifeed_posicao_import_inicio
            )
              ? "Erro Importação"
              : "Erro Processamento";

            return (
              <Tooltip
                arrow
                title={
                  <Stack
                    spacing={1}
                    sx={{
                      px: 3,
                      py: 2,

                      whiteSpace: "normal",
                    }}
                  >
                    {row.original.ifeed_errors.map((error, index) => {
                      return (
                        <Stack key={`file-error-${index}`} spacing={1}>
                          <Typography variant="caption" color="textPrimary">
                            {error.description || error}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                }
              >
                <span>
                  <Chip size="small" label={xError} color="error" />
                </span>
              </Tooltip>
            );
          }

          if (!validators.isEmpty(cell.getValue())) {
            return <Chip size="small" label="Ok" color="success" />;
          }

          if (!validators.isNull(row.original.arquivo)) {
            // return <LinearProgress />;
            return (
              // <Stack
              //   sx={{
              //     width: "80px",
              //     alignItems: "center",
              //     justifyContent: "center",
              //   }}
              // >
              <CircularProgress size={24} />
              // </Stack>
            );
          }

          return null;
        },
      },
      {
        accessorKey: "data_envio",
        header: "Envio",
        enableColumnActions: false,
        enableSorting: false,
        maxSize: 100,
        Cell: ({ cell }) => {
          if (validators.isNull(cell.getValue())) {
            return;
          }
          return formats.formatDate(dates.toDate(cell.getValue()));
        },
      },
      {
        id: "processado",
        accessorKey: "ifeed_posicao_import_fim",
        header: "Processado",
        enableColumnActions: false,
        enableSorting: false,
        maxSize: 100,
        Cell: ({ cell }) => {
          if (validators.isNull(cell.getValue())) {
            return "";
          }
          return formats.formatDate(dates.toDate(cell.getValue()));
        },
      },
      {
        accessorKey: "ifeed_carteira",
        header: "Carteira",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        id: "posicao",
        accessorKey: "ifeed_data_posicao",
        header: "Posição",
        enableColumnActions: false,
        enableSorting: false,
        maxSize: 100,
        Cell: ({ cell }) => {
          if (validators.isNull(cell.getValue())) {
            return "";
          }
          return formats.formatDate(dates.toDate(cell.getValue()));
        },
      },
    ],
    []
  );

  const xProps = useMemo(() => {
    return {
      columns,
      ...restProps,
      enableRowSelection: true,
      enableGlobalFilter: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableDensityToggle: false,
      enableBottomToolbar: true,
      muiTopToolbarProps: {
        sx: {
          backgroundColor: "none",
          alignItems: "center",
        },
      },
      initialState: props.initialState,
    };
  }, [columns, props.initialState, restProps]);

  return <DataTable data={data} {...xProps} />;
};

ArquivosDataGrid.propTypes = {
  data: PropTypes.array,
  isFetching: PropTypes.bool,
  initialState: PropTypes.object,
};

ArquivosDataGrid.default = {
  initialState: {},
};

export default ArquivosDataGrid;
