import {
  INDICADORES_CHANGED,
  INDICADORES_LOGOUT,
} from "../../const/actionsType";
import { dates } from "investira.sdk";

const INITIAL_STATE = {
  data: {},
  updatedAt: null,
  isFetching: false,
};

const RESET_STATE = {
  ...INITIAL_STATE,
};

function indicadoresReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INDICADORES_CHANGED:
      return {
        ...state,
        data: action.payload,
        updatedAt: dates.toDate(),
      };
    case INDICADORES_LOGOUT:
      return {
        ...RESET_STATE,
      };
    default:
      return state;
  }
}

export default indicadoresReducer;
