import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stack, Box, Container } from "investira.react.components";
import { validators } from "investira.sdk";

import { ScrollContainer } from "../../atoms";
import { Head } from "../../molecules";
import { Breadcrumbs } from "../../organisms";
import withResponseHandling from "../../../hoc/withResponseHandling";

const AtivoTemplate = memo((props) => {
  const {
    title = "Sem Título",
    description = "Sistemas para fundos de investimentos, empresas e instituições financeiras dos mais diversos portes como Fundos de Pensão, Bancos, Assets Managements e Family Offices.",
    head,
    searchComponent = null,
    children,
    header,
    maxWidth = false,
  } = props;

  return (
    <>
      <Head title={title}>
        <meta name="description" content={description} />
        {head}
      </Head>

      <Stack
        id="page"
        flexGrow={1}
        sx={{
          height: "100%",
          paddingTop: 3,
        }}
      >
        <ScrollContainer>
          <Container maxWidth={maxWidth} sx={{ pb: 8 }}>
            {!validators.isNull(searchComponent) && (
              <Box mb={4}>{searchComponent}</Box>
            )}

            <Breadcrumbs />

            {header}

            {children}
          </Container>
        </ScrollContainer>
      </Stack>
    </>
  );
});

AtivoTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  head: PropTypes.element,
  searchComponent: PropTypes.object,
};

export default withResponseHandling(AtivoTemplate);
