import React, { memo } from "react";
import { Card, CardContent } from "investira.react.components";
import { validators } from "investira.sdk";
import { Info } from "../";

const CardInfo = memo((props) => {
  if (validators.isEmpty(props.value)) {
    return null;
  }

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Info {...props} type="card" />
      </CardContent>
    </Card>
  );
});

CardInfo.displayName = "CardInfo";

export default CardInfo;
