export const GENERIC = {
  EMPTY_RESPONSE: "Sem resultado para esta pesquisa",
  LAST_ACCESS: "Último acesso em ",
  LOADING: "Loading...",
  PRELOAD: "Preparando mais alguns detalhes",
  SUCCESS: "com sucesso.",
  TRY_AGAIN: "Tentar Novamente",
  GO_TO: "Ir para",
  UPDATE_SUCCESS: `foi atualizada com sucesso`,
  KNOW_MORE: "Saiba mais",
  NOT_NOW: "Agora não",
  OOOPS: "Ooops!",
};

export const CADASTRO = {
  SUCCESS: "Ótimo! Cadastro efetuado!",
  CONFIRM:
    "É necessário que você efetue a confirmação do seu cadasstro através do e-mail que enviamos para:",
};

export const LOGIN = {
  TITLE: {
    PART1: "Ajudando você",
    PART2: "a alcançar suas metas",
  },
  PLACEHOLDER: "Realize seu login ou cadastro:",
};

export const NOTIFICACOES = {
  EMPTY_NOTIFICATIONS: "Sem notificações",
};

export const REDIRECT_APP = {
  DESCRIPTION: "Não deixe de usar o app do",
  BUTTON: "Usar o Aplicativo",
};

export const REMEMBER_PASSWORD = {
  NEW: `Nova Senha`,
  CREATE: "Você criará uma nova senha do usuário",
  ALTERACAO: "Alteração de senha",
  INSTRUCOES:
    "As instruções para você alterar sua senha, serão enviadas para o e-mail",
  SUCCESS: "Senha alterada com sucesso",
  CONFIRM: `Confirme a Senha`,
  FORGOT_PASSWORD: "Esqueci minha senha",
  LOGIN: "Login",
  SENHA: "Senha",
  ENTRAR: "Entrar",
  ENVIAR: "Enviar",
};

export const WELCOME = {
  WELCOME: "boas vindas ao Investira!",
  BUTTONS: {
    LOGIN: "Fazer login",
    SIGNUP: "Realizar cadastro",
    RETRY: "Tentar Novamente",
  },
  CONFIRM: {
    EMAIL_CONFIRM: "Seu cadastro foi confirmado com sucesso.",
    EMAIL_CONFIRM_FAIL:
      "Não foi possível efetuar a confirmação do seu cadastro. A confirmação deve ser efetuada em um prazo de 24h.",
  },
  NEW_SIGNUP: "Por favor realize um novo cadastro",
  ERROR:
    "Ocorreu um erro e não foi possível efetuar a confirmação do seu cadastro.",
  NO_CONNEXION: "Sem conexão com internet!",
};

export default {
  GENERIC,
  CADASTRO,
  LOGIN,
  NOTIFICACOES,
  REDIRECT_APP,
  REMEMBER_PASSWORD,
  WELCOME,
};
