import React, { memo } from "react";
import { displays } from "investira.react.lib";
import { validators } from "investira.sdk";
import {
  Stack,
  Typography,
  CircularProgress,
} from "investira.react.components";

import { EmpresaTemplate } from "../../../../components/templates";
import { Search } from "../../../../components/organisms";

import { useEmpresasContext } from "../../../../contexts/EmpresasContext";
import EmpresaHeader from "../EmpresaHeader";

import InfosSection from "./InfosSection";
import B3Section from "./B3Section";
import CvmSection from "./CvmSection";
import ContabilSection from "./ContabilSection";
import AtivosSection from "./AtivosSection";
import PrevidenciaSection from "./PrevidenciaSection";
import EmpresasSection from "./EmpresasSection";
import EmpresaCharts from "./EmpresaCharts/EmpresaCharts";

const EmpresaOverview = memo(() => {
  const { state } = useEmpresasContext();
  const { pessoa, isPageLoading } = state;

  if (isPageLoading) {
    return (
      <Stack sx={{ height: "100%" }}>
        <Stack justifyContent="center" alignItems="center" flexGrow={1}>
          <CircularProgress />
        </Stack>
      </Stack>
    );
  }

  if (!validators.isEmpty(pessoa)) {
    return (
      <EmpresaTemplate
        title={`${pessoa.pessoa_fantasia || pessoa.pessoa} - ${displays.cnpj(
          pessoa.cnpj
        )}`}
        header={<EmpresaHeader preciseOption={false} />}
        searchComponent={
          <Search pageIsLoading={isPageLoading} clearOnClose={true} />
        }
        maxWidth={"xl"}
      >
        <Stack direction="row" sx={{ gap: 2, marginTop: 4 }} flexWrap="wrap">
          <InfosSection pessoa={pessoa} />
          <PrevidenciaSection pessoa={pessoa} />
          <B3Section pessoa={pessoa} />
          <CvmSection pessoa={pessoa} />
        </Stack>

        <ContabilSection key={`contabil-${pessoa.pessoa_id}`} />
        <AtivosSection pessoa={pessoa} />
        <EmpresasSection pessoa={pessoa} />
        <EmpresaCharts />
      </EmpresaTemplate>
    );
  } else {
    return (
      <Stack sx={{ height: "100%" }}>
        <Stack justifyContent="center" alignItems="center" flexGrow={1}>
          <Typography variant="body2" color="textSecondary">
            Empresa não encontrada
          </Typography>
        </Stack>
      </Stack>
    );
  }
});

export default EmpresaOverview;
