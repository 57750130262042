import { INDICADORES_LIST, INDICADORES_COTACAO } from "../const/servicesURI";
import { httpRequests } from "investira.sdk";
import requestService from "./requestService";
import utils from "../utils";

export const list = (pProps, pResolve, pReject, pFinally) => {
  const xProps = {
    ...pProps,
  };

  return requestService(xProps, INDICADORES_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Indicadores List");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const cotacao = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: pParams,
  };

  return requestService(xProps, INDICADORES_COTACAO, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Indicadores Cotação");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const indicadores = { list, cotacao };

export default indicadores;
