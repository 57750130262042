import React, { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import {
  Typography,
  CenterInView,
  LoadingButton,
  Stack,
  Box,
} from "investira.react.components";
import { MESSAGES, GENERIC } from "../../const";
import services from "../../services";

import withMessage from "../../hoc/withMessage";
import withResponseHandling from "../../hoc/withResponseHandling";

function EsqueciSenha(props) {
  const navigate = useNavigate();
  const match = useMatch("/esqueci-senha/:username");

  const [isSubmitting, setIsSubmitting] = useState(false);

  /* TODO: Verificar conexão */
  const doResetPassword = (pUsername) => {
    setIsSubmitting(true);

    services.auth.passwordReset(
      {
        data: {
          username: pUsername,
        },
      },
      (_rRes) => {
        props.onMessageSuccess(MESSAGES.LOGIN.PASSWORD_REQUEST_RESET);
        navigate(`/login/${pUsername}`);
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  useEffect(() => {
    if (match.pattern.path !== "/esqueci-senha/:username") {
      navigate("/");
    }
  }, [match.pattern.path, navigate]);

  return (
    <>
      <Stack flexGrow={1} sx={{ padding: "24px" }}>
        <CenterInView>
          <Stack spacing={4} alignItems="center">
            <Stack spacing={2} alignItems="center">
              <Stack sx={{ width: "180px" }}>
                <svg
                  id="padlock"
                  x="0px"
                  y="0px"
                  width="100%"
                  height="100%"
                  viewBox="0 0 16 16"
                >
                  <path
                    style={{ fill: "#00dfa8" }}
                    d="M12.5,8V4.391C12.5,1.97,10.481,0,8,0S3.5,1.97,3.5,4.391V8h0c-1.105,0-2,0.895-2,2v6h11 c1.104,0,2-0.895,2-2V8H12.5z M4.5,4.391C4.5,2.521,6.07,1,8,1s3.5,1.521,3.5,3.391V8h-7V4.391z M13.5,14c0,0.552-0.449,1-1,1h-10 v-5c0-0.552,0.449-1,1-1h10V14z"
                  />
                </svg>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  gutterBottom
                  variant={"h4"}
                  color={"textPrimary"}
                  align={"center"}
                >
                  {GENERIC.REMEMBER_PASSWORD.ALTERACAO}
                </Typography>

                <Typography
                  gutterBottom
                  variant={"body1"}
                  align={"center"}
                  color={"textPrimary"}
                >
                  As instruções para você alterar sua senha, serão enviadas
                  <br /> para o e-mail <b>{match.params.username}</b>
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <LoadingButton
                color={"primary"}
                loading={isSubmitting}
                variant={"contained"}
                onClick={() => doResetPassword(match.params.username)}
                disabled={isSubmitting}
                sx={{ px: 4 }}
                size="large"
              >
                Sim, quero alterar minha senha
              </LoadingButton>
            </Box>
          </Stack>
        </CenterInView>
      </Stack>
    </>
  );
}

EsqueciSenha.displayName = "EsqueciSenha";

export default withResponseHandling(withMessage(EsqueciSenha));
