import React, { memo, useState } from "react";
import {
  Stack,
  Box,
  useMediaQuery,
  Icon,
  IconButton,
  Button,
  Toolbar,
  Slide,
  ListItem,
  ListItemText,
  Dialog,
  List,
  Collapse,
} from "investira.react.components";
import { Link } from "react-router-dom";
import { NavButton } from "../../../components/molecules";
import { PATHS } from "../../../enums";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MainMenu = memo((props) => {
  const OPTIONS = [PATHS.home, PATHS.ferramentas.nested[1]];
  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);
  const [itemOpen, setItemOpen] = React.useState({});

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickItem = (pKey) => {
    setItemOpen((prevItemOpen) => ({
      ...prevItemOpen,
      [pKey]: !prevItemOpen[pKey],
    }));
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  if (!isUpMd) {
    return (
      <>
        <IconButton
          onClick={handleClick}
          color="primary"
          aria-haspopup="true"
          edge="end"
          size="large"
        >
          <Icon iconName="menu" size={21} />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleMenuClose}
          fullScreen
          fullWidth
          PaperProps={{ sx: { margin: 0 } }}
          //TransitionComponent={Transition}
        >
          <Toolbar sx={{ justifyContent: "right", px: 2 }}>
            <IconButton
              onClick={handleMenuClose}
              aria-label="close"
              color="primary"
              edge="end"
              size="large"
            >
              <Icon iconName="cancel" size={16} />
            </IconButton>
          </Toolbar>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ px: 2, py: 2 }}
          >
            <Button
              id="appbar-btn-login"
              variant="contained"
              size="small"
              sx={{ px: 2 }}
              component={Link}
              to="/login"
              fullWidth
            >
              Acessar Conta
            </Button>
          </Stack>
          <List>
            {Object.values(OPTIONS).map((item) => {
              return (
                <React.Fragment key={`menu-${item.key}`}>
                  <ListItem
                    button
                    component={item.nested ? "div" : Link}
                    to={item.path}
                    onClick={() =>
                      item.nested
                        ? handleClickItem(item.key)
                        : handleMenuClose()
                    }
                  >
                    <ListItemText primary={item.name} />
                    {item.nested ? (
                      itemOpen[item.key] ? (
                        <Icon iconName={"arrow-up"} size={12} color="primary" />
                      ) : (
                        <Icon
                          iconName={"arrow-down"}
                          size={12}
                          color="primary"
                        />
                      )
                    ) : null}
                  </ListItem>
                  {item.nested && (
                    <Collapse
                      in={itemOpen[item.key]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.nested.map((nestedItem) => {
                          return (
                            <ListItem
                              button
                              component={nestedItem.nested ? "div" : Link}
                              to={nestedItem.path}
                              key={`menu-sub-${nestedItem.key}`}
                              sx={{ pl: 4 }}
                              onClick={handleMenuClose}
                            >
                              <ListItemText primary={nestedItem.name} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Stack
        id="main-nav"
        direction="row"
        spacing={1}
        alignItems="center"
        component="nav"
      >
        {OPTIONS.map((xOption, xIndex) => {
          const xKey = crypto.randomUUID();
          console.log(`${xIndex}nav-${xOption.key}`);
          return (
            <Box>
              <NavButton
                key={xKey}
                id={xOption.key}
                label={xOption.name}
                nested={xOption.nested}
                path={xOption.path}
              />
            </Box>
          );
        })}
      </Stack>
    </>
  );
});

export default MainMenu;
