import { MERCADOS_CHANGED, MERCADOS_LOGOUT } from "../../const/actionsType";

export function acMercadosChanged(pData) {
  return {
    type: MERCADOS_CHANGED,
    payload: pData,
  };
}

export function acMercadosLogout() {
  return {
    type: MERCADOS_LOGOUT,
  };
}
