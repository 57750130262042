import React from "react";
import { Stack } from "investira.react.components";
import { ErrorBoundary } from "../../../components/molecules";
import AppBar from "./AppBar";

const Main = (props) => {
  return (
    <Stack
      id="main"
      component="main"
      sx={{ flexGrow: 1, height: "calc(100% - 64px)", minWidth: "1px" }}
    >
      <Stack
        id="content"
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </Stack>
    </Stack>
  );
};

export default Main;
