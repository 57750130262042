import React from "react";
import PropTypes from "prop-types";
import { dates, formats, validators } from "investira.sdk";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
  Toolbar,
} from "investira.react.components";

const Eventos = (props) => {
  const { eventos, data } = props;

  function removePastDate(pEventos, pData) {
    const xCutoffDate = dates.toDate(pData);

    const xData = Object.values(pEventos).filter((xEvento) => {
      return (
        dates.daysBetween(xCutoffDate, dates.toDate(xEvento.data_util)) >= 0
      );
    });

    return xData;
  }

  function separateEvents(pEventos, pData) {
    const xData = [];
    const xEventos = removePastDate(pEventos, pData);

    Object.values(xEventos).forEach((xEvento) => {
      const { amortizacao = {}, juros = {}, cm = {}, ...restAttrs } = xEvento;

      if (!validators.isEmpty(amortizacao)) {
        const xAmortizacao = {
          ...restAttrs,
          tipo: "Armotização",
          ...amortizacao,
        };
        xData.push(xAmortizacao);
      }

      if (!validators.isEmpty(juros)) {
        const xJuros = {
          ...restAttrs,
          tipo: "Juros",
          ...juros,
        };
        xData.push(xJuros);
      }

      if (!validators.isEmpty(cm)) {
        const xCorrecao = {
          ...restAttrs,
          tipo: "Correção Monetária",
          ...cm,
        };
        xData.push(xCorrecao);
      }
    });

    return xData;
  }

  const xEventos = separateEvents(eventos, data);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, pb: 2 }}>
        <Toolbar sx={{ pl: { sm: 2 } }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Eventos
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Data</TableCell>

              <TableCell align="right">Dias Úteis</TableCell>
              <TableCell>Data Base</TableCell>
              <TableCell align="right">Taxa</TableCell>
              <TableCell>Evento</TableCell>
              <TableCell align="right">Valor Presente</TableCell>
            </TableHead>
            {xEventos.map((xEvento) => {
              return (
                <TableRow>
                  <TableCell>
                    {formats.formatDateCustom(xEvento.data_util, "DD/MMM/YYYY")}
                  </TableCell>

                  <TableCell align="right">{xEvento.cotacao_dias}</TableCell>
                  <TableCell>
                    {formats.formatDateCustom(xEvento.data_base, "DD/MMM/YYYY")}
                  </TableCell>
                  <TableCell align="right">{`${formats.formatNumber(
                    xEvento.fluxo,
                    6,
                    true
                  )}`}</TableCell>
                  <TableCell>{xEvento.tipo}</TableCell>
                  <TableCell align="right">{`${formats.formatNumber(
                    xEvento.cotacao_fluxo_descontado,
                    6,
                    true
                  )}`}</TableCell>
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

Eventos.propTypes = {};

Eventos.payload = "Eventos";

export default Eventos;
