import {
  CALCULADORAS_MOEDA_CHANGED,
  CALCULADORAS_CORRECAO_CHANGED,
  CALCULADORAS_MERCADO_CHANGED,
  CALCULADORAS_JUROS_CHANGED,
  CALCULADORAS_CURVA_CHANGED,
  CALCULADORAS_MOEDA_DELETED,
  CALCULADORAS_CORRECAO_DELETED,
  CALCULADORAS_MERCADO_DELETED,
  CALCULADORAS_JUROS_DELETED,
  CALCULADORAS_CURVA_DELETED,
} from "../../const/actionsType";

export function acCalculadoraMoedaChanged(pObj) {
  return {
    type: CALCULADORAS_MOEDA_CHANGED,
    payload: pObj,
  };
}

export function acCalculadoraCorrecaoChanged(pObj) {
  return {
    type: CALCULADORAS_CORRECAO_CHANGED,
    payload: pObj,
  };
}

export function acCalculadoraMercadoChanged(pObj) {
  return {
    type: CALCULADORAS_MERCADO_CHANGED,
    payload: pObj,
  };
}

export function acCalculadoraJurosChanged(pObj) {
  return {
    type: CALCULADORAS_JUROS_CHANGED,
    payload: pObj,
  };
}

export function acCalculadoraMoedaDeleted(pArray) {
  return {
    type: CALCULADORAS_MOEDA_DELETED,
    payload: pArray,
  };
}

export function acCalculadoraCorrecaoDeleted(pArray) {
  return {
    type: CALCULADORAS_CORRECAO_DELETED,
    payload: pArray,
  };
}

export function acCalculadoraMercadoDeleted(pArray) {
  return {
    type: CALCULADORAS_MERCADO_DELETED,
    payload: pArray,
  };
}

export function acCalculadoraJurosDeleted(pArray) {
  return {
    type: CALCULADORAS_JUROS_DELETED,
    payload: pArray,
  };
}

export function acCalculadoraCurvaChanged(pObj) {
  return {
    type: CALCULADORAS_CURVA_CHANGED,
    payload: pObj,
  };
}

export function acCalculadoraCurvaDeleted(pArray) {
  return {
    type: CALCULADORAS_CURVA_DELETED,
    payload: pArray,
  };
}
