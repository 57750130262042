import React, { memo } from "react";
import { validators } from "investira.sdk";
import { Info } from "../";

const InlineInfo = memo((props) => {
  if (validators.isEmpty(props.value)) {
    return null;
  }
  return <Info {...props} type="row" valueProps={{ align: "right" }} />;
});

InlineInfo.displayName = "InlineInfo";

export default InlineInfo;
