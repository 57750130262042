import React, { memo, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { TextField, Autocomplete, ListItem } from "investira.react.components";
import { validators } from "investira.sdk";
import withMessage from "../../../hoc/withMessage";
import utils from "../../../utils";

const InputAsync = memo((props) => {
  const {
    service,
    serviceParams,
    id,
    sx,
    label = "label",
    renderOptionKey,
    initOptions,
    initValue,
    initInputValue,
    placeholder,
    onChange,
    // name,
    // getOptionLabel,
    // renderOption,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const request = useMemo(() => {
    return utils.helpers.debounce(
      (pParams, pRes, pErr, pFin) => service(pParams, pRes, pErr, pFin),
      400
    );
  }, []);

  function handleChange(pEvent, pNewValue) {
    setOptions(pNewValue ? [pNewValue, ...options] : options);
    setValue(pNewValue);
    onChange && onChange(pEvent, pNewValue);
  }

  function verifySpecialChar(pStr) {
    const regex = /[./-]/;
    return regex.test(pStr);
  }

  function cleanSpecialChar(pStr) {
    const regex = /[./-]/g;
    if (verifySpecialChar(pStr)) {
      return pStr.replace(regex, "");
    }

    return pStr;
  }

  function formatSearch(pValue) {
    let xValue = cleanSpecialChar(pValue);

    let xStrings = xValue.split(" ");
    xStrings = xStrings.map((xString) => {
      return `+${xString}*`.trim();
    });

    const xSearchFormated = xStrings.join("");

    return xSearchFormated;
  }

  useEffect(() => {
    if (!validators.isEmpty(initOptions)) {
      setOptions(initOptions);
      setValue(initValue);
      setInputValue(initInputValue);
    }
  }, [initOptions, initValue, initInputValue]);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (open) {
      setLoading(true);
      const xPesquisa = formatSearch(inputValue);
      request(
        { size: 25, pesquisa: xPesquisa, ...serviceParams },
        (rRes) => {
          if (active) {
            let xNewOptions = [];

            if (value) {
              xNewOptions = [value];
            }

            if (!validators.isEmpty(rRes.data)) {
              const xData = Object.values(rRes.data);
              xNewOptions = [...xNewOptions, ...xData];
            }

            setOptions(xNewOptions);
          }
        },
        (rErr) => {
          props.onMessageError(props.messageError);
        },
        () => {
          setLoading(false);
        }
      );
    }

    return () => {
      active = false;
    };
  }, [request, inputValue, value, open]);

  return (
    <Autocomplete
      id={`async-${id}`}
      sx={{ ...sx }}
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option[renderOptionKey];
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      loading={loading}
      loadingText="Buscando..."
      filterSelectedOptions
      value={value}
      noOptionsText="Nenhum dado encontrado"
      onChange={handleChange}
      onInputChange={(_pEvent, pNewInputValue) => {
        setInputValue(pNewInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
          // InputProps={{
          //   endAdornment: (
          //     <>
          //       {loading ? (
          //         <CircularProgress color="inherit" size={20} />
          //       ) : null}
          //       {params.InputProps.endAdornment}
          //     </>
          //   ),
          // }}
        />
      )}
      renderOption={(pProps, pOption, pState, pOwnerState) => {
        if (!validators.isNull(props.renderOption)) {
          return props.renderOption(pProps, pOption, pState, pOwnerState);
        }

        return (
          <ListItem component="li" {...pProps}>
            {pOption[renderOptionKey]}
          </ListItem>
        );
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    />
  );
});

InputAsync.propTypes = {
  service: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  renderOptionKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  sx: PropTypes.object,
};

InputAsync.displayName = "InputAsync";

export default withMessage(InputAsync);
