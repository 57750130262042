import React, { memo, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  MenuItem,
  IconButton,
  Icon,
} from "investira.react.components";
import { dates, formats } from "investira.sdk";
import { useEmpresasContext } from "../../../../../contexts/EmpresasContext";
import { HighchartsReact } from "investira.react.charts";
import { validators } from "investira.sdk";
import services from "../../../../../services";
import highchartsTheme from "../../../../../styles/highchartsTheme";

const EmpresaCharts = memo((props) => {
  const { state, actions } = useEmpresasContext();

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const variants = useMemo(() => {
    const TODAY = new Date();
    const DATA_FIM = dates.toSqlDate(dates.toSqlDate(TODAY));
    const DATA_INICIO = dates.addYears(dates.toSqlDate(TODAY), -10);

    const xResult = {
      pl: {
        key: "pl",
        request: services.pessoas.pl,
        params: {
          data_inicio: DATA_INICIO,
          data_fim: DATA_FIM,
          pessoa_id: state.pessoa.pessoa_id,
          sort: "data ASC",
          consolidacao_tipo: state.config.consolidacao_tipo,
        },
        formatData: (pData) => {
          return pData.map((xPoint) => {
            const xDate = dates
              .startOf("month", dates.toDate(xPoint.data))
              .getTime();
            return [xDate, xPoint.pl];
          });
        },
        options: {
          title: {
            text:
              state.pessoa?.setor_id === "65.41.3.00"
                ? "Patrimônio Social"
                : "Patrimônio líquido",
          },
          tooltip: {
            formatter: function (tooltip) {
              const xDate = formats.formatDateCustom(this.point.x, "MMM/YYYY");
              const xValue = formats.friendlyNumber(this.point.y, 2, false);
              const xFullValue = formats.formatNumber(
                this.point.y,
                2,
                true,
                false
              );

              return `
              <span style="font-size: 1em"><b>${xDate}</b></span><br/>
              <span style="color:${this.color}">\u25CF</span>
              ${this.series.name}: <b>${xValue}</b> (${xFullValue})<br/>
              `;
            },
          },
          yAxis: {
            type: "linear",
            title: {
              text:
                state.pessoa?.setor_id === "65.41.3.00"
                  ? `Patrimônio social (R$)`
                  : `Patrimônio líquido (R$)`,
            },
          },
          xAxis: {
            type: "datetime",
            crosshair: true,
          },
          series: [
            {
              name: state.pessoa?.setor_id === "65.41.3.00" ? "PS" : "PL",
              type: "line",
              data: data,
            },
          ],
        },
      },
      saldo: {
        key: "saldo",
        request: services.pessoas.saldos,
        params: {
          data_inicio: DATA_INICIO,
          data_fim: DATA_FIM,
          pessoa_id: state.pessoa.pessoa_id,
          sort: "data ASC",
          consolidacao_tipo: state.config.consolidacao_tipo,
          conta: "3.08.00.00.00.00.00",
        },
        formatData: (pData) => {
          return pData.map((xPoint) => {
            const xDate = dates
              .startOf("month", dates.toDate(xPoint.data))
              .getTime();
            return [xDate, xPoint.saldo];
          });
        },
        options: {
          title: {
            text: "Superávit/Déficit Técnico",
          },
          tooltip: {
            formatter: function (tooltip) {
              const xDate = formats.formatDateCustom(this.point.x, "MMM/YYYY");
              const xValue = formats.friendlyNumber(this.point.y, 2, false);
              const xFullValue = formats.formatNumber(
                this.point.y,
                2,
                true,
                false
              );
              const xSerieName = this.point.y > 0 ? "Superávit" : "Déficit";

              return `
              <span style="font-size: 1em"><b>${xDate}</b></span><br/>
              <span style="color:${this.color}">\u25CF</span>
              ${xSerieName}: <b>${xValue}</b> (${xFullValue})<br/>
              `;
            },
          },
          yAxis: {
            type: "linear",
            title: {
              text: "Superávit/Déficit Técnico",
            },
          },
          xAxis: {
            type: "datetime",
            crosshair: true,
          },
          series: [
            {
              name: "Superávit/Déficit",
              type: "line",
              data: data,
              zones: [
                {
                  value: 0, // Up to zero
                  color: "#ed442c", // Color for values above zero
                },
                {
                  color: "#00dfa8", // Color for values below zero
                },
              ],
            },
          ],
        },
      },
      cs: {
        key: "cs",
        request: services.pessoas.cs,
        params: {
          data_inicio: DATA_INICIO,
          data_fim: DATA_FIM,
          pessoa_id: state.pessoa.pessoa_id,
          sort: "data ASC",
        },
        formatData: (pData) => {
          const xData = [];
          pData.forEach((xPoint) => {
            const xDate = dates
              .startOf("month", dates.toDate(xPoint.data))
              .getTime();
            const xPapeis = Object.keys(xPoint.acao);

            if (validators.isEmpty(xData[0])) {
              xData[0] = [];
            }

            xData[0].push([xDate, xPoint.cs]);

            if (xPapeis.includes("on")) {
              if (validators.isEmpty(xData[1])) {
                xData[1] = [];
              }

              xData[1].push([xDate, xPoint.acao.on]);
            }

            if (xPapeis.includes("pn")) {
              if (validators.isEmpty(xData[2])) {
                xData[2] = [];
              }

              xData[2].push([xDate, xPoint.acao.pn]);
            }
          });

          return xData;
        },
        options: {
          title: {
            text: "Capital social",
          },
          tooltip: {
            formatter: function (tooltip) {
              const xDate = formats.formatDateCustom(this.point.x, "MMM/YYYY");
              let xHTML = `<span style="font-size: 1em"><b>${xDate}</b></span><br/>`;
              let xTotalPapeis = 0;

              this.points.forEach((xPoint) => {
                if (["ON", "PN"].includes(xPoint.series.name)) {
                  xTotalPapeis = xTotalPapeis + xPoint.point.y;
                }
                const xValue = formats.friendlyNumber(xPoint.point.y, 2, false);
                const xFullValue = formats.formatNumber(
                  xPoint.point.y,
                  2,
                  true,
                  false
                );

                xHTML =
                  xHTML +
                  `<span style="color:${xPoint.color}">\u25CF</span>${xPoint.series.name}: <b>${xValue}</b> (${xFullValue})<br/>`;
              });

              xHTML =
                xHTML +
                `Total de Papéis: <b>${formats.friendlyNumber(
                  xTotalPapeis,
                  2,
                  false
                )}</b> (${xTotalPapeis})<br/>`;

              return xHTML;
            },
            shared: true,
          },
          yAxis: [
            {
              type: "linear",
              title: {
                text: `Capital Social (R$)`,
              },
              showEmpty: false,
            },
            {
              type: "linear",
              title: {
                text: `ON`,
              },
              visible: false,
              showEmpty: false,
            },
            {
              type: "linear",
              title: {
                text: `PN`,
              },
              visible: false,
              showEmpty: false,
            },
          ],
          xAxis: {
            type: "datetime",
            crosshair: true,
          },
          series: [
            {
              name: "Capital Social",
              type: "line",
              data: data[0] || [],
              connectNulls: true,
              zIndex: 10,
              showInLegend: Boolean(data[0]),
            },

            {
              name: "ON",
              type: "column",
              data: data[1] || [],
              connectNulls: true,
              yAxis: 1,
              showInLegend: Boolean(data[1]),
              pointWidth: 10,
              opacity: 0.8,
            },

            {
              name: "PN",
              type: "column",
              data: data[2] || [],
              connectNulls: true,
              yAxis: 2,
              showInLegend: Boolean(data[2]),
              pointWidth: 10,
              opacity: 0.8,
            },
          ],
        },
      },
    };

    return xResult;
  }, [data, state.pessoa.pessoa_id, state.config.consolidacao_tipo]);

  function handleClose() {
    actions.handleChartVariantChange(null);
    setData([]);
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!validators.isNull(state.variantChart)) {
      setLoading(true);
      const xVariant = variants[state.variantChart];

      xVariant.request({ ...xVariant.params, data: null }, (rRes) => {
        const xData = xVariant.formatData(rRes.data);
        setData(xData);
        setLoading(false);
      });
    }
  }, [state.variantChart]);

  // Render

  if (validators.isNull(state.variantChart)) {
    return null;
  }

  const options = {
    ...variants[state.variantChart].options,
  };

  function renderMenu(pVariants, pSetorId) {
    const xVariants = { ...pVariants };

    if (pSetorId === "65.41.3.00") {
      delete xVariants.cs;
    } else {
      delete xVariants.saldo;
    }

    return (
      <Menu
        anchorEl={anchorEl}
        id={`chart-menu`}
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          component: "nav",
          elevation: 1,
          sx: {
            overflow: "visible",
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        keepMounted
      >
        {Object.values(xVariants).map((xItem) => {
          return (
            <MenuItem
              key={xItem.key}
              onClick={() => actions.handleChartVariantChange(xItem.key)}
            >
              {xItem.options.title.text}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  return (
    <Dialog
      open={Boolean(state.variantChart)}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack>
            <IconButton onClick={handleMenuClick} color="primary">
              <Icon iconName="menu" size={24} />
            </IconButton>
          </Stack>
          <Stack>Histórico</Stack>
        </Stack>
        {renderMenu(variants, state.pessoa.setor_id)}
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        color="primary"
        sx={{
          position: "absolute",
          right: 8,
          top: 16,
        }}
      >
        <Icon iconName="cancel" size={16} />
      </IconButton>
      <DialogContent sx={{ paddingTop: "8px !important" }}>
        <HighchartsReact
          key={state.variantChart}
          options={options}
          theme={highchartsTheme}
        />
      </DialogContent>
    </Dialog>
  );
});

EmpresaCharts.propTypes = {};

export default EmpresaCharts;
