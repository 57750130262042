import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Tooltip } from "investira.react.components";

const Warp = styled("span")(() => ({
  display: "inline-block",
  position: "relative",
  pointerEvents: "visible",
  verticalAlign: "top",
  border: 0,
  margin: 0,
  padging: 0,
}));

function MutedCopy(props) {
  const [tooltip, setTooltip] = useState("Copiar");

  let body = document.getElementsByTagName("body")[0];

  let timeout = useRef(null);

  const copyToClipboard = (pValue, pEvent) => {
    pEvent.preventDefault();
    pEvent.stopPropagation();
    const tempInput = document.createElement("input");
    body.appendChild(tempInput);
    tempInput.setAttribute("value", pValue.toString());
    tempInput.select();
    document.execCommand("copy");
    body.removeChild(tempInput);

    setTooltip("COPIADO!");
  };

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setTooltip("Copiar");
    }, 3000);
  }, [tooltip]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Tooltip title={tooltip} placement="top">
      <Warp onClick={(e) => copyToClipboard(props.value, e)}>
        {props.children}
      </Warp>
    </Tooltip>
  );
}

MutedCopy.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MutedCopy;
