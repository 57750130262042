export function capitalize(string) {
  if (process.env.NODE_ENV !== "production" && typeof string !== "string") {
    throw new Error("Investira: capitalize(string) espera uma string.");
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFirstLetters(pFrase = "", pUppercase = false) {
  // Dividir a frase em palavras separadas
  const xPalavras = pFrase.split(" ");

  // Array para armazenar as primeiras letras
  const xPrimeirasLetras = [];

  // Iterar sobre as palavras
  for (let i = 0; i < xPalavras.length; i++) {
    const xPalavra = xPalavras[i];

    // Obter a primeira letra da palavra e adicioná-la ao array
    const xPrimeiraLetra = xPalavra.charAt(0);
    xPrimeirasLetras.push(xPrimeiraLetra);
  }

  const xResult = xPrimeirasLetras.join("");

  if (pUppercase) {
    return xResult.toUpperCase();
  }

  // Retornar as primeiras letras como uma string
  return xResult;
}

export function shortname(pName, pMaxLength = 10) {
  return pName.length > pMaxLength
    ? `${pName.substring(0, pMaxLength)}...`
    : pName;
}

export function removeUrlProtocol(
  pStr = "",
  pProtocol = ["http://", "https://"]
) {
  if (typeof pStr !== "string") {
    throw new Error("removeUrlProtocol espera uma string.");
  }
  // Remover protocolos da string
  pProtocol.forEach((protocol) => {
    pStr = pStr.replace(protocol, "");
  });

  return pStr;
}

export default {
  capitalize,
  getFirstLetters,
  shortname,
  removeUrlProtocol,
};
