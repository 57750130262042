import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
} from "investira.react.components";
import { PESSOA_TIPO } from "@investirapri/financa.js/lib/enums";
import { SETORES } from "../../../enums";

const BuscaEmpresaFilter = memo((props) => {
  const { open, onClose, onSubmit } = props;

  const empresasSearch = useSelector((store) => store.empresas.search);

  const { top_setor_id = 0, pessoa_tipo_id } = empresasSearch;

  const [selected, setSelected] = useState(
    conciliationSelected(pessoa_tipo_id)
  );

  function orderedValues(pValues, pSortKey) {
    return Object.values(pValues).sort((a, b) => {
      if (a[pSortKey] < b[pSortKey]) {
        return -1;
      }
      if (a[pSortKey] > b[pSortKey]) {
        return 1;
      }

      return 0;
    });
  }

  function cleanUpObject(pObj) {
    return Object.fromEntries(
      Object.entries(pObj).filter(([_, pValue]) => pValue !== "0")
    );
  }

  function conciliationSelected(pPessoaTipoId) {
    let xPessoaTipoId = {};

    Object.entries(PESSOA_TIPO).forEach(([xKey, xValue]) => {
      if (!["FND", "FIS", "OUT"].includes(xKey)) {
        xPessoaTipoId[xKey] = (pPessoaTipoId & xValue.id) === xValue.id;
      }
    });

    return xPessoaTipoId;
  }

  const handleChange = (event) => {
    setSelected({
      ...selected,
      [event.target.name]: event.target.checked,
    });
  };

  function handleSubmit(pEvent) {
    pEvent.preventDefault();

    const xFormData = new FormData(pEvent.currentTarget);
    const xFormJson = Object.fromEntries(xFormData.entries());
    const xCleanedJson = cleanUpObject(xFormJson);
    // storeDispatch(acEmpresasSearchChanged(xCleanedJson));

    onSubmit(xCleanedJson);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle id="alert-dialog-title">Filtrar por:</DialogTitle>
      <DialogContent sx={{ paddingTop: "8px !important" }}>
        <Stack spacing={2} sx={{ position: "relative" }}>
          <TextField
            id="top_setor_id"
            name="top_setor_id"
            variant="outlined"
            select
            label="Setor"
            defaultValue={top_setor_id || 0}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value={0}>Todos os setores</MenuItem>
            {orderedValues(SETORES, "setor").map((option) => (
              <MenuItem key={option.setor_id} value={option.setor_id}>
                {option.setor}
              </MenuItem>
            ))}
          </TextField>

          <FormControl>
            <FormLabel component="legend">Tipo</FormLabel>
            <FormGroup>
              {Object.entries(selected).map(([xKey, xValue]) => (
                <FormControlLabel
                  key={xKey}
                  control={
                    <Checkbox
                      checked={xValue}
                      onChange={handleChange}
                      name={xKey}
                    />
                  }
                  label={PESSOA_TIPO[xKey].descricao}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button type="submit" autoFocus>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default BuscaEmpresaFilter;
