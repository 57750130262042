import { SEARCH_CHANGED, SEARCH_LOGOUT } from "../../const/actionsType";

export function acSearchChanged(pData) {
  return {
    type: SEARCH_CHANGED,
    payload: pData,
  };
}

export function acSearchLogout() {
  return {
    type: SEARCH_LOGOUT,
  };
}
