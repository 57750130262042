import auth from "./auth";
import ativos from "./ativos";
import ativosbase from "./ativosbase";
import info from "./info";
import notificacoes from "./notificacoes";
import user from "./user";
import status from "./status";
import relatorios from "./relatorios";
import posicoes from "./posicoes";
import entidades from "./entidades";
import entidadesUsuario from "./entidadesUsuario";
import convites from "./convites";
import carteiras from "./carteiras";
import indicadores from "./indicadores";
import consultas from "./consultas";
import outputs from "./outputs";
import pessoas from "./pessoas";
import setores from "./setores";

const services = {
  auth,
  ativos,
  ativosbase,
  info,
  notificacoes,
  status,
  user,
  relatorios,
  posicoes,
  entidades,
  entidadesUsuario,
  convites,
  carteiras,
  indicadores,
  consultas,
  outputs,
  pessoas,
  setores,
};

export default services;
