import React, { memo, useState, useEffect, useRef } from "react";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { dates, formats, validators } from "investira.sdk";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  InputAdornment,
  MenuItem,
  TextField,
  Icon,
  LoadingButton,
  Skeleton,
  FormControlLabel,
  Switch,
} from "investira.react.components";
import { VALIDATIONS } from "../../const";
import { DatePicker, DecimalTextField } from "../../components/molecules";
import { useRelatorioContext } from "../../contexts/RelatorioContext";
import services from "../../services";
import withResponseHandling from "../../hoc/withResponseHandling";

const VALIDATION_SCHEMA = Yup.object().shape({
  carteira_id: Yup.string().required(VALIDATIONS.REQUIRED),
  data_posicao: Yup.string().required(VALIDATIONS.REQUIRED),
  // data: Yup.date()
  //   .required(VALIDATIONS.REQUIRED)
  //   .typeError(`${VALIDATIONS.INVALID_DATE}`),
  juros_delta: Yup.number()
    .min(-10.0, "Minímo permitido é -10,0%")
    .max(10.0, "Máximo permitido é 10,0%")
    .required(VALIDATIONS.REQUIRED),
  aberta: Yup.bool().required(VALIDATIONS.REQUIRED),
});

const DurationParamsDialog = memo((props) => {
  const { state, actions } = useRelatorioContext();
  const [datas, setDatas] = useState([]);

  const xInitialValues = {
    carteira_id: !validators.isEmpty(props.carteiras)
      ? props.carteiras[0]?.carteira_id
      : "",
    data_posicao: "",
    data: dates.toDate(),
    juros_delta: 1,
    aberta: false,
  };

  const formik = useFormik({
    initialValues: xInitialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (pValues, pActions) => {
      actions.handleParamsUpdate(pValues);
      actions.handleCreate(pValues, () => {
        pActions.setSubmitting(false);
        actions.handleNewReportCancel();
      });
    },
  });

  // const handleDateChange = (pFieldName, pFieldValue, pSetValue) => {
  //   const xDate = dates.toDate(pFieldValue);
  //   pSetValue && pSetValue(pFieldName, xDate, true);
  // };

  const handleNumberChange = (pFieldName, pFieldValue, pSetValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  const handleSwitchChange = (pFieldName, pFieldValue, pSetValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  // useEffect(() => {
  //   if (state.isDialogOpen && !validators.isEmpty(props.carteiras[0])) {
  //     setDatas([]);

  //     services.posicoes.datas(
  //       {
  //         sort: "data DESC",
  //         carteira_id: props.carteiras[0].carteira_id,
  //       },
  //       (rRes) => {
  //         const xDates = rRes.data.map((xPosicao) => {
  //           return {
  //             value: xPosicao.data,
  //             label: formats.formatDateCustom(
  //               dates.toDate(xPosicao.data),
  //               "DD/MM/YYYY"
  //             ),
  //           };
  //         });
  //         setDatas(xDates);
  //       },
  //       (rErr) => {
  //         props.responseErrorHandling(rErr);
  //       }
  //     );
  //   }
  // }, [state.isDialogOpen, state.carteiras, props]);

  // useEffect(() => {
  //   if (
  //     validators.isEmpty(formik.values.data_posicao) &&
  //     !validators.isEmpty(datas)
  //   ) {
  //     formik.setFieldValue("data_posicao", datas[0].value);
  //   }
  // }, [datas, formik, formik.values.data_posicao]);

  useEffect(() => {
    if (state.isDialogOpen) {
      setDatas([]);

      services.posicoes.datas(
        {
          sort: "data DESC",
          carteira_id: formik.values.carteira_id,
        },
        (rRes) => {
          const xDates = rRes.data.map((xPosicao) => {
            return {
              value: xPosicao.data,
              label: formats.formatDateCustom(
                dates.toDate(xPosicao.data),
                "DD/MM/YYYY"
              ),
            };
          });
          setDatas(xDates);
          formik.setFieldValue("data_posicao", xDates[0].value);
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        }
      );
    }
  }, [state.isDialogOpen, formik.values.carteira_id]);

  return (
    <Dialog
      open={state.isDialogOpen}
      maxWidth="sm"
      //onClose={actions.handleNewReportCancel}
    >
      <DialogTitle>Novo Relatório</DialogTitle>

      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack sx={{ width: "500px", marginBottom: 2, py: 1 }} spacing={2}>
            <Stack flexGrow={1}>
              <TextField
                id="carteira_id"
                name="carteira_id"
                label="Carteira"
                variant="outlined"
                value={formik.values.carteira_id}
                onChange={formik.handleChange}
                select
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon iconName="wallet" size={21} />
                    </InputAdornment>
                  ),
                }}
              >
                {props.carteiras.map((xOption) => (
                  <MenuItem
                    key={xOption.carteira_id}
                    value={xOption.carteira_id}
                  >
                    {xOption.carteira}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            {formik.values.carteira_id && (
              <>
                <Stack direction="row" spacing={2}>
                  <Stack flexGrow={1} flexBasis={1}>
                    {validators.isEmpty(dates) ? (
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={32}
                      />
                    ) : (
                      <TextField
                        id="data-posicao"
                        name="data_posicao"
                        label="Posição de"
                        variant="outlined"
                        value={formik.values.data_posicao}
                        onChange={formik.handleChange}
                        select
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon iconName="calendar" size={21} />
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(formik.errors.data_posicao)}
                        helperText={formik.errors.data_posicao}
                        disabled={datas.length <= 1}
                      >
                        {datas.map((xOption, xIndex) => (
                          <MenuItem key={xOption.value} value={xOption.value}>
                            {xOption.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Stack>
                </Stack>

                <Stack>
                  <Stack>
                    <DecimalTextField
                      id="juros_delta"
                      name="juros_delta"
                      label="Juros Delta"
                      variant="outlined"
                      value={formik.values.juros_delta}
                      placeholder={"1,0"}
                      onChange={(_, pValue) =>
                        handleNumberChange("juros_delta", pValue)
                      }
                      decimal={1}
                      allowNegative={true}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      error={Boolean(formik.errors.juros_delta)}
                      helperText={formik.errors.juros_delta}
                      fullWidth
                    />
                  </Stack>
                </Stack>

                <Stack>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.aberta}
                        onChange={(e, value) => {
                          handleSwitchChange("aberta", value);
                        }}
                        name="aberta"
                        disabled={true}
                      />
                    }
                    label="Incluir posição indireta"
                  />
                </Stack>
              </>
            )}

            <Stack
              alignItems="center"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
            >
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={actions.handleNewReportCancel}
              >
                Cancelar
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  validators.isEmpty(formik.values.carteira_id)
                }
              >
                Processar
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
});

export default withResponseHandling(DurationParamsDialog);
