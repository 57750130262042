import {
  OUTPUTS_LOGS,
  OUTPUTS_DURATION,
  OUTPUTS_VAR,
  OUTPUTS_DOWNLOAD,
  OUTPUTS_PREVIEW,
  OUTPUTS_LOGS_DELETE,
} from "../const/servicesURI";
// import { httpRequests } from "investira.sdk";
import requestService from "./requestService";
import utils from "../utils";

// Logs
export const logs = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: {
      sort: "log_id DESC, data_posicao",
      excluido: false,
      ...pParams,
    },
  };

  return requestService(xProps, OUTPUTS_LOGS, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras List");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const removeLogs = (pLogId, pResolve, pReject, pFinally) => {
  const xProps = {
    data: {
      log_id: pLogId,
    },
    method: "delete",
  };

  return requestService(xProps, OUTPUTS_LOGS_DELETE, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Logs Remove");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

// Duration
export const duration = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: pParams,
    method: "post",
  };

  return requestService(xProps, OUTPUTS_DURATION, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras Read");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

// VaR
export const risco = (pParams, pResolve, pReject, pFinally) => {
  const xProps = {
    params: pParams,
  };

  return requestService(xProps, OUTPUTS_VAR, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Carteiras Read");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

// Download
export const download = (pLogId, pResolve, pReject, pFinally) => {
  const xProps = {
    params: {
      log_id: pLogId,
    },
    responseType: "blob",
  };

  return requestService(xProps, OUTPUTS_DOWNLOAD, 3, 2400, true)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Outputs Download");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

// Preview
export const preview = (pLogId, pResolve, pReject, pFinally) => {
  const xProps = {
    params: {
      log_id: pLogId,
    },
    responseType: "blob",
  };

  return requestService(xProps, OUTPUTS_PREVIEW, 3, 2400, true)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Outputs Preview");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const entidades = { logs, removeLogs, duration, risco, download, preview };

export default entidades;
