import React, { memo, useEffect, useCallback } from "react";
import services from "../services";
import { useDispatch, useSelector } from "react-redux";
import withMessage from "../hoc/withMessage";
import withResponseHandling from "../hoc/withResponseHandling";
import { acEntidadeCurrentChanged } from "../store/actions";

// Context
const EntidadesContext = React.createContext();

const initialState = {
  isDialogOpen: false,
  entidade: {},
  entidades: [],
  membros: [],
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "NEW_ENTIDADE_OPENED") {
    return {
      ...state,
      isDialogOpen: true,
      entidade: {},
    };
  }

  if (action.type === "NEW_ENTIDADE_CANCELED") {
    return {
      ...state,
      isDialogOpen: false,
    };
  }

  if (action.type === "ENTIDADES_CHANGED") {
    return {
      ...state,
      isDialogOpen: false,
      entidades: action.payload,
    };
  }

  if (action.type === "EDIT_ENTIDADE_OPENED") {
    return {
      ...state,
      isDialogOpen: true,
      entidade: action.payload,
    };
  }

  if (action.type === "EDIT_ENTIDADE_CANCELED") {
    return {
      ...state,
      isDialogOpen: true,
      entidade: {},
    };
  }

  if (action.type === "MEMBROS_CHANGED") {
    return {
      ...state,
      membros: action.payload,
    };
  }
};

// Dispatchs
const acNewEntidadeOpened = (pPayload = {}) => ({
  type: "NEW_ENTIDADE_OPENED",
  payload: pPayload,
});

const acNewEntidadeCanceled = () => ({
  type: "NEW_ENTIDADE_CANCELED",
});

const acEntidadesChanged = (pPayload = []) => ({
  type: "ENTIDADES_CHANGED",
  payload: pPayload,
});

const acEditEntidadeOpened = (pPayload = {}) => ({
  type: "EDIT_ENTIDADE_OPENED",
  payload: pPayload,
});

const acEditEntidadeCanceled = () => ({
  type: "EDIT_ENTIDADE_CANCELED",
});

const acMembrosChanged = (pPayload = []) => ({
  type: "MEMBROS_CHANGED",
  payload: pPayload,
});

// Custom Hook
export const useEntidadesContext = () => React.useContext(EntidadesContext);

// Provider
export const EntidadesProvider = memo((props) => {
  const storeDispatch = useDispatch();
  const sEntidade = useSelector((state) => state.entidade.current);

  const [state, dispatch] = React.useReducer(reducers, initialState);

  const handleNewEntidade = (pPayload = {}) => {
    dispatch(acNewEntidadeOpened(pPayload));
  };

  const handleNewEntidadeCancel = () => {
    dispatch(acNewEntidadeCanceled());
  };

  const handleEntidadeMerge = (pParams = {}, pFinally, isUpdate = false) => {
    const isDefaultEntidade = pParams.entidade === sEntidade.entidade;

    const xMsg = isUpdate
      ? `Entidade ${pParams.entidade} atualizada!`
      : "Entidade criada!";

    const xParams = {
      method: isUpdate ? "patch" : "post",
      ...pParams,
    };

    services.entidades.merge(
      xParams,
      (rRes) => {
        props.onMessage(xMsg);
        readEntidades();
        // Força refresh do logo da aplicação
        isDefaultEntidade &&
          storeDispatch(
            acEntidadeCurrentChanged({
              entidade: pParams.entidade,
              ...sEntidade,
            })
          );
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      },
      () => {
        pFinally && pFinally();
      }
    );
  };

  const handleEditEntidade = (pPayload = {}) => {
    dispatch(acEditEntidadeOpened(pPayload));
  };

  const handleEditEntidadeCancel = () => {
    dispatch(acEditEntidadeCanceled());
  };

  const handleRemoveMembro = (pParams = {}, pFinally) => {
    services.entidadesUsuario.remove(
      pParams,
      (_rRes) => {
        readMembros();
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      },
      () => {
        pFinally && pFinally();
      }
    );
  };

  const handleModifyMembro = (pParams = {}) => {
    services.entidadesUsuario.modify(
      pParams,
      (_rRes) => {
        readMembros();
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      }
    );
  };

  const readEntidades = useCallback((pParams) => {
    services.entidadesUsuario.entidades(
      { ...pParams },
      (rRes) => {
        dispatch(acEntidadesChanged(rRes.data));
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      }
    );
  }, []);

  function deleteEntidade(pEntidadeId, pFinally) {
    services.entidades.remove(
      pEntidadeId,
      (rRes) => {
        readEntidades();
        props.onMessageSuccess("Entidade removida com sucesso.");
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      },
      () => {
        pFinally && pFinally();
      }
    );
  }

  function handleSetDefaultEntidade(pEntidadeId) {
    services.user.entidade(
      pEntidadeId,
      (_rRes) => {
        readEntidades();
        props.onMessageSuccess("Entidade Ativa foi alterada.");
      },
      (rErr) => {
        props.responseErrorHandling(rErr);
      }
    );
  }

  const handleRemoveEntidade = (pEntidadeId) => {
    pEntidadeId && deleteEntidade(pEntidadeId);
  };

  const readMembros = useCallback(
    (pParams = {}) => {
      services.entidadesUsuario.list(
        { params: pParams },
        (rRes) => {
          const xData = rRes.data.sort((a, b) => (a.name > b.name ? 1 : -1));
          dispatch(acMembrosChanged(xData));
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        }
      );
    },
    [props]
  );

  function inviteMembro(pValues, pActions) {
    services.convites.convidar(
      {
        data: {
          email: pValues.email,
        },
      },
      (rRes) => {
        readMembros();
        pActions.resetForm();
      },
      (rErr) => {
        if (rErr.code.ref === "602") {
          props.onMessageError(rErr.description);
        } else {
          props.responseErrorHandling(rErr);
        }
      },
      () => {
        pActions.setSubmitting(false);
      }
    );
  }

  // Atualiza carteiras caso a entidade seja alterada
  useEffect(() => {
    readEntidades();
  }, [readEntidades]);

  const VALUES = {
    state: { ...state },
    actions: {
      readEntidades,
      readMembros,
      inviteMembro,
      handleNewEntidade,
      handleNewEntidadeCancel,
      handleEntidadeMerge,
      handleEditEntidade,
      handleEditEntidadeCancel,
      handleSetDefaultEntidade,
      handleRemoveMembro,
      handleRemoveEntidade,
      handleModifyMembro,
    },
  };

  return (
    <EntidadesContext.Provider value={VALUES}>
      {props.children}
    </EntidadesContext.Provider>
  );
});

export default withResponseHandling(withMessage(EntidadesProvider));
