import {
  ATIVOS_LIST,
  ATIVOS_MERCADO,
  ATIVOS_TIPOS,
  ATIVOS_COTACAO,
  ATIVOS_CURVA,
} from "../const/servicesURI";
import requestService from "./requestService";
import { httpRequests } from "investira.sdk";
import utils from "../utils";

const cancel = {
  list: null,
  mercado: null,
  tipos: null,
  cotacao: null,
  curva: null,
};

export const list = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.list) {
    cancel.list.cancel();
  }

  cancel.list = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    //cancelToken: cancel.list.token,
  };

  return requestService(xProps, ATIVOS_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Ativos List");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const mercado = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.mercado) {
    cancel.mercado.cancel();
  }

  cancel.mercado = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.mercado.token,
  };

  return requestService(xProps, ATIVOS_MERCADO, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Ativos Mercado");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const tipos = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.tipos) {
    cancel.tipos.cancel();
  }

  cancel.tipos = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.tipos.token,
  };

  return requestService(xProps, ATIVOS_TIPOS, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Ativos Tipos");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const cotacao = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.cotacao) {
    cancel.cotacao.cancel();
  }

  cancel.cotacao = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.cotacao.token,
  };

  return requestService(xProps, ATIVOS_COTACAO, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Ativos Cotação");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const curva = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.curva) {
    cancel.curva.cancel();
  }

  cancel.curva = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.curva.token,
  };

  return requestService(xProps, ATIVOS_CURVA, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Ativos Curva");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const ativos = { list, mercado, tipos, cotacao, curva, cancel };

export default ativos;
