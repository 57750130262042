import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
//import { HashLinkLink } from "react-router-hash-link";
import { validators } from "investira.sdk";
import { displays } from "investira.react.lib";
import {
  Grid,
  Stack,
  Typography,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Icon,
} from "investira.react.components";

import { EmpresaTemplate } from "../../../../components/templates";
import { BuscaEmpresa } from "../../../../components/organisms";
import { useEmpresasContext } from "../../../../contexts/EmpresasContext";
import services from "../../../../services";
import { ATIVO_TIPO } from "@investirapri/financa.js/lib/enums";
import { MERCADOS_ICONES } from "../../../../enums";
import EmpresaAtivosList from "./EmpresaAtivosList";

import EmpresaHeader from "../EmpresaHeader";

const EmpresaAtivos = memo(() => {
  const params = useParams();

  const { state } = useEmpresasContext();
  const { pessoa, isPageLoading } = state;

  const [ativosTipo, setAtivosTipo] = useState([]);

  function filterAtivoTipo(pAtivoTipoList, pCurrentAtivoTipo) {
    if (validators.isEmpty(pCurrentAtivoTipo)) {
      return pAtivoTipoList;
    }

    return pAtivoTipoList.filter(
      (xAtivoTipo) => xAtivoTipo.ativo_tipo_id === pCurrentAtivoTipo
    );
  }

  function somaQuantidade(pAtivosTipo) {
    if (validators.isEmpty(pAtivosTipo)) {
      return 0;
    }

    return pAtivosTipo.reduce((acc, curr) => acc + curr.quantidade, 0);
  }

  useEffect(() => {
    if (params.pessoa_id) {
      services.pessoas.ativos(
        {
          pessoa_id: params.pessoa_id,
        },
        (rRes) => {
          setAtivosTipo(rRes.data);
        },
        null
      );
    }
  }, [isPageLoading, params.pessoa_id]);

  if (isPageLoading) {
    return (
      <Stack sx={{ height: "100%" }}>
        <Stack justifyContent="center" alignItems="center" flexGrow={1}>
          <CircularProgress />
        </Stack>
      </Stack>
    );
  }

  if (!validators.isEmpty(pessoa)) {
    const isSelected = (pAtivoTipo = undefined, pCurrentItem) => {
      console.log(pAtivoTipo, pCurrentItem, pAtivoTipo === pCurrentItem);
      return pAtivoTipo === pCurrentItem;
    };

    // const listTypoProps = {
    //   color: "textPrimary",
    //   variant: "body2",
    // };

    return (
      <EmpresaTemplate
        title={`${pessoa.pessoa}  - ${
          params.tipo ? ATIVO_TIPO[params.tipo].descricao : "Todos os Ativos"
        } - ${displays.cnpj(pessoa.cnpj)}`}
        header={<EmpresaHeader preciseOption={false} />}
        searchComponent={<BuscaEmpresa />}
        maxWidth="lg"
      >
        <Grid
          container
          direction="row"
          //alignItems="stretch"
          justifyContent="flex-start"
          flexGrow={1}
        >
          <Grid container item xs="12">
            <Grid item xs="12" lg="4" alignItems="strech">
              <Stack component="nav">
                <List>
                  <ListItemButton
                    component={Link}
                    button
                    to={`/empresas/${params.pessoa_id}/ativos`}
                    selected={isSelected(params.tipo)}
                  >
                    <ListItemIcon>
                      <Icon iconName={"portofolio"} size={24} color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Todos os ativos (${somaQuantidade(
                        ativosTipo
                      )})`}
                      // secondary={
                      //   ATIVO_TIPO[xAtivoTipo.ativo_tipo_id].descricao
                      // }
                      // secondaryAction={
                      //   <Stack>
                      //     <Typography>{xAtivoTipo.quantidade}</Typography>
                      //   </Stack>
                      // }
                      //primaryTypographyProps={listTypoProps}
                    />
                  </ListItemButton>
                  {ativosTipo.map((xAtivoTipo) => {
                    return (
                      <ListItemButton
                        key={xAtivoTipo.ativo_tipo_id}
                        component={Link}
                        button
                        to={`/empresas/${params.pessoa_id}/ativos/${xAtivoTipo.ativo_tipo_id}`}
                        selected={isSelected(
                          params.tipo,
                          xAtivoTipo.ativo_tipo_id
                        )}
                      >
                        <ListItemIcon>
                          <Icon
                            iconName={MERCADOS_ICONES[xAtivoTipo.mercado].icon}
                            size={24}
                            color="primary"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${
                            ATIVO_TIPO[xAtivoTipo.ativo_tipo_id].sigla ||
                            ATIVO_TIPO[xAtivoTipo.ativo_tipo_id].descricao
                          } (${xAtivoTipo.quantidade})
                          `}
                          secondary={
                            ATIVO_TIPO[xAtivoTipo.ativo_tipo_id].descricao
                          }
                          secondaryAction={
                            <Stack>
                              <Typography>{xAtivoTipo.quantidade}</Typography>
                            </Stack>
                          }
                          //primaryTypographyProps={listTypoProps}
                          //selected={isSelected}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Stack>
            </Grid>
            <Grid item xs="12" lg="8">
              <Stack pl={{ lg: 2 }} py={2} spacing={2} flexGrow={1}>
                {filterAtivoTipo(ativosTipo, params.tipo).map((xAtivoTipo) => {
                  return (
                    <EmpresaAtivosList
                      key={xAtivoTipo.ativo_tipo_id}
                      ativoTipo={xAtivoTipo}
                      ativos={xAtivoTipo.ativos}
                      pessoa={pessoa}
                    />
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </EmpresaTemplate>
    );
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <Stack justifyContent="center" alignItems="center" flexGrow={1}>
        <Typography variant="body2" color="textSecondary">
          Empresa não encontrada
        </Typography>
      </Stack>
    </Stack>
  );
});

EmpresaAtivos.propTypes = {};

export default EmpresaAtivos;
