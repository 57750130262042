import { validators, formats, dates } from "investira.sdk";
import {
  Tooltip,
  Stack,
  Chip,
  Typography,
  CircularProgress,
} from "investira.react.components";
import { OUTPUT } from "../../enums";

const columns = {
  [OUTPUT.duration.id]: [
    {
      id: "carteira",
      accessorKey: "carteira",
      header: "Carteira",
      enableColumnActions: false,
      enableSorting: false,
      enableClickToCopy: true,
    },
    {
      id: "codigo",
      accessorKey: "carteira_seuid",
      header: "ISIN/Código",
      enableColumnActions: false,
      enableSorting: false,
      enableClickToCopy: true,
    },
    {
      id: "status",
      accessorKey: "processamento_evento",
      header: "Status",
      enableColumnActions: false,
      enableSorting: false,
      maxSize: 80,
      muiTableHeadCellProps: () => {
        return { align: "center" };
      },
      muiTableBodyCellProps: () => {
        return { align: "center" };
      },
      Cell: (xProps) => {
        const { cell, row } = xProps;

        if (validators.isNull(cell.getValue())) {
          return null;
        }

        if (!validators.isEmpty(row.original.processamento_erros)) {
          const xError = row.original.processamento_erros;
          return (
            <Tooltip
              arrow
              title={
                <Stack
                  spacing={1}
                  sx={{
                    px: 3,
                    py: 2,

                    whiteSpace: "normal",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="caption" color="textPrimary">
                      {validators.isObject(xError.description)
                        ? "Aconteceu um erro inesperado"
                        : xError.description || xError}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <span>
                <Chip size="small" label="Erro" color="error" />
              </span>
            </Tooltip>
          );
        }

        if (cell.getValue() !== "LF") {
          return (
            <Stack
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={24} />
            </Stack>
          );
        }

        return <Chip size="small" label="Ok" color="success" />;
      },
    },
    {
      id: "posicao",
      accessorKey: "payload.data",
      header: "Posição de",
      enableColumnActions: false,
      enableSorting: false,
      //maxSize: 100,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    // {
    //   id: "data",
    //   accessorKey: "payload.data",
    //   header: "Atualiza para",
    //   enableColumnActions: false,
    //   enableSorting: false,
    //   Cell: ({ cell }) => {
    //     if (validators.isNull(cell.getValue())) {
    //       return;
    //     }
    //     return formats.formatDate(dates.toDate(cell.getValue()));
    //   },
    // },
    {
      id: "juros",
      accessorKey: "payload.juros_delta",
      header: "Juros Delta",
      enableColumnActions: false,
      enableSorting: true,
      //maxSize: 100,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return `${cell.getValue().toFixed(4)}%`;
      },
    },
    {
      id: "log_id",
      accessorKey: "log_id",
      header: "Log ID",
      enableColumnActions: false,
      enableSorting: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      id: "criacao",
      accessorKey: "data",
      header: "Criação",
      enableColumnActions: false,
      enableSorting: false,
      //maxSize: 100,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
  ],
  [OUTPUT.enquadramento.id]: [
    {
      id: "carteira",
      accessorKey: "carteira_id",
      header: "Carteira",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "posicao",
      accessorKey: "data_posicao",
      header: "Data Posição",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "data",
      accessorKey: "data",
      header: "Data",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "logid",
      accessorKey: "log_id",
      header: "Log ID",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "criacao",
      accessorKey: "created",
      header: "Criação",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "status",
      accessorKey: "processamento_arquivo",
      header: "Status",
      enableColumnActions: false,
      enableSorting: false,
      Cell: (xProps) => {
        // console.log(xProps);
        const { cell, row } = xProps;

        if (!validators.isEmpty(row.original.processamento_erros)) {
          const xError = row.original.processamento_erros;
          return (
            <Tooltip
              arrow
              title={
                <Stack
                  spacing={1}
                  sx={{
                    px: 3,
                    py: 2,

                    whiteSpace: "normal",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="caption" color="textPrimary">
                      {xError.description || xError}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <span>
                <Chip size="small" label="Erro" color="error" />
              </span>
            </Tooltip>
          );
        }

        if (validators.isNull(cell.getValue())) {
          return <Chip size="small" label="Processando" color="warn" />;
        }

        return <Chip size="small" label="Ok" color="success" />;
      },
    },
  ],
  [OUTPUT.raiox.id]: [
    {
      id: "carteira",
      accessorKey: "carteira_id",
      header: "Carteira",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "posicao",
      accessorKey: "data_posicao",
      header: "Data Posição",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "data",
      accessorKey: "data",
      header: "Data",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "juros",
      accessorKey: "payload.juros_delta",
      header: "Juros Delta",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return `${cell.getValue().toFixed(4)}%`;
      },
    },
    {
      id: "logid",
      accessorKey: "log_id",
      header: "Log ID",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "criacao",
      accessorKey: "created",
      header: "Criação",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "status",
      accessorKey: "processamento_arquivo",
      header: "Status",
      enableColumnActions: false,
      enableSorting: false,
      Cell: (xProps) => {
        // console.log(xProps);
        const { cell, row } = xProps;

        if (!validators.isEmpty(row.original.processamento_erros)) {
          const xError = row.original.processamento_erros;
          return (
            <Tooltip
              arrow
              title={
                <Stack
                  spacing={1}
                  sx={{
                    px: 3,
                    py: 2,

                    whiteSpace: "normal",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="caption" color="textPrimary">
                      {xError.description || xError}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <span>
                <Chip size="small" label="Erro" color="error" />
              </span>
            </Tooltip>
          );
        }

        if (validators.isNull(cell.getValue())) {
          return <Chip size="small" label="Processando" color="warn" />;
        }

        return <Chip size="small" label="Ok" color="success" />;
      },
    },
  ],
  [OUTPUT.rentabilidade.id]: [
    {
      id: "carteira",
      accessorKey: "carteira_id",
      header: "Carteira",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "posicao",
      accessorKey: "data_posicao",
      header: "Data Posição",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "data",
      accessorKey: "data",
      header: "Data",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "juros",
      accessorKey: "payload.juros_delta",
      header: "Juros Delta",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return `${cell.getValue().toFixed(4)}%`;
      },
    },
    {
      id: "logid",
      accessorKey: "log_id",
      header: "Log ID",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "criacao",
      accessorKey: "created",
      header: "Criação",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "status",
      accessorKey: "processamento_arquivo",
      header: "Status",
      enableColumnActions: false,
      enableSorting: false,
      Cell: (xProps) => {
        // console.log(xProps);
        const { cell, row } = xProps;

        if (!validators.isEmpty(row.original.processamento_erros)) {
          const xError = row.original.processamento_erros;
          return (
            <Tooltip
              arrow
              title={
                <Stack
                  spacing={1}
                  sx={{
                    px: 3,
                    py: 2,

                    whiteSpace: "normal",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="caption" color="textPrimary">
                      {xError.description || xError}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <span>
                <Chip size="small" label="Erro" color="error" />
              </span>
            </Tooltip>
          );
        }

        if (validators.isNull(cell.getValue())) {
          return <Chip size="small" label="Processando" color="warn" />;
        }

        return <Chip size="small" label="Ok" color="success" />;
      },
    },
  ],
  [OUTPUT.var.id]: [
    {
      id: "carteira",
      accessorKey: "carteira_id",
      header: "Carteira",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "posicao",
      accessorKey: "data_posicao",
      header: "Data Posição",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "data",
      accessorKey: "data",
      header: "Data",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "juros",
      accessorKey: "payload.juros_delta",
      header: "Juros Delta",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return `${cell.getValue().toFixed(4)}%`;
      },
    },
    {
      id: "logid",
      accessorKey: "log_id",
      header: "Log ID",
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      id: "criacao",
      accessorKey: "created",
      header: "Criação",
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        if (validators.isNull(cell.getValue())) {
          return null;
        }
        return formats.formatDate(dates.toDate(cell.getValue()));
      },
    },
    {
      id: "status",
      accessorKey: "processamento_arquivo",
      header: "Status",
      enableColumnActions: false,
      enableSorting: false,
      Cell: (xProps) => {
        // console.log(xProps);
        const { cell, row } = xProps;

        if (!validators.isEmpty(row.original.processamento_erros)) {
          const xError = row.original.processamento_erros;
          return (
            <Tooltip
              arrow
              title={
                <Stack
                  spacing={1}
                  sx={{
                    px: 3,
                    py: 2,

                    whiteSpace: "normal",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="caption" color="textPrimary">
                      {xError.description || xError}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <span>
                <Chip size="small" label="Erro" color="error" />
              </span>
            </Tooltip>
          );
        }

        if (validators.isNull(cell.getValue())) {
          return <Chip size="small" label="Processando" color="warn" />;
        }

        return <Chip size="small" label="Ok" color="success" />;
      },
    },
  ],
};

export default columns;
