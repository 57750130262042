import React, { memo, useRef, useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  InputDecimal,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
} from "investira.react.components";
//import NumericFormatCustom from "../NumericFormatCustom";

// const DecimalTextField = memo((props) => {
//   const { TextFieldProps, onFocus, ...restProps } = props;
//   const [firstFocus, setFirstFocus] = useState(true);

//   const handleFocus = (e) => {
//     if (firstFocus) {
//       setFirstFocus(false);
//       onFocus && onFocus(e);
//     }
//   };
//   return (
//     <NumericFormatCustom
//       {...restProps}
//       customInput={TextField}
//       {...TextFieldProps}
//       onFocus={handleFocus}
//     />
//   );
// });

const DecimalTextField = memo((props) => {
  const { InputProps, onChange, label, allowNegative, ...restProps } = props;
  const inputRef = useRef(null);

  const [negative, setNegative] = useState(1);
  const [value, setValue] = useState(props.value);

  function handleChange(_, pValue) {
    setValue(pValue);
  }

  function setCursorToEnd(pInput) {
    setTimeout(
      () => pInput.setSelectionRange(pInput.value.length, pInput.value.length),
      0
    );
  }

  function handleFocus(pEvent) {
    setCursorToEnd(inputRef.current);
    props.onFocus && props.onFocus(pEvent);
  }

  function handleKeyUp(pEvent) {
    if (pEvent.key === "-") {
      setNegative(negative * -1);
    }
  }

  // function handleUpdateNegative(pEvent) {
  //   const xValue = Number(pEvent.currentTarget.value);
  //   setNegative(xValue * -1);
  // }

  useEffect(() => {
    onChange && onChange(null, value * negative);
  }, [value, negative]);

  return (
    <Stack>
      <TextField
        {...restProps}
        label={label}
        inputRef={inputRef}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onChange={handleChange}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          ...InputProps,
          inputComponent: InputDecimal,
        }}
        inputProps={{ decimal: props.decimal }}
      />
      {/* {allowNegative && (
        <Box>
          <FormControlLabel
            value={negative}
            control={<Checkbox />}
            label={`Negativo`}
            labelPlacement="end"
            onChange={handleUpdateNegative}
            componentsProps={{
              typography: {
                color: "textSecondary",
                variant: "caption",
              },
            }}
          />
        </Box>
      )} */}
    </Stack>
  );
});

export default DecimalTextField;
