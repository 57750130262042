import React, { memo, useState, useRef } from "react";
import PropTypes from "prop-types";
import { validators } from "investira.sdk";
import { useSelector } from "react-redux";
import {
  Stack,
  Box,
  Icon,
  ResponsiveImage,
  Typography,
} from "investira.react.components";
import { EntidadeAvatar } from "../../components/molecules";

const EntidadeLogo = memo((props) => {
  const IFEED_LOGO_HOST_URL = useSelector((store) => store.info.logo_ifeed);

  const { pessoa, onChange } = props;

  const [imgSrc, setImgSrc] = useState();

  const inputFile = useRef();

  const imageUrl = (pPessoaId) => {
    const xCurrentDate = new Date().getTime();
    const xPessoaFolder = String(pPessoaId).padStart(10, "0");
    const xBucketPath = `${IFEED_LOGO_HOST_URL}/${xPessoaFolder}`;

    const xImages = [
      {
        srcSet: `${xBucketPath}/lg@1x.png?${xCurrentDate} 1x, ${xBucketPath}/lg@2x.png?${xCurrentDate} 2x, ${xBucketPath}/lg@3x.png?${xCurrentDate} 3x`,
        media: "(max-width: 420px)",
      },
      {
        srcSet: `${xBucketPath}/lg@1x.png?${xCurrentDate} 1x, ${xBucketPath}/lg@2x.png?${xCurrentDate} 2x, ${xBucketPath}/lg@3x.png?${xCurrentDate} 3x`,
      },
    ];

    return xImages;
  };

  function handleClick(pEvent) {
    inputFile.current.click();
  }

  function handleChangeFile(pEvent) {
    const xFile = pEvent.target.files[0];

    if (!validators.isEmpty(xFile)) {
      const xReader = new FileReader();

      xReader.readAsDataURL(xFile);

      xReader.onloadend = () => {
        setImgSrc(xReader.result);
        onChange && onChange(xFile);
      };
    }
  }

  return (
    <>
      <Stack
        spacing={1}
        sx={{ position: "relative", width: "50px", cursor: "pointer" }}
        flexGrow={0}
        onClick={handleClick}
      >
        {/* Edit */}
        {!validators.isEmpty(props.entidade) && validators.isEmpty(imgSrc) && (
          <>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: "50px", height: "50px" }}
            >
              <EntidadeAvatar
                alt={props.entidade.entidade}
                sx={{ width: 50, height: 50 }}
                sizeLetters={2}
                entidadeId={props.entidade.entidade_id}
              />
            </Stack>
            <Stack
              sx={{
                borderRadius: "25px",
                backgroundColor: "#00dfa8",
              }}
            >
              <Typography
                variant="caption"
                align="center"
                color="secondary"
                sx={{ fontWeight: 500 }}
              >
                Editar
              </Typography>
            </Stack>
          </>
        )}

        {/* Sem Imagem */}
        {validators.isEmpty(props.entidade) &&
          validators.isEmpty(pessoa) &&
          validators.isEmpty(imgSrc) && (
            <>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ width: "50px", height: "50px" }}
              >
                <Icon
                  iconName={"picture"}
                  color="primary"
                  size={34}
                  style={{ padding: "2px" }}
                />
              </Stack>
              <Stack
                sx={{
                  borderRadius: "25px",
                  backgroundColor: "#00dfa8",
                }}
              >
                <Typography
                  variant="caption"
                  align="center"
                  color="secondary"
                  sx={{ fontWeight: 500 }}
                >
                  Add
                </Typography>
              </Stack>
            </>
          )}

        {/* Imagem da Pessoa */}
        {validators.isEmpty(props.entidade) &&
          !validators.isEmpty(pessoa) &&
          validators.isEmpty(imgSrc) && (
            <>
              <Stack
                sx={{ width: "50px", height: "50px", borderRadius: "15%" }}
              >
                <ResponsiveImage
                  source={imageUrl(pessoa.logo_pessoa_id)}
                  alt={pessoa.pessoa}
                />
              </Stack>

              <Stack
                sx={{
                  borderRadius: "25px",
                  backgroundColor: "#00dfa8",
                }}
              >
                <Typography
                  variant="caption"
                  align="center"
                  color="secondary"
                  sx={{ fontWeight: 500 }}
                >
                  Editar
                </Typography>
              </Stack>
            </>
          )}

        {/* Preview do Upload */}
        {!validators.isEmpty(imgSrc) && (
          <>
            <Box
              component="img"
              sx={{ width: "50px", height: "50px", borderRadius: "15%" }}
              src={imgSrc}
              alt="Entidade"
            />
            <Stack
              sx={{
                borderRadius: "25px",
                backgroundColor: "#00dfa8",
              }}
            >
              <Typography
                variant="caption"
                align="center"
                color="secondary"
                sx={{ fontWeight: 500 }}
              >
                Editar
              </Typography>
            </Stack>
          </>
        )}

        {/* Input escondido do Upload */}
        <input
          ref={inputFile}
          id="file"
          name="file"
          type="file"
          accept="image/*"
          onChange={handleChangeFile}
          style={{ display: "none" }}
        />
      </Stack>
    </>
  );
});

EntidadeLogo.propTypes = {
  pessoa: PropTypes.object,
  file: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

EntidadeLogo.displayName = "EntidadeLogo";

export default EntidadeLogo;
