import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { validators, formats, dates } from "investira.sdk";
import { IconButton, Tooltip, Stack, Icon } from "investira.react.components";
import { BulkContextActions, DataTable } from "../";
import { displays } from "investira.react.lib";

const SimulacoesDataGrid = (props) => {
  const {
    state,
    output_id,
    onFavClick,
    onEditClick,
    onBulkDeleteClick,
    onDeleteClick,
    renderDetailPanel,
    ...restProps
  } = props;

  const columns = useMemo(
    () => [
      {
        accessorKey: "carteira",
        header: "Carteira",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "carteira_seuid",
        header: "ISIN/Código",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "data_posicao",
        header: "Posição mais recente",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          if (validators.isNull(cell.getValue())) {
            return;
          }
          return formats.formatDate(dates.toDate(cell.getValue()));
        },
      },
      {
        accessorKey: "ifeed_cnpj",
        header: "CNPJ",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return displays.cnpj(cell.getValue());
        },
      },
    ],
    []
  );

  const xProps = {
    columns,
    state,
    enableRowSelection: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const isSomeRowSelected = selectedRows.length > 0;

      return (
        <BulkContextActions
          onDeleteClick={() => {
            onBulkDeleteClick(selectedRows);
          }}
          disabled={
            state.isLoading || state.showProgressBars || !isSomeRowSelected
          }
        />
      );
    },
    renderRowActions: ({ row }) => {
      const { carteira_id, favorito } = row.original;
      return (
        <Stack direction="row" spacing={2}>
          <Tooltip title="Editar">
            <IconButton
              color="primary"
              onClick={() => onEditClick(row.original)}
            >
              <Icon iconName="edit" size={24} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Favoritar">
            <IconButton
              color="primary"
              onClick={() => {
                onFavClick &&
                  onFavClick({
                    carteira_id,
                    favorito: Boolean(favorito) ? 0 : 1,
                  });
              }}
            >
              <Icon
                iconName={Boolean(favorito) ? "star5_filled" : "star5"}
                size={24}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    },
    ...restProps,
  };

  return <DataTable {...xProps} />;
};

SimulacoesDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  state: PropTypes.object,
  initialState: PropTypes.object,
};

export default SimulacoesDataGrid;
