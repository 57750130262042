import React, { memo, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { validators, objects } from "investira.sdk";
import {
  Stack,
  Box,
  Typography,
  Card,
  CircularProgress,
  Button,
  Icon,
} from "investira.react.components";
import { PessoaImage } from "../../../../components/molecules";
import services from "../../../../services";

const EmpresasSection = memo((props) => {
  const { pessoa } = props;

  const params = useParams();
  const [pessoas, setPessoas] = useState([]);
  const [isLoading, setLoading] = useState(true);

  function setParams(pPessoa) {
    const { pessoa_tipo_id, top_setor_id, setor_id, compe } = pPessoa;
    const hasCompe = !validators.isNull(compe);

    const xParams = {
      pessoa_tipo_id,
      top_setor_id,
      setor_id,
      ...(hasCompe && { compe: true }),
      cancelado: "null",
      logo: 1,
      logo_ok: 1,
    };

    return xParams;
  }

  function seeAllUrl(pPessoa) {
    const xParams = setParams(pPessoa);

    return `/empresas${objects.objectToQueryString(xParams)}`;
  }

  function filterResponse(pReponse, pPessoaId) {
    return pReponse
      .filter((xPessoa) => {
        return xPessoa.pessoa_id !== Number(pPessoaId);
      })
      .slice(0, 10);
  }

  useEffect(() => {
    if (pessoa.pessoa_id) {
      setLoading(true);

      const xParams = setParams(pessoa);

      services.pessoas.list(
        {
          ...xParams,
          size: 11,
          //sort: "relevancia ASC, top_setor_id ASC, setor_id DESC, pessoa_fantasia ASC",
          sort: "relevancia ASC, setor_id ASC, pessoa_tipo_id ASC, top_setor_id ASC, setor_id DESC",
        },
        (rRes) => {
          setPessoas(
            filterResponse(Object.values(rRes.data), params.pessoa_id)
          );
        },
        null,
        () => {
          setLoading(false);
        }
      );
    }

    //return () => services.pessoas.list.cancel();
  }, [pessoa.pessoa_id, pessoa]);

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ py: 2, height: "100%" }}
      >
        <CircularProgress size={24} />
      </Stack>
    );
  }

  if (pessoas.length === 0) {
    return null;
  }

  return (
    <Stack spacing={2} sx={{ py: 2 }}>
      <Stack direction="row">
        <Stack>
          <Typography variant="h6" color="textPrimary">
            Empresas Relacionadas
          </Typography>
        </Stack>
        <Stack flexGrow="1"></Stack>
        <Stack>
          <Button
            component={Link}
            endIcon={<Icon iconName="arrow-next" size={12} />}
            to={seeAllUrl(pessoa)}
            size="small"
          >
            Ver todos
          </Button>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(3, 1fr)",
            md: "repeat(4, 1fr)",
            lg: "repeat(5, 1fr)",
          },
          gap: "8px 8px",
        }}
      >
        {!validators.isEmpty(pessoas) &&
          pessoas.map((xPessoa) => {
            const {
              logo_pessoa_id,
              logo_ok,
              mercado,
              pessoa,
              pessoa_fantasia,
              pessoa_id,
            } = xPessoa;
            return (
              <Card key={pessoa_id}>
                <Link to={`/empresas/${pessoa_id}`}>
                  <Stack px={2} py={2} spacing={2}>
                    <Stack>
                      <PessoaImage
                        logoPessoaId={logo_pessoa_id}
                        alt={pessoa}
                        hasLogo={logo_ok}
                        mercado={mercado}
                        size={40}
                      />
                    </Stack>
                    <Stack flexGrow={1}>
                      <Stack flexGrow={1}>
                        <Typography
                          variant="caption"
                          color="textPrimary"
                          sx={{ fontWeight: 600 }}
                        >
                          {pessoa_fantasia || pessoa}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Link>
              </Card>
            );
          })}
      </Box>
    </Stack>
  );
});

export default EmpresasSection;
