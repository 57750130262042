import React, { memo, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Link,
  useParams,
  useMatch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Stack,
  Pagination,
  PaginationItem,
  Card,
  Skeleton,
  Typography,
  Button,
  Icon,
  Drawer,
  Toolbar,
  Divider,
  IconButton,
} from "investira.react.components";
import { validators, strings, objects } from "investira.sdk";

import withResponseHandling from "../../../hoc/withResponseHandling";
import { BaseTemplate } from "../";
import { useMediaQuery } from "investira.react.components";

const PaginationTemplate = memo((props) => {
  const {
    id = "default",
    title = "Sem Título",
    description = "Sistemas para fundos de investimentos, empresas e instituições financeiras dos mais diversos portes como Fundos de Pensão, Bancos, Assets Managements e Family Offices.",
    head,
    dataRead = null,
    dataReadParams = {},
    filterComponent = null,
    searchComponent = null,
    searchProps = {},
    termsComponent = null,
    listItem,
    listItemKey = "_rid",
    listItemProps = {},
    pageSize = 10,
    match = "/*",
    maxWidth = false,
    state = {},
  } = props;

  const trigger = useMemo(() => {
    return { ...state };
  }, [state]);

  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  let { pathnameBase } = useMatch(match);
  let params = useParams();
  let location = useLocation();
  let navigate = useNavigate();

  const page = parseInt(params.page || "1", 10);

  const search = useMemo(
    () => strings.querystringToObject(location.search),
    [location.search]
  );

  function formatSearch(pValue) {
    let xStrings = pValue.split(" ");
    xStrings = xStrings.map((xString) => {
      return `+${xString}*`;
    });

    const xSearchFormated = xStrings.join("");

    return xSearchFormated;
  }

  function handleDelete(_e, pLocation, pPathname) {
    const xLocation = strings.querystringToObject(pLocation.search);
    delete xLocation.pesquisa;
    const xUrl = `${pPathname}${objects.objectToQueryString(xLocation)}`;

    navigate(xUrl);
  }

  const ItemComponent = listItem;
  const SearchComponent = searchComponent;
  const FilterComponent = filterComponent;
  const TermsComponent = termsComponent;

  useEffect(() => {
    setIsLoading(true);
    dataRead &&
      dataRead(
        {
          page: page,
          size: pageSize,
          ...dataReadParams,
          ...search,
          ...(search.pesquisa && { pesquisa: formatSearch(search.pesquisa) }),
        },
        (rRes) => {
          setData(Object.values(rRes.data));
          setCount(rRes.pages.total_pages);
          setTotalResults(rRes.pages.total_items);
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          setIsLoading(false);
        }
      );
  }, [page, search]);

  return (
    <BaseTemplate
      id={`page-${id}`}
      title={title}
      description={description}
      head={head}
      // filterComponent={
      //   filterComponent && (
      //     <FilterComponent pageIsLoading={isLoading} count={count} />
      //   )
      // }
      searchComponent={
        searchComponent && (
          <SearchComponent pageIsLoading={isLoading} {...searchProps} />
        )
      }
      termsComponent={
        termsComponent && <TermsComponent pageIsLoading={isLoading} />
      }
      maxWidth={maxWidth}
      //search={search.pesquisa}
      onDeleteSearch={(e) => handleDelete(e, location, pathnameBase)}
    >
      <Stack direction={{ xs: "col", md: "row" }} gap={{ xs: 2, md: 8 }}>
        {!validators.isEmpty(filterComponent) && (
          <>
            {isUpMd ? (
              <Stack flexGrow={0}>
                <FilterComponent
                  pageIsLoading={isLoading}
                  totalResults={totalResults}
                />
              </Stack>
            ) : (
              <Stack flexGrow={1} direction="row" justifyContent="flex-end">
                <Button
                  startIcon={
                    <Icon iconName="filter" color="secondary" size={16} />
                  }
                  variant="contained"
                  onClick={toggleDrawer(true)}
                >
                  Filtrar Resultado
                </Button>
                <Drawer open={open} onClose={toggleDrawer(false)}>
                  <Toolbar sx={{ px: 2 }}>
                    <IconButton
                      size="large"
                      edge="start"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      color="primary"
                      onClick={toggleDrawer(false)}
                    >
                      <Icon iconName="arrow_left" size={16} />
                    </IconButton>
                  </Toolbar>
                  <Divider />
                  <Stack sx={{ px: 2, py: 2 }}>
                    <FilterComponent
                      pageIsLoading={isLoading}
                      totalResults={totalResults}
                    />
                  </Stack>
                </Drawer>
              </Stack>
            )}
          </>
        )}

        <Stack flexGrow={1}>
          {isLoading ? (
            <Stack id={`${id}-list`} spacing={2} mb={4}>
              {Array(10)
                .fill()
                .map((_xElem, xIndex) => {
                  return (
                    <Card key={xIndex}>
                      <Stack direction="row" px={2} py={2} spacing={2}>
                        <Stack>
                          <Skeleton
                            variant="rectangular"
                            width={40}
                            height={40}
                          />
                        </Stack>
                        <Stack flexGrow={1}>
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={40}
                          />
                        </Stack>
                        <Stack>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={40}
                          />
                        </Stack>
                      </Stack>
                    </Card>
                  );
                })}
            </Stack>
          ) : (
            <>
              <Stack id={`${id}-list`} spacing={2} mb={4}>
                {!validators.isEmpty(data) ? (
                  data.map((xData) => {
                    return (
                      <ItemComponent
                        key={xData[listItemKey]}
                        data={xData}
                        pathnameBase={pathnameBase}
                        {...listItemProps}
                      />
                    );
                  })
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ py: 4 }}
                  >
                    <Typography variant="body2" color="textPrimary">
                      Nenhum resultado encontrado
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Stack alignItems="center" mb={4}>
                <Pagination
                  id={`${id}-pagination`}
                  page={page}
                  count={count}
                  color="primary"
                  shape="rounded"
                  size="large"
                  showFirstButton
                  showLastButton
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={`${pathnameBase}${
                        item.page === 1
                          ? location.search
                          : `/page/${item.page}${location.search}`
                      }`}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </BaseTemplate>
  );
});

PaginationTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  head: PropTypes.element,
  dataRead: PropTypes.func,
  dataReadParams: PropTypes.object,
  searchComponent: PropTypes.func,
  searchProps: PropTypes.object,
  termsComponent: PropTypes.object,
  listItem: PropTypes.object,
  listItemKey: PropTypes.string,
  pageSize: PropTypes.number,
  match: PropTypes.string,
};

PaginationTemplate.displayName = "PaginationTemplate";

export default withResponseHandling(PaginationTemplate);
