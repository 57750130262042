import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import {
  Stack,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
  Chip,
} from "investira.react.components";
import { strings, objects, validators } from "investira.sdk";
import { PESSOA_TIPO } from "@investirapri/financa.js/lib/enums";
import { SETORES } from "../../enums";
import services from "../../services";
import useSearch from "../../hooks/useSearch";

const EmpresaFilter = memo((props) => {
  const { totalResults, pageIsLoading } = props;

  const location = useLocation();
  const match = useMatch("/*");
  const navigate = useNavigate();

  const {
    pesquisa = "",
    top_setor_id = 0,
    pessoa_tipo_id = 0,
    setor_id = null,
  } = useSearch();

  const [selected, setSelected] = useState({});
  const [topSetorId, setTopSetorId] = useState(top_setor_id);
  const [setorId, setSetorId] = useState(null);

  function orderedValues(pValues, pSortKey) {
    return Object.values(pValues).sort((a, b) => {
      if (a[pSortKey] < b[pSortKey]) {
        return -1;
      }
      if (a[pSortKey] > b[pSortKey]) {
        return 1;
      }

      return 0;
    });
  }

  function conciliationSelected(pPessoaTipoId) {
    let xPessoaTipoId = {};

    Object.entries(PESSOA_TIPO).forEach(([xKey, xValue]) => {
      if (!["FND", "FIS", "OUT", "AI", "EST", "AUD"].includes(xKey)) {
        xPessoaTipoId[xKey] = (pPessoaTipoId & xValue.id) === xValue.id;
      }
    });

    return xPessoaTipoId;
  }

  function somaTiposSelected(pObjTipos) {
    let xPessoaTipoId = 0;
    const xTiposSelected = [];
    Object.entries(pObjTipos).forEach(([xKey, xValue]) => {
      xValue && xTiposSelected.push(xKey);
    });

    xTiposSelected.forEach((xKey) => {
      xPessoaTipoId = xPessoaTipoId + PESSOA_TIPO[xKey].id;
    });

    return xPessoaTipoId;
  }

  function handleDeleteSearch(
    pPesquisa = [],
    pTerm = "",
    pMatch,
    pSearchQuery
  ) {
    const xNewPesquisa = pPesquisa.filter((xTerm) => {
      return !pTerm.includes(xTerm);
    });

    let xSearchQuery = strings.querystringToObject(pSearchQuery);

    xSearchQuery = {
      ...xSearchQuery,
      pesquisa: validators.isEmpty(xSearchQuery)
        ? null
        : xNewPesquisa.join(" "),
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = objects.objectToQueryString(xSearchQuery);

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function handleChangeSetor(pEvent, pMatch, pSearchQuery) {
    const xPessoaTipoId = pEvent.target.value;

    let xSearchQuery = strings.querystringToObject(pSearchQuery);

    setTopSetorId(xPessoaTipoId);

    xSearchQuery = {
      ...xSearchQuery,
      top_setor_id: xPessoaTipoId === 0 ? null : xPessoaTipoId,
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = objects.objectToQueryString(xSearchQuery);

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function handleChangeTipo(pEvent, pMatch, pSearchQuery) {
    let xTipos = {};

    setSelected((prevSelected) => {
      xTipos = {
        ...prevSelected,
        [pEvent.target.name]: pEvent.target.checked,
      };
      return xTipos;
    });

    const xPessoaTipoId = somaTiposSelected(xTipos);

    let xSearchQuery = strings.querystringToObject(pSearchQuery) || {};

    xSearchQuery = {
      ...xSearchQuery,
      pessoa_tipo_id: xPessoaTipoId === 0 ? null : xPessoaTipoId,
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = objects.objectToQueryString(xSearchQuery || {});

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function handleDeleteSubsetor(pMatch, pSearchQuery) {
    let xSearchQuery = strings.querystringToObject(pSearchQuery);

    xSearchQuery = {
      ...xSearchQuery,
      setor_id: null,
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = validators.isEmpty(xSearchQuery)
      ? ""
      : objects.objectToQueryString(xSearchQuery);

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function renderPesquisa(pStrPesquisa = "", pMatch = "", pSearchQuery = "") {
    if (validators.isEmpty(pStrPesquisa)) {
      return [];
    }

    const xPesquisa = pStrPesquisa.split(" ");

    return (
      <Stack direction="row" spacing={1}>
        {xPesquisa.map((xTerm) => {
          return (
            <Chip
              key={xTerm}
              size="small"
              color="primary"
              label={xTerm}
              onDelete={() =>
                handleDeleteSearch(xPesquisa, xTerm, pMatch, pSearchQuery)
              }
            />
          );
        })}
      </Stack>
    );
  }

  function renderSetor(pSetor = null, pMatch = "", pSearchQuery = "") {
    if (validators.isNull(pSetor)) {
      return null;
    }

    return (
      <Stack>
        <Stack>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            Subsetor
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="primary"
            label={pSetor}
            onDelete={() => handleDeleteSubsetor(pMatch, pSearchQuery)}
          />
        </Stack>
      </Stack>
    );
  }

  useEffect(() => {
    const xTiposSelected = conciliationSelected(pessoa_tipo_id);
    setSelected(xTiposSelected);
  }, [pessoa_tipo_id]);

  useEffect(() => {
    if (!validators.isNull(setor_id)) {
      services.setores.read(setor_id, (rRes) => {
        setSetorId(rRes.data[0].setor);
      });
    } else {
      setSetorId(null);
    }
  }, [setor_id]);

  useEffect(() => {
    setTopSetorId(top_setor_id);
  }, [top_setor_id]);

  return (
    <Stack sx={{ width: "240px" }} spacing={4}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h6" color="textPrimary">
            {strings.toTitleCase(pesquisa)}
          </Typography>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ fontWeight: 600 }}
          >
            {totalResults} resultados
          </Typography>
        </Stack>
        {renderPesquisa(pesquisa, match, location.search)}
      </Stack>

      {!validators.isNull(setor_id) && (
        <Stack>{renderSetor(setorId, match, location.search)}</Stack>
      )}

      <Stack sx={{ position: "relative" }}>
        <TextField
          id="top_setor_id"
          name="top_setor_id"
          variant="outlined"
          select
          label="Setor"
          size="small"
          value={topSetorId}
          onChange={(e) => handleChangeSetor(e, match, location.search)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value={0}>Todos os setores</MenuItem>
          {orderedValues(SETORES, "setor").map((option) => (
            <MenuItem key={option.setor_id} value={option.setor_id}>
              {option.setor}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      {/* <Stack>
        <FormControl>
          <FormLabel component="legend">
            <Typography variant="caption">Tipo</Typography>
          </FormLabel>
          <FormGroup>
            {Object.entries(selected).map(([xKey, xValue]) => (
              <FormControlLabel
                key={xKey}
                control={
                  <Checkbox
                    checked={xValue}
                    onChange={(e) =>
                      handleChangeTipo(e, match, location.search)
                    }
                    name={xKey}
                  />
                }
                label={PESSOA_TIPO[xKey].descricao}
                slotProps={{
                  typography: {
                    color: "textPrimary",
                    variant: "body2",
                  },
                }}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Stack> */}
    </Stack>
  );
});

EmpresaFilter.displayName = "EmpresaFilter";

export default EmpresaFilter;
