import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { validators, dates } from "investira.sdk";
import {
  Card,
  Box,
  Tab,
  TabContext,
  TabList,
  TabPanel,
} from "investira.react.components";
import services from "../../../../services";
import withResponseHandling from "../../../../hoc/withResponseHandling";
import {
  acCalculadoraCorrecaoChanged,
  acCalculadoraJurosChanged,
  acCalculadoraMercadoChanged,
  acCalculadoraMoedaChanged,
} from "../../../../store/actions";

import { useCalculadoraVariacaoContext } from "../../../../contexts/CalculadoraVariacaoContext";
import { VARIACOES_ACUMULADAS } from "@investirapri/financa.js/lib/enums";

import FatorForm from "./FatorForm";
import FatorResult from "./FatorResult";

const Fator = (props) => {
  const dispatch = useDispatch();
  const calculadoras = useSelector((store) => store.calculadoras);
  const { state, actions } = useCalculadoraVariacaoContext();

  const initialValues = useMemo(() => {
    const xData =
      calculadoras[state.indicador_tipo].data[0]?.payload ||
      state.initValues ||
      {};

    const xDataInicio = dates.toSqlDate(dates.addDays(dates.toDate(), -2));
    const xDataFim = dates.toSqlDate(dates.addWorkingDays(dates.toDate(), 0));

    return {
      data_inicio: xDataInicio,
      data_fim: xDataFim,
      valor_correcao: 0,
      ...xData,
    };
  }, [calculadoras, state.indicador_tipo]);

  const indicadorTipo = useMemo(
    () => ({
      M: {
        label: "Moeda",
        id: "M",
        initIndicadorId: "USD",
        fields: ["data_inicio", "data_fim", "indicador_id", "valor_correcao"],
        initialValues: {
          indicador_id: "USD",
          ...initialValues,
        },
        action: acCalculadoraMoedaChanged,
      },
      C: {
        label: "Correção Monetária",
        id: "C",
        initIndicadorId: "IPCA",
        fields: [
          "data_inicio",
          "data_fim",
          "indicador_id",
          "taxa_pre",
          "dia_base",
          "valor_correcao",
          "dia_base",
          "dia_util",
          "projetada",
        ],
        initialValues: {
          indicador_id: "IPCA",
          taxa_pre: 0,
          dia_base: "null",
          dia_util: "null",
          projetada: false,
          ...initialValues,
        },
        action: acCalculadoraCorrecaoChanged,
      },
      I: {
        label: "Índice de Mercado",
        id: "I",
        initIndicadorId: "IBOV",
        fields: ["data_inicio", "data_fim", "indicador_id", "valor_correcao"],
        initialValues: {
          indicador_id: "IBOV",
          ...initialValues,
        },
        action: acCalculadoraMercadoChanged,
      },
      J: {
        label: "Juros",
        id: "J",
        initIndicadorId: "SELIC",
        fields: [
          "data_inicio",
          "data_fim",
          "indicador_id",
          "percentual_pos",
          "valor_correcao",
        ],
        initialValues: {
          indicador_id: "SELIC",
          percentual_pos: 100,
          ...initialValues,
        },
        action: acCalculadoraJurosChanged,
      },
    }),
    [initialValues]
  );

  const [result, setResult] = useState({});
  const [payload, setPayload] = useState({});
  const [error, setError] = useState(null);

  const handleChange = (_, pNewValue) => {
    actions.handleUpdateIndicadorTipo(pNewValue);
  };

  const calcularFator = useCallback(
    (pParams, pActions = null, pTipoId) => {
      setError(null);
      setResult({});
      const xParams = {
        ...pParams,
        data_inicio: dayjs(pParams.data_inicio).format("YYYY-MM-DD"),
        data_fim: dayjs(pParams.data_fim).format("YYYY-MM-DD"),
      };

      services.consultas.indicadores.variacao(
        xParams,
        (rRes) => {
          if (validators.isEmpty(rRes.data)) {
            setError({
              description:
                "Não foi possível calcular os fatores. Tente novamente.",
            });
          } else {
            const xData = {
              payload: xParams,
              result: rRes.data[0],
            };
            setResult(xData.result);
            setPayload(xData.payload);
            dispatch(indicadorTipo[pTipoId].action(xData));
          }
        },
        (rErr) => {
          setError({ description: rErr.description });
        },
        () => {
          pActions && pActions.setSubmitting(false);
        }
      );
    },
    [dispatch, indicadorTipo]
  );

  function handleSubmit(pValues, pActions, pTipoId) {
    let xValues = {};

    indicadorTipo[pTipoId].fields.forEach((xKey) => {
      xValues[xKey] = pValues[xKey];
    });

    xValues = { ...xValues, dia_util: "null" };

    const xDiaBase = xValues.dia_base === "null" ? null : xValues.dia_base;
    if (!validators.isNull(xDiaBase)) {
      const xKey = String(xValues.dia_base).toUpperCase();
      const xVariacao = VARIACOES_ACUMULADAS[xKey];

      xValues = {
        ...xValues,
        dia_util: xVariacao.util,
      };
    }

    calcularFator(xValues, pActions, pTipoId);
  }

  useEffect(() => {
    const xIndicadorTipo = state.indicador_tipo;
    const xData = calculadoras[xIndicadorTipo].data;

    if (validators.isEmpty(xData)) {
      calcularFator(
        indicadorTipo[xIndicadorTipo].initialValues,
        null,
        xIndicadorTipo
      );
    } else {
      setResult(xData.at(0).result);
    }
  }, [calculadoras, calcularFator, indicadorTipo, state.indicador_tipo]);

  return (
    <>
      <Card>
        <TabContext value={state.indicador_tipo}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              id="tablist"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={indicadorTipo.C.label} value={indicadorTipo.C.id} />
              <Tab label={indicadorTipo.J.label} value={indicadorTipo.J.id} />
              <Tab label={indicadorTipo.I.label} value={indicadorTipo.I.id} />
              <Tab label={indicadorTipo.M.label} value={indicadorTipo.M.id} />
            </TabList>
          </Box>

          <TabPanel
            id={`${indicadorTipo.M.id}-tabpanel`}
            value={indicadorTipo.M.id}
          >
            <FatorForm
              key={indicadorTipo.M.id}
              label={indicadorTipo.M.label}
              tipoId={state.indicador_tipo}
              onSubmit={handleSubmit}
              initialValues={indicadorTipo.M.initialValues}
              repeatValues={state.initValues}
            />
          </TabPanel>
          <TabPanel
            id={`${indicadorTipo.C.id}-tabpanel`}
            value={indicadorTipo.C.id}
          >
            <FatorForm
              key={indicadorTipo.C.id}
              label={indicadorTipo.C.label}
              tipoId={state.indicador_tipo}
              onSubmit={handleSubmit}
              initialValues={indicadorTipo.C.initialValues}
              repeatValues={state.initValues}
            />
          </TabPanel>
          <TabPanel
            id={`${indicadorTipo.I.id}-tabpanel`}
            value={indicadorTipo.I.id}
          >
            <FatorForm
              key={indicadorTipo.I.id}
              label={indicadorTipo.I.label}
              tipoId={state.indicador_tipo}
              onSubmit={handleSubmit}
              initialValues={indicadorTipo.I.initialValues}
              repeatValues={state.initValues}
            />
          </TabPanel>
          <TabPanel
            id={`${indicadorTipo.J.id}-tabpanel`}
            value={indicadorTipo.J.id}
          >
            <FatorForm
              key={indicadorTipo.J.id}
              label={indicadorTipo.J.label}
              tipoId={state.indicador_tipo}
              onSubmit={handleSubmit}
              initialValues={indicadorTipo.J.initialValues}
              repeatValues={state.initValues}
            />
          </TabPanel>
        </TabContext>
      </Card>

      <Card>
        <FatorResult
          error={error}
          result={result}
          payload={payload}
          indicadorTipo={indicadorTipo}
        />
      </Card>
    </>
  );
};

Fator.displayName = "Fator";

export default withResponseHandling(Fator);
