import { PESSOA_TIPO } from "@investirapri/financa.js/lib/enums";
import { validators } from "investira.sdk";

function countNewNotifications(pData, pNotifications) {
  const xPrevNotificacoesSize = Object.values(pNotifications).length;
  const xNotificacoesSize = Object.values(pData).length;
  const xNewNotificationsAmount = Math.abs(
    xPrevNotificacoesSize - xNotificacoesSize
  );
  return xNewNotificationsAmount;
}

function getPath(pPathname = "", size = 1) {
  const xResult = pPathname.split("/")[size];
  return xResult;
}

function getRoute(pPathname = "", size = 2) {
  let xSize = size - 1;
  if (size <= 1) {
    xSize = 1;
  }
  let xResult = pPathname.split("/");
  //const xPathSize = xResult.length;
  console.log(xResult);
  console.log(xResult);

  xResult = xResult.slice(0, xResult - xSize);

  console.log(xResult);
  xResult = xResult.join("/");

  return xResult;
}

function getPessoaTipoById(pId) {
  const xResultado = [];

  if (validators.isEmpty(pId)) {
    return xResultado;
  }

  for (const pKey in PESSOA_TIPO) {
    if (PESSOA_TIPO.hasOwnProperty(pKey)) {
      const xTipo = PESSOA_TIPO[pKey];

      if ((pId & xTipo.id) === xTipo.id) {
        xResultado.push(xTipo);
      }
    }
  }

  return xResultado;
}

function debounce(pFunc, pDelay = 1000) {
  let timer;

  return function () {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      pFunc.apply(this, arguments);
    }, pDelay);
  };
}

const helpers = {
  countNewNotifications,
  getPath,
  getRoute,
  getPessoaTipoById,
  debounce,
};

export default helpers;
