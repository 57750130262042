import React, { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { dates, formats } from "investira.sdk";
import {
  Stack,
  Menu,
  MenuItem,
  Divider,
  Icon,
  ListItemIcon,
  IconButton,
  ListItemText,
  Tooltip,
  Persona,
  Typography,
} from "investira.react.components";
import { useSelector } from "react-redux";
import utils from "../../../utils";
import packageJson from "../../../../package.json";
import { SECTIONS } from "../../../enums";

const xEnv = process.env.REACT_APP_ENV;
const xVersion = process.env.REACT_APP_VERSION;
const xBuildDate = formats.formatDateCustom(
  dates.toDate(packageJson.buildDate),
  "YYYYMMDD.HHmm"
);

const UserProfile = memo(() => {
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const options = [
  //   {
  //     ...SECTIONS["informacoes-pessoais"],
  //     onClick: () => navigate("/configuracoes/informacoes-pessoais"),
  //   },
  //   {
  //     ...SECTIONS.seguranca,
  //     icon: <Icon iconName="shield" size={21} />,
  //     onClick: () => navigate("/configuracoes/seguranca"),
  //   },
  //   {
  //     ...SECTIONS["pagamentos-assinaturas"],
  //     icon: <Icon iconName="wallet" size={21} />,
  //     onClick: () => navigate("/configuracoes/pagamentos-assinaturas"),
  //   },
  // ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    utils.app.logout();
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" mb={2}>
        <Tooltip title={user.name}>
          <IconButton id="user-profile" onClick={handleClick} size="small">
            <Persona alt={user.name} src={user.avatar} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="menu-account"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* {options.map((xOption) => {
          return (
            <MenuItem key={xOption.key} onClick={xOption.onClick}>
              <ListItemIcon>{xOption.icon}</ListItemIcon>
              <ListItemText>{xOption.label}</ListItemText>
            </MenuItem>
          );
        })}
        <Divider /> */}
        {/* <MenuItem onClick={() => navigate(SECTIONS.entidades.path)}>
          <ListItemIcon>
            <Icon iconName="parthenon" size={21} />
          </ListItemIcon>
          <ListItemText>{SECTIONS.entidades.label}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(SECTIONS.membros.path)}>
          <ListItemIcon>
            <Icon iconName="users" size={21} />
          </ListItemIcon>
          <ListItemText>{SECTIONS.membros.label}</ListItemText>
        </MenuItem> */}
        <MenuItem
          id="item-configuracoes"
          component={Link}
          to={"/configuracoes"}
        >
          <ListItemIcon>
            <Icon iconName="gears" size={21} />
          </ListItemIcon>
          <ListItemText>Configurações</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem id="item-logout" onClick={handleLogout}>
          <ListItemIcon>
            <Icon iconName="on_off" size={21} />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
        <Divider />
        <Stack direction="row" sx={{ px: 2 }}>
          <Typography variant="caption" color="textSecondary">
            V.{xVersion} Build: {xBuildDate}.{xEnv}
          </Typography>
        </Stack>
      </Menu>
    </>
  );
});

UserProfile.displayName = "UserProfile";

export default UserProfile;
