const SETORES = {
  A: {
    setor_id: "A",
    setor: "Agronegócio",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  B: {
    setor_id: "B",
    setor: "Mineração",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  C: {
    setor_id: "C",
    setor: "Indústria",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  D: {
    setor_id: "D",
    setor: "Energia",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  E: {
    setor_id: "E",
    setor: "Água e Esgoto",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  F: {
    setor_id: "F",
    setor: "Construção",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  G: {
    setor_id: "G",
    setor: "Comércio",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  H: {
    setor_id: "H",
    setor: "Transporte e Logística",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  I: {
    setor_id: "I",
    setor: "Hotelaria e Gastromonia",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  J: {
    setor_id: "J",
    setor: "Informação e Comunicação",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  K: {
    setor_id: "K",
    setor: "Financeiro",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  L: {
    setor_id: "L",
    setor: "Imóvel",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  M: {
    setor_id: "M",
    setor: "Atividade Profissional",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  N: {
    setor_id: "N",
    setor: "Serviços",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  O: {
    setor_id: "O",
    setor: "Público",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  P: {
    setor_id: "P",
    setor: "Educação",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  Q: {
    setor_id: "Q",
    setor: "Saúde",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  R: {
    setor_id: "R",
    setor: "Cultura e Esporte",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  S: {
    setor_id: "S",
    setor: "Outras Atividades e Serviços",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  T: {
    setor_id: "T",
    setor: "Serviços Domésticos",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
  U: {
    setor_id: "U",
    setor: "Organismos Internacionais",
    pai_setor_id: null,
    top_setor_id: null,
    nivel: 0,
  },
};

export default SETORES;
