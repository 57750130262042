import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import {
  Stack,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
  Chip,
} from "investira.react.components";
import { strings, objects, validators } from "investira.sdk";
import { ATIVO_MERCADO, ATIVO_TIPO } from "@investirapri/financa.js/lib/enums";
import { SETORES } from "../../enums";

import services from "../../services";
import useSearch from "../../hooks/useSearch";

const AtivosFilter = memo((props) => {
  const { totalResults, pageIsLoading } = props;

  const location = useLocation();
  const match = useMatch("/*");
  const navigate = useNavigate();

  const ATIVO_TIPO_FILTERED = sortAndFilterValues(ATIVO_TIPO, "descricao", [
    "ACNB",
    "ACNA",
    "ACND",
    "ACNC",
    "ACNR",
    "BTSR",
    "BTUR",
    "XTBA",
    "XTPR",
    "XTPU",
    "BDRETFCM",
    "BDRETFRF",
    "BDRETFRV",
    "CTCE",
    "CTDE",
    "CTPI",
    "DC",
    "RDB",
    "CCCB",
    "CDCA",
    "CCR",
    "DEBI",
    "DEBP",
    "NCA",
    "NCE",
    "TPRT",
    "BTN",
    "LFTA",
    "LFTB",
    "NTND",
    "NTNE",
    "NTNG",
    "NTNH",
    "NTNJ",
    "NTNL",
    "NTNM",
    "NTNN",
    "NTNO",
    "NTNR",
    "NTNS",
    "NTNT",
    "NTNU",
    "TPUT",
  ]);

  const {
    pesquisa = "",
    top_setor_id = 0,
    mercado = "ACN",
    ativo_tipo_id = 0,
  } = useSearch();

  const [mercadoSelected, setMercadoSelected] = useState(mercado);
  const [topSetorId, setTopSetorId] = useState(top_setor_id);
  const [ativoTipoId, setAtivoTipoId] = useState(ativo_tipo_id);

  function sortAndFilterValues(
    pValues = [],
    pSortKey = "descricao",
    pRemoveValues = []
  ) {
    const xValues = Object.values(pValues).filter((xValue) => {
      return !pRemoveValues.includes(xValue.id);
    });

    return Object.values(xValues).sort((a, b) => {
      if (a[pSortKey] < b[pSortKey]) {
        return -1;
      }
      if (a[pSortKey] > b[pSortKey]) {
        return 1;
      }

      return 0;
    });
  }

  function handleDeleteSearch(
    pPesquisa = [],
    pTerm = "",
    pMatch,
    pSearchQuery
  ) {
    const xNewPesquisa = pPesquisa.filter((xTerm) => {
      return !pTerm.includes(xTerm);
    });

    let xSearchQuery = strings.querystringToObject(pSearchQuery);

    xSearchQuery = {
      ...xSearchQuery,
      pesquisa: validators.isEmpty(xSearchQuery)
        ? null
        : xNewPesquisa.join(" "),
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = objects.objectToQueryString(xSearchQuery);

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function handleChangeMercado(pEvent, pMatch, pSearchQuery) {
    const xMercadoId = pEvent.target.value;

    let xSearchQuery = strings.querystringToObject(pSearchQuery);

    setMercadoSelected(xMercadoId);

    xSearchQuery = {
      ...xSearchQuery,
      mercado: xMercadoId === 0 ? null : xMercadoId,
      ativo_tipo_id: null,
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = objects.objectToQueryString(xSearchQuery);

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function handleChangeSetor(pEvent, pMatch, pSearchQuery) {
    const xPessoaTipoId = pEvent.target.value;

    let xSearchQuery = strings.querystringToObject(pSearchQuery);

    setTopSetorId(xPessoaTipoId);

    xSearchQuery = {
      ...xSearchQuery,
      top_setor_id: xPessoaTipoId === 0 ? null : xPessoaTipoId,
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = objects.objectToQueryString(xSearchQuery);

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function handleChangeAtivoTipo(pEvent, pMatch, pSearchQuery) {
    const xAtivoTipoId = pEvent.target.value;

    let xSearchQuery = strings.querystringToObject(pSearchQuery);

    setAtivoTipoId(xAtivoTipoId);

    xSearchQuery = {
      ...xSearchQuery,
      ativo_tipo_id: xAtivoTipoId === 0 ? null : xAtivoTipoId,
    };

    xSearchQuery = objects.deleteNull(xSearchQuery);

    const xSearchQueryString = objects.objectToQueryString(xSearchQuery);

    navigate(`${pMatch.pathname}${xSearchQueryString}`);
  }

  function renderPesquisa(pStrPesquisa = "", pMatch = "", pSearchQuery = "") {
    if (validators.isEmpty(pStrPesquisa)) {
      return [];
    }

    const xPesquisa = pStrPesquisa.split(" ");

    return (
      <Stack direction="row" spacing={1}>
        {xPesquisa.map((xTerm) => {
          return (
            <Chip
              key={xTerm}
              size="small"
              color="primary"
              label={xTerm}
              onDelete={() =>
                handleDeleteSearch(xPesquisa, xTerm, pMatch, pSearchQuery)
              }
            />
          );
        })}
      </Stack>
    );
  }

  function orderedValues(pValues, pSortKey) {
    return Object.values(pValues).sort((a, b) => {
      if (a[pSortKey] < b[pSortKey]) {
        return -1;
      }
      if (a[pSortKey] > b[pSortKey]) {
        return 1;
      }

      return 0;
    });
  }

  function filterAtivosTiposByMercado(pAtivosTipos, pMercado = 0) {
    if (pMercado === 0 || validators.isNull(pMercado)) {
      return pAtivosTipos;
    }

    const xAtivosTipos = Object.values(pAtivosTipos).filter((xAtivoTipo) => {
      return xAtivoTipo.mercado === pMercado;
    });

    return xAtivosTipos;
  }

  useEffect(() => {
    const xAtivoTipo = filterAtivosTiposByMercado(
      ATIVO_TIPO_FILTERED,
      mercadoSelected
    );

    console.log(xAtivoTipo[0].id);

    setAtivoTipoId(0);
  }, [mercadoSelected]);

  return (
    <Stack sx={{ width: "240px" }} spacing={4}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h6" color="textPrimary">
            {strings.toTitleCase(pesquisa)}
          </Typography>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ fontWeight: 600 }}
          >
            {totalResults} resultados
          </Typography>
        </Stack>
        {renderPesquisa(pesquisa, match, location.search)}
      </Stack>
      {/* mercado */}
      <Stack sx={{ position: "relative" }}>
        <TextField
          id="mercado"
          name="mercado"
          variant="outlined"
          select
          label="Mercado"
          size="small"
          value={mercadoSelected}
          onChange={(e) => handleChangeMercado(e, match, location.search)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        >
          {/* <MenuItem value={0}>Todos os mercados</MenuItem> */}
          {sortAndFilterValues(ATIVO_MERCADO, "descricao", [
            "POU",
            "CRP",
            "DRI",
            "ETF",
            "CNT",
            "PRV",
            "FGT",
            "IMV",
            "VEI",
            "CAR",
            "MOE",
            "AGR",
            "MTL",
            "PDR",
            "ART",
            "FDL",
            "BEM",
            "SEG",
            "CBL",
          ]).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.descricao}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack sx={{ position: "relative" }}>
        <TextField
          id="ativo_tipo_id"
          name="ativo_tipo_id"
          variant="outlined"
          select
          label="Tipo"
          size="small"
          value={ativoTipoId}
          onChange={(e) => handleChangeAtivoTipo(e, match, location.search)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value={0}>Todos os tipos</MenuItem>
          {orderedValues(
            filterAtivosTiposByMercado(ATIVO_TIPO_FILTERED, mercadoSelected),
            "descricao"
          ).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {`${option.sigla} - ${option.descricao}`}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack sx={{ position: "relative" }}>
        <TextField
          id="top_setor_id"
          name="top_setor_id"
          variant="outlined"
          select
          label="Setor"
          size="small"
          value={topSetorId}
          onChange={(e) => handleChangeSetor(e, match, location.search)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value={0}>Todos os setores</MenuItem>
          {orderedValues(SETORES, "setor").map((option) => (
            <MenuItem key={option.setor_id} value={option.setor_id}>
              {option.setor}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
});

AtivosFilter.displayName = "AtivosFilter";

export default AtivosFilter;
