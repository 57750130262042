import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import services from "../services";
import withMessage from "./withMessage";
import withResponseHandling from "./withResponseHandling";
import { OUTPUT } from "../enums";
import { validators } from "investira.sdk";

const PAGE_SIZE = 10;

const withReports = (Component) => {
  function WrapComponent(props) {
    const reportsUpdatedAt = useSelector(
      (state) => state.background.reports.updatedAt
    );

    const entidadeId = useSelector(
      (state) => state.entidade.current.default_entidade_id
    );

    const { output } = useParams();

    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowSelection, setRowSelection] = useState({});
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");

    function formatColumnFilters(pColumnFilters = []) {
      const xFilters = {};

      pColumnFilters.forEach((xItem) => {
        xFilters[xItem.id] = xItem.value;
      });

      return xFilters;
    }

    function formatSorting(pSorting = []) {
      if (validators.isEmpty(pSorting)) {
        return "log_id DESC, data_posicao";
      }

      const xSorts = {};

      pSorting.forEach((xItem) => {
        xSorts[xItem.id] = xItem.desc;
      });

      let xStrSort = ``;

      for (const [key, value] of Object.entries(xSorts)) {
        const xOrder = value === true ? "DESC" : "ASC";

        xStrSort = xStrSort + key + " " + xOrder;
      }

      return `${xStrSort}, data_posicao`;
    }

    const readLogs = useCallback(
      (pParams = {}) => {
        if (!logs.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }

        const xParams = {
          output_id: OUTPUT[output].output_id,
          size: PAGE_SIZE,
          page: 1,
          ...pParams,
        };

        services.outputs.logs(
          xParams,
          (rRes) => {
            setLogs(Object.values(rRes.data));
            console.log("RESULTADO LISTA LOGS", rRes.data);
            setRowCount(rRes.pages.total_items);
          },
          (rErr) => {
            props.responseErrorHandling(rErr);
          },
          () => {
            setIsLoading(false);
            setIsRefetching(false);
          }
        );
      },
      [logs.length, output, props]
    );

    const deleteLogs = (pLogId, onFinish) => {
      services.outputs.removeLogs(
        pLogId,
        (rRes) => {
          props.onMessageSuccess("Relatório excluído com sucesso");
          readLogs();
          setRowSelection({});
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          onFinish && onFinish();
        }
      );
    };

    const bulkDeleteLogs = (pRows) => {
      const xLogsId = pRows.map((xRow) => {
        const { log_id } = xRow.original;
        return log_id;
      });

      xLogsId.forEach((xId) => {
        deleteLogs(xId);
      });
    };

    useEffect(() => {
      //TODO: Se trocar de entidade, refazer o request e resetar a paginação
      const xPage = pagination.pageIndex + 1;

      const xSort = formatSorting(sorting);

      const xFilters = formatColumnFilters(columnFilters);

      readLogs({
        page: xPage,
        size: pagination.pageSize,
        ...xFilters,
        sort: xSort,
      });
    }, [
      readLogs,
      pagination.pageIndex,
      pagination.pageSize,
      reportsUpdatedAt,
      entidadeId,
      columnFilters,
      sorting,
      globalFilter,
    ]);

    const xProps = {
      logs,
      isLoading,
      isRefetching,
      rowCount,
      pagination,
      sorting,
      columnFilters,
      rowSelection,
      setPagination,
      setRowSelection,
      setColumnFilters,
      setSorting,
      setGlobalFilter,
      readLogs,
      deleteLogs,
      bulkDeleteLogs,
    };

    return <Component {...xProps} />;
  }

  return withMessage(withResponseHandling(WrapComponent));
};

export default withReports;
