import React from "react";
import { Routes, Route } from "react-router-dom";
import { Empresas, Empresa } from "../../../pagesPublic";

const EmpresasRoutes = () => {
  return (
    <Routes>
      <Route exact path={"/page/:page"} element={<Empresas />} />
      <Route exact path={`:cnpj/*`} element={<Empresa />} />
      <Route path={"*"} element={<Empresas />} />
    </Routes>
  );
};

export default EmpresasRoutes;
