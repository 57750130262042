import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Stack,
  Tooltip,
  Icon,
  useMediaQuery,
} from "investira.react.components";
import { EntidadeAvatar } from "../../molecules";

const Logo = memo((props) => {
  const sEntidade = useSelector((store) => store.entidade.current);
  const sEntidadeUpdateAt = useSelector((store) => store.entidade.updateAt);

  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  if (props.isLoggedIn) {
    return (
      <Stack>
        <Tooltip title={sEntidade.entidade}>
          <span>
            <EntidadeAvatar
              key={sEntidadeUpdateAt}
              alt={sEntidade.entidade}
              entidadeId={sEntidade.default_entidade_id}
              sx={{
                height: "32px",
                width: "32px",
              }}
            />
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Link
      style={{ display: "block", height: "32px" }}
      to={props.isLoggedIn ? "/dashboard" : "/"}
    >
      <Icon iconName="brdados_icon" size={32} color="white" />
    </Link>
  );
});

export default Logo;
