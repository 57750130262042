import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Button,
  Typography,
  Stack,
  CenterInView,
  Success,
  Box,
} from "investira.react.components";
import { GENERIC } from "../../const";

const RememberPasswordEnd = memo((props) => {
  // Constants
  const animationTimeout = useRef(null);

  // States
  const [startAnimation, setStartAnimation] = React.useState(false);

  // Methods

  function drawerOnAnimationEnd() {
    const ANIMATION_TIMEOUT = 50;

    animationTimeout.current = setTimeout(() => {
      if (startAnimation === false) {
        setStartAnimation(true);
      }
    }, ANIMATION_TIMEOUT);
  }

  // Effects
  useEffect(() => {
    return () => {
      clearTimeout(animationTimeout.current);
    };
  }, []);

  // Render
  return (
    <Drawer onAnimationEnd={drawerOnAnimationEnd()} {...props}>
      <Stack width={"100vw"} height={"100vh"}>
        <CenterInView>
          <Stack spacing={4} alignItems="center">
            <Stack spacing={2}>
              <Success />
              <Typography
                gutterBottom
                variant={"h5"}
                color={"primary"}
                align={"center"}
              >
                {GENERIC.REMEMBER_PASSWORD.SUCCESS}
              </Typography>
            </Stack>
            <Box>
              <Button
                variant="contained"
                color={"primary"}
                onClick={props.onClose}
                sx={{ px: 4 }}
              >
                {GENERIC.REMEMBER_PASSWORD.LOGIN}
              </Button>
            </Box>
          </Stack>
        </CenterInView>
      </Stack>
    </Drawer>
  );
});

RememberPasswordEnd.propTypes = {
  onClose: PropTypes.func.isRequired,
};

RememberPasswordEnd.defaultProps = {};

RememberPasswordEnd.displayName = "RememberPasswordEnd";

export default RememberPasswordEnd;
