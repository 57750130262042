import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  IconButton,
  Icon,
  Tooltip,
  Button,
  MenuItem,
  Menu,
} from "investira.react.components";
import { dates, arrays, formats, validators } from "investira.sdk";
import services from "../../../../../../services";
import { useEmpresasContext } from "../../../../../../contexts/EmpresasContext";
import CardState from "../CardState";
import { CONSOLIDACAO_TIPO } from "@investirapri/financa.js/lib/enums";
import { HighchartsReact } from "investira.react.charts";

const TecnicoCard = memo(() => {
  const params = useParams();
  const { state, actions } = useEmpresasContext();
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [consolidacaoTipo, setConsolidacaoTipo] = useState(
    state.config.consolidacao_tipo
  );
  const [anchorElConsolidaco, setAnchorElConsolidaco] = useState(null);
  const [options, setOptions] = useState([]);

  const openConsolidacao = Boolean(anchorElConsolidaco);
  const xData = data[consolidacaoTipo];

  const xOptions = {
    title: false,
    chart: {
      height: 120,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
    },
    legend: { enabled: false },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: true,
      plotLines: [
        {
          value: 0, // Position of the line
          color: "#7A81A4", // Color of the line
          width: 2, // Width of the line
          zIndex: 1, // Z-index to position it above other elements
          label: {
            align: "left",
            verticalAlign: "bottom",
            x: 5,
            y: -10,
            style: {
              color: "red",
            },
          },
        },
      ],
    },
    responsive: {
      condition: {
        maxWidth: 500,
      },
    },
    series: [
      {
        name: "Superávit/Déficit",
        type: "line",
        data: formatData(state.historico.saldos),
        marker: {
          enabled: false,
        },
        zones: [
          {
            value: 0, // Up to zero
            color: "#ed442c", // Color for values above zero
          },
          {
            color: "#00dfa8", // Color for values below zero
          },
        ],
      },
    ],
    tooltip: {
      formatter: function (tooltip) {
        const xDate = formats.formatDateCustom(this.point.x, "DD/MMM/YYYY");
        const xValue = formats.friendlyNumber(this.point.y, 2, false);
        const xFullValue = formats.formatNumber(this.point.y, 2, true, false);

        const xSerieName = this.point.y > 0 ? "Superávit" : "Déficit";

        return `
        <span style="font-size: 1em"><b>${xDate}</b></span><br/>
        <span style="color:${this.color}">\u25CF</span>
        ${xSerieName}: <b>${xValue}</b> (${xFullValue})<br/>
        `;
      },
    },
  };

  function formatData(pData = []) {
    if (validators.isNull(pData)) {
      return [];
    }

    return pData.map((xPoint) => {
      const xDate = dates.toDate(xPoint.data).getTime();
      return [xDate, xPoint.saldo];
    });
  }

  function handleOpenClick() {
    actions.handleChartVariantChange("saldo");
  }

  useEffect(() => {
    if (params.pessoa_id) {
      setLoading(true);
      services.pessoas.saldos(
        {
          data: null,
          data_inicio: dates.toSqlDate(dates.addYears(dates.toDate(), -10)),
          pessoa_id: params.pessoa_id,
          conta: "3.08.00.00.00.00.00",
        },
        (rRes) => {
          const xData = arrays.arrayToObject(rRes.data, "consolidacao_tipo");
          let xOptions = new Set();

          rRes.data.forEach((xElem) => {
            xOptions.add(xElem.consolidacao_tipo);
          });

          xOptions = Array.from(xOptions);

          setData(xData);
          setOptions(xOptions);
        },
        null,
        () => {
          setLoading(false);
        }
      );
    }

    return () => services.pessoas.cancel.saldos.cancel();
  }, [params.pessoa_id]);

  function renderConsolidacaoMenu(
    pConsolidacoaTipo,
    pOptions = [],
    pCurrentConsolidacao
  ) {
    if (validators.isEmpty(pOptions)) {
      return null;
    }

    const xMenu = pOptions.map((xId) => {
      const xConsolidacaoTipo = Object.values(pConsolidacoaTipo).filter(
        (xItem) => xItem.id === xId
      )[0];

      return {
        label: xConsolidacaoTipo.descricao,
        value: xConsolidacaoTipo.id,
      };
    });

    const xCurrentLabel = Object.values(pConsolidacoaTipo).filter(
      (xItem) => xItem.id === pCurrentConsolidacao
    )[0];

    const xProps = {
      ...(pOptions.length >= 2
        ? { onClick: (pEvent) => setAnchorElConsolidaco(pEvent.currentTarget) }
        : { disabled: true }),
    };

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          disableElevation
          {...xProps}
          sx={{ fontSize: "0.7rem", padding: "3px 8px" }}
        >
          {xCurrentLabel?.descricao}
        </Button>
        <Menu
          anchorEl={anchorElConsolidaco}
          open={openConsolidacao}
          onClose={() => setAnchorElConsolidaco(null)}
        >
          {xMenu.map((xItem) => {
            return (
              <MenuItem
                key={xItem.value}
                onClick={() => {
                  setConsolidacaoTipo(xItem.value);
                  actions.handleConfigChange({
                    consolidacao_tipo: xItem.value,
                  });
                }}
              >
                {xItem.label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Stack direction="row" spacing={2}>
          <Stack flexGrow={1}>
            <Typography variant="caption" color="textPrimary" gutterBottom>
              Superávit/Déficit Técnico
            </Typography>
          </Stack>
          {!validators.isEmpty(xData) && (
            <Stack>
              <Tooltip title="Histórico Superávit/Déficit Técnico">
                <div>
                  <IconButton color="primary" onClick={handleOpenClick}>
                    <Icon iconName="chart_bar" size={16} />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        <CardState loading={isLoading} data={xData}>
          <Stack spacing={1} justifyContent="space-between">
            <Stack>
              <Typography
                variant="h4"
                color="textPrimary"
                sx={{ fontWeight: 600 }}
              >
                {actions.formatNumber(xData?.saldo, state.showPrecise)}
              </Typography>

              <Typography variant="caption" color="textPrimary" gutterBottom>
                Data de referência{" "}
                {formats.formatDateCustom(xData?.data, "DD/MMM/YYYY")}
              </Typography>
            </Stack>
            <Stack flexGrow="1">
              <HighchartsReact options={xOptions} />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                {renderConsolidacaoMenu(
                  CONSOLIDACAO_TIPO,
                  options,
                  consolidacaoTipo
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardState>
      </CardContent>
    </Card>
  );
});

TecnicoCard.displayName = "TecnicoCard";

export default TecnicoCard;
