import React, { memo } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
} from "investira.react.components";

import { validators } from "investira.sdk";

const EntidadeMembroConfirmDelete = memo((props) => {
  console.log(props);

  const { open, membro, onClose, onDelete } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Excluir membro?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          O membro <b>{membro.name || membro.username}</b> será excluído
          permanentemente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          variant={"contained"}
          onClick={() =>
            onDelete(
              {
                usuario_id: membro.usuario_id,
                ...(validators.isNull(membro.usuario_id) && {
                  username: membro.username,
                }),
              },
              onClose
            )
          }
          autoFocus
        >
          Sim, excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default EntidadeMembroConfirmDelete;
