import React, { memo } from "react";
import { Stack } from "investira.react.components";
import { BaseTemplate } from "../../../components/templates";
import Fator from "./Fator";
import Historico from "./Historico";
import CalculadoraVariacaoProvider from "../../../contexts/CalculadoraVariacaoContext";

const Variacao = memo(() => {
  return (
    <CalculadoraVariacaoProvider>
      <BaseTemplate
        title="Cálculo de Variação"
        description="Calculadora de Correção de Valores. Atualize uma quantia, usando a correção monetária, o índice de inflação, a taxa Selic entre outras."
        maxWidth="xl"
      >
        <Stack spacing={2}>
          <Fator />
          <Historico />
        </Stack>
      </BaseTemplate>
    </CalculadoraVariacaoProvider>
  );
});

Variacao.displayName = "Variacao";

export default Variacao;
