import {
  PESSOAS_LIST,
  PESSOAS_BALANCO,
  PESSOAS_BALANCO_RESUMO,
  PESSOAS_BALANCO_CONTAS,
  PESSOAS_BALANCO_SALDOS,
  PESSOAS_PL,
  PESSOAS_CS,
  PESSOAS_ATIVOS,
  PESSOAS_SETORES,
  PESSOAS_BR,
} from "../const/servicesURI";
import requestService from "./requestService";
import { httpRequests } from "investira.sdk";
import utils from "../utils";

const cancel = {
  list: null,
  balanco: null,
  pl: null,
  cs: null,
  saldos: null,
  ativos: null,
  balancoResumo: null,
  balancoContas: null,
  setores: null,
  br: null,
};

export const list = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.list) {
    cancel.list.cancel();
  }

  cancel.list = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.list.token,
  };

  return requestService(xProps, PESSOAS_LIST, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas List");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const balanco = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.balanco) {
    cancel.balanco.cancel();
  }

  cancel.balanco = httpRequests.cancelToken();

  const xProps = {
    params: { ...pParams },
    //cancelToken: cancel.balanco.token,
  };

  return requestService(xProps, PESSOAS_BALANCO, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas Balanco");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const balancoResumo = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.balancoResumo) {
    cancel.balancoResumo.cancel();
  }

  cancel.balancoResumo = httpRequests.cancelToken();

  const xProps = {
    params: { ...pParams },
    //cancelToken: cancel.balancoResumo.token,
  };

  return requestService(xProps, PESSOAS_BALANCO_RESUMO, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas Balanco Resumo");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const balancoContas = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.balancoContas) {
    cancel.balancoContas.cancel();
  }

  cancel.balancoContas = httpRequests.cancelToken();

  const xProps = {
    params: { ...pParams },
    //cancelToken: cancel.balancoContas.token,
  };

  return requestService(xProps, PESSOAS_BALANCO_CONTAS, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas Balanco Contas");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const pl = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.pl) {
    cancel.pl.cancel();
  }

  cancel.pl = httpRequests.cancelToken();

  const xProps = {
    params: { data: new Date(), ...pParams },
    //cancelToken: cancel.pl.token,
  };

  return requestService(xProps, PESSOAS_PL, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas PL");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const saldos = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.saldos) {
    cancel.saldos.cancel();
  }

  cancel.saldos = httpRequests.cancelToken();

  const xProps = {
    params: { data: new Date(), ...pParams },
    //cancelToken: cancel.saldos.token,
  };

  return requestService(xProps, PESSOAS_BALANCO_SALDOS, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas Balanço Saldos");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const cs = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.cs) {
    cancel.cs.cancel();
  }

  cancel.cs = httpRequests.cancelToken();

  const xProps = {
    params: { data: new Date(), ...pParams },
    //cancelToken: cancel.cs.token,
  };

  return requestService(xProps, PESSOAS_CS, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas CS");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const ativos = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.ativos) {
    cancel.ativos.cancel();
  }

  cancel.ativos = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.ativos.token,
  };

  return requestService(xProps, PESSOAS_ATIVOS, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas Ativo");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const setores = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.setores) {
    cancel.setores.cancel();
  }

  cancel.setores = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.setores.token,
  };

  return requestService(xProps, PESSOAS_SETORES, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas Setores");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

export const br = (pParams, pResolve, pReject, pFinally) => {
  if (cancel.br) {
    cancel.br.cancel();
  }

  cancel.br = httpRequests.cancelToken();

  const xProps = {
    params: pParams,
    cancelToken: cancel.br.token,
  };

  return requestService(xProps, PESSOAS_BR, 3)
    .then((rRes) => {
      return pResolve && pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Pessoas BR");
      return pReject && pReject(rErr);
    })
    .finally(() => {
      pFinally && pFinally();
    });
};

const pessoas = {
  list,
  cancel,
  balanco,
  balancoResumo,
  balancoContas,
  pl,
  cs,
  ativos,
  saldos,
  setores,
  br,
};

export default pessoas;
