import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Stack, useMediaQuery } from "investira.react.components";
import { SimulacoesTemplate } from "../../components/templates";
import withSimulacoes from "../../hoc/withSimulacoes";
import { useSimulacoesContext } from "../../contexts/SimulacoesContext";

const Simulacoes = memo((props) => {
  const navigate = useNavigate();
  const { actions } = useSimulacoesContext();
  const isUpSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <SimulacoesTemplate
      subtitle="Carteiras"
      title="Simuladas"
      headerRenderActions={
        <Stack direction="row" spacing={2}>
          <Button
            disabled={props.disabledButtons}
            variant="contained"
            color="primary"
            startIcon={<Icon iconName="insert" size={16} />}
            size="small"
            sx={{ px: 2 }}
            onClick={() => navigate("/simulacoes/add")}
          >
            {isUpSm ? "Nova Carteira" : "Nova"}
          </Button>
        </Stack>
      }
      dataGridProps={{
        data: props.carteiras,
        readData: props.readCarteiras,
        isLoading: props.isLoading,
        initialState: { pagination: props.pagination },
        manualPagination: true,
        getRowId: (row) => {
          return row.carteira_id;
        },
        state: {
          isLoading: props.isLoading,
          pagination: props.pagination,
          showProgressBars: props.isRefetching,
          rowSelection: props.rowSelection,
        },
        rowCount: props.rowCount,
        onPaginationChange: props.setPagination,
        onRowSelectionChange: props.setRowSelection,
        onFavClick: props.favCarteira,
        onEditClick: actions.handleEditCarteira,
        onDeleteClick: props.deleteCarteira,
        onBulkDeleteClick: props.bulkDeleteCarteiras,
      }}
    />
  );
});

Simulacoes.displayName = "Simulacoes";

export default withSimulacoes(Simulacoes);
