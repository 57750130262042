import React, { memo } from "react";
import { Link } from "react-router-dom";
import { displays } from "investira.react.lib";
import { validators } from "investira.sdk";
import {
  Card,
  Stack,
  Typography,
  Chip,
  useMediaQuery,
} from "investira.react.components";
import { PessoaImage, MutedCopy } from "../../components/molecules";
import utils from "../../utils";

const EmpresasItem = memo((props) => {
  const {
    logo_pessoa_id,
    logo_ok,
    cnpj,
    pessoa_id,
    mercado,
    pessoa,
    pessoa_fantasia,
    isin,
    setor,
    top_setor,
    b3,
    pessoa_tipo_id,
  } = props.data;

  const xPessoaTipoList = utils.helpers.getPessoaTipoById(pessoa_tipo_id);
  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Card>
      <Link to={`${props.pathnameBase}/${cnpj}`}>
        <Stack
          direction="row"
          sx={{
            cursor: "pointer",
            px: 2,
            py: 2,
          }}
          spacing={2}
        >
          <Stack>
            <PessoaImage
              logoPessoaId={logo_pessoa_id}
              alt={pessoa}
              hasLogo={logo_ok}
              mercado={mercado}
              size={40}
            />
          </Stack>

          <Stack flexGrow={1} spacing={1}>
            <Stack flexGrow={1} direction="row" spacing={3}>
              <Stack flexGrow={1}>
                <Stack flexGrow={1}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{ fontWeight: 600 }}
                  >
                    {pessoa_fantasia || pessoa}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={{ xs: 0, sm: 1 }} mb={1}>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      display: { xs: "none", sm: "inline-block" },
                    }}
                  >
                    {`${pessoa}`}
                  </Typography>
                  {cnpj && (
                    <MutedCopy>
                      <Typography variant="caption" color="textSecondary">
                        {`${displays.cnpj(cnpj)}`}
                      </Typography>
                    </MutedCopy>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Stack sx={{ gap: "8px 8px" }} flexWrap="wrap" direction="row">
              {top_setor && (
                <Chip
                  label={top_setor}
                  size="small"
                  color="primary"
                  sx={{ display: { xs: "flex", md: "none" } }}
                />
              )}
              {!validators.isEmpty(setor) && (
                <Chip
                  key={setor}
                  label={setor}
                  size="small"
                  color="secondary"
                  sx={{ maxWidth: "230px" }}
                />
              )}
              {xPessoaTipoList.map((xPessoaTipo) => {
                return (
                  <Chip
                    key={xPessoaTipo.descricao}
                    label={xPessoaTipo.descricao}
                    size="small"
                    color="secondary"
                  />
                );
              })}
              {b3?.codigo_emissor && (
                <Chip
                  label={b3?.codigo_emissor}
                  size="small"
                  color="secondary"
                />
              )}
            </Stack>
          </Stack>
          {isUpMd && (
            <Stack>
              <Stack gap={1} justifyContent="end">
                <Chip label={top_setor} size="small" color="primary" />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Link>
    </Card>
  );
});

export default EmpresasItem;
