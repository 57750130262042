import React, { memo, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { validators } from "investira.sdk";
import { ReportsTemplate } from "../../components/templates";
import { useRelatorioContext } from "../../contexts/RelatorioContext";
import { OUTPUT } from "../../enums";
import DurationParamsDialog from "./DurationParamsDialog";
import withReports from "../../hoc/withReports";
import columns from "./columns";

const Reports = memo((props) => {
  const params = useParams();
  const { actions } = useRelatorioContext();
  const carteiras = useSelector((store) => store.carteiras.data);

  const xColumns = useMemo(() => columns[params.output], [params.output]);

  useEffect(() => {
    actions.handleOutputUpdate(params.output);
  }, [params.output]);

  return (
    <>
      <ReportsTemplate
        subtitle="Reports"
        title={OUTPUT[params.output].descricao}
        onNewReportClick={actions.handleNewReport}
        disabledButtons={validators.isEmpty(carteiras)}
        dataGridProps={{
          columns: xColumns,
          data: props.logs,
          readData: props.readLogs,
          isLoading: props.isLoading,
          initialState: {
            pagination: props.pagination,
            columnVisibility: { carteira: false },
          },
          manualFiltering: true,
          manualSorting: true,
          manualPagination: true,
          getRowId: (row) => row.log_id,
          state: {
            isLoading: props.isLoading,
            pagination: props.pagination,
            showProgressBars: props.isRefetching,
            columnFilters: props.columnFilters,
            sorting: props.sorting,
            rowSelection: props.rowSelection,
          },
          rowCount: props.rowCount,
          onColumnFiltersChange: props.setColumnFilters,
          onSortingChange: props.setSorting,
          onPaginationChange: props.setPagination,
          onRowSelectionChange: props.setRowSelection,
          onGlobalFilterChange: props.setGlobalFilter,
          onBulkDeleteClick: props.bulkDeleteLogs,
        }}
      />
      <DurationParamsDialog carteiras={carteiras} />
    </>
  );
});

Reports.displayName = "Reports";

export default withReports(Reports);
