import { ATIVOS_SEARCH, ATIVOS_LOGOUT } from "../../const/actionsType";

export function acAtivosSearchChanged(pData) {
  return {
    type: ATIVOS_SEARCH,
    payload: pData,
  };
}

export function acAtivosLogout() {
  return {
    type: ATIVOS_LOGOUT,
  };
}
