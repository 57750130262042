import React, { memo, useState } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { validators, formats } from "investira.sdk";
import { displays } from "investira.react.lib";
import { useDispatch } from "react-redux";
import {
  Stack,
  Box,
  Card,
  Typography,
  LoadingButton,
  Divider,
} from "investira.react.components";
import { UNIDADE } from "@investirapri/financa.js/lib/enums";
import { BaseTemplate } from "../../../components/templates";
import {
  DatePicker,
  InputAsync,
  PessoaImage,
  CardInfo,
  ExcelLikeInput,
} from "../../../components/molecules";
import { VALIDATIONS } from "../../../const";
import services from "../../../services";
import { PERIODICIDADE } from "../../../enums";
import withResponseHandling from "../../../hoc/withResponseHandling";
import { acCalculadoraCurvaChanged } from "../../../store/actions";
import CurvaHistorico from "./CurvaHistorico";
import Eventos from "./Eventos";

const Curva = memo((props) => {
  const xInitialValues = {
    isin: "",
    data: dayjs(),
    operacao_taxa: 1.0,
    quantidade: 1.0,
  };

  const xEnv = process.env.REACT_APP_ENV;

  const [result, setResult] = useState({});
  const [payload, setPayload] = useState(xInitialValues);
  const [ativo, setAtivo] = useState({});
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const VALIDATION_SCHEMA = Yup.object().shape({
    isin: Yup.string().required(VALIDATIONS.REQUIRED),
    data: Yup.date()
      .required(VALIDATIONS.REQUIRED)
      .typeError(`${VALIDATIONS.INVALID_DATE}`),
  });

  const formik = useFormik({
    initialValues: xInitialValues,
    validationSchema: VALIDATION_SCHEMA,
    validateOnMount: false,
    onSubmit: (pValues, pActions) => {
      const xPayload = {
        ...pValues,
        data: dayjs(pValues.data).format("YYYY-MM-DD"),
        eventos: true,
      };

      setPayload(xPayload);

      services.ativos.curva(
        xPayload,
        (rRes) => {
          const xResult = rRes.data.data;

          setResult(xResult);
          services.ativos.list(
            {
              size: 10,
              isin: xResult.isin,
              ativo_tipo: xResult.ativo_tipo,
            },
            (rRes) => {
              const xAtivo = Object.values(rRes.data)[0];
              setAtivo(xAtivo);

              dispatch(
                acCalculadoraCurvaChanged({
                  payload: xPayload,
                  result: xResult,
                  ativo: xAtivo,
                })
              );
            }
          );
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          pActions.setSubmitting(false);
        }
      );
    },
  });

  function renderOption(pProps, pOption) {
    if (validators.isNull(pOption)) {
      return;
    }

    return (
      <Stack
        {...pProps}
        key={pOption["ativo_id"]}
        direction="row"
        spacing={3}
        alignItems="center"
        component="li"
        sx={{
          px: 2,
          py: 1,
          cursor: "pointer",
          "&:hover": {
            color: "gray",
            backgroundColor: "background.main",
          },
        }}
      >
        <Stack>
          <PessoaImage
            logoPessoaId={pOption.logo_pessoa_id}
            alt={pOption.pessoa}
            hasLogo={pOption.logo_ok}
            size={36}
          />
        </Stack>
        <Stack>
          <Typography variant="body2" color="textPrimary">
            {pOption["ativo_seuid"]}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" color="textSecondary">
              {pOption["isin"]}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {pOption["serie"] && `Série: ${pOption["serie"]}`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  const handleDateChange = (pFieldName, pFieldValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  const handleNumberChange = (pFieldName, pFieldValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  const handleAsyncChange = (pValue) => {
    if (validators.isNull(pValue)) {
      return;
    }

    const { isin } = pValue;

    formik.setValues({
      ...formik.values,
      isin,
    });
  };

  const handleRepeat = (pPayload, pAtivo) => {
    const { data, isin, operacao_taxa, quantidade } = pPayload;
    formik.setValues({ data: dayjs(data), isin, operacao_taxa, quantidade });
    setOptions([pAtivo]);
  };

  return (
    <BaseTemplate title="Cálculo de Curva" maxWidth="xl">
      <Stack spacing={2}>
        <Card
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <Stack px={2} py={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack flexGrow={8} sx={{ minWidth: "300px" }}>
                <InputAsync
                  label="Ativo"
                  name="isin"
                  id="isin"
                  initOptions={options}
                  initValue={options[0]}
                  initInputValue={options[0]?.ativo_seuid}
                  placeholder="Nome ou ISIN"
                  messageError="Falha na busca de ativos"
                  service={services.ativos.list}
                  serviceParams={{
                    sort: "relevancia DESC, ativo ASC, vencimento ASC",
                    ...(xEnv === "prod"
                      ? { mercado: "TPU" }
                      : { mercado: "TPU,TPR,TBA" }),
                    size: 50,
                    cancelado: "null",
                    vencimento: "null",
                  }}
                  renderOptionKey="ativo_seuid"
                  onChange={(_e, pValues) => {
                    handleAsyncChange(pValues);
                  }}
                  getOptionKey={(pOption) => {
                    return pOption["isin"];
                  }}
                  renderOption={renderOption}
                  getOptionLabel={(option) => {
                    return !validators.isNull(option?.ativo)
                      ? option.ativo
                      : "";
                  }}
                  disabled={formik.isSubmitting}
                />
              </Stack>
              <Stack flexGrow={1}>
                <DatePicker
                  label="Data"
                  name="data"
                  onChange={(pValue) => {
                    handleDateChange("data", pValue);
                  }}
                  // maxDate={values.data_fim}
                  value={formik.values.data}
                  error={Boolean(formik.errors.data)}
                  helperText={formik.errors.data}
                  disabled={formik.isSubmitting}
                  fullWidth
                />
              </Stack>
              <Stack flexGrow={1}>
                <ExcelLikeInput
                  id="quantidade"
                  name="quantidade"
                  label="Quantidade"
                  variant="outlined"
                  value={formik.values.quantidade}
                  onChange={(pValue) => {
                    handleNumberChange("quantidade", pValue);
                  }}
                  error={Boolean(formik.errors.quantidade)}
                  helperText={formik.errors.quantidade}
                  disabled={formik.isSubmitting}
                  decimal={4}
                  fullWidth
                />
              </Stack>
              <Stack flexGrow={1}>
                <ExcelLikeInput
                  id="operacao_taxa"
                  name="operacao_taxa"
                  label="Taxa de Compra"
                  variant="outlined"
                  value={formik.values.operacao_taxa}
                  onChange={(pValue) => {
                    handleNumberChange("operacao_taxa", pValue);
                  }}
                  error={Boolean(formik.errors.operacao_taxa)}
                  helperText={formik.errors.operacao_taxa}
                  disabled={formik.isSubmitting}
                  decimal={6}
                  fullWidth
                />
              </Stack>
              <Stack flexGrow={1}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Calcular
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        {!validators.isEmpty(result) && (
          <>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "8px 8px",
              }}
            >
              <CardInfo
                label="PU"
                value={formats.formatNumber(result.operacao?.pu, 6, true)}
              />
              <CardInfo
                label="Financeiro"
                value={formats.formatNumber(
                  result.operacao?.pu * payload.quantidade,
                  2,
                  true,
                  true
                )}
              />
            </Box>

            <Stack
              py={2}
              direction="row"
              sx={{ gap: "8px 8px" }}
              alignItems
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Stack flexGrow={1} flexBasis={1}>
                <Card>
                  <Stack alignItems="flex-start" spacing={1} p={2} flexGrow={1}>
                    <Stack spacing={1} sx={{ my: 2, width: "100%" }}>
                      {!validators.isEmpty(result?.ativo) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Ativo:
                            </Typography>
                            <Typography variant="caption">
                              {ativo.ticker
                                ? `${ativo.ticker} - ${result.ativo}`
                                : result.ativo}
                            </Typography>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(ativo.isin) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              ISIN:
                            </Typography>
                            <Typography variant="caption">
                              {ativo.isin}
                            </Typography>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result?.vencimento) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Vencimento:
                            </Typography>
                            <Typography variant="caption">
                              {formats.formatDateCustom(
                                result.vencimento,
                                "DD/MMM/YYYY"
                              )}
                            </Typography>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result.juros?.taxa) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Juros
                            </Typography>
                            <Stack direction="row" spacing={1}>
                              <Typography variant="caption">
                                {`${formats.formatNumber(
                                  result.juros?.taxa,
                                  2
                                )}%`}
                              </Typography>

                              {!validators.isEmpty(result.juros?.unidade) && (
                                <Typography variant="caption">
                                  ({UNIDADE[result.juros?.unidade].descricao})
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      <Stack flexGrow={1}>
                        <Typography variant="caption" sx={{ fontWeight: 700 }}>
                          Pagamento de Juros:
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          {!validators.isEmpty(result.juros?.intervalo) && (
                            <Typography variant="caption">
                              {result.juros?.intervalo}
                            </Typography>
                          )}
                          <Typography variant="caption">
                            {!validators.isEmpty(result.juros?.periodiciade) &&
                              `(${
                                PERIODICIDADE[result.juros.periodiciade]
                                  .descricao
                              })`}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider flexItem />

                      {!validators.isEmpty(result.curva?.indexador) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Índice:
                            </Typography>
                            <Stack direction="row" spacing={1}>
                              <Typography variant="caption">
                                {result.curva?.indexador}
                              </Typography>
                              <Typography variant="caption">
                                {result.curva?.indexador_percentual}%
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(ativo.serie) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Série:
                            </Typography>
                            <Typography variant="caption">
                              {ativo.serie}
                            </Typography>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(ativo.classe) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Classe:
                            </Typography>
                            <Typography variant="caption">
                              {ativo.classe}
                            </Typography>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(ativo.emissor) && (
                        <>
                          <Stack flexGrow={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Emissor:
                            </Typography>
                            <Stack direction="row" spacing={1}>
                              {ativo.emissor?.pessoa_fantasia && (
                                <Typography variant="caption">
                                  {ativo.emissor.pessoa_fantasia}
                                </Typography>
                              )}
                              {ativo.emissor_cnpj && (
                                <Typography variant="caption">
                                  ({displays.cnpj(ativo.emissor_cnpj)})
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack flexGrow={1} flexBasis={1} spacing={1}>
                <Card>
                  <Stack alignItems="flex-start" spacing={1} p={2} flexGrow={1}>
                    <Stack spacing={1} sx={{ my: 2, width: "100%" }}>
                      <Stack flexGrow={1} direction="row" spacing={2}>
                        <Stack sx={{ width: "150px" }}></Stack>
                        <Stack flexGrow={1} flexBasis={1} alignItems="flex-end">
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: 700 }}
                          >
                            PU
                          </Typography>
                        </Stack>
                        <Stack flexGrow={1} flexBasis={1} alignItems="flex-end">
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: 700 }}
                          >
                            Financeiro
                          </Typography>
                        </Stack>
                      </Stack>
                      {!validators.isEmpty(result.curva?.pu_principal) && (
                        <>
                          <Stack flexGrow={1} direction="row" spacing={2}>
                            <Stack sx={{ width: "150px" }}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 600 }}
                              >
                                Principal:
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.curva?.pu_principal,
                                  6,
                                  true
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.curva?.pu_principal *
                                    payload.quantidade,
                                  2,
                                  true
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result.curva?.indexador) &&
                        !validators.isEmpty(result.curva?.pu_vna) &&
                        !validators.isEmpty(result.curva?.pu_principal) && (
                          <>
                            <Stack flexGrow={1} direction="row" spacing={2}>
                              <Stack sx={{ width: "150px" }}>
                                <Typography
                                  variant="caption"
                                  sx={{ fontWeight: 700 }}
                                >
                                  Correção:
                                </Typography>
                              </Stack>
                              <Stack
                                flexGrow={1}
                                flexBasis={1}
                                alignItems="flex-end"
                              >
                                <Typography variant="caption">
                                  {formats.formatNumber(
                                    result.curva?.pu_vna -
                                      result.curva?.pu_principal,
                                    6,
                                    true
                                  )}
                                </Typography>
                              </Stack>
                              <Stack
                                flexGrow={1}
                                flexBasis={1}
                                alignItems="flex-end"
                              >
                                <Typography variant="caption">
                                  {formats.formatNumber(
                                    (result.curva?.pu_vna -
                                      result.curva?.pu_principal) *
                                      payload.quantidade,
                                    2,
                                    true
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Divider flexItem />
                          </>
                        )}

                      {!validators.isEmpty(result.curva?.pu_vna) && (
                        <>
                          <Stack flexGrow={1} direction="row" spacing={2}>
                            <Stack sx={{ width: "150px" }}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                VNA:
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.curva?.pu_vna,
                                  6,
                                  true
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.curva?.pu_vna * payload.quantidade,
                                  2,
                                  true
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result.juros?.pu) && (
                        <>
                          <Stack flexGrow={1} direction="row" spacing={2}>
                            <Stack sx={{ width: "150px" }}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                Juros:
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.juros?.pu,
                                  6,
                                  true
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.juros?.pu * payload.quantidade,
                                  2,
                                  true
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result.curva?.pu_par) && (
                        <>
                          <Stack flexGrow={1} direction="row" spacing={2}>
                            <Stack sx={{ width: "150px" }}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                Par:
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.curva?.pu_par,
                                  6,
                                  true
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.curva?.pu_par * payload.quantidade,
                                  2,
                                  true
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result.operacao?.pu_agiodesagio) && (
                        <>
                          <Stack flexGrow={1} direction="row" spacing={2}>
                            <Stack sx={{ width: "150px" }}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                Ágio / Deságio:{" "}
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.operacao?.pu_agiodesagio,
                                  6,
                                  true
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              flexGrow={1}
                              flexBasis={1}
                              alignItems="flex-end"
                            >
                              <Typography variant="caption">
                                {formats.formatNumber(
                                  result.operacao?.pu_agiodesagio *
                                    payload.quantidade,
                                  2,
                                  true
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Card>

                <Card>
                  <Stack alignItems="flex-start" spacing={1} p={2} flexGrow={1}>
                    <Stack spacing={1} sx={{ my: 2, width: "100%" }}>
                      {!validators.isEmpty(result.operacao?.cotacao) && (
                        <>
                          <Stack flexGrow={1}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                Cotação:
                              </Typography>
                              <Typography variant="caption">
                                {`${formats.formatNumber(
                                  result.operacao?.cotacao,
                                  6,
                                  true
                                )}%`}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result.analise?.prazo_medio) && (
                        <>
                          <Stack flexGrow={1}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                Prazo Médio:
                              </Typography>
                              <Typography variant="caption">
                                {result.analise?.prazo_medio}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(result.analise?.duration) && (
                        <>
                          <Stack flexGrow={1}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                Duration:
                              </Typography>
                              <Typography variant="caption">
                                {`${formats.formatNumber(
                                  result.analise?.duration,
                                  6,
                                  true
                                )}`}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(
                        result.analise?.duration_modificada
                      ) && (
                        <>
                          <Stack flexGrow={1}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                variant="caption"
                                sx={{ fontWeight: 700 }}
                              >
                                Duration Modificada
                              </Typography>
                              <Typography variant="caption">
                                {`${formats.formatNumber(
                                  result.analise?.duration_modificada,
                                  6,
                                  true
                                )}`}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider flexItem />
                        </>
                      )}

                      {!validators.isEmpty(
                        result.analise?.duration_modificada_sensibilidade
                      ) && (
                        <Stack flexGrow={1}>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 700 }}
                            >
                              Dur. Mod. Sensibilidade:
                            </Typography>
                            <Typography variant="caption">
                              {`${formats.formatNumber(
                                result.analise
                                  ?.duration_modificada_sensibilidade,
                                6,
                                true
                              )}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Stack>
            <Eventos eventos={result.eventos} data={payload.data} />
          </>
        )}

        <CurvaHistorico onClickRepeat={handleRepeat} />
      </Stack>
    </BaseTemplate>
  );
});

Curva.displayName = "Curva";

export default withResponseHandling(Curva);
