import React, { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { validators } from "investira.sdk";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "investira.react.components";
import { VARIACOES_ACUMULADAS } from "@investirapri/financa.js/lib/enums";

const VaricaoAcumuladaSelect = memo((props) => {
  let indicadores = useSelector((state) => state.indicadores.data);

  function options(pIndicadores, pTipoId = "C", pIndicadorId = "IPCA") {
    if (validators.isEmpty(pIndicadores)) {
      return [];
    }

    const xOptions = {};

    const xIndicadores = pIndicadores[pTipoId].filter((xItem) => {
      return (
        xItem.indicador_id === pIndicadorId &&
        !validators.isEmpty(xItem?.fator_calculo?.variacoes_acumuladas)
      );
    });

    xIndicadores.forEach((xItem) => {
      const xVariacoes = xItem?.fator_calculo?.variacoes_acumuladas.split(",");

      if (validators.isEmpty(xVariacoes)) {
        return [];
      }

      xOptions[xItem.indicador_id] = [
        { label: "Sem aniversário", value: "null" },
        ...xVariacoes.map((xVar) => {
          const xKey = String(xVar).toUpperCase();
          const xVariacao = VARIACOES_ACUMULADAS[xKey];

          return {
            label: `Dia ${xVariacao?.dia_base} ${
              xVariacao?.util ? "(útil)" : ""
            }`,
            value: xVariacao?.id,
          };
        }),
      ];
    });

    return xOptions[pIndicadorId];
  }

  const handleChange = (event) => {
    props.onChange && props.onChange(event);
  };

  return (
    <FormControl fullWidth sx={{ minWidth: "180px" }}>
      <InputLabel shrink={true} id="variacoes-select-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="variacoes-select-label"
        id={props.name}
        defaultValue={props.defaultValue}
        value={props.value}
        label={props.label}
        name={props.name}
        onChange={handleChange}
        disabled={props.disabled}
      >
        {options(indicadores, props.tipoId, props.indicadorId).map((xItem) => {
          return (
            <MenuItem key={xItem.value} value={xItem.value}>
              {xItem.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});

VaricaoAcumuladaSelect.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  tipoId: PropTypes.oneOf(["M", "C", "I", "J"]),
};

VaricaoAcumuladaSelect.defaultProps = {};

VaricaoAcumuladaSelect.displayName = "VaricaoAcumuladaSelect";

export default VaricaoAcumuladaSelect;
