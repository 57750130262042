import React, { memo, useEffect, useState } from "react";
import services from "../../../../services";
import { Stack, CircularProgress } from "investira.react.components";
import { InlineInfo } from "../../../../components/molecules";
const EmpresaSetores = memo((props) => {
  const [setores, setSetores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    services.pessoas.setores(
      { pessoa_id: props.pessoaId },
      (rRes) => {
        console.log(rRes);
        setSetores(rRes.data);
      },
      (rErr) => {
        console.log(rErr);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [props.pessoaId]);

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ py: 2 }}
      >
        <CircularProgress size={24} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={2}>
      {setores.map((setor, index) => {
        return (
          <InlineInfo
            key={index}
            label={setor.top_setor}
            value={setor.setor}
            searchProps={{
              path: "/empresas",
              search: {
                top_setor_id: setor.top_setor_id,
                setor_id: setor.setor_id,
                pessoa_tipo_id: "17061205",
              },
            }}
          />
        );
      })}
    </Stack>
  );
});

export default EmpresaSetores;
