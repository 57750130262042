import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Stack,
  Typography,
  Icon,
  Menu,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Persona,
} from "investira.react.components";
import { EntidadeAvatar } from "../../molecules";
import services from "../../../services";
import withResponseHandling from "../../../hoc/withResponseHandling";

const EntidadeShortcut = (props) => {
  const entidade = useSelector((state) => state.entidade);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const hasMenu = entidade.data.length > 1;

  function trocaEntidade(pEntidadeId) {
    services.user.entidade(pEntidadeId, null, (rErr) => {
      props.responseErrorHandling(rErr);
    });
  }

  const hideCurrentEntidade = (pEntidades, pCurrentEntidadeId) => {
    return pEntidades.filter((xEntidade) => {
      return xEntidade.entidade_id !== pCurrentEntidadeId;
    });
  };

  function handleEntidadeClick(pEntidadeId) {
    trocaEntidade(pEntidadeId);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!hasMenu) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          px: 1,
          py: 1,
        }}
      >
        {/* <Icon iconName="parthenon" size={12} color="secondaryLightness" /> */}
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontWeight: 600 }}
        >
          {entidade.current.entidade}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          cursor: "pointer",
          px: { xs: 0.5, sm: 1 },
          py: { xs: 0.5, sm: 1 },
          borderRadius: 2,
          "&:hover": {
            backgroundColor: "#2e2e46",
          },
        }}
        onClick={handleClick}
      >
        <Typography
          variant="caption"
          color="textPrimary"
          sx={{
            fontWeight: 600,
            maxWidth: { xs: "80px", sm: "100%" },
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {entidade.current.entidade}
        </Typography>
        <Icon
          iconName={open ? "arrow-up" : "arrow-down"}
          size={11}
          color="primary"
        />
      </Stack>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {hideCurrentEntidade(
          entidade.data,
          entidade.current.default_entidade_id
        ).map((xEntidade, xIndex) => {
          return (
            <MenuItem
              key={`${xEntidade.entidade}-${xIndex}`}
              onClick={() => handleEntidadeClick(xEntidade.entidade_id)}
            >
              <ListItemAvatar>
                <EntidadeAvatar
                  alt={xEntidade.entidade}
                  sx={{ width: 24, height: 24 }}
                  sizeLetters={1}
                  entidadeId={xEntidade.entidade_id}
                />
              </ListItemAvatar>
              <ListItemText
                primary={xEntidade.entidade}
                primaryTypographyProps={{ variant: "caption" }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

EntidadeShortcut.propTypes = {};

EntidadeShortcut.displayName = "EntidadeShortcut";

export default withResponseHandling(EntidadeShortcut);
