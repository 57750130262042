import React, { memo } from "react";
import PropTypes from "prop-types";
import { browsers } from "investira.react.lib";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Box,
  PasswordField,
  Stack,
  LoadingButton,
} from "investira.react.components";

import { BaseTemplate } from "../../components/templates";
import withResponseHandling from "../../hoc/withResponseHandling";
import withMessage from "../../hoc/withMessage";
import services from "../../services";

const CHANGE_PASSWORD_SCHEMA = Yup.object().shape({
  registerPassword: Yup.string()
    .matches(/^(?=.*[a-z])/, "Ao menos uma letra minúscula")
    .matches(/^(?=.*[A-Z])/, "Ao menos uma letra maiúscula")
    .matches(/^(?=.*[0-9])/, "Ao menos um número")
    .min(6, "Muito curta")
    .required("Obrigatório"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("registerPassword")], "A senhas devem ser iguais")
    .required("Obrigatório"),
});

const ChangePassword = memo((props) => {
  // Methods

  function changePassword(pValues, pActions) {
    if (browsers.isOnline()) {
      const xReqAttrs = {
        data: {
          password: pValues.registerPassword,
          password_confirm: pValues.confirmPassword,
        },
      };

      services.user.updatePassword(
        xReqAttrs,
        (_rRes) => {
          props.onMessageSuccess("Senha alterada!");
          pActions.resetForm();
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          pActions.setSubmitting(false);
        }
      );
    } else {
      props.offline();
    }
  }

  // Handlers

  function handleSubmit(pValues, pActions) {
    changePassword(pValues, pActions);
  }

  // Render

  return (
    <BaseTemplate title="Alterar Senha" maxWidth="sm">
      <Formik
        initialValues={{
          registerPassword: "",
          confirmPassword: "",
        }}
        validationSchema={CHANGE_PASSWORD_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting,
        }) => {
          return (
            <Form noValidate>
              <Stack spacing={3}>
                <PasswordField
                  name="registerPassword"
                  label="Nova Senha"
                  variant="outlined"
                  autoComplete={"new-password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.registerPassword}
                  error={
                    errors.registerPassword && touched.registerPassword
                      ? true
                      : false
                  }
                  helperText={errors.registerPassword}
                  fullWidth={true}
                  disabled={isSubmitting}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <PasswordField
                  variant="outlined"
                  name="confirmPassword"
                  label="Confirme a Nova Senha"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete={"new-password"}
                  value={values.confirmPassword}
                  error={
                    errors.confirmPassword && touched.confirmPassword
                      ? true
                      : false
                  }
                  helperText={errors.confirmPassword}
                  fullWidth={true}
                  disabled={isSubmitting}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Box sx={{ my: 1.5, py: 1 }}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting || errors.confirmPassword
                        ? true
                        : false || values.confirmPassword === ""
                    }
                    loading={isSubmitting}
                    fullWidth
                  >
                    Alterar
                  </LoadingButton>
                </Box>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </BaseTemplate>
  );
});

ChangePassword.propTypes = {
  id: PropTypes.string,
  onBack: PropTypes.func,
  onOpenDialog: PropTypes.func,
  offline: PropTypes.func,
  responseErrorHandling: PropTypes.func,
};

ChangePassword.defaultProps = {
  id: "change-password",
};

ChangePassword.displayName = "ChangePassword";

export default withResponseHandling(withMessage(ChangePassword));
