import React from "react";
import PropTypes from "prop-types";
import { Stack } from "investira.react.components";
import {
  ReportProcessing,
  FilesProcessing,
  // PosicaoProcessing,
  EntidadeShortcut,
} from "../";

const StatusBar = (props) => {
  return (
    <Stack
      id="status-bar"
      flexGrow={1}
      sx={{
        height: `${props.height}px`,
        px: 2,
      }}
      direction="row"
      alignItems="center"
      spacing={3}
    >
      <EntidadeShortcut />
      <Stack flexGrow={1}></Stack>
      <FilesProcessing />
      <ReportProcessing />
    </Stack>
  );
};

StatusBar.propTypes = {
  height: PropTypes.number,
};

StatusBar.defaultProps = {
  height: 32,
};

export default StatusBar;
