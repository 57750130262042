import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { validators } from "investira.sdk";
import {
  Typography,
  Stack,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Icon,
} from "investira.react.components";

const NavButton = memo((props) => {
  const { label, nested, path, id } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const xBtnSx = { "& :hover": {}, textTransform: "initial", color: "white" };

  if (!validators.isEmpty(nested)) {
    return (
      <>
        <Button
          size="small"
          sx={xBtnSx}
          onClick={handleClick}
          endIcon={
            <Icon
              iconName={open ? "arrow-up" : "arrow-down"}
              size={12}
              color="primary"
            />
          }
        >
          <Typography variant="body2" component="span" sx={{ fontWeight: 500 }}>
            {label}
          </Typography>
        </Button>
        <Menu
          anchorEl={anchorEl}
          id={`${id}-menu`}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            component: "nav",
            elevation: 1,
            sx: {
              overflow: "visible",
              mt: 1.5,
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          keepMounted
        >
          {nested.map((xLink, xIndex) => {
            const xKey = crypto.randomUUID();
            return (
              <MenuItem key={xKey} component={Link} to={xLink.path}>
                <ListItemIcon>
                  <Icon iconName={xLink.icon} size={16} color="primary" />
                </ListItemIcon>
                {xLink.name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }

  return (
    <Button size="small" sx={xBtnSx} component={Link} to={path}>
      <Stack direction="row">
        <Typography variant="body2" component="span" sx={{ fontWeight: 500 }}>
          {label}
        </Typography>
      </Stack>
    </Button>
  );
});

NavButton.propTypes = {
  label: PropTypes.string,
  nested: PropTypes.array,
  path: PropTypes.string,
};

NavButton.displayName = "NavButton";

export default NavButton;
