import React from "react";
import PropTypes from "prop-types";
import { validators, formats, dates } from "investira.sdk";
import {
  Stack,
  Typography,
  Card,
  IconButton,
  Icon,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  CircularProgress,
  Toolbar,
  Paper,
  Success,
  Error,
} from "investira.react.components";

import { useUploadContext } from "../../../contexts/UploadContext";

import { UploadContextActions } from "../";

const Upload = (props) => {
  const { state, actions } = useUploadContext();

  function handleSendClick() {
    actions.sendFiles(null, null, props.onFinish);
  }

  const acceptedFileItems = state.files.map((xItem) => (
    <TableRow key={xItem.file.name}>
      <TableCell>{xItem.file.name}</TableCell>
      <TableCell>
        {formats.formatDateCustom(dates.toDate(xItem.file.lastModified), "LL")}
      </TableCell>
      <TableCell>{formats.friendlyByte(xItem.file.size)}</TableCell>
      <TableCell sx={{ width: "74px" }} align="center">
        <Stack direction="row">
          {xItem.status === "pending" ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 1 }}
            >
              <CircularProgress
                size={24}
                variant="indeterminate"
                value={xItem.progress}
              />
            </Stack>
          ) : xItem.status === "uploaded" ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 1 }}
            >
              <Success width={24} height={24} />
            </Stack>
          ) : xItem.status === "error" ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 1 }}
            >
              <Error width={24} height={24} />
            </Stack>
          ) : (
            <IconButton
              aria-label="delete"
              onClick={() => actions.handleCleanFiles(xItem.file.name)}
            >
              <Icon iconName="delete" color="error" size={24} />
            </IconButton>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  ));

  return (
    <Stack
      id="upload"
      component="section"
      // {...actions.getRootProps({})}
      flexGrow={1}
      sx={{ height: "100%" }}
    >
      {/* {!validators.isEmpty(state.files) && (
        <UploadContextActions
          isUploading={state.isUploading}
          onCancelClick={actions.handleCleanFiles}
          onSendClick={handleSendClick}
          onAddClick={actions.open}
        />
      )} */}
      {state.isOnDrag && (
        <Stack
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          sx={{
            border: "2px dashed #7A81B3",
            borderRadius: 3,
            height: "200px",
            ...(state.isFocused ? { borderColor: "#00dfa8" } : {}),
          }}
        >
          <Stack>
            {/* <input {...actions.getInputProps()} /> */}
            <Typography variant="body1" color="textPrimary" align="center">
              Arraste e solte alguns arquivos aqui
            </Typography>
            <Typography
              component="p"
              variant="caption"
              color="textPrimary"
              align="center"
            >
              (Somente serão aceitos arquivos .xml)
            </Typography>
          </Stack>
        </Stack>
      )}
      {!state.isOnDrag && !validators.isEmpty(state.files) && (
        <Stack>
          <Paper>
            <Toolbar sx={{ width: "100%" }}>
              <UploadContextActions
                isUploading={state.isUploading}
                onCancelClick={actions.handleCleanFiles}
                onSendClick={handleSendClick}
                onAddClick={actions.open}
              />
            </Toolbar>
            <TableContainer component={Card} sx={{ pb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Última modificação</TableCell>
                    <TableCell>Tamanho</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{acceptedFileItems}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Stack>
      )}
      {!(state.isOnDrag || !validators.isEmpty(state.files)) && props.children}
    </Stack>
  );
};

Upload.propTypes = {
  onStart: PropTypes.func,
  onEnd: PropTypes.func,
};

export default Upload;
