import React, { memo } from "react";
import PropTypes from "prop-types";
import { validators } from "investira.sdk";

const VerifyValue = memo((props) => {
  console.log(props);
  const { value, content } = props;

  console.log(value);

  if (validators.isEmpty(value)) {
    return null;
  }

  return content;
  //return children;
});

VerifyValue.propTypes = {
  //value: PropTypes.any,
};

VerifyValue.displayName = "VerifyValue";

export default VerifyValue;
