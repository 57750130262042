import React, { memo, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { dates, validators, formats } from "investira.sdk";
import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  TextField,
  LoadingButton,
  MenuItem,
  Skeleton,
  InputAdornment,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Chip,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "investira.react.components";

import { VALIDATIONS } from "../../const";
import { useSimulacoesContext } from "../../contexts/SimulacoesContext";
import services from "../../services";
import withResponseHandling from "../../hoc/withResponseHandling";
import withCarteiras from "../../hoc/withCarteiras";

//TODO: add loading

const DialogCloneCarteira = memo((props) => {
  const { state, actions } = useSimulacoesContext();
  const [simulada, setSimulada] = useState(0);
  const [datas, setDatas] = useState([]);
  const params = useParams();

  const isEdit = !validators.isNull(state.posicaoSelected.id);
  const isOpen = state.isDialogOpen && state.dialogType === "clone";

  const xInitialValues = {
    carteira_id: "",
    data: new Date(),
  };

  const formik = useFormik({
    initialValues: xInitialValues,
    validationSchema: Yup.object().shape({
      carteira_id: Yup.string().required(VALIDATIONS.REQUIRED),
      data: Yup.date()
        .required(VALIDATIONS.REQUIRED)
        .typeError(`${VALIDATIONS.INVALID_DATE}`),
    }),
    onSubmit: (pValues, pActions) => {
      actions.readPosicoes(
        {
          carteira_id: pValues.carteira_id,
          data: pValues.data,
        },
        (rRes) => {
          actions.savePosicoes(
            { carteira_id: params.id, ativos: rRes.data },
            () => {
              pActions.resetForm();
              actions.handleClonePosicaoCancel();
            },
            (rErr) => {
              props.responseErrorHandling(rErr);
            },
            null
          );
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          pActions.setSubmitting(false);
        }
      );
    },
  });

  const xCarteiraDefaultValue = useMemo(
    () => ({ carteira: formik.values.carteira }),
    [formik.values.carteira]
  );

  const handleSimuladaChange = (event) => {
    setSimulada(event.target.value);
  };

  const handleCarteiraClick = (pFieldName, pFieldValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  const handleClose = () => {
    actions.handleClonePosicaoCancel();
  };

  function filterCarteira(pCarteiras, pSelectedCarteiraId, pRemoveCarteiraId) {
    let xCarteiras = pCarteiras;

    if (pRemoveCarteiraId) {
      xCarteiras = xCarteiras.filter((xCarteira) => {
        return xCarteira.carteira_id !== pRemoveCarteiraId;
      });
    }

    if (pSelectedCarteiraId) {
      return xCarteiras.filter((xCarteira) => {
        return xCarteira.carteira_id === pSelectedCarteiraId;
      });
    }

    return xCarteiras;
  }

  useEffect(() => {
    // EDIT
    if (isEdit) {
      formik.setValues(state.posicaoSelected);
    } else {
      formik.setValues(xInitialValues);
    }
  }, [isEdit, state.posicaoSelected]);

  useEffect(() => {
    if (!validators.isEmpty(formik.values.carteira_id)) {
      services.posicoes.datas(
        {
          sort: "data DESC",
          carteira_id: formik.values.carteira_id,
        },
        (rRes) => {
          const xDates = rRes.data.map((xPosicao) => {
            return {
              value: xPosicao.data,
              label: formats.formatDateCustom(
                dates.toDate(xPosicao.data),
                "DD/MM/YYYY"
              ),
            };
          });
          setDatas(xDates);
          formik.setFieldValue("data", xDates[0].value);
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        }
      );
    }
  }, [isOpen, formik.values.carteira_id]);

  useEffect(() => {
    if (isOpen) {
      const SIMULADA = [null, true, false];
      setDatas([]);
      handleCarteiraClick("carteira_id", "");
      props.readCarteiras({ simulada: SIMULADA[simulada] });
    }
  }, [isOpen, simulada]);

  return (
    <Dialog open={isOpen} maxWidth="lg" fullWidth onClose={handleClose}>
      <DialogTitle>Copiar Posição</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 24,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <Icon iconName="cancel" size={16} />
      </IconButton>
      <DialogContent>
        <Stack sx={{ marginBottom: 2, py: 1 }} spacing={2}>
          <Stack spacing={2} flex="1">
            {validators.isEmpty(formik.values.carteira_id) ? (
              <Stack>
                <Stack>
                  <Typography variant="body2" color="textSecondary">
                    Carteiras
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <FormControl>
                    <RadioGroup
                      row
                      name="simulada"
                      value={simulada}
                      onChange={handleSimuladaChange}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Todas"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Simuladas"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="Reais"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Divider />
              </Stack>
            ) : (
              <Stack>
                <Typography variant="body2" color="textSecondary">
                  Carteira
                </Typography>
              </Stack>
            )}
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack mb={2}>
                <Stack flex="1">
                  <List sx={{ marginBottom: 2 }}>
                    {filterCarteira(
                      props.carteiras,
                      formik.values.carteira_id,
                      params.id
                    ).map((xCarteira) => {
                      return (
                        <ListItemButton
                          key={xCarteira.carteira_id}
                          onClick={() =>
                            handleCarteiraClick(
                              "carteira_id",
                              xCarteira.carteira_id
                            )
                          }
                          secondaryAction={
                            formik.values.carteira_id && (
                              <IconButton
                                color="primary"
                                onClick={(pEvent) => {
                                  pEvent.preventDefault();
                                  pEvent.stopPropagation();
                                  handleCarteiraClick("carteira_id", "");
                                }}
                              >
                                <Icon iconName="cancel" />
                              </IconButton>
                            )
                          }
                        >
                          <ListItemText
                            primary={xCarteira.carteira_seuid}
                            secondary={
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Typography variant="caption">
                                  {xCarteira.carteira}
                                </Typography>
                                {xCarteira.simulada && (
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    label={"Simulada"}
                                  />
                                )}
                              </Stack>
                            }
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Stack>
                <Stack flex="1">
                  {!validators.isEmpty(formik.values.carteira_id) && (
                    <Stack flexGrow={1} flexBasis={1}>
                      {validators.isEmpty(dates) ? (
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          height={32}
                        />
                      ) : (
                        <TextField
                          id="data-posicao"
                          name="data_posicao"
                          label="Posição de"
                          variant="outlined"
                          value={formik.values.data}
                          onChange={formik.handleChange}
                          select
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon iconName="calendar" size={21} />
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(formik.errors.data)}
                          helperText={formik.errors.data}
                        >
                          {datas.map((xOption) => (
                            <MenuItem key={xOption.value} value={xOption.value}>
                              {xOption.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Stack>
                  )}
                </Stack>
              </Stack>

              {!validators.isEmpty(formik.values.carteira_id) && (
                <Stack
                  alignItems="center"
                  justifyContent="flex-end"
                  direction="row"
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting || formik.initialValid}
                  >
                    Copiar
                  </LoadingButton>
                </Stack>
              )}
            </form>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
});

export default withCarteiras(withResponseHandling(DialogCloneCarteira));
