import React, { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { MasterTemplate } from "../../../components/templates";

const PublicSession = memo((props) => {
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);

  return (
    <MasterTemplate key="master" isLoggedIn={isLoggedIn}>
      {props.children}
    </MasterTemplate>
  );
});

PublicSession.propTypes = {};

export default PublicSession;
