import React, { memo, useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { validators, strings, objects } from "investira.sdk";
import { displays } from "investira.react.lib";
import { ATIVO_MERCADO } from "@investirapri/financa.js/lib/enums";
import {
  Stack,
  CircularProgress,
  TextField,
  InputAdornment,
  Icon,
  Popover,
  Backdrop,
  Typography,
  Divider,
  IconButton,
  Button,
  Skeleton,
  Badge,
  Chip,
} from "investira.react.components";
import services from "../../../services";
import utils from "../../../utils";
import { formats } from "investira.sdk";
import withResponseHandling from "../../../hoc/withResponseHandling";
import { acAtivosSearchChanged } from "../../../store/actions";
import { PessoaImage } from "../../molecules";
import { PATHS } from "../../../enums";
import BuscaAtivoFilter from "./BuscaAtivoFilter";

const BuscaAtivo = memo((props) => {
  const PAGE_SIZE = 10;
  const ativosSearch = useSelector((store) => store.ativos.search);

  const { mercado } = ativosSearch;

  const location = useLocation();
  const navigate = useNavigate();

  const storeDispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState({
    mercado: mercado,
  });

  const [countFilters, setCountFilters] = useState(
    Object.keys(ativosSearch).length
  );
  const [isLoading, setLoading] = useState(true);
  const [pesquisa, setPesquisa] = useState("");
  const [data, setData] = useState({});
  const [pages, setPages] = useState({});

  const anchorRef = useRef();

  const open = Boolean(anchorEl);

  const TOTAL_ITEMS = pages.total_items;

  const handleOpen = (pEvent) => {
    setAnchorEl(pEvent.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenFilters = () => {
    setOpenFilters(true);
  };

  const handleCloseOpenFilters = () => {
    setOpenFilters(false);
  };

  const handleChange = (pEvent) => {
    const xValue = pEvent.currentTarget.value;

    setPesquisa(pEvent.currentTarget.value);
    handleOpen(pEvent);

    if (validators.isEmpty(xValue)) {
      handleClose();
      return;
    }
  };

  const handleFocus = (pEvent) => {
    if (!validators.isEmpty(data) && !validators.isEmpty(pesquisa) && !open) {
      handleOpen(pEvent);
    }
  };

  const handleClearPesquisa = (pLocation) => {
    setPesquisa("");
    setData({});
    setPages({});

    const xSearch = strings.querystringToObject(pLocation.search);

    if (!validators.isEmpty(xSearch.pesquisa)) {
      delete xSearch.pesquisa;
      const xQuery = objects.objectToQueryString(xSearch);
      navigate(`/empresas${xQuery}`);
    }
  };

  const handleGoTo = (pEvent, pPessoaId, pMercado) => {
    pEvent.preventDefault();
    pEvent.stopPropagation();

    const xPath = PATHS[pMercado.toLowerCase()].path;

    handleClose();
    navigate(`${xPath}/${pPessoaId}`);
  };

  const handleSearch = (pPesquisa, pFilters) => {
    const xFilters = objects.deleteNull({ ...pFilters });

    const xSearch = { pesquisa: pPesquisa, ...xFilters };
    const xSearchQueryString = objects.objectToQueryString(xSearch);
    const xUrl = `/ativos${xSearchQueryString}`;

    handleClose();
    navigate(xUrl);
  };

  const handleUpdateFilters = (pObjFilters, pAnchorRef, pPesquisa) => {
    const xFilters = {};
    const { mercado } = pObjFilters;

    filtersSize(pObjFilters);

    xFilters["mercado"] = mercado;
    // xFilters["pessoa_tipo_id"] = somaTiposSelected({ ...tipos });

    setFilters((prevFilters) => {
      const xNewFilters = {
        ...prevFilters,
        ...xFilters,
      };

      storeDispatch(acAtivosSearchChanged({ ...xNewFilters }));

      return {
        ...prevFilters,
        ...xFilters,
      };
    });

    !validators.isEmpty(pPesquisa) && setAnchorEl(pAnchorRef.current);
  };

  function filtersSize(pObj) {
    const xFiltersSize = Object.keys(pObj).length;
    setCountFilters(xFiltersSize);
  }

  function verifySpecialChar(pStr) {
    const regex = /[./-]/;
    return regex.test(pStr);
  }

  function cleanSpecialChar(pStr) {
    const regex = /[./-]/g;
    if (verifySpecialChar(pStr)) {
      return pStr.replace(regex, "");
    }

    return pStr;
  }

  function isNumeric(pStr) {
    if (typeof pStr !== "string") {
      return false;
    }

    return /^[0-9]+$/.test(pStr);
  }

  const request = useMemo(() => {
    return utils.helpers.debounce(
      (pParams, pRes, pErr, pFin) =>
        services.ativos.list(pParams, pRes, pErr, pFin),
      500
    );
  }, []);

  function formatSearch(pValue) {
    let xValue = cleanSpecialChar(pValue);

    let xStrings = xValue.split(" ");
    xStrings = xStrings.map((xString) => {
      return `+${xString}*`.trim();
    });

    const xSearchFormated = xStrings.join("");

    return xSearchFormated;
  }

  useEffect(() => {
    if (pesquisa && pesquisa.length > 1 && !props.pageIsLoading) {
      setLoading(true);

      const xPesquisa = formatSearch(pesquisa);

      request(
        {
          pesquisa: xPesquisa,
          size: PAGE_SIZE,
          cancelado: "null",
          ...(!isNumeric(xPesquisa) && { ...filters }),
          sort: "relevancia DESC, __mr__ DESC, mercado ASC",
          fracionario: 0,
          prazo: "VST",
        },
        (rRes) => {
          setData(rRes.data);
          setPages(rRes.pages);
        },
        (rErr) => {
          props.responseErrorHandling(rErr);
        },
        () => {
          setTimeout(() => setLoading(false), 500);
        }
      );
    }
  }, [pesquisa, request, filters]);

  return (
    <>
      <Stack>
        <Stack sx={{ position: "relative", minWidth: "400px", zIndex: "999" }}>
          {props.pageIsLoading ? (
            <Skeleton variant="rounded" width="100%" height={56} />
          ) : (
            <TextField
              id="busca-ativo"
              inputRef={anchorRef}
              value={pesquisa}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleClose}
              variant="outlined"
              placeholder={"Pesquise por ativos (Ações, Fundos, CDBs...)"}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon iconName="find" size={21} color={"primary"} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Stack direction="row">
                    <InputAdornment position="end">
                      {!validators.isEmpty(pesquisa) && (
                        <IconButton
                          onClick={() => handleClearPesquisa(location)}
                          size="small"
                        >
                          <Icon
                            iconName="cancel"
                            size={12}
                            color="secondaryLightness"
                          />
                        </IconButton>
                      )}
                      <Divider orientation="vertical" />
                      <Badge
                        badgeContent={countFilters}
                        color="warning"
                        overlap="circular"
                      >
                        <IconButton onClick={handleClickOpenFilters}>
                          <Icon iconName="filter" size={21} color={"primary"} />
                        </IconButton>
                      </Badge>
                    </InputAdornment>
                  </Stack>
                ),
              }}
              disabled={props.pageIsLoading}
            />
          )}
        </Stack>
        <Popover
          id="popover-busca-empresa"
          disableAutoFocus={true}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -16,
            horizontal: "center",
          }}
          sx={{ minWidth: "592px", maxHeight: "420px" }}
        >
          <Stack sx={{ minWidth: "592px" }}>
            {isLoading && (
              <Stack
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                sx={{ py: 2 }}
              >
                <CircularProgress size={24} />
              </Stack>
            )}

            {!isLoading && !validators.isEmpty(data) && (
              <>
                <Stack id="busca-empresa-list" sx={{ px: 0 }} component="ul">
                  {Object.values(data).map((xAtivo, xIndex) => {
                    return (
                      <Stack
                        id={xAtivo.ativo_id}
                        key={xAtivo.ativo_id}
                        direction="row"
                        spacing={3}
                        component="li"
                        onClick={(e) =>
                          handleGoTo(e, xAtivo.ativo_id, xAtivo.mercado)
                        }
                        sx={{
                          px: 2,
                          py: 2,
                          cursor: "pointer",
                          borderBottom: "1px solid",
                          borderBottomColor: "background.main",
                          "&:hover": {
                            color: "gray",
                            backgroundColor: "background.main",
                          },
                        }}
                      >
                        <Stack>
                          <PessoaImage
                            logoPessoaId={xAtivo.logo_pessoa_id}
                            alt={xAtivo.pessoa}
                            hasLogo={xAtivo.logo_ok}
                            size={36}
                          />
                        </Stack>
                        <Stack flexGrow={1} spacing={1}>
                          <Stack direction="row" spacing={3}>
                            <Stack flexGrow={1}>
                              <Typography variant="body2" color="textPrimary">
                                {xAtivo.ticker ||
                                  xAtivo.ativo_seuid ||
                                  xAtivo.ativo}
                              </Typography>
                              <Stack direction="row" spacing={1}>
                                {(xAtivo.ativo || xAtivo.isin) && (
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {xAtivo.ativo || xAtivo.isin}
                                  </Typography>
                                )}
                              </Stack>
                            </Stack>
                            <Stack>
                              <Chip
                                label={ATIVO_MERCADO[xAtivo.mercado].descricao}
                                size="small"
                                color="primary"
                              />
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{ gap: "8px 8px" }}
                            flexWrap="wrap"
                            direction="row"
                          >
                            {xAtivo.emissor_cnpj && (
                              <Chip
                                label={displays.cnpj(xAtivo.emissor_cnpj)}
                                size="small"
                                color="secondary"
                              />
                            )}

                            {xAtivo.isin && (
                              <Chip
                                label={xAtivo.isin}
                                size="small"
                                color="secondary"
                              />
                            )}
                            {xAtivo.vencimento && (
                              <Chip
                                label={`Venc.: ${formats.formatDate(
                                  xAtivo.vencimento
                                )}`}
                                size="small"
                                color="secondary"
                              />
                            )}
                            {xAtivo.serie && (
                              <Chip
                                label={`Série: ${xAtivo.serie}`}
                                size="small"
                                color="secondary"
                              />
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
                <Stack sx={{ px: 2, py: 2 }}>
                  <Button
                    variant="contained"
                    onClick={() => handleSearch(pesquisa, filters)}
                  >
                    Visualizar Todos ({TOTAL_ITEMS})
                  </Button>
                </Stack>
              </>
            )}

            {!isLoading && validators.isEmpty(data) && (
              <Stack
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                sx={{ py: 2 }}
              >
                <Typography variant="body2" color="textPrimary">
                  Nenhuma empresa encontrada
                </Typography>
              </Stack>
            )}
          </Stack>
        </Popover>
      </Stack>
      <Backdrop open={open} />
      <BuscaAtivoFilter
        open={openFilters}
        onSubmit={(pObjFilters) =>
          handleUpdateFilters(pObjFilters, anchorRef, pesquisa)
        }
        onClose={handleCloseOpenFilters}
      />
    </>
  );
});

export default withResponseHandling(BuscaAtivo);
