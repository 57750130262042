import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from "investira.react.components";

import { ATIVO_MERCADO } from "@investirapri/financa.js/lib/enums";

const BuscaAtivoFilter = memo((props) => {
  const { open, onClose, onSubmit } = props;

  const ativosSearch = useSelector((store) => store.ativos.search);

  const { mercado } = ativosSearch;

  function sortAndFilterValues(
    pValues = [],
    pSortKey = "descricao",
    pRemoveValues = []
  ) {
    const xValues = Object.values(pValues).filter((xValue) => {
      return !pRemoveValues.includes(xValue.id);
    });

    return Object.values(xValues).sort((a, b) => {
      if (a[pSortKey] < b[pSortKey]) {
        return -1;
      }
      if (a[pSortKey] > b[pSortKey]) {
        return 1;
      }

      return 0;
    });
  }

  function cleanUpObject(pObj) {
    return Object.fromEntries(
      Object.entries(pObj).filter(([_, pValue]) => pValue !== "0")
    );
  }

  function handleSubmit(pEvent) {
    pEvent.preventDefault();

    const xFormData = new FormData(pEvent.currentTarget);
    const xFormJson = Object.fromEntries(xFormData.entries());
    const xCleanedJson = cleanUpObject(xFormJson);

    onSubmit(xCleanedJson);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle id="alert-dialog-title">Filtrar por:</DialogTitle>
      <DialogContent sx={{ paddingTop: "8px !important" }}>
        <Stack spacing={2} sx={{ position: "relative" }}>
          <TextField
            id="mercado"
            name="mercado"
            variant="outlined"
            select
            label="Mercado"
            defaultValue={mercado || 0}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value={0}>Todos os mercados</MenuItem>
            {sortAndFilterValues(ATIVO_MERCADO, "descricao", [
              "POU",
              "CNT",
              "PRV",
              "FGT",
              "IMV",
              "VEI",
              "CAR",
              "MOE",
              "AGR",
              "MTL",
              "PDR",
              "ART",
              "FDL",
              "BEM",
              "SEG",
              "CBL",
            ]).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.descricao}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button type="submit" autoFocus>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default BuscaAtivoFilter;
