import { RELATORIOS_CREATE } from "../const/servicesURI";
import { httpRequests } from "investira.sdk";
import requestService from "./requestService";
import utils from "../utils";

import { mockRequest } from "investira.react.lib";

const DATA = {
  relatorio: "relatorio-1233456689-xyz",
  data_criacao: "30 Jun 2023",
  params: ["param 1", "param 1", "param 1", "param 1"].join(),
  url_preview: `${process.env.PUBLIC_URL}/mock/duration.pdf`,
  url_download: `${process.env.PUBLIC_URL}/mock/duration.pdf`,
  filename: "duration.pdf",
};

export const cancel = {
  create: null,
};

export const create = (pProps = {}, pResolve, pReject, pFinally) => {
  if (cancel.create) {
    cancel.create.cancel();
  }

  cancel.create = httpRequests.cancelToken();

  const xProps = {
    params: pProps.params,
    cancelToken: cancel.create.token,
  };

  mockRequest(DATA)
    .then((response) => response.json())
    .then((data) => {
      return pResolve && pResolve(data);
    })
    .catch((error) => {
      utils.request.tracer(error, "Error Service Report Create");
      return pReject && pReject(error);
    })
    .finally(() => {
      return pFinally && pFinally();
    });

  // return requestService(xProps, `${RELATORIOS_CREATE}${pProps.url}`, 3, 2400)
  //   .then((rRes) => {
  //     return pResolve && pResolve(rRes);
  //   })
  //   .catch((rErr) => {
  //     utils.request.tracer(rErr, "Error Service Report Create");
  //     return pReject && pReject(rErr);
  //   })
  //   .finally(() => {
  //     pFinally && pFinally();
  //   });
};

export const duration = (pProps = {}, pResolve, pReject, pFinally) => {
  return create(pProps, pResolve, pReject, pFinally);
};

export const enquadramento = (pProps = {}, pResolve, pReject, pFinally) => {
  return create(pProps, pResolve, pReject, pFinally);
};

export const risco = (pProps = {}, pResolve, pReject, pFinally) => {
  return create(pProps, pResolve, pReject, pFinally);
};

export const raiox = (pProps = {}, pResolve, pReject, pFinally) => {
  return create(pProps, pResolve, pReject, pFinally);
};

const reports = { create, duration, cancel };

export default reports;
