import { NOTIFICACAO_LIST, NOTIFICACAO_RESPONDER } from "../const/servicesURI";
import requestService from "./requestService";
import utils from "../utils";

export const list = (pProps, pResolve, pReject) => {
  const xProps = {
    size: 100,
    ...pProps,
    method: "get",
  };

  return requestService(xProps, NOTIFICACAO_LIST, 3)
    .then((rRes) => {
      return pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Notificacoes List");
      return pReject(rErr);
    });
};

export const reply = (pProps, pResolve, pReject) => {
  const xProps = {
    ...pProps,
    method: "post",
  };
  return requestService(xProps, NOTIFICACAO_RESPONDER)
    .then((rRes) => {
      pResolve(rRes);
    })
    .catch((rErr) => {
      utils.request.tracer(rErr, "Error Service Notificacoes Reply");
      pReject(rErr);
    });
};

const notificacoes = { list, reply };

export default notificacoes;
