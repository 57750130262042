const ORIGEM = {
  MAN: { id: "MAN", descricao: "Manual" },
  B3: { id: "B3", descricao: "B3" },
  BC: { id: "BC", descricao: "Banco Central" },
  CVM: { id: "CVM", descricao: "CVM" },
  TES: { id: "TES", descricao: "Tesouro" },
  IBG: { id: "IBG", descricao: "IBGE" },
  ANB: { id: "ANB", descricao: "Anbima" },
  DEB: { id: "DEB", descricao: "Debentures" },
  FNP: { id: "FNP", descricao: "Finep" },
  FIP: { id: "FNP", descricao: "Fipe" },
  BND: { id: "BND", descricao: "BNDES" },
  FNE: { id: "FNE", descricao: "FUNDO.NET" },
  RF: { id: "RF", descricao: "Receita Federal" },
  UPL: { id: "UPL", descricao: "Upload" },
  SIS: { id: "SIS", descricao: "Sistema" },
  USR: { id: "USR", descricao: "Usuário" },
};

export default ORIGEM;
