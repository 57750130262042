import React, { memo } from "react";
import PropTypes from "prop-types";
import { PosicoesDataGrid, RelatoriosDataGrid } from "../../organisms";
import { PageTemplate } from "../";

const PosicoesTemplate = memo((props) => {
  const { initialState, state, ...restDataGridProps } = props.dataGridProps;

  return (
    <PageTemplate
      title={props.title}
      subtitle={props.subtitle}
      scrollable={false}
    >
      <PosicoesDataGrid
        {...restDataGridProps}
        initialState={{
          ...initialState,
          density: "comfortable",
        }}
        state={state}
        onCreateClick={props.onCreateClick}
        onListClick={props.onListClick}
      />
    </PageTemplate>
  );
});

PosicoesTemplate.propTypes = {
  data: PropTypes.array,
  isFetching: PropTypes.bool,
  readData: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PosicoesTemplate;
