import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent } from "investira.react.components";
import { useSimulacoesContext } from "../../contexts/SimulacoesContext";
import withResponseHandling from "../../hoc/withResponseHandling";

import Carteira from "./Carteira";

const DialogCarteira = memo((props) => {
  const { state, actions } = useSimulacoesContext();
  const params = useParams();

  const isOpen = state.isDialogOpen && state.dialogType === "carteira";

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogContent sx={{ pt: 4 }}>
        <Carteira id={params.id} mode="edit" />
      </DialogContent>
    </Dialog>
  );
});

export default withResponseHandling(DialogCarteira);
