const PERIODICIDADE = {
  D: { id: "D", descricao: "Diária", index: 1 },
  S: { id: "S", descricao: "Semanal", index: 2 },
  M: { id: "M", descricao: "Mensal", index: 3 },
  A: { id: "A", descricao: "Anual", index: 4 },
  I: { id: "I", descricao: "Irregular", index: 5 },
  E: { id: "E", descricao: "Emissão", index: 6 },
  V: { id: "V", descricao: "Vencimento", index: 7 },
};

export default PERIODICIDADE;
