import {
  USER_LOADED,
  USER_LOGOUT,
  USER_USERNAME_CHANGED,
  USER_DEFAULT_ENTIDADE_CHANGED,
} from "../../const/actionsType";

const INITIAL_STATE = {
  name: "",
  name_first: "",
  name_last: "",
  name_middle: "",
  username: "",
  usuario_id: null,
};

const RESET_STATE = {
  ...INITIAL_STATE,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case USER_USERNAME_CHANGED:
      return {
        ...state,
        username: action.payload,
      };
    case USER_DEFAULT_ENTIDADE_CHANGED:
      const { default_entidade_id, entidade } = action.payload;
      return {
        ...state,
        default_entidade_id,
        entidade,
      };
    case USER_LOGOUT:
      return {
        ...RESET_STATE,
      };
    default:
      return state;
  }
};

export default userReducer;
