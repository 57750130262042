import React, { memo, useMemo } from "react";
import {
  Typography,
  Stack,
  Slider,
  useMediaQuery,
} from "investira.react.components";
import { validators } from "investira.sdk";

const Period = memo((props) => {
  const { framing, saldos, period, onChange } = props;

  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const periodSize = saldos.length;

  const marks = useMemo(() => {
    const xResultado = [];
    const xSize = 5;

    const xMarks = saldos.map(({ label, value }) => {
      return { label, value };
    });

    if (!isUpMd) {
      const xStep = xMarks.length / xSize;

      for (
        let i = 0;
        i < xMarks.length && xResultado.length < xSize;
        i += xStep
      ) {
        xResultado.push(xMarks[Math.floor(i)]);
      }
      return xResultado;
    }

    return xMarks;
  }, [saldos, isUpMd]);

  function valueLabelFormat(pValue, pMarks) {
    if (validators.isEmpty(pValue) || validators.isEmpty(pMarks)) {
      return "";
    }

    if (validators.isNull(pMarks[pValue])) {
      return "";
    }

    return `${pMarks[pValue].label}`;
  }

  return (
    <React.Fragment key={framing[0]}>
      <Stack>
        <Typography variant="caption" color="textPrimary" gutterBottom>
          Definir o período
        </Typography>
      </Stack>
      <Stack px={3} mb={3}>
        <Slider
          disabled={periodSize < 2}
          key={`${framing[0]}-slider`}
          id={`${framing[0]}-slider`}
          value={period}
          onChange={onChange}
          getAriaValueText={(xValue) => valueLabelFormat(xValue, saldos)}
          valueLabelFormat={(xValue) => valueLabelFormat(xValue, saldos)}
          valueLabelDisplay="auto"
          //shiftStep={30}
          size="small"
          step={1}
          marks={marks}
          min={0}
          max={periodSize - 1}
        />
      </Stack>
    </React.Fragment>
  );
});

export default Period;
