import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stack, Button, Icon, Divider } from "investira.react.components";

const BulkContextActions = memo((props) => {
  const { onDeleteClick } = props;

  return (
    <Stack justifyContent="center" sx={{ height: "40px" }}>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" spacing={2}>
          {onDeleteClick && (
            <Button
              disabled={props.disabled}
              variant="contained"
              color="error"
              startIcon={<Icon iconName="delete" size={16} />}
              size="small"
              sx={{ px: 2 }}
              onClick={onDeleteClick}
            >
              Excluir
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
});

BulkContextActions.propTypes = {
  disabled: PropTypes.bool,
};

export default BulkContextActions;
