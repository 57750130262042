import React, { memo } from "react";

// Context
const NavigationContext = React.createContext();

const initialState = {
  title: "",
  subtitle: "",
};

// Reducers
const reducers = (state = initialState, action) => {
  if (action.type === "TITLE_CHANGED") {
    return {
      ...state,
      title: action.payload,
    };
  }
  if (action.type === "SUBTITLE_CHANGED") {
    return {
      ...state,
      subtitle: action.payload,
    };
  }
};

// Dispatchs
const updateTitle = (pString) => ({
  type: "TITLE_CHANGED",
  payload: pString,
});

const updateSubtitle = (pString) => ({
  type: "SUBTITLE_CHANGED",
  payload: pString,
});

// Custom Hook
export const useNavigationContext = () => React.useContext(NavigationContext);

export const NavigationProvider = memo(({ children }) => {
  const [state, dispatch] = React.useReducer(reducers, initialState);

  const handleUpdateTitle = (pValue) => {
    dispatch(updateTitle(pValue));
  };

  const handleUpdateSubtitle = (pValue) => {
    dispatch(updateSubtitle(pValue));
  };

  return (
    <NavigationContext.Provider
      value={{ state, actions: { handleUpdateTitle, handleUpdateSubtitle } }}
    >
      {children}
    </NavigationContext.Provider>
  );
});

export default NavigationProvider;
