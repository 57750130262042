const OUTPUT = {
  duration: {
    id: "duration",
    output_id: "RPDUR",
    descricao: "Duration",
  },
  var: {
    id: "var",
    output_id: "RPVAR",
    descricao: "VaR",
  },
  enquadramento: {
    id: "enquadramento",
    output_id: "RPENQ",
    descricao: "Enquadramento",
  },
  raiox: {
    id: "raiox",
    output_id: "RPRAX",
    descricao: "Raio X",
  },
  rentabilidade: {
    id: "rentabilidade",
    output_id: "RPREN",
    descricao: "Rentabilidade",
  },

  stresstest: {
    id: "stresstest",
    output_id: "RPSTR",
    descricao: "Stress Test",
  },
  backtesting: {
    id: "backtesting",
    output_id: "RPBAC",
    descricao: "Back Testing",
  },
  fronteiraeficiente: {
    id: "fronteiraeficiente",
    output_id: "RPFEF",
    descricao: "Fronteira Eficiente",
  },
};

export default OUTPUT;
