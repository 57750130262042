import React, { memo } from "react";
import { Box, Typography } from "investira.react.components";
import { formats, dates } from "investira.sdk";
import { BaseTemplate } from "../../components/templates";
import packageJson from "../../../package.json";

const About = memo(() => {
  const xVersion = process.env.REACT_APP_VERSION;
  const xEnv = process.env.REACT_APP_ENV;
  const xBuildDate = formats.formatDateCustom(
    dates.toDate(packageJson.buildDate),
    "YYYYMMDD.HHmm"
  );

  return (
    <BaseTemplate title="Sobre" maxWidth="md">
      <Typography variant="h5" component="h2" color="textPrimary" gutterBottom>
        A Investira
      </Typography>
      <Typography variant="h6" component="h3" color="textPrimary" gutterBottom>
        Sistemas para gestão de investimentos
      </Typography>
      <Typography variant="body2" color="textPrimary" gutterBottom>
        Sempre inovando para que nossos clientes sintam-se seguros para atingir
        suas metas e ousados para buscar novos desafios.
      </Typography>
      <Typography variant="body2" color="textPrimary" gutterBottom>
        Vinte anos de experiência em desenvolvimento de sistemas para fundos de
        investimentos, empresas e instituições financeiras dos mais diversos
        portes como Fundos de Pensão, Bancos, Assets Managements e Family
        Offices.
      </Typography>
      <Box>
        <Typography variant="caption" color="textSecondary">
          Versão: {xVersion} Build: {xBuildDate}.{xEnv}
        </Typography>
      </Box>
    </BaseTemplate>
  );
});

export default About;
