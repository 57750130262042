import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { dates, validators } from "investira.sdk";
import {
  Stack,
  InputAdornment,
  Box,
  LoadingButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "investira.react.components";

import { VALIDATIONS } from "../../../../const";
import {
  IndicadorSelect,
  VaricaoAcumuladaSelect,
} from "../../../../components/organisms";
import {
  DatePicker,
  TextFieldCurrency,
  ExcelLikeInput,
} from "../../../../components/molecules";

const FatorForm = (props) => {
  const [minDateError, setMinDateError] = useState(null);

  Yup.addMethod(Yup.date, "methodDataInicio", function (pMessage) {
    return this.test("test-data-inicio", pMessage, function (value) {
      const { parent } = this;
      return dates.daysBetween(value, parent.data_fim) >= 1;
    });
  });

  Yup.addMethod(Yup.date, "methodDataFim", function (pMessage) {
    return this.test("test-data-fim", pMessage, function (value) {
      const { parent } = this;
      return dates.daysBetween(value, parent.data_inicio) <= 1;
    });
  });

  const VALIDATION_SCHEMA = Yup.object().shape({
    data_inicio: Yup.date()
      .typeError(`${VALIDATIONS.INVALID_DATE}`)
      .methodDataInicio('"Data Início" deve ser anterior "Data Fim"')
      .required(VALIDATIONS.REQUIRED),
    data_fim: Yup.date()
      .typeError(`${VALIDATIONS.INVALID_DATE}`)
      .methodDataFim('"Data Fim" deve ser posterior a "Data Início"')
      .required(VALIDATIONS.REQUIRED),
  });

  const { data_inicio, data_fim } = props.initialValues;

  const xInitialValues = {
    ...props.initialValues,
    data_inicio: dayjs(data_inicio),
    data_fim: dayjs(data_fim),
  };

  const formik = useFormik({
    initialValues: xInitialValues,
    validationSchema: VALIDATION_SCHEMA,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: (pValues, pActions) => {
      props.onSubmit && props.onSubmit(pValues, pActions, props.tipoId);
    },
  });

  const handleDateChange = (pFieldName, pFieldValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  const handleNumberChange = (pFieldName, pFieldValue) => {
    formik.setFieldValue(pFieldName, pFieldValue, true);
  };

  useEffect(() => {
    if (!validators.isEmpty(props.repeatValues)) {
      const { data_inicio, data_fim, ...restValues } = props.repeatValues;
      formik.setValues({
        data_inicio: dayjs(data_inicio),
        data_fim: dayjs(data_fim),
        ...restValues,
      });
    }
  }, [props.repeatValues]);

  return (
    <Box
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <Stack spacing={2}>
        <IndicadorSelect
          label={props.label}
          name="indicador_id"
          defaultValue={xInitialValues.indicador_id}
          tipoId={props.tipoId}
          onChange={formik.handleChange}
          value={formik.values.indicador_id}
          disabled={formik.isSubmitting}
        />
        <Stack
          direction={{ xs: "col", md: "row" }}
          gap={{ xs: 2, md: 1 }}
          //alignItems="flex-start"
        >
          <Stack flexGrow={1}>
            <DatePicker
              label="Data Início"
              name="data_inicio"
              onChange={(pValue) => {
                handleDateChange("data_inicio", pValue, formik.setFieldValue);
              }}
              maxDate={dayjs()}
              value={formik.values.data_inicio}
              slotProps={{
                textField: {
                  error: Boolean(formik.errors.data_inicio),
                  helperText: formik.errors.data_inicio,
                  disabled: formik.isSubmitting,
                  variant: "outlined",
                },
              }}
              fullWidth
            />
          </Stack>
          <Stack flexGrow={1}>
            <DatePicker
              label="Data Fim"
              name="data_fim"
              onChange={(pValue) => {
                handleDateChange("data_fim", pValue, formik.setFieldValue);
                setMinDateError(null);
              }}
              value={formik.values.data_fim}
              slotProps={{
                textField: {
                  error: Boolean(formik.errors.data_fim),
                  helperText: formik.errors.data_fim,
                  disabled: formik.isSubmitting,
                  variant: "outlined",
                },
              }}
              maxDate={dayjs()}
              fullWidth
            />
          </Stack>

          {props.tipoId === "J" && (
            <Stack flexGrow={1}>
              <ExcelLikeInput
                id="percentual_pos"
                name="percentual_pos"
                label="Percentual do Índice"
                variant="outlined"
                value={formik.values.percentual_pos}
                onChange={(_, pValue) =>
                  handleNumberChange(
                    "percentual_pos",
                    pValue,
                    formik.setFieldValue
                  )
                }
                decimal={4}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                error={Boolean(formik.errors.percentual_pos)}
                helperText={formik.errors.percentual_pos}
                disabled={formik.isSubmitting}
                fullWidth
              />
            </Stack>
          )}

          {props.tipoId === "C" && (
            <Stack flexGrow={1}>
              <ExcelLikeInput
                id="taxa_pre"
                name="taxa_pre"
                label="Taxa Pré"
                variant="outlined"
                value={formik.values.taxa_pre}
                onChange={(_, pValue) => {
                  handleNumberChange("taxa_pre", pValue);
                }}
                decimal={4}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                error={Boolean(formik.errors.taxa_pre)}
                helperText={formik.errors.taxa_pre}
                disabled={formik.isSubmitting}
                fullWidth
              />
            </Stack>
          )}

          <Stack flexGrow={1}>
            <TextFieldCurrency
              id="valor_correcao"
              name="valor_correcao"
              label="Valor a Corrigir"
              variant="outlined"
              value={formik.values.valor_correcao}
              placeholder={"0,00"}
              onChange={(_, pValue) =>
                handleNumberChange(
                  "valor_correcao",
                  pValue,
                  formik.setFieldValue
                )
              }
            />
          </Stack>

          {props.tipoId === "C" &&
            ["IPCA", "IGPM"].includes(formik.values.indicador_id) && (
              <>
                <Stack flexGrow={1}>
                  <VaricaoAcumuladaSelect
                    label={"Aniversário"}
                    name="dia_base"
                    defaultValue={"null"}
                    indicadorId={formik.values.indicador_id}
                    tipoId="C"
                    onChange={formik.handleChange}
                    value={formik.values.dia_base}
                    disabled={formik.isSubmitting}
                  />
                </Stack>
                {formik.values.dia_base !== "null" && (
                  <Stack flexGrow={1} sx={{ minWidth: "100px" }}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="projetada-label">
                        Projetada
                      </InputLabel>
                      <Select
                        labelId="projetada-label"
                        id="projetada"
                        value={formik.values.projetada}
                        label="Projetada"
                        name="projetada"
                        onChange={formik.handleChange}
                        disabled={props.disabled}
                      >
                        {[
                          { value: false, label: "Não" },
                          { value: true, label: "Sim" },
                        ].map((xItem) => {
                          return (
                            <MenuItem key={xItem.label} value={xItem.value}>
                              {xItem.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                )}
              </>
            )}
        </Stack>

        <Box
          sx={{
            textAlign: "right",
          }}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            loading={formik.isSubmitting}
            disabled={
              formik.isSubmitting || !formik.isValid || Boolean(minDateError)
            }
          >
            Calcular
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  );
};

FatorForm.propTypes = {
  label: PropTypes.string,
  tipoId: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

FatorForm.displayName = "FatorForm";

export default FatorForm;
